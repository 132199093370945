import produce from 'immer'

import { TaskStatuses } from '@ui/models'
import { TasksGetById } from '@ui/types'

import { gqlClient } from '.'
import {
  TasksGetByIdVariables,
  TasksSearchAllVariables,
  TasksSearchAll,
  TasksSearchAllQuery_data,
  TasksSearchAllQueryVariables,
  BlocksAll,
  BlocksAllVariables,
  TasksCreate_data,
  BlocksAll_data,
  BlockSchema,
} from './__generated__/types'
import { blockFragment, blocksGetAllQuery } from './blocks.gql'
import { tasksSearchAll, tasksGetById } from './tasks.gql'

export const taskRemoveCache = (
  taskId: string,
  search: TasksSearchAllQueryVariables,
  block?: BlocksAll_data,
) => {
  const cache = gqlClient.core.cache

  try {
    const data = cache.readQuery<TasksSearchAll, TasksSearchAllVariables>({
      query: tasksSearchAll,
      variables: search,
    })

    if (data) {
      cache.writeQuery<TasksGetById, TasksGetByIdVariables>({
        query: tasksGetById,
        variables: {
          id: taskId,
        },
        data: {
          data: null,
        },
      })

      cache.writeQuery<TasksSearchAll | TasksSearchAllVariables>({
        query: tasksSearchAll,
        variables: search,
        data: produce(data, (draft) => {
          const index = draft!.data.findIndex((e) => e?.id === taskId)
          if (index >= 0) {
            draft!.data.splice(index, 1)
          }
        }),
      })
    }
  } catch (err) {
    console.error('"taskRemoveCache" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const taskAddCache = (newTask: TasksCreate_data, search: TasksSearchAllQueryVariables) => {
  const cache = gqlClient.core.cache

  try {
    const data = cache.readQuery<TasksSearchAll, TasksSearchAllVariables>({
      query: tasksSearchAll,
      variables: search,
    })
    const existingItem = data?.data.find((item) => item.id === newTask.id)
    const newData = data || { data: [] }
    if (!existingItem && newData) {
      cache.writeQuery<TasksSearchAll, TasksSearchAllVariables>({
        query: tasksSearchAll,
        variables: search,
        data: produce(newData, (draft) => {
          draft!.data.push(newTask)
        }),
      })
    }
  } catch (err) {
    console.error('"taskAddCache" fn is crashed on operation: ".writeQuery"', err)
  }
}

// -------------------------------
// working with tasks into blockAll
// -------------------------------

export const taskRemoveInBlock = (
  newTask: TasksSearchAllQuery_data,
  sectionId: string | null | undefined,
  blockId: string | null | undefined,
) => {
  const cache = gqlClient.core.cache

  try {
    // remove task from preview block
    if (sectionId && blockId) {
      const dataBlocks = cache.readQuery<BlocksAll, BlocksAllVariables>({
        query: blocksGetAllQuery,
        variables: {
          sectionId,
        },
      })
      if (dataBlocks) {
        cache.writeQuery<BlocksAll, BlocksAllVariables>({
          query: blocksGetAllQuery,
          variables: {
            sectionId,
          },
          data: produce(dataBlocks, (draft) => {
            const index = draft!.data.findIndex((e) => e?.uuid === blockId)
            const isTaskStatusDone = newTask.status === TaskStatuses.done
            if (!isTaskStatusDone) {
              draft!.data[index].tasksCount = dataBlocks.data[index].tasksCount - 1
            }
          }),
        })
      }
    }
  } catch (err) {
    console.error('"taskRemoveInBlock" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const taskAddInBlock = (
  newTask: TasksSearchAllQuery_data,
  sectionId: string | null | undefined,
  blockId: string | null | undefined,
) => {
  const cache = gqlClient.core.cache
  try {
    // add task to preview block
    if (sectionId && blockId) {
      const dataBlocks = cache.readQuery<BlocksAll, BlocksAllVariables>({
        query: blocksGetAllQuery,
        variables: {
          sectionId,
        },
      })
      if (dataBlocks) {
        cache.writeQuery<BlocksAll, BlocksAllVariables>({
          query: blocksGetAllQuery,
          variables: {
            sectionId,
          },
          data: produce(dataBlocks, (draft) => {
            const index = draft!.data.findIndex((e) => e?.uuid === blockId)
            draft!.data[index].tasksCount = dataBlocks.data[index].tasksCount + 1
          }),
        })
      }
    }
  } catch (err) {
    console.error('"taskAddInBlock" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const taskUpdateInBlock = (
  newTask: TasksSearchAllQuery_data,
  sectionId?: string | null | undefined,
  blockId?: string | null | undefined,
  search?: TasksSearchAllVariables,
) => {
  const cache = gqlClient.core.cache

  try {
    // update task to preview block
    if (sectionId && blockId && search) {
      const dataTasks = cache.readQuery<TasksSearchAll, TasksSearchAllVariables>({
        query: tasksSearchAll,
        variables: {
          ...search,
        },
      })
      const tasksLength = dataTasks?.data.filter((task) => !task.isDone).length

      const updatedBlock =
        cache.readFragment<BlockSchema>({
          id: cache.identify({
            __typename: 'Block',
            uuid: blockId,
          }),
          fragment: blockFragment,
          fragmentName: 'BlockSchema',
        }) || undefined

      if (updatedBlock) {
        cache.writeFragment({
          fragment: blockFragment,
          id: cache.identify({
            __typename: 'Block',
            uuid: blockId,
          }),
          fragmentName: 'BlockSchema',
          data: {
            ...updatedBlock,
            tasksCount: tasksLength || 0,
          },
        })
      }
    }
  } catch (err) {
    console.error('"taskUpdateInBlock" fn is crashed on operation: ".writeQuery"', err)
  }
}
