import numeral from 'numeral'

export const replaceSpacesBudget = (budget: string | number | null) => {
  return budget ? budget.toString().replace(/\s/g, '') : '0'
}

export const budgetFormat = (value: number | string | null) => {
  const valueInput = replaceSpacesBudget(value)
  const matchValue = valueInput.match(/^[0-9]{1,16}(\.[0-9]{0,2})?$/)
  const val = matchValue ? matchValue[0] : valueInput.slice(0, -1)

  let res = numeral(val).format('0,0[.][00]')
  if (valueInput.slice(-1) === '.' && valueInput.length !== 16) {
    res += '.'
  }
  if (
    valueInput.indexOf('.') &&
    valueInput[valueInput.indexOf('.') + 1] === '0' &&
    valueInput.slice(-1) === '0'
  ) {
    res += '.0'
  }
  if (valueInput === '0') {
    res = '0'
  }
  const resFormated = res.replace(/,/g, ' ')
  return resFormated
}
