import { useMemo } from 'react'

import { gqlActions } from '@ui/gql'

import { getDeepValues } from './../../utils/object'
import { PERMISSION } from './constants'

type PermissionObject = { [key: string]: boolean }

const ALL_KEYS = getDeepValues(PERMISSION)

const grantPermission = (data: PermissionObject, permissions: any) => {
  getDeepValues(permissions).forEach((key) => {
    data[key] = true
  })
}

const stab = ALL_KEYS.reduce((acc, key) => ({ ...acc, [key]: false }), {} as PermissionObject)
grantPermission(stab, PERMISSION.project)

const usePermissions = (permissionKey: string): boolean => {
  const { profile } = gqlActions.useMyInfo()
  const convertArrayToObject =
    profile?.permissions.reduce((acc, item) => {
      return { ...acc, [item.type]: true }
    }, {} as PermissionObject) || {}

  const mergeObjects = useMemo(
    () => ({
      ...stab,
      ...convertArrayToObject,
    }),
    [stab, convertArrayToObject],
  )

  return mergeObjects[permissionKey] || false
}

export { usePermissions }
