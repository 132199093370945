import gql from 'graphql-tag'

import { avatarFragment } from './avatar.gql'
import { permissionListFragment } from './permission.qgl'
import { kUserInfoFragment } from './profile.gql'
import { rolesAllFragment } from './roles.gql'

/**
 * Мы не можем экспортировать GQL из .apollo файлов
 * что бы не засорять скоуп глобальными кверями в gqlActions
 */

export const employeeSmallFragment = gql`
  fragment EmployeeSmallSchema on Employee {
    id
    name
    username
    kuserId
    updatedAt
  }
`

export const employeeFragment = gql`
  fragment EmployeeSchema on Employee {
    ...EmployeeSmallSchema
    avatar {
      ...AvatarSchema
    }
  }
  ${avatarFragment}
  ${employeeSmallFragment}
`

export const employeeInfoFragment = gql`
  fragment EmployeeInfoSchema on Employee {
    description
    language
    status
    registrated
    edited
    position
    companyPhone
    phone
    address
    email
    skype
    whatsapp
    telegram
    portfolio
    twitter
    linkedin
    facebook
  }
`

export const employeeListFragment = gql`
  fragment EmployeeListSchema on Employee {
    ...EmployeeSchema
    roleId
    companyId
    isActive
    isArchived
    kuserId
    kUser {
      ...kUserInfoSchema
    }
    roles {
      ...RolesAllSchema
      permission {
        ...PermissionListSchema
      }
    }
    projects {
      id
    }
    tags
    updatedAt
    createdAt
  }
  ${employeeFragment}
  ${kUserInfoFragment}
  ${rolesAllFragment}
  ${permissionListFragment}
`

export const employeesGetAllQuery = gql`
  query EmployeesAll($companyId: String!, $search: EmployeeSearch) {
    data: employeesAll(companyId: $companyId, search: $search) {
      employees {
        ...EmployeeListSchema
      }
      limit
      offset
      total
    }
  }
  ${employeeListFragment}
`

export const employeesGetByIdQuery = gql`
  query EmployeesGetById($id: String!, $companyId: String!) {
    data: employeesGetById(id: $id, companyId: $companyId) {
      ...EmployeeListSchema
      ...EmployeeInfoSchema
    }
  }
  ${employeeInfoFragment}
  ${employeeListFragment}
`

export const employeesSubscribe = gql`
  subscription EmployeesSubscribe($companyId: String!) {
    data: employee(companyId: $companyId) {
      type
      employee {
        ...EmployeeInfoSchema
        ...EmployeeListSchema
      }
    }
  }
  ${employeeInfoFragment}
  ${employeeListFragment}
`
