import gql from 'graphql-tag'

export const tagsFragment = gql`
  fragment TagSchema on Tag {
    id
    name
    parentId
    employee {
      id
      roleId
    }
    parent {
      name
      companyId
    }
    project {
      id
    }
  }
`

export const tagsGetByName = gql`
  query TagsGetByName($name: String!, $companyId: String!) {
    data: tagsGetByName(name: $name, companyId: $companyId) {
      ...TagSchema
    }
  }
  ${tagsFragment}
`

export const tagsGetAllQuery = gql`
  query TagsAll($companyId: String!) {
    data: tagsAll(companyId: $companyId) {
      ...TagSchema
    }
  }
  ${tagsFragment}
`
