import { nanoid } from 'nanoid'
import theme from 'theme'

import { SectionBlockType } from '@ui/models'

export const emptyLayoutTemplate = (id: string): SectionBlockType => {
  return {
    __typename: 'Block',
    elements: {},
    type: 'absolute',
    options: {
      tabsIndex: null,
      backgroundColor: '#fff',
      backgroundImage: null,
      indents: null,
      tabs: null,
      __typename: 'BlockOptions',
      tabsPosition: null,
      borderRadius: 10,
      questionFeedback: null,
    },
    files: [],
    name: 'Пустой',
    mode: 'view',
    uuid: nanoid(),
    version: null,
    sectionId: '',
    test: null,
    tasksCount: 0,
    schema: [[[id]]],
    createdAt: Date.now().toString(),
    updatedAt: Date.now().toString(),
  }
}

export const layoutSize = {
  width: theme.editorBlockW,
  height: theme.editorBlockH,
}
