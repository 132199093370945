import React from 'react'

const ProjectIcon = ({
  accent = '#0061FF',
  accentWr = '#B6D2EA',
  style,
}: {
  accent?: string
  accentWr?: string
  style?: React.CSSProperties
}) => {
  return (
    <svg height='1em' style={style} viewBox='0 0 16 16' width='1em'>
      <g fillRule='evenodd'>
        <path d='M2.494 10.25l5.487 3.495 5.488-3.496-5.488-3.496z' fill={accentWr} />
        <path d='M2.494 5.73L7.98 9.226l5.49-3.496-5.489-3.496z' fill={accent} />
      </g>
    </svg>
  )
}

export { ProjectIcon }
