import moment from 'moment'

import { TaskType } from '@ui/models'
import { TasksSearchAll_data } from '@ui/types'

const FILTER_BY = {
  deadline: 'deadline',
  creation: 'creation',
  userId: 'userId',
  type: 'type',
  owner: 'owner',
  search: 'search',
}

type ActiveFilters = 'deadline' | 'creation' | 'userId' | 'type' | 'owner' | 'search'

export const tasksFiter = ({
  tasksAll,
  ownersIds,
  usersIds,
  types,
  createFrom,
  createTo,
  deadlineFrom,
  deadlineTo,
  search,
}: {
  tasksAll: (TaskType | null)[]
  ownersIds?: string[]
  usersIds?: string[]
  types?: string | string[] | null
  createFrom?: string | string[] | null
  createTo?: string | string[] | null
  deadlineFrom?: string | string[] | null
  deadlineTo?: string | string[] | null
  search?: string | undefined
}) => {
  const byDeadline = {
    name: FILTER_BY.deadline,
    value: deadlineTo && deadlineFrom,
  }
  const byCreation = {
    name: FILTER_BY.creation,
    value: createTo && createFrom,
  }
  const byUserId = {
    name: FILTER_BY.userId,
    value: usersIds && usersIds.length,
  }
  const byType = {
    name: FILTER_BY.type,
    value: types === 'task' || types === 'bug' || types === 'isArchived',
  }
  const byOwner = {
    name: FILTER_BY.owner,
    value: ownersIds && ownersIds.length,
  }
  const bySearch = {
    name: FILTER_BY.search,
    value: search && typeof search === 'string',
  }

  const taskSearch = search && search.toLowerCase()

  const activeFilters = [byDeadline, byCreation, byUserId, byType, byOwner, bySearch].reduce(
    (acc, { value, name }) => {
      value && acc.push(name as ActiveFilters)

      return acc
    },
    [] as ActiveFilters[],
  )

  const tasks = tasksAll.filter((task) => {
    const resultFilters = {
      [FILTER_BY.deadline]:
        byDeadline &&
        Number(task?.deadline) >= Number(moment(deadlineFrom).startOf('day')) &&
        Number(task?.deadline) <= Number(moment(deadlineTo).endOf('day')),
      [FILTER_BY.creation]:
        byCreation &&
        Number(moment(task?.createdAt).startOf('day')) >=
          Number(moment(createFrom).startOf('day')) &&
        Number(moment(task?.createdAt).endOf('day')) <= Number(moment(createTo).endOf('day')),
      [FILTER_BY.userId]: usersIds?.find((id: string) => id === task?.assigned!.id),
      [FILTER_BY.type]: task?.type === types || (types === 'isArchived' && task?.isArchived),
      [FILTER_BY.owner]: ownersIds?.find((id: string) => id === task?.owner!.id),
      [FILTER_BY.search]: (taskSearch && task!.name!.toLowerCase().includes(taskSearch)) || false,
    }

    return activeFilters.every((filterName) => resultFilters[filterName] || false)
  })

  return tasks as TasksSearchAll_data[]
}
