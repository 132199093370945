const i18nRu = {
  translations: {
    blocks: 'Сетка',
    bars: 'Список',
    collage: 'Коллаж',
    showAs: 'Отобразить как',
    images: 'Изображения',
    videos: 'Видео',
    music: 'Музыка',
    documents: 'Документы',
    other: 'Прочее',
    view: 'Проcмотр',
    fileName: 'Имя',
    fileSize: 'Размер',
    fileUpload: 'Загрузка файла',
    download: 'Скачать',
    close: 'Закрыть',
    delete: 'Удалить',
    main: 'Основные',
    quickSearch: 'Быстрый поиск',
    apply: 'Выбрать',
    mediaLibrary: 'Медиатека',
  },
}

export default i18nRu
