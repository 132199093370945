import { nanoid } from 'nanoid'

import { SectionBlockType } from '@ui/models'

export const landingLayoutTemplate = (id: string): SectionBlockType => {
  return {
    __typename: 'Block',
    elements: {
      [id]: {
        id: id,
        options: {},
        type: 'empty',
        containerOptions: {
          backgroundColor: '#fff',
        },
      },
    },
    type: 'landing',
    options: {
      tabsIndex: null,
      __typename: 'BlockOptions',
      backgroundColor: '#fff',
      backgroundImage: null,
      indents: null,
      tabs: null,
      tabsPosition: null,
      borderRadius: 10,
      questionFeedback: null,
    },
    files: [],
    name: 'Лендинг',
    mode: 'view',
    uuid: nanoid(),
    version: 0,
    test: null,
    tasksCount: 0,
    sectionId: '',
    schema: [[[id]]],
    createdAt: Date.now().toString(),
    updatedAt: Date.now().toString(),
  }
}
