import { useParams } from 'react-router'

import { EditorParams } from '@ui/models'

import { useCompanyGetMyInfo } from './companies.apollo'
import { useGetUserInfo } from './profile.apollo'

export const useMyInfo = () => {
  const { data: currentUserData, loading: loadingCurrentUser } = useGetUserInfo()

  const params = useParams<EditorParams>()
  const { data: companyProfileData, loading: loadingInfo } = useCompanyGetMyInfo(
    params.companyId || '',
  )

  const currentUser = currentUserData?.data
  const companyProfile = companyProfileData?.data

  return {
    profile: companyProfile,
    user: currentUser,
    loading: loadingInfo || loadingCurrentUser,
  }
}
