import { onError } from '@apollo/client/link/error'
import { notification } from 'antd'

const errorMessages = {
  USERNAME_EXIST: 'Имя пользователя существует',
  USER_DOESNT_EXIST: 'Пользователь не существует',
  NETWORK_ERROR: 'Нет подключения к серверу',
  TOKEN_DOESNT_EXIST: 'Токен не существует',
  EMAIL_DOESNT_MATCH: 'Неверный Email',
  USER_NOT_FOUND: 'Пользователь не найден',
  TOKEN_DOESNT_SAVE: 'Не удалось сохранить токен',
  MESSAGE_DOESNT_SENT: 'Не удалось отправить сообщение',
  CANT_CREATE_USER: 'Не удалось создать пользователя',
  TOKEN_INVALID: 'Токен устарел',
  PASSWORD_INVALID: 'Неправильный пароль',
  CANT_CHANGE_PASSWORD: 'Не удалось изменить пароль',
  CANT_BLOCK_USER: 'Не удалось удалить пользователя',
  CANT_RECOVER_PASSWORD: 'Нельзя востановить пароль',
  PASSWORD_OR_EMAIL_INVALID: 'Пароль или почта, неверны',
  PASSWORD_OR_EMAIL_EXIST: 'Пароль или почта, не совпадают',
  DOESNT_AUTH: 'Не удалось авторизовать',
  LOGIN_OR_PASSWORD_DOESNT_EXIST: 'Логин или пароль, не совпадают',
  EMAIL_EXIST: 'Почта занята',
  COMPANY_ALREADY_EXIST: 'Компания уже существует',
  COMPANY_DOESNT_EXIST: 'Компания не существует',
  'You cannot delete yourself': 'Вы не можете удалить себя',
  'Cannot delete owner': 'Нельзя удалить пользователя с ролью "Владелец"',
  PROJECT_GROUP_NAME_EXIST: 'Группа с таким названием уже существует',
  EMAIL_INVALID: 'Неправильный формат E-mail',
  COMPANY_LEGAL_ID_ALREADY_EXIST: 'такое УНП уже есть в системе',
  CANT_CREATE_TAG: 'Такой тег уже существует',
  'Employee already exists in company': 'Этот email уже приглашен в компанию',
  CANT_CREATE_ROLE: 'Роль с таким именем уже существует',
  CANT_CREATE_EMPLOYEE: 'Невозможно создать пользователя',
  PASSWORDS_DOESNT_EQUAL: 'Пароль не правильный, попробуйте снова',
  EMPLOYEE_DOESNT_EXIST: 'Пользователь не существует',
  EMAIL_ALREADY_EXIST: 'Почта уже занята',
  ROLE_ALREADY_EXIST: 'Роль с таким названием уже есть',
  ROLE_DOESNT_EXIST: 'Роль не существует',
  ROLE_CANNOT_MODIFY: 'Роль нельзя изменить',
  COMPANY_DOESNT_FOUND: 'Такой компании не существует',
  COMPANY_NAME_ALREADY_EXIST: 'Название компании уже занято',
  PROJECT_DOESNT_EXIST: 'Такой проект не существует',
  PROJECT_DOESNT_FOUND: 'Проект не найден',
  PROJECT_NAME_ALREADY_EXIST: 'Проект с таким именем уже существует',
  PROJECT_GROUP_DOESNT_EXIST_IN_COMPANY: 'Группа не существует в рамках компании',
  PROJECT_GROUP_HAS_NESTED_PROJECT_GROUP: 'Группа содержит вложенные подгруппы',
  PROJECT_GROUP_HAS_NESTED_PROJECTS: 'Группа содержит вложенные проекты',
  PROJECT_GROUP_WITH_NAME_ALREADY_EXIST: 'Группа с таким именем уже существует',
  SECTION_DOESNT_EXIST: 'Такой секции не существует',
  RECOVER_PASSWORD: 'Пароль востановлен',
  CHANGE_PASSWORD: 'Пароль изменен',
  AT_LEAST_ONE_OWNER: 'Необходим как минимум один владелец компании',
  CANT_TAKE_USER: 'Не удалось получить пользователя',
  CANT_REGISTRATE_USER: 'Не удалось зарегистрировать пользователя',
  CANT_CONFIRM_USER: 'Не удалось подтвердить регистрацию',
  CANT_LOGIN_USER: 'Невозможно авторизовать',
  CANT_SEND_INVITE_FOR_USER: 'Невозможно отправить приглашение пользователю',
  CANT_ACCEPT_INVITE: 'Невозможно подтвердить приглашение',
  CANT_CHANGE_USERNAME: 'Невозможно подтвердить username',
  CANT_CHANGE_EMAIL: 'Невозможно изменить почту',
  CANT_SEND_LINK_FOR_RECOVER_PASSWORD: 'Невозможно отправить ссылку на востановление пароля',
  CANT_SEND_LINK_FOR_CHANGE_PASSWORD: 'Невозможно отправить ссылку на изменение пароля',
  CANT_CREATE_COMPANY: 'Невозможно создать компанию',
  CANT_UPDATE_COMPANY: 'Невозможно обновить компанию',
  CANT_DELETE_COMPANY: 'Невозможно удалить компанию',
  CANT_FORCE_DELETE_COMPANY: 'Невозможно принудительно удалить компанию',
  CANT_CREATE_BLOCK: 'Невозможно создать блок',
  CANT_UPDATE_BLOCK: 'Невозможно обновить блок',
  CANT_CREATE_SECTION: 'Невозможно создать раздел',
  CANT_UPDATE_SECTION: 'Невозможно обновить раздел',
  CANT_UPDATE_EMPLOYEE: 'Невозможно обновить пользователя',
  CANT_DELETE_EMPLOYEE: 'Невозможно удалить пользователя',
  CANT_FORCE_DELETE_EMPLOYEE: 'Невозможно принудительно удалить пользователя',
  CANT_CREATE_PROJECT: 'Невозможно создать проект',
  CANT_UPDATE_PROJECT: 'Невозможно обновить проект',
  CANT_FORCE_DELETE_PROJECT: 'Невозможно принудительно удалить проект',
  CANT_DELETE_PROJECT: 'Невозможно удалить проект',
  CANT_DELETE_FILES_META: 'Невозможно удалить файл',
  FILEMETA_DOESNT_EXIST: 'Файл не существует',
  CANT_CREATE_PROJECT_GROUP: 'Невозможно создать группу',
  CANT_DELETE_PROJECT_GROUP: 'Невозможно удалить группу',
  CANT_FORCE_DELETE_PROJECT_GROUP: 'Невозможно принудительно удалить группу',
  CANT_DELETE_ROLE: 'Невозможно удалить роль',
  CANT_FORCE_DELETE_ROLE: 'Невозможно принудительно удалить роль',
  CANT_SET_ROLE: 'Невозможно создать роль',
  CANT_UPDATE_ROLE: 'Невозможно обновить роль',
  TAG_WITH_NAME_ALREADY_EXIST: 'Тег с таким именем уже создан',
  TAG_DOESNT_EXIST: 'Тег не существует',
  CANT_UPDATE_TAG: 'Невозможно обновить тег',
  CANT_DELETE_TAG: 'Невозможно удалить тег',
  CANT_GET_ACCESS_TO_COMPANY: 'Нет прав на доступ к компании',
  CANT_GRANT_PRIVILEGE: 'Невозможно назначить привелегии',
  CANT_GRANT_ACCESS_TO_PROJECT: 'Невозможно получить доступ к проекту',
  CANT_DELETE_SECTION: 'Невозможно удалить раздел',
  DOESNT_HAVE_PERMISSION: 'Нет прав',
  CANT_CREATE_MULTY_BLOCKS: 'Невозможно создать много блоков',
  TOO_MANY_CHARS: 'Слишком длинное слово',
  NEED_DIFFERENT_ROLES: 'Нужны разные роли',
  CANT_TAKE_REGISTRATE_USER: 'Пользователь с таким именем уже есть',
  CANT_RETURN_COMPANIES: 'Невозможно вернуть компании',
  GROUP_WITH_NAME_ALREADY_EXIST: 'Папка с таким названием уже есть',
  FILE_META_GROUP_COLLISION: 'Невозможно переместить во вложенную папку',
  CANT_MOVED_FILE_META_GROUP: 'Невозможно переместить папку',
  CANT_DELETE_FILE_META_GROUP: 'Невозможно удалить папку',
  CANT_CREATE_FILE_META_GROUP: 'Невозможно загрузить папку',
  CANT_CREATE_FILE: 'Невозможно загрузить файл',
  CANT_UPDATE_FILE: 'Невозможно обновиить файл',
  CANT_DELETE_FILE: 'Невозмножно удалить файл',
  'user from token doesnt found': 'Сесссия закончилась',
  FILE_META_GROUP_DOESNT_EXIST_IN_COMPANY: 'Такой папки или файла не существует в компании',
  'Variable "$password" of required type "String!" was not provided.': 'Заполните все поля',
  "PASSWORDS_DOSN'T_EQUAL": 'Неверный пароль',
  'Variable "$data" got invalid value {}; Field "name" of required type "String!" was not provided.':
    'Заполните все поля',
} as { [key: string]: string }

const errorRedirect = [
  errorMessages.COMPANY_DOESNT_EXIST,
  errorMessages.PROJECT_GROUP_DOESNT_EXIST_IN_COMPANY,
  errorMessages.SECTION_DOESNT_EXIST,
  errorMessages.DOESNT_HAVE_PERMISSION,
]

const logout = () => {
  window.location.hash = '#/auth/login'
  window.localStorage.removeItem('token')
}

// защищает от красного экрана смерти в реакте
// https://www.apollographql.com/docs/react/api/link/apollo-link-error/
const ignoreError = (response?: any) => {
  response && (response.errors = null)
}

const validIdFromString = (str?: string) => {
  return (str || '').replace(/\W/g, '_').toLowerCase() //cleanedId is "What_ever_your_id_is__________"
}

const dedupeErrors = (message: string, cb: (id: string) => void) => {
  const id = validIdFromString(message)

  let isVisible = id === ''
  if (!isVisible) {
    const el = document.querySelector(`.${id}`)
    isVisible = el === null
  }
  if (isVisible) {
    cb(id)
  }
}

export const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const { response } = operation.getContext()

  if (response?.status === 401) {
    ignoreError(response)
    logout()
    return
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // известные ошибки
      if (errorMessages[message]) {
        dedupeErrors(message, (id) => {
          notification.error({
            message: errorMessages[message],
            className: id,
            duration: null,
          })
        })

        // redirect subscribtions
        if (errorRedirect.includes(errorMessages[message])) {
          ignoreError(response)
          window.location.hash = '#/app/h'
        }
        return
      }

      // TODO: это условие под вопросом, возможно неактуальное
      if (message === 'Cannot return null for non-nullable field Query.getKUser.') {
        ignoreError(response)
        logout()
      } else {
        // неизвестные ошибки
        ignoreError(response)
        dedupeErrors(message, (id) => {
          notification.error({
            message: `[GraphQL error]`,
            duration: null,
            className: id,
            description: `Message: ${message}, Location: ${JSON.stringify(
              locations,
            )}, Path: ${path}`,
          })
        })
      }
    })
  }

  if (networkError && networkError.message === 'Failed to fetch') {
    ignoreError(response)
    dedupeErrors('NETWORK_ERROR', (id) => {
      notification.error({
        message: errorMessages['NETWORK_ERROR'],
        className: id,
        duration: 5,
      })
    })
  }

  return
})
