import gql from 'graphql-tag'

import { avatarFragment } from './avatar.gql'

export const kUserInfoFragment = gql`
  fragment kUserInfoSchema on KUser {
    id
    name
    username
    country
    city
    isActive
    isArchived
    createdAt
    updatedAt
    description
    language
    position
    skype
    whatsapp
    phone
    address
    email
    telegram
    portfolio
    twitter
    facebook
    linkedin
    other
  }
`

export const getUser = gql`
  query getKUser {
    data: getKUser {
      avatar {
        ...AvatarSchema
      }
      ...kUserInfoSchema
    }
  }
  ${avatarFragment}
  ${kUserInfoFragment}
`
