import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client'

import {
  TagsAll,
  TagsGetById,
  TagsCreate,
  TagsCreateVariables,
  TagsUpdateById,
  TagsUpdateByIdVariables,
  TagsDeleteById,
  TagsDeleteByIdVariables,
  TagsGetByName,
  TagsForceDeleteById,
  TagsForceDeleteByIdVariables,
} from '@ui/types'

import { tagsAddCache, tagsRemoveCache, tagsUpdateCache } from './tags.cache'
import { tagsFragment, tagsGetAllQuery, tagsGetByName } from './tags.gql'

export const useTagsGetAll = (companyId: string) =>
  useQuery<TagsAll>(tagsGetAllQuery, {
    variables: {
      companyId,
    },
    fetchPolicy: 'cache-and-network',
    onError: (err) => console.error('"useTagsGetAll" fn is crashed on operation: "useQuery"', err),
  })

export const useTagsGetAllLazy = (companyId: string) =>
  useLazyQuery<TagsAll>(tagsGetAllQuery, {
    variables: {
      companyId,
    },
    fetchPolicy: 'cache-and-network',
    onError: (err) =>
      console.error('"useTagsGetAllLazy" fn is crashed on operation: "useLazyQuery"', err),
  })

const tagsGetById = gql`
  query TagsGetById($id: String!, $companyId: String!) {
    data: tagsGetById(id: $id, companyId: $companyId) {
      id
      name
      parentId
    }
  }
`

export const useTagsGetById = (id: string, companyId: string) =>
  useLazyQuery<TagsGetById>(tagsGetById, {
    variables: {
      id,
      companyId,
    },
  })

export const useTagsGetByName = (variables: any) => {
  return useQuery<TagsGetByName>(tagsGetByName, {
    fetchPolicy: 'cache-and-network',
    variables,
    onError: (err) =>
      console.error('"useTagsGetByName" fn is crashed on operation: "useQuery"', err),
  })
}

export const useTagsCreate = (companyId: string) =>
  useMutation<TagsCreate, TagsCreateVariables>(
    gql`
      mutation TagsCreate($companyId: String!, $data: TagInput!) {
        data: tagsCreate(companyId: $companyId, data: $data) {
          ...TagSchema
        }
      }
      ${tagsFragment}
    `,
    {
      update: (cache, { data: item }) => {
        if (item) {
          tagsAddCache(item?.data, companyId)
        }
      },
      onError: (err) =>
        console.error('"useTagsCreate" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useTagsUpdate = (companyId: string) =>
  useMutation<TagsUpdateById, TagsUpdateByIdVariables>(
    gql`
      mutation TagsUpdateById($idTag: String!, $dataTag: TagInput!, $companyId: String!) {
        data: tagsUpdateById(id: $idTag, data: $dataTag, companyId: $companyId) {
          ...TagSchema
        }
      }
      ${tagsFragment}
    `,
    {
      update: (cache, { data: item }) => {
        if (item) {
          tagsUpdateCache(item?.data, companyId)
        }
      },
      onError: (err) =>
        console.error('"useTagsUpdate" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useTagsDelete = (companyId: string) =>
  useMutation<TagsDeleteById, TagsDeleteByIdVariables>(
    gql`
      mutation TagsDeleteById($id: String!, $companyId: String!) {
        data: tagsDeleteById(id: $id, companyId: $companyId) {
          id
          errors {
            type
            items {
              id
              name
            }
          }
        }
      }
    `,
    {
      update: (cache, { data: item }) => {
        if (item && item?.data && !item?.data.errors?.length) {
          tagsRemoveCache(item?.data.id, companyId)
        }
      },
      onError: (err) =>
        console.error('"useTagsDelete" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useTagsForceDeleteById = (companyId: string) =>
  useMutation<TagsForceDeleteById, TagsForceDeleteByIdVariables>(
    gql`
      mutation TagsForceDeleteById($id: String!, $companyId: String!) {
        data: tagsForceDeleteById(id: $id, companyId: $companyId) {
          id
          errors {
            type
            items {
              id
              name
            }
          }
        }
      }
    `,
    {
      update: (cache, { data: item }) => {
        if (item && item?.data) {
          tagsRemoveCache(item?.data.id, companyId)
        }
      },
      onError: (err) =>
        console.error('"useTagsForceDeleteById" fn is crashed on operation: "useMutation"', err),
    },
  )
