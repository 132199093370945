import {
  TasksSearchAll_data,
  TasksSearchAll_data_assigned,
  TasksSearchAll_data_employee,
} from '@ui/types'

export enum TaskStatuses {
  done = 'DONE',
  new = 'NEW',
  pending = 'PENDING',
  negotiations = 'NEGOTIATIONS',
  inprogress = 'IN_PROGRESS',
}

export type TaskType = TasksSearchAll_data
export type TaskEmployeeType = TasksSearchAll_data_employee
export type TaskAssignedType = TasksSearchAll_data_assigned
