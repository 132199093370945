import React, { useMemo } from 'react'
import { useLocation, useParams } from 'react-router'

import {
  CSKey,
  CSGit,
  CSUser,
  CSTasks,
  CSFolderCloud,
  CSCourses,
  CSDashboard,
  CSSkils,
} from '@ui/icons'
import { EditorParams, NavRoutes } from '@ui/models'

import { permissionConts, useCheckPermission } from './constansPermissions'

export const useNavigationRoutes = (): NavRoutes[] => {
  const params = useParams<EditorParams>()
  const tageView = useCheckPermission(permissionConts.TAG_VIEW)
  const resourceView = useCheckPermission(permissionConts.FILE_ALL_ACCESS)
  const roleView = useCheckPermission(permissionConts.ROLE_VIEW)

  return useMemo(
    () => [
      {
        parent: {
          name: 'Компания',
          key: `/app/${params.companyId}`,
        },
        keys: ['projects', 'sources', 'tags', 'trello'],
        items: [
          {
            name: 'Аналитика',
            key: `/app/${params.companyId}/dashboard`,
            icon: <CSDashboard />,
            disabled: true,
            id: 'analytics',
          },
          {
            name: 'Каталог',
            key: `/app/${params.companyId}/projects`,
            icon: <CSCourses />,
            id: 'projects',
          },
          {
            name: 'Библиотека',
            key: `/app/${params.companyId}/sources`,
            disabled: !resourceView,
            icon: <CSFolderCloud />,
            id: 'sources',
          },
          {
            name: 'Задачи',
            key: `/app/${params.companyId}/trello`,
            disabled: !tageView,
            icon: <CSTasks />,
            id: 'trello',
          },
          {
            name: 'Теги',
            key: `/app/${params.companyId}/tags`,
            disabled: !tageView,
            icon: <CSSkils />,
            id: 'tags',
          },
        ],
      },
      {
        keys: ['users', 'settings'],
        parent: {
          name: 'Управление',
          key: `/app/${params.companyId}/users`,
        },
        items: [
          {
            name: 'Пользователи',
            key: `/app/${params.companyId}/users/employees`,
            icon: <CSUser />,
            id: 'employees',
          },
          {
            name: 'Права доступа',
            key: `/app/${params.companyId}/users/roles`,
            disabled: !roleView || false,
            icon: <CSGit />,
            id: 'roles',
          },
          {
            name: 'Настройки компании',
            key: `/app/${params.companyId}/settings`,
            icon: <CSKey />,
            id: 'settings',
          },
        ],
      },
    ],
    [tageView, resourceView, roleView, params.companyId],
  )
}

const findSelected = (item: NavRoutes, path: string) => {
  return item.items.find((e) => (e.pattern ? path.match(e.pattern) : path.includes(e.key)))
}

export const useNavigationRoutesSelected = (routes: NavRoutes[]) => {
  const location = useLocation()

  return useMemo(() => {
    const findItem = (path: string) => {
      const selected = routes.filter((e) => e.keys.find((key) => path.includes(key)))

      if (selected.length) {
        return selected[selected.length - 1]
      }

      return null
    }

    const menuItem = findItem(location.pathname)
    if (menuItem) {
      const item = findSelected(menuItem, location.pathname)
      return item || null
    }
    return null
  }, [location, routes])
}
