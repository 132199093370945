import produce from 'immer'

import {
  EditorSectionSubscription_data_sectionsOrder,
  ProjectGroupsAll,
  ProjectGroupsAllVariables,
  ProjectGroupsSearch,
  ProjectListSchema,
  ProjectsAll_data_projects,
  ProjectSchema,
} from '@ui/types'

import { gqlClient } from '.'
import { projectGroupAll } from './project-groups.gql'
import { projectDeepQuery, projectFragment, projectListFragment } from './projects.gql'

export const setProjectDeep = (projectId: string, data: any) => {
  const cache = gqlClient.core.cache

  try {
    cache.writeQuery({
      query: projectDeepQuery,
      variables: {
        projectId,
      },
      data: data,
    })
  } catch (err) {
    console.error('"setProjectDeep" fn is crashed on operation: ".writeQuery"', err)
  }
}

// sections cache - оглавления кэшы
export const projectUpdateSectionOrderCache = (
  sectionsOrder: EditorSectionSubscription_data_sectionsOrder[] | null,
  projectId: string,
) => {
  const cache = gqlClient.core.cache
  try {
    // TODO maybe remove sectionsOrder -> eachSection may have own order
    projectModifySectionsOrder(projectId, sectionsOrder)
    if (sectionsOrder) {
      sectionsOrder.forEach((e) => {
        cache.modify({
          id: 'EditorSection:' + e.id,
          fields: {
            lvl: () => {
              return e.lvl
            },
          },
        })
      })
    }
  } catch (err) {
    console.error('"projectUpdateSectionOrderCache" fn is crashed on operation: ".modify"', err)
  }
}

export const projectModifySectionsOrder = (
  projectId: string,
  sectionsOrder?: EditorSectionSubscription_data_sectionsOrder[] | null,
) => {
  const cache = gqlClient.core.cache
  try {
    cache.modify({
      id: 'Project:' + projectId,
      fields: {
        sectionsOrder: () => {
          return sectionsOrder
        },
      },
    })
  } catch (err) {
    console.error('"projectModifySectionsOrder" fn is crashed on operation: ".modify"', err)
  }
}

export const projectsAddCache = (
  project: ProjectsAll_data_projects,
  companyId: string,
  search: ProjectGroupsSearch,
  parentId?: string | null,
) => {
  const cache = gqlClient.core.cache
  try {
    const data = cache.readQuery<ProjectGroupsAll, ProjectGroupsAllVariables>({
      query: projectGroupAll,
      variables: {
        companyId: companyId,
        parentId,
        search: search,
      },
    })
    if (data) {
      const existingItem = data?.data.projects.find((item) => item.id === project.id)
      if (!existingItem && data) {
        cache.writeQuery<ProjectGroupsAll, ProjectGroupsAllVariables>({
          query: projectGroupAll,
          variables: {
            companyId: companyId,
            parentId,
            search: search,
          },
          data: produce(data, (draft) => {
            draft!.data!.projects.push(project)
            if (draft!.data!.total) {
              draft!.data!.total += 1
            }
          }),
        })
      }
    }
  } catch (err) {
    console.error('"projectsAddCache" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const projectsUpdateCache = (project: ProjectSchema | null, id?: string) => {
  const cache = gqlClient.core.cache
  try {
    cache.modify({
      id: cache.identify({
        __typename: 'Project',
        id: project?.id,
      }),
      fields: {
        owner: () => project?.owner,
      },
    })

    cache.writeFragment({
      id: 'Project:' + (project?.id || id),
      fragment: projectFragment,
      fragmentName: 'ProjectSchema',
      data: project,
    })
  } catch (err) {
    console.error(
      '"projectsUpdateCache" fn is crashed on operation: ".modify or .writeFragment"',
      err,
    )
  }
}

export const projectsUpdateCacheList = (project: ProjectListSchema | null, id?: string) => {
  const cache = gqlClient.core.cache
  try {
    cache.writeFragment({
      id: 'Project:' + (project?.id || id),
      fragment: projectListFragment,
      fragmentName: 'ProjectListSchema',
      data: project,
    })
  } catch (err) {
    console.error('"projectsUpdateCacheList" fn is crashed on operation: ".writeFragment"', err)
  }
}

export const projectsRemoveCache = (projectId: string) => {
  const cache = gqlClient.core.cache
  try {
    cache.writeFragment({
      id: 'Project:' + projectId,
      fragment: projectFragment,
      fragmentName: 'ProjectSchema',
      data: undefined,
    })
  } catch (err) {
    console.error('"projectsRemoveCache" fn is crashed on operation: ".writeFragment"', err)
  }
}
