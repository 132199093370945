import { ReactElement } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'

import { useRouter } from 'utils/useRouter'

type RouteWithCheckOrRedirectType = (props: RouteProps) => JSX.Element

const RouteWithCheckOrRedirect = (
  rule: () => boolean,
  redirect: string,
): RouteWithCheckOrRedirectType => {
  const getRoute = ({ component: Component, ...rest }: RouteProps): ReactElement => {
    const { location } = useRouter()
    const isApp = location.pathname.includes('/app')

    const encodedRedirectPath = isApp ? encodeURIComponent(location.pathname) : ''
    const redirectPath = encodedRedirectPath.length ? `${redirect}/login` : redirect

    return (
      <Route
        {...rest}
        render={(props) =>
          rule() ? (
            Component && <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: redirectPath,
                search: `?redirectTo=${encodedRedirectPath}`,
              }}
            />
        )}
      />
    )
  }
  return getRoute
}

export { RouteWithCheckOrRedirect }
