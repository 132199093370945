import { usePermissions } from 'services/Permission'

export function useCheckPermission(permissionFind: string): boolean {
  return usePermissions(permissionFind)
}

export const permissionConts = {
  PROJECTGROUP_CREATE: 'PROJECTGROUP_CREATE',
  PROJECTGROUP_UPDATE: 'PROJECTGROUP_UPDATE',
  PROJECTGROUP_DELETE: 'PROJECTGROUP_DELETE',
  PROJECTGROUP_VIEW_ALL: 'PROJECTGROUP_VIEW_ALL',
  PROJECTGROUP_VIEW_TASK_EMPLOYEE: 'PROJECTGROUP_VIEW_TASK_EMPLOYEE',

  PROJECTGROUP_PROJECT_CREATE: 'PROJECTGROUP_PROJECT_CREATE',
  PROJECTGROUP_PROJECT_UPDATE: 'PROJECTGROUP_PROJECT_UPDATE',
  PROJECTGROUP_PROJECT_DELETE: 'PROJECTGROUP_PROJECT_DELETE',
  PROJECTGROUP_PROJECT_VIEW_ALL: 'PROJECTGROUP_PROJECT_VIEW_ALL',
  PROJECTGROUP_PROJECT_VIEW_PARTICIPANT: 'PROJECTGROUP_PROJECT_VIEW_PARTICIPANT',

  PROJECT_SECTION_CREATE: 'PROJECT_SECTION_CREATE',
  PROJECT_SECTION_UPDATE: 'PROJECT_SECTION_UPDATE',
  PROJECT_SECTION_VIEW: 'PROJECT_SECTION_VIEW',
  PROJECT_SECTION_CUSTOMIZATION_FILLING: 'PROJECT_SECTION_CUSTOMIZATION_FILLING',
  PROJECT_SECTION_CUSTOMIZATION_VIEW: 'PROJECT_SECTION_CUSTOMIZATION_VIEW',
  PROJECT_SECTION_CUSTOMIZATION_DELETE_SECTION: 'PROJECT_SECTION_CUSTOMIZATION_DELETE_SECTION',
  PROJECT_SECTION_CUSTOMIZATION_CLOSE_SECTION: 'PROJECT_SECTION_CUSTOMIZATION_CLOSE_SECTION',

  PROJECT_SECTION_FORMATS_VIEW: 'PROJECT_SECTION_FORMATS_VIEW',
  PROJECT_SECTION_FORMATS_FILLING: 'PROJECT_SECTION_FORMATS_FILLING',

  TASK_VIEW_ALL: 'TASK_VIEW_ALL',
  TASK_VIEW_PARTICIPANT: 'TASK_VIEW_PARTICIPANT',
  TASK_CREATE: 'TASK_CREATE',
  TASK_UPDATE: 'TASK_UPDATE',
  TASK_DELETE: 'TASK_DELETE',
  TASK_PROJECT_VIEW_PARTICIPANT: 'TASK_PROJECT_VIEW_PARTICIPANT',
  AGREEMENT_VIEW_ALL: 'AGREEMENT_VIEW_ALL',
  AGREEMENT_VIEW_EMPLOYEE: 'AGREEMENT_VIEW_EMPLOYEE',
  AGREEMENT_VIEW_PARTICIPANT: 'AGREEMENT_VIEW_PARTICIPANT',
  AGREEMENT_PROJECT_VIEW_PARTICIPANT: 'AGREEMENT_PROJECT_VIEW_PARTICIPANT',

  FILE_UPLOAD: 'FILE_UPLOAD',
  FILE_ALL_ACCESS: 'FILE_ALL_ACCESS',
  FILE_PROJECT_PARTICIPANT_ACCESS: 'FILE_PROJECT_PARTICIPANT_ACCESS',
  FILE_COMPANY_ACCESS: 'FILE_COMPANY_ACCESS',
  FILE_DELETE: 'FILE_DELETE',

  EMPLOYEE_INVITE: 'EMPLOYEE_INVITE',
  EMPLOYEE_INVITE_ASSIGN_ROLE: 'EMPLOYEE_INVITE_ASSIGN_ROLE',
  EMPLOYEE_UPDATE: 'EMPLOYEE_UPDATE',
  EMPLOYEE_DELETE: 'EMPLOYEE_DELETE',
  EMPLOYEE_VIEW: 'EMPLOYEE_VIEW',

  ROLE_CREATE: 'ROLE_CREATE',
  ROLE_UPDATE: 'ROLE_UPDATE',
  ROLE_DELETE: 'ROLE_DELETE',
  ROLE_VIEW: 'ROLE_VIEW',

  TAG_CREATE: 'TAG_CREATE',
  TAG_UPDATE: 'TAG_UPDATE',
  TAG_DELETE: 'TAG_DELETE',
  TAG_VIEW: 'TAG_VIEW',

  COMPANY_UPDATE: 'COMPANY_UPDATE',
  COMPANY_DELETE: 'COMPANY_DELETE',
  COMPANY_VIEW: 'COMPANY_VIEW',

  EMPLOYEE_PROFILE_UPDATE_ALL_PROFILES: 'EMPLOYEE_PROFILE_UPDATE_ALL_PROFILES',
  EMPLOYEE_PROFILE_UPDATE_MAIN_PROFILE: 'EMPLOYEE_PROFILE_UPDATE_MAIN_PROFILE',
  EMPLOYEE_PROFILE_UPDATE_INFORMATION: 'EMPLOYEE_PROFILE_UPDATE_INFORMATION',
  EMPLOYEE_PROFILE_UPDATE_CONTACT: 'EMPLOYEE_PROFILE_UPDATE_CONTACT',
  EMPLOYEE_PROFILE_UPDATE_LOGIN: 'EMPLOYEE_PROFILE_UPDATE_LOGIN',
  EMPLOYEE_PROFILE_UPDATE_EMAIL: 'EMPLOYEE_PROFILE_UPDATE_EMAIL',
  EMPLOYEE_PROFILE_UPDATE_MAIN_PASSWORD: 'EMPLOYEE_PROFILE_UPDATE_MAIN_PASSWORD',

  COURSE_SETTINGS_ACCESS: 'COURSE_SETTINGS_ACCESS',
  COURSE_SETTINGS_CREATE_NAME: 'COURSE_SETTINGS_CREATE_NAME',
  COURSE_SETTINGS_UPDATE_NAME: 'COURSE_SETTINGS_UPDATE_NAME',
  COURSE_SETTINGS_CREATE_PARTICIPANT: 'COURSE_SETTINGS_CREATE_PARTICIPANT',
  COURSE_SETTINGS_UPDATE_PARTICIPANT: 'COURSE_SETTINGS_UPDATE_PARTICIPANT',
  COURSE_SETTINGS_UPDATE_DATE: 'COURSE_SETTINGS_UPDATE_DATE',
  COURSE_SETTINGS_ACCESS_BUDGET: 'COURSE_SETTINGS_ACCESS_BUDGET',
  COURSE_SETTINGS_CREATE_BUDGET: 'COURSE_SETTINGS_CREATE_BUDGET',
  COURSE_SETTINGS_UPDATE_BUDGET: 'COURSE_SETTINGS_UPDATE_BUDGET',
  COURSE_SETTINGS_VIEW_BUDGET: 'COURSE_SETTINGS_VIEW_BUDGET',

  BLOCK_ADD: 'BLOCK_ADD',

  TEMPLATE_ACCESS_COMPANY: 'TEMPLATE_ACCESS_COMPANY',
  TEMPLATE_ACCESS_ALL: 'TEMPLATE_ACCESS_ALL',
}
