import { gql } from '@apollo/client'

import { avatarFragment } from './avatar.gql'
import { employeeFragment } from './employees.gql'

export const taskEmployessFragment = gql`
  fragment TaskEmployeeSchema on Employee {
    roleId
    companyId
    isActive
    isArchived
    updatedAt
    createdAt
  }
`
export const taskBlockFragment = gql`
  fragment TaskBlockSchema on Block {
    uuid
    name
  }
`

export const taskSectionFragment = gql`
  fragment TaskSectionSchema on EditorSection {
    id
    name
    deadline
    isDone
  }
`

export const taskFragment = gql`
  fragment TaskSchema on Task {
    id
    name
    isDone
    description
    deadline
    doneTime
    employee {
      ...EmployeeSchema
      ...TaskEmployeeSchema
    }
    assigned {
      ...EmployeeSchema
      ...TaskEmployeeSchema
    }
    type
    todo
    status
    parentId
    sectionId
    section {
      ...TaskSectionSchema
    }
    projectId
    blockId
    block {
      ...TaskBlockSchema
    }
    timelog
    owner {
      ...EmployeeSchema
    }
    isArchived
    createdAt
    updatedAt
  }
  ${avatarFragment}
  ${employeeFragment}
  ${taskEmployessFragment}
  ${taskBlockFragment}
  ${taskSectionFragment}
`

export const tasksGetAllQuery = gql`
  query TasksAllQuery($companyId: String!, $entityId: TasksEntityIdInput) {
    data: tasksAll(companyId: $companyId, entityId: $entityId) {
      ...TaskSchema
    }
  }
  ${taskFragment}
`

export const tasksSearchAll = gql`
  query TasksSearchAllQuery($companyId: String!, $search: TaskSearch!) {
    data: tasksSearchAll(companyId: $companyId, search: $search) {
      ...TaskSchema
    }
  }
  ${taskFragment}
`

export const tasksGetById = gql`
  query TasksGetByIdQuery($id: String!) {
    data: tasksGetById(id: $id) {
      id
      companyId
    }
  }
`

export const tasksSubscription = gql`
  subscription TasksSubscription($data: TaskInputSubscription!) {
    data: task(data: $data) {
      task {
        ...TaskSchema
      }
      type
    }
  }
  ${taskFragment}
`
