import produce from 'immer'

import { TagsCreate_data, TagsGetByName, TagsUpdateById_data } from '@ui/types'

import { gqlClient } from '.'
import { tagsGetByName } from './tags.gql'

export const tagsAddCache = (tag: TagsCreate_data | null, companyId: string) => {
  const cache = gqlClient.core.cache
  try {
    const tags = cache.readQuery<TagsGetByName>({
      query: tagsGetByName,
      variables: {
        companyId,
        name: '',
      },
    })

    const existingItem = tag && tags?.data.find((item) => item.id === tag.id)

    if (!existingItem && tags && tag) {
      cache.writeQuery<TagsGetByName>({
        query: tagsGetByName,
        variables: {
          companyId: companyId,
          name: '',
        },
        data: produce(tags, (draft) => {
          draft!.data.push(tag)
        }),
      })
    }
  } catch (err) {
    console.error('"tagsAddCache" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const tagsUpdateCache = (tag: TagsUpdateById_data | null, companyId: string) => {
  const cache = gqlClient.core.cache
  try {
    const tags = cache.readQuery<TagsGetByName>({
      query: tagsGetByName,
      variables: {
        companyId,
        name: '',
      },
    })

    if (tags && tag) {
      cache.writeQuery<TagsGetByName>({
        query: tagsGetByName,
        variables: {
          companyId: companyId,
          name: '',
        },
        data: produce(tags, (draft) => {
          const index = draft.data.findIndex((e) => e.id === tag?.id)
          draft!.data[index] = tag
        }),
      })
    }
  } catch (err) {
    console.error('"tagsUpdateCache" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const tagsRemoveCache = (tagId: string, companyId: string) => {
  const cache = gqlClient.core.cache
  try {
    const tags = cache.readQuery<TagsGetByName>({
      query: tagsGetByName,
      variables: {
        companyId,
        name: '',
      },
    })

    if (tags) {
      cache.writeQuery<TagsGetByName>({
        query: tagsGetByName,
        variables: {
          companyId: companyId,
          name: '',
        },
        data: produce(tags, (draft) => {
          const index = draft.data!.findIndex((e) => e.id === tagId)
          if (index >= 0) {
            draft.data.splice(index, 1)
          }
        }),
      })
    }
  } catch (err) {
    console.error('"tagsRemoveCache" fn is crashed on operation: ".writeQuery"', err)
  }
}
