import { gql, useMutation, useLazyQuery } from '@apollo/client'

import {
  TasksCreate,
  TasksCreateVariables,
  TasksGetById,
  TasksUpdateById,
  TasksUpdateByIdVariables,
  TasksDeleteById,
  TasksDeleteByIdVariables,
  TasksChangeStatus,
  TasksChangeStatusVariables,
  TasksSearchAll,
  TasksSearchAllVariables,
  TasksSearchAllQueryVariables,
  BlocksAll_data,
} from '@ui/types'

import { employeeFragment, employeeSmallFragment } from './employees.gql'
import { taskAddCache, taskRemoveCache } from './tasks.cache'
import { taskFragment } from './tasks.gql'

export * from './tasks.subscription'

export const tasksSearchAll = gql`
  query TasksSearchAll($companyId: String!, $search: TaskSearch!) {
    data: tasksSearchAll(companyId: $companyId, search: $search) {
      ...TaskSchema
    }
  }
  ${taskFragment}
`
//
export const useTasksSearchAll = (cache?: boolean) =>
  useLazyQuery<TasksSearchAll, TasksSearchAllVariables>(tasksSearchAll, {
    fetchPolicy: !cache ? 'cache-and-network' : 'cache-and-network',
    onError: (err) =>
      console.error('"useTasksSearchAll" fn is crashed on operation: "useLazyQuery"', err),
  })

const tasksGetById = gql`
  query TasksGetById($id: String!) {
    data: tasksGetById(id: $id) {
      id
      projectId
      sectionId
      owner {
        ...EmployeeSchema
      }
      section {
        id
        name
      }
      block {
        uuid
        name
      }
      employee {
        ...EmployeeSchema
      }
      assigned {
        ...EmployeeSchema
      }
      name
      description
      deadline
      type
      todo
      status
      parentId
      blockId
      timelog
      # isArchived
      createdAt
      updatedAt
    }
  }
  ${employeeFragment}
`

export const useTasksGetById = () => useLazyQuery<TasksGetById>(tasksGetById)

export const useTasksCreate = (search: TasksSearchAllQueryVariables, block?: BlocksAll_data) =>
  useMutation<TasksCreate, TasksCreateVariables>(
    gql`
      mutation TasksCreate($companyId: String!, $data: TaskInput!) {
        data: tasksCreate(companyId: $companyId, data: $data) {
          ...TaskSchema
        }
      }
      ${taskFragment}
    `,
    {
      update: (cache, { data: item }) => {
        if (item) {
          taskAddCache(item.data, search)
        }
      },
      onError: (err) =>
        console.error('"useTasksCreate" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useTasksUpdate = (search: TasksSearchAllQueryVariables) =>
  useMutation<TasksUpdateById, TasksUpdateByIdVariables>(
    gql`
      mutation TasksUpdateById($id: String!, $data: TaskUpdateInput!) {
        data: tasksUpdateById(id: $id, data: $data) {
          ...TaskSchema
        }
      }
      ${taskFragment}
    `,
    {
      onError: (err) =>
        console.error('"useTasksUpdate" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useTasksDelete = (search: TasksSearchAllQueryVariables, block?: BlocksAll_data) =>
  useMutation<TasksDeleteById, TasksDeleteByIdVariables>(
    gql`
      mutation TasksDeleteById($id: String!) {
        data: tasksDeleteById(id: $id)
      }
    `,
    {
      update: (cache, { data: item }) => {
        if (item && item.data) {
          taskRemoveCache(item.data, search)
        }
      },
      onError: (err) =>
        console.error('"useTasksDelete" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useTasksChangeStatus = () =>
  useMutation<TasksChangeStatus, TasksChangeStatusVariables>(
    gql`
      mutation TasksChangeStatus($data: TaskChangeStatusInput!) {
        data: tasksChangeStatus(data: $data) {
          id
          employee {
            ...EmployeeSchema
          }
          name
          description
          deadline
          type
          todo
          status
          parentId
          blockId
          timelog
          owner {
            ...EmployeeSmallSchema
          }
          # isArchived
          createdAt
          updatedAt
        }
      }
      ${employeeSmallFragment}
      ${employeeFragment}
    `,
    {
      onError: (err) =>
        console.error('"useTasksChangeStatus" fn is crashed on operation: "useMutation"', err),
    },
  )
