import { useMutation, useQuery } from '@apollo/client'

import {
  GetPublicLinkInProjectGetById,
  GetPublicLinkInProjectGetByIdVariables,
  GetPublicPreviewData,
  GetPublicPreviewDataVariables,
} from '@ui/types'

import {
  PublicLinkInProjectGetByIdUpdate,
  PublicLinkInProjectGetByIdUpdateVariables,
} from './__generated__/types'
import { updatePublicLinkInProjectGetById } from './projects.gql'
import { getPublicLinkInProjectGetById, getPublicPreviewData } from './public-link.gql'

export const useGetPublicPreviewData = (shortLink: string) => {
  const dataQuery = useQuery<GetPublicPreviewData, GetPublicPreviewDataVariables>(
    getPublicPreviewData,
    {
      variables: { shortLink },
    },
  )
  const accessToken = dataQuery.data?.data.token
  if (accessToken) {
    localStorage.setItem('visitorToken', accessToken)
  }
  return dataQuery
}

export const useGetPublicLinkInProjectGetById = (projectId: string) =>
  useQuery<GetPublicLinkInProjectGetById, GetPublicLinkInProjectGetByIdVariables>(
    getPublicLinkInProjectGetById,
    {
      variables: {
        projectId,
      },
      fetchPolicy: 'cache-and-network',
    },
  )

export const useUpdateProjectPublicLink = () =>
  useMutation<PublicLinkInProjectGetByIdUpdate, PublicLinkInProjectGetByIdUpdateVariables>(
    updatePublicLinkInProjectGetById,
  )
