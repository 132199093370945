// import { gql } from '@apollo/client';
import gql from 'graphql-tag'

import { avatarFragment } from './avatar.gql'
import { employeeSmallFragment } from './employees.gql'

export const NotificationListFragment = gql`
  fragment NotificationListSchema on Notification {
    id
    type
    employee {
      id
      name
      username
      avatar {
        ...AvatarSchema
      }
    }
    owner {
      ...EmployeeSmallSchema
    }
    count
    entity
    entityId
    isNew
    createdAt
    updatedAt
  }
  ${avatarFragment}
  ${employeeSmallFragment}
`

// export const notificationsAllQuery = gql`
//   query NotificationsAll($companyId: String!) {
//     data: notificationsAll(companyId: $companyId) {
//       ...NotificationSchema
//     }
//   }
//   ${notificationFragment}
// `;

// export const notificationsNewQuery = gql`
//   query NotificationsNew($companyId: String!) {
//     data: notificationsNew(companyId: $companyId) {
//       ...NotificationSchema
//     }
//   }
//   ${notificationFragment}
// `;

// export const notificationsNewCountQuery = gql`
//   query NotificationsCountNew($companyId: String!) {
//     data: notificationsCountNew(companyId: $companyId)
//   }
// `;

export const notificationSubscription = gql`
  subscription Notification($employeeId: String!) {
    data: notification(employeeId: $employeeId) {
      notification {
        ...NotificationListSchema
      }
      type
    }
  }
  ${NotificationListFragment}
`
