import gql from 'graphql-tag'

import { blockFragment } from './blocks.gql'
import { employeeFragment } from './employees.gql'

export const testFragment = gql`
  fragment TestSchema on EditorSectionTest {
    time
    testRetry
    resultShouldBeGreaterThen
    isNextOnSubmit
    isValidationVisible
    isResultValuePercent
    questionOrder
    isShuffleQuestions
    isProgressShown
  }
`

export const sectionFragment = gql`
  fragment SectionSchema on EditorSection {
    id
    name
    description
    type
    blocksOrder
    deadline
    blocks {
      uuid
      mode
    }
    test {
      ...TestSchema
    }
    lvl
    isChapter
    updatedAt
  }
  ${testFragment}
`

export const sectionWithBlocksFragment = gql`
  fragment SectionWithBlocksSchema on EditorSection {
    ...SectionSchema
    blocks {
      ...BlockSchema
    }
  }
  ${sectionFragment}
  ${blockFragment}
`

export const sectionOrderFragment = gql`
  fragment SectionOrderSchema on EditorSectionOrderOptionalType {
    id
    lvl
  }
`

export const sectionEditorFragment = gql`
  fragment SectionEditorSchema on EditorSection {
    ...SectionSchema
    isDone
    projectId
    deadline
    totalCost
    formats {
      id
      cost
      unitType
      count
      formatId
    }
    tags
    isArchived
    createdAt
    participants {
      ...EmployeeSchema
    }
  }
  ${employeeFragment}
  ${sectionFragment}
`

/**
 * Мы не можем экспортировать GQL из .apollo файлов
 * что бы не засорять скоуп глобальными кверями в gqlActions
 */
export const sectionsAllQuery = gql`
  query SectionsAll($projectId: String!) {
    data: editorSectionsAll(projectId: $projectId) {
      ...SectionEditorSchema
    }
  }
  ${sectionEditorFragment}
`

export const sectionsGetById = gql`
  query SectionsGetById($id: String!, $projectId: String!) {
    data: editorSectionsGetById(id: $id, projectId: $projectId) {
      ...SectionEditorSchema
    }
  }
  ${sectionEditorFragment}
`

export const sectionsSubscription = gql`
  subscription EditorSectionSubscription($projectId: String!) {
    data: editorSection(projectId: $projectId) {
      section {
        ...SectionEditorSchema
      }
      type
      sectionsOrder {
        ...SectionOrderSchema
      }
    }
  }
  ${sectionEditorFragment}
  ${sectionOrderFragment}
`
