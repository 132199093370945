import { gql, useMutation, useQuery } from '@apollo/client'

import {
  GetServerStorage,
  RemoveServerStorage,
  RemoveServerStorageVariables,
  SetServerStorage,
  SetServerStorageVariables,
} from '@ui/types'

import { gqlClient } from '.'

const getServerStorageGql = gql`
  query GetServerStorage($key: String!) {
    data: getItem(key: $key)
  }
`

const setServerStorageGql = gql`
  mutation SetServerStorage($key: String!, $template: String!) {
    data: setItem(key: $key, template: $template)
  }
`

export const useGetServerStorage = (
  params: { variables: { key: string } },
  fetchPolicy?:
    | 'cache-first'
    | 'network-only'
    | 'cache-only'
    | 'no-cache'
    | 'standby'
    | 'cache-and-network'
    | undefined,
) =>
  useQuery<GetServerStorage>(getServerStorageGql, {
    variables: params.variables,
    client: gqlClient.serverStorage,
    // research TODO
    fetchPolicy: fetchPolicy || 'no-cache',
    onError: (err) =>
      console.error('"useGetServerStorage" fn is crashed on operation: "useQuery"', err),
  })

export const useSetServerStorage = () =>
  useMutation<SetServerStorage, SetServerStorageVariables>(setServerStorageGql, {
    client: gqlClient.serverStorage,
    onError: (err) =>
      console.error('"useSetServerStorage" fn is crashed on operation: "useMutation"', err),
  })

export const setServerStorage = (variables: SetServerStorageVariables) => {
  try {
    gqlClient.serverStorage.mutate<SetServerStorage, SetServerStorageVariables>({
      mutation: setServerStorageGql,
      variables: variables,
    })
  } catch (err) {
    console.error('"setServerStorage" fn is crashed on operation: ".mutate"', err)
  }
}

export const useRemoveServerStorage = () =>
  useMutation<RemoveServerStorage, RemoveServerStorageVariables>(
    gql`
      mutation RemoveServerStorage($key: String!) {
        data: removeItem(key: $key)
      }
    `,
    {
      client: gqlClient.serverStorage,
      onError: (err) =>
        console.error('"useRemoveServerStorage" fn is crashed on operation: "useMutation"', err),
    },
  )
