import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client'

import {
  EmployeesAll,
  EmployeesGetById,
  EmployeesUpdateById,
  EmployeesUpdateByIdVariables,
  EmployeesDeleteById,
  EmployeesDeleteByIdVariables,
  EmployeesSendInviteVariables,
  EmployeesSendInvite,
  EmployeeSearch,
} from '@ui/types'

import { gqlClient } from '.'
import { EmployeesAllVariables } from './__generated__/types'
import { updateEmployeeAvatar } from './employees.cache'
import {
  employeesGetByIdQuery,
  employeesGetAllQuery,
  employeeListFragment,
  employeeInfoFragment,
} from './employees.gql'
import { rolesAllGql } from './roles.gql'

export * from './employees.subscription'

export const useEmployeesGetAll = (companyId: string, search?: EmployeeSearch) =>
  useQuery<EmployeesAll, EmployeesAllVariables>(employeesGetAllQuery, {
    variables: {
      companyId,
      search,
    },
    fetchPolicy: 'cache-and-network',
    onError: (err) =>
      console.error('"useEmployeesGetAll" fn is crashed on operation: "useQuery"', err),
  })

export const useEmployeesGetAllLazy = () =>
  useLazyQuery<EmployeesAll, EmployeesAllVariables>(employeesGetAllQuery, {
    fetchPolicy: 'cache-and-network',
    onError: (err) =>
      console.error('"useEmployeesGetAllLazy" fn is crashed on operation: "useLazyQuery"', err),
  })

export const useEmployeesGetById = () => useLazyQuery<EmployeesGetById>(employeesGetByIdQuery)

export const useEmployeesUpdateById = () =>
  useMutation<EmployeesUpdateById, EmployeesUpdateByIdVariables>(
    gql`
      mutation EmployeesUpdateById($id: String!, $companyId: String!, $data: EmployeeUpdateInput!) {
        data: employeesUpdateById(id: $id, companyId: $companyId, data: $data) {
          ...EmployeeListSchema
          ...EmployeeInfoSchema
        }
      }
      ${employeeInfoFragment}
      ${employeeListFragment}
    `,
    {
      update: (cache, { data: item }) => {
        try {
          gqlClient.core.query({
            query: rolesAllGql,
            variables: {
              companyId: item?.data?.companyId,
            },
          })
        } catch (err) {
          console.error('"useEmployeesUpdateById" fn is crashed on operation: ".query"', err)
        }
        if (item) {
          updateEmployeeAvatar(item.data)
        }
      },
      onError: (err) =>
        console.error('"useEmployeesUpdateById" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useEmployeesDeleteById = (companyId: string, searchParams: EmployeeSearch) =>
  useMutation<EmployeesDeleteById, EmployeesDeleteByIdVariables>(
    gql`
      mutation EmployeesDeleteById($id: String!, $companyId: String!) {
        data: employeesDeleteById(id: $id, companyId: $companyId) {
          id
          errors {
            type
            items {
              id
              name
              entityId
              entityType
            }
          }
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: rolesAllGql,
          variables: {
            companyId,
          },
        },
      ],
      onError: (err) =>
        console.error('"useEmployeesDeleteById" fn is crashed on operation: "useMutation"', err),
      client: gqlClient.core,
    },
  )

export const useEmployeesForceDeleteById = (companyId: string, searchParams: EmployeeSearch) =>
  useMutation(
    gql`
      mutation EmployeesForceDeleteById($id: ID!, $companyId: String!, $employeeId: ID) {
        data: employeesForceDeleteById(id: $id, newId: $employeeId, companyId: $companyId)
      }
    `,
    {
      refetchQueries: [
        {
          query: rolesAllGql,
          variables: {
            companyId,
          },
        },
      ],
      onError: (err) =>
        console.error(
          '"useEmployeesForceDeleteById" fn is crashed on operation: "useMutation"',
          err,
        ),
      client: gqlClient.core,
    },
  )

export const useEmployeesSendInvite = (companyId: string, searchParams: EmployeeSearch) => {
  return useMutation<EmployeesSendInvite, EmployeesSendInviteVariables>(
    gql`
      mutation EmployeesSendInvite($companyId: String!, $payload: InviteInput!) {
        data: inviteEmployee(payload: $payload, companyId: $companyId) {
          isMailSent
          employeeId
        }
      }
    `,
    {
      onError: (err) =>
        console.error('"useEmployeesSendInvite" fn is crashed on operation: "useMutation"', err),
      client: gqlClient.auth,
      update: () => {
        try {
          gqlClient.core.query({
            query: employeesGetAllQuery,
            variables: {
              companyId,
              search: searchParams,
            },
            fetchPolicy: 'network-only',
          })
          gqlClient.core.query({
            query: rolesAllGql,
            variables: {
              companyId,
            },
            fetchPolicy: 'network-only',
          })
        } catch (err) {
          console.error('"useEmployeesSendInvite" fn is crashed on operation: ".query"', err)
        }
      },
    },
  )
}
