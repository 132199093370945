import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client'
import { useMemo } from 'react'

import {
  ProjectsCreate,
  ProjectsCreateVariables,
  ProjectsUpdateById,
  ProjectsUpdateByIdVariables,
  ProjectsDeleteById,
  ProjectsDeleteByIdVariables,
  ProjectSearch,
  ProjectsForceDeleteById,
  ProjectsForceDeleteByIdVariables,
  ProjectsDeepGetByIdVariables,
  ProjectsDeepGetById,
  ProjectsFilesGetById,
  ProjectsFilesGetByIdVariables,
  ProjectsGetById,
  ProjectsGetByIdVariables,
} from '@ui/types'

import { gqlClient } from '.'
import { projectsAddCache, projectsRemoveCache } from './projects.cache'
import {
  projectDeepQuery,
  projectFragment,
  projectListFragment,
  projectsFilesGetByIdQuery,
  projectsGetByIdQuery,
} from './projects.gql'

export * from './projects.subscription'

export const useProjectsGetById = (
  projectId: string,
  isNoCache?: boolean,
  fetchPolicy?:
    | 'cache-and-network'
    | 'cache-first'
    | 'network-only'
    | 'cache-only'
    | 'no-cache'
    | 'standby'
    | undefined,
) =>
  useQuery<ProjectsGetById, ProjectsGetByIdVariables>(projectsGetByIdQuery, {
    variables: {
      projectId,
    },
    nextFetchPolicy: fetchPolicy,
    fetchPolicy: !isNoCache ? 'cache-only' : 'cache-and-network',
    onError: (err) =>
      console.error('"useProjectsGetById" fn is crashed on operation: "useQuery"', err),
  })

export const useProjectsGetByIdLazy = (projectId: string, isNoCache?: boolean) =>
  useLazyQuery<ProjectsGetById, ProjectsGetByIdVariables>(projectsGetByIdQuery, {
    variables: {
      projectId,
    },
    fetchPolicy: !isNoCache ? 'cache-only' : 'cache-and-network',
    onError: (err) =>
      console.error('"useProjectsGetByIdLazy" fn is crashed on operation: "useLazyQuery"', err),
  })

export const useProjectsFilesGetById = (projectId: string, isCacheOnly?: boolean) =>
  useQuery<ProjectsFilesGetById, ProjectsFilesGetByIdVariables>(projectsFilesGetByIdQuery, {
    variables: {
      projectId,
    },
    fetchPolicy: isCacheOnly ? 'cache-only' : 'cache-and-network',
    onError: (err) =>
      console.error('"useProjectsFilesGetById" fn is crashed on operation: "useQuery"', err),
  })

export const useProjectsDeepGetSetCache = (): void => {
  useMemo(() => {
    // @ts-ignore
    const data = window.defaultData?.data?.data
    try {
      gqlClient.core.writeQuery<ProjectsDeepGetById, ProjectsDeepGetByIdVariables>({
        query: projectDeepQuery,
        variables: {
          projectId: data?.id,
        },
        data: {
          data,
        },
      })
    } catch (err) {
      console.error('"useProjectsDeepGetSetCache" fn is crashed on operation: ".writeQuery"', err)
    }
  }, [])
}

export const useProjectsDeepGetById = (projectId: string, isCacheOnly?: boolean) =>
  useQuery<ProjectsDeepGetById, ProjectsDeepGetByIdVariables>(projectDeepQuery, {
    variables: {
      projectId,
    },
    fetchPolicy: isCacheOnly ? 'cache-only' : 'cache-first',
    onError: (err) =>
      console.error('"useProjectsDeepGetById" fn is crashed on operation: "useQuery"', err),
  })

export const useLazyProjectsDeepGetById = () =>
  useLazyQuery<ProjectsDeepGetById, ProjectsDeepGetByIdVariables>(projectDeepQuery, {
    fetchPolicy: 'cache-and-network',
    onError: (err) =>
      console.error('"useLazyProjectsDeepGetById" fn is crashed on operation: "useLazyQuery"', err),
  })

export const useProjectsCreate = (parentId: string, companyId: string, search: ProjectSearch) =>
  useMutation<ProjectsCreate, ProjectsCreateVariables>(
    gql`
      mutation ProjectsCreate($companyId: String!, $data: ProjectCreateInput!) {
        data: projectsCreate(companyId: $companyId, data: $data) {
          ...ProjectListSchema
        }
      }
      ${projectListFragment}
    `,
    {
      update: (_cache, { data: item }) => {
        if (item) {
          projectsAddCache(item.data, companyId, search, parentId)
        }
      },
      onError: (err) =>
        console.error('"useProjectsCreate" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useProjectsUpdate = () =>
  useMutation<ProjectsUpdateById, ProjectsUpdateByIdVariables>(
    gql`
      mutation ProjectsUpdateById($projectId: String!, $data: ProjectUpdateInput!) {
        data: projectsUpdateById(projectId: $projectId, data: $data) {
          ...ProjectSchema
        }
      }
      ${projectFragment}
    `,
    {
      onError: (err) =>
        console.error('"useProjectsUpdate" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useProjectsDelete = () =>
  useMutation<ProjectsDeleteById, ProjectsDeleteByIdVariables>(
    gql`
      mutation ProjectsDeleteById($id: String!, $companyId: String!) {
        data: projectsDeleteById(id: $id, companyId: $companyId) {
          id
          errors {
            type
            items {
              id
              name
              projectId
            }
          }
        }
      }
    `,
    {
      update: (_cache, { data: item }) => {
        if (item?.data) {
          projectsRemoveCache(item.data.id)
        }
      },
      onError: (err) =>
        console.error('"useProjectsDelete" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useProjectsForceDeleteById = () =>
  useMutation<ProjectsForceDeleteById, ProjectsForceDeleteByIdVariables>(
    gql`
      mutation ProjectsForceDeleteById($id: String!, $companyId: String!) {
        data: projectsForceDeleteById(id: $id, companyId: $companyId)
      }
    `,
    {
      onError: (err) =>
        console.error(
          '"useProjectsForceDeleteById" fn is crashed on operation: "useMutation"',
          err,
        ),
    },
  )
