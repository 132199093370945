import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  FileImageOutlined,
  FileTextOutlined,
  FontSizeOutlined,
  ItalicOutlined,
  OrderedListOutlined,
  StrikethroughOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignTopOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import React from 'react'

const buttonIcons: any = {
  text: FileTextOutlined,
  title: FontSizeOutlined,
  list: UnorderedListOutlined,
  image: FileImageOutlined,
  video: VideoCameraOutlined,
  bold: BoldOutlined,
  italic: ItalicOutlined,
  underline: UnderlineOutlined,
  strikethrough: StrikethroughOutlined,
  'align-left': AlignLeftOutlined,
  'align-center': AlignCenterOutlined,
  'align-right': AlignRightOutlined,
  'vertical-align-top': VerticalAlignTopOutlined,
  'vertical-align-middle': VerticalAlignMiddleOutlined,
  'vertical-align-bottom': VerticalAlignBottomOutlined,
  'unordered-list': UnorderedListOutlined,
  'ordered-list': OrderedListOutlined,
}

export const getIcon = (name: string) => {
  const Icon = buttonIcons[name]
  return Icon ? <Icon /> : name
}
