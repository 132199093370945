export const TEST_BLOCK_ELEMENTS = {
  editorMatching: 'editor-matching',
  editorKeyboardInput: 'editor-keyboard-input',
  editorRadio: 'editor-radio',
  editorCheckbox: 'editor-checkbox',
  editorRankingV2: 'editor-ranking-v2',
  startButton: 'editor-start',
  endButton: 'editor-end',
  submitButton: 'editor-validate-and-next',
  restartButton: 'editor-restart',
  editorResult: 'editor-result',
  nextButton: 'editor-next',
  editorRanking: 'editor-ranking',
  testQuestionsFeedback: 'test-questions-feedback',
  editorRadioGroup: 'editor-radio-group',
  editorSubmit: 'editor-submit',
  nextSection: 'next-section',
  prevSection: 'prev-section',
  editorFeedback: 'editor-feedback',
}
