import { gql, useLazyQuery, useMutation } from '@apollo/client'

import {
  TemplatesAll,
  TemplatesAllVariables,
  TemplatesDeleteById,
  TemplatesDeleteByIdVariables,
  TemplatesCreate,
  TemplatesCreateVariables,
  TemplatesDeleteByIds,
  TemplatesDeleteByIdsVariables,
} from '@ui/types'

import { templatesUpdateCache } from './templates.cache'
import { templatesGetAllQuery, templatesListFragment } from './templates.gql'

export const useTemplatesAll = () =>
  useLazyQuery<TemplatesAll, TemplatesAllVariables>(templatesGetAllQuery, {
    fetchPolicy: 'cache-and-network',
    onError: (err) =>
      console.error('"useTemplatesAll" fn is crashed on operation: "useLazyQuery"', err),
  })

export const useTemplatesCreate = () =>
  useMutation<TemplatesCreate, TemplatesCreateVariables>(
    gql`
      mutation TemplatesCreate($companyId: String!, $data: EditorTemplateCreateInput!) {
        data: editorTemplatesCreate(companyId: $companyId, data: $data) {
          ...TemplatesListSchema
        }
      }
      ${templatesListFragment}
    `,
    {
      onError: (err) =>
        console.error('"useTemplatesCreate" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useTemplatesDeleteById = () =>
  useMutation<TemplatesDeleteById, TemplatesDeleteByIdVariables>(
    gql`
      mutation TemplatesDeleteById($companyId: String!, $uuid: String!) {
        data: editorTemplatesDeleteById(companyId: $companyId, uuid: $uuid)
      }
    `,
    {
      onError: (err) =>
        console.error('"useTemplatesDeleteById" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useTemplatesDeleteByIds = (params: {
  companyId: string
  projectId: string
  type: string
  createdFor: string
}) =>
  useMutation<TemplatesDeleteByIds, TemplatesDeleteByIdsVariables>(
    gql`
      mutation TemplatesDeleteByIds($companyId: String!, $uuid: [String!]!) {
        data: editorTemplatesDeleteByIds(companyId: $companyId, uuid: $uuid)
      }
    `,
    {
      update: (cache, { data: templates }) => {
        if (templates?.data) {
          templatesUpdateCache(params, templates)
        }
      },
      onError: (err) =>
        console.error('"useTemplatesDeleteByIds" fn is crashed on operation: "useMutation"', err),
    },
  )
