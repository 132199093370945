import gql from 'graphql-tag'

export const scormBuildFragment = gql`
  fragment ScormBuildSchema on Build {
    status
    sessionId
    payload {
      employee {
        id
        name
      }
      project {
        id
        name
      }
      category {
        id
      }
      percents
    }
    onBuild
    lastBuild {
      createdAt
      link
    }
  }
`

export const scormBuildInitFragment = gql`
  fragment ScormPackageSchema on BuildInitResult {
    status
    error
    currentSession {
      id
    }
  }
`

export const scormBuild = gql`
  query BuildLastGetByProjectId($employeeId: String!, $projectId: String!) {
    data: buildLastGetByProjectId(employeeId: $employeeId, projectId: $projectId) {
      ...ScormBuildSchema
    }
  }
  ${scormBuildFragment}
`

export const scormBuildSubscription = gql`
  subscription Scorm($employeeId: String!, $projectId: String!) {
    data: scorm(employeeId: $employeeId, projectId: $projectId) {
      ...ScormBuildSchema
    }
  }
  ${scormBuildFragment}
`

export const scormBuildInit = gql`
  mutation BuildScormPackageInit($employeeId: String!, $projectId: String!) {
    data: buildScormPackageInit(employeeId: $employeeId, projectId: $projectId) {
      ...ScormPackageSchema
    }
  }
  ${scormBuildInitFragment}
`

export const scormCurrentSessionFragment = gql`
  fragment CurrentSessionSchema on BuildSessionCurrent {
    currentSession {
      id
    }
  }
`

export const scormCurrentSession = gql`
  query GetProjectExportCurrentSessionId($employeeId: String!, $projectId: String!) {
    data: getProjectExportCurrentSessionId(employeeId: $employeeId, projectId: $projectId) {
      ...CurrentSessionSchema
    }
  }
  ${scormCurrentSessionFragment}
`
