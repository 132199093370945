import { useQuery, useSubscription } from '@apollo/client'

import { AnnounceGetStatus, AnnounceStatuses, AnnounceSubscribe } from '@ui/types'

import { routineMaintenance, routineMaintenanceSubscription } from './routineMaintenance.gql'

export const useGetMaintenanceNotification = () => useQuery<AnnounceGetStatus>(routineMaintenance)

export const useRoutineMaintenanceSubscription = () => {
  return useSubscription<AnnounceSubscribe>(routineMaintenanceSubscription)
}

export const useRoutineMaintenance = () => {
  const { subscribeToMore, data } = useGetMaintenanceNotification()
  useRoutineMaintenanceSubscription()

  subscribeToMore &&
    subscribeToMore({
      document: routineMaintenance,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const newFeedItem = subscriptionData.data
        return newFeedItem
      },
    })

  return data?.data.status === AnnounceStatuses.NONE ? undefined : data?.data
}
