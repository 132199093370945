import { useSubscription } from '@apollo/client'

import { EmployeesSubscribe } from '@ui/types'

import { EmployeeSearch, EmployeesSubscribeVariables } from './__generated__/types'
import { addEmployee, employeeRemove, updateEmployeeAvatar } from './employees.cache'
import { employeesSubscribe } from './employees.gql'

export const useSubscriptionEmployees = (companyId: string, search?: EmployeeSearch) =>
  useSubscription<EmployeesSubscribe, EmployeesSubscribeVariables>(employeesSubscribe, {
    onSubscriptionData: (options) => {
      const data = options.subscriptionData.data?.data
      if (data && data.employee) {
        switch (data.type) {
          case 'create':
            addEmployee(data.employee, companyId, search)
            break
          case 'delete':
            employeeRemove(data.employee, companyId, search)
            break
          case 'update':
            updateEmployeeAvatar(data.employee)
            break
        }
      }
    },
    variables: { companyId },
  })
