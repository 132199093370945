import { gql } from '@apollo/client'

import { fileFragment } from './files.gql'

export const blockFragment = gql`
  fragment BlockSchema on Block {
    uuid
    name
    test {
      time
      successRules
      onSuccess {
        goToBlockId
      }
      onFail {
        goToBlockId
        retry
      }
      isResult
    }
    elements
    schema
    files {
      ...FileMetaSchema
    }
    sectionId
    mode
    type
    options {
      backgroundColor
      backgroundImage
      indents
      tabs
      tabsIndex
      tabsPosition
      borderRadius
      questionFeedback
    }
    version
    createdAt
    updatedAt
    tasksCount
  }
  ${fileFragment}
`
export const blockVersionFragment = gql`
  fragment BlockVersionSchema on BlockVersionItem {
    uuid
    name
    test {
      time
      successRules
      onSuccess {
        goToBlockId
      }
      onFail {
        goToBlockId
        retry
      }
      isResult
    }
    elements
    schema
    files {
      ...FileMetaSchema
    }
    sectionId
    mode
    type
    options {
      backgroundColor
      backgroundImage
      indents
      tabs
      tabsIndex
      tabsPosition
    }
    version
    createdAt
    updatedAt
    tasksCount
  }
  ${fileFragment}
`

export const blocksGetById = gql`
  query BlocksGetById($sectionId: String!, $uuid: String!) {
    data: editorBlocksGetById(sectionId: $sectionId, uuid: $uuid) {
      ...BlockSchema
    }
  }
  ${blockFragment}
`

export const blocksGetAllQuery = gql`
  query BlocksAll($sectionId: String!) {
    data: editorBlocksAll(sectionId: $sectionId) {
      ...BlockSchema
    }
  }
  ${blockFragment}
`

export const blocksSubscription = gql`
  subscription EditorBlockSubscription($sectionId: String!) {
    data: editorBlock(sectionId: $sectionId) {
      block {
        ...BlockSchema
      }
      type
      blocksOrder
    }
  }
  ${blockFragment}
`

export const blockUpdateMutation = gql`
  mutation BlocksUpdateById($payload: BlockUpdateInput!) {
    data: editorBlocksUpdateById(payload: $payload) {
      ...BlockSchema
    }
  }
  ${blockFragment}
`
