import gql from 'graphql-tag'

export const routineMaintenanceFragment = gql`
  fragment routineMaintenanceSchema on Announce {
    id
    status
    date
    message
    linkUrl
    linkLabel
  }
`

export const routineMaintenance = gql`
  query AnnounceGetStatus {
    data: announceGetStatus {
      ...routineMaintenanceSchema
    }
  }
  ${routineMaintenanceFragment}
`

export const routineMaintenanceSubscription = gql`
  subscription AnnounceSubscribe {
    data: announceSubscribe {
      ...routineMaintenanceSchema
    }
  }
  ${routineMaintenanceFragment}
`
