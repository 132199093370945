import produce from 'immer'

import { gqlClient } from '.'
import {
  FileMetaGroupAll,
  FileMetaGroupAllVariables,
  FileMetaGroupDeleteByIdsVariables,
  FileMetaGroupDeleteByIds_data_removes,
  FileMetaSchema,
  FileMetasTypesCount,
  FileMetasTypesCountVariables,
  FileMetaType,
  FilesGroupsSubscription_data,
  SubcribeOnCompanyFilesUpdate_data,
  SubcribeOnCompanyFilesUpdate_data_counts,
} from './__generated__/types'
import { fileMetaGroupAllQuery, fileMetasTypesCountQuery } from './files.gql'

export const fileMetaAndGroupRemove = (
  data: FilesGroupsSubscription_data,
  params: FileMetaGroupAllVariables,
): void => {
  const cache = gqlClient.core.cache

  try {
    const cachedFiles = cache.readQuery<FileMetaGroupAll, FileMetaGroupAllVariables>({
      query: fileMetaGroupAllQuery,
      variables: params,
    })

    if (cachedFiles) {
      cache.writeQuery<FileMetaGroupAll, FileMetaGroupAllVariables>({
        query: fileMetaGroupAllQuery,
        variables: params,
        data: {
          breadcrumb: cachedFiles.breadcrumb,
          data: {
            ...cachedFiles.data,
            fileMetas: cachedFiles.data.fileMetas.filter(
              (file) =>
                !data.fileMetas.find((el) => {
                  return el.id === file.id
                }),
            ),
            groups: cachedFiles.data.groups.filter(
              (group) =>
                !data.groups.find((elem) => {
                  return elem.id === group.id
                }),
            ),
          },
        },
      })
    }
  } catch (err) {
    console.error('"fileMetaAndGroupRemove" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const fileMetaAndGroupCreate = (
  data: FilesGroupsSubscription_data,
  params: FileMetaGroupAllVariables,
): void => {
  const cache = gqlClient.core.cache
  try {
    const cachedFiles = cache.readQuery<FileMetaGroupAll, FileMetaGroupAllVariables>({
      query: fileMetaGroupAllQuery,
      variables: params,
    })

    if (cachedFiles) {
      const notExistingFileMetas = data.fileMetas.filter(
        (item) => !cachedFiles.data.fileMetas.find((el) => el.id === item.id),
      )
      const notExistingGroups = data.groups.filter(
        (item) => !cachedFiles.data.groups.find((el) => el.id === item.id),
        // && cachedFiles.data.groups.find(element=> element.parentId === params.parentId)
      )

      cache.writeQuery<FileMetaGroupAll, FileMetaGroupAllVariables>({
        query: fileMetaGroupAllQuery,
        variables: params,
        data: {
          breadcrumb: cachedFiles.breadcrumb,
          data: {
            ...cachedFiles.data,
            fileMetas: [...cachedFiles.data.fileMetas, ...notExistingFileMetas],
            groups: [...cachedFiles.data.groups, ...notExistingGroups],
          },
        },
      })
    }
  } catch (err) {
    console.error('"fileMetaAndGroupCreate" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const fileMetaCreate = (
  data: SubcribeOnCompanyFilesUpdate_data,
  params: FileMetaGroupAllVariables,
): void => {
  const cache = gqlClient.core.cache

  try {
    const cachedFiles = cache.readQuery<FileMetaGroupAll, FileMetaGroupAllVariables>({
      query: fileMetaGroupAllQuery,
      variables: params,
    })

    if (cachedFiles) {
      const existingFilesMeta = cachedFiles.data.fileMetas.find(
        (item) => data.files[0].id === item.id,
      )
      if (!existingFilesMeta) {
        cache.writeQuery<FileMetaGroupAll, FileMetaGroupAllVariables>({
          query: fileMetaGroupAllQuery,
          variables: params,
          data: {
            breadcrumb: cachedFiles.breadcrumb,
            data: {
              ...cachedFiles.data,
              fileMetas: [...cachedFiles.data.fileMetas, data.files[0]],
            },
          },
        })
      }
    }
  } catch (err) {
    console.error('"fileMetaCreate" fn is crashed on operation: ".writeQuery"', err)
  }
}

//TODO replace with type from backend when develop pagination
export const fileMetasByCompanyUpdateCount = (
  newCounts: SubcribeOnCompanyFilesUpdate_data_counts[],
  companyId: string,
): void => {
  const cache = gqlClient.core.cache

  try {
    const cachedCount = cache.readQuery<FileMetasTypesCount, FileMetasTypesCountVariables>({
      query: fileMetasTypesCountQuery,
      variables: {
        id: companyId,
        fileMetaType: FileMetaType.companies,
      },
    })

    if (cachedCount) {
      cache.writeQuery<FileMetasTypesCount, FileMetasTypesCountVariables>({
        query: fileMetasTypesCountQuery,
        variables: {
          id: companyId,
          fileMetaType: FileMetaType.companies,
        },

        data: produce(cachedCount, (draft) => {
          newCounts.map(
            (elem) =>
              (draft.data = draft.data.map((drafted) => {
                drafted.count = drafted.type === elem.type ? elem.count : drafted.count
                return drafted
              })),
          )
        }),
      })
    }
  } catch (err) {
    console.error('"fileMetasByCompanyUpdateCount" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const fileMetaRemoveFakeFile = (id: string): void => {
  const cache = gqlClient.core.cache

  try {
    cache.evict({
      id: cache.identify({ __typename: 'FileMeta', id }),
    })
    cache.gc()
  } catch (err) {
    console.error('"fileMetaRemoveFakeFile" fn is crashed on operation: ".evict"', err)
  }
}

// upsert after mutation
export const fileMetaUpsertUpdateFileList = (
  params: FileMetaGroupAllVariables,
  fileMetas: FileMetaSchema,
): void => {
  const cache = gqlClient.core.cache
  try {
    const cachedFiles = cache.readQuery<FileMetaGroupAll, FileMetaGroupAllVariables>({
      query: fileMetaGroupAllQuery,
      variables: params,
    })

    const isExist = cachedFiles?.data.fileMetas.find((oldFile) => oldFile.id === fileMetas.id)
    if (cachedFiles && !isExist) {
      cache.writeQuery<FileMetaGroupAll, FileMetaGroupAllVariables>({
        query: fileMetaGroupAllQuery,
        variables: params,
        data: produce(cachedFiles, (draft) => {
          draft!.data.fileMetas.push(fileMetas)
        }),
      })
    }
  } catch (err) {
    console.error('"fileMetaUpsertUpdateFileList" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const fileMetaGroupAllQueryRemove = (
  removes: FileMetaGroupDeleteByIds_data_removes[] | undefined,
  params: FileMetaGroupDeleteByIdsVariables,
): void => {
  const cache = gqlClient.core.cache

  try {
    const cachedFiles = cache.readQuery<FileMetaGroupAll, FileMetaGroupAllVariables>({
      query: fileMetaGroupAllQuery,
      variables: params,
    })

    if (cachedFiles && removes?.length) {
      cache.writeQuery<FileMetaGroupAll, FileMetaGroupAllVariables>({
        query: fileMetaGroupAllQuery,
        variables: params,
        data: {
          breadcrumb: cachedFiles.breadcrumb,
          data: {
            ...cachedFiles.data,
            fileMetas: cachedFiles.data.fileMetas.filter(
              (file) =>
                !removes.find((el) => {
                  return el.id === file.id
                }),
            ),
            groups: cachedFiles.data.groups.filter(
              (group) =>
                !removes.find((elem) => {
                  return elem.id === group.id
                }),
            ),
          },
        },
      })
    }
  } catch (err) {
    console.error('"fileMetaGroupAllQueryRemove" fn is crashed on operation: ".writeQuery"', err)
  }
}
