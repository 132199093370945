import { nanoid } from 'nanoid'

import { SectionBlockType } from '@ui/models'

export const sliderLayoutTemplate = (id: string): SectionBlockType => {
  return {
    __typename: 'Block',
    elements: {
      [id]: {
        id: id,
        options: {},
        type: 'empty',
        containerOptions: {
          backgroundColor: '#fff',
        },
      },
    },
    type: 'slider',
    options: {
      tabsIndex: null,
      backgroundColor: '#fff',
      tabs: ['Заголовок 1'],
      __typename: 'BlockOptions',
      backgroundImage: null,
      indents: null,
      tabsPosition: null,
      borderRadius: 10,
      questionFeedback: null,
    },
    files: [],
    name: 'Карусель',
    mode: 'view',
    uuid: nanoid(),
    version: 0,
    schema: [[[id]]],
    sectionId: '',
    test: null,
    tasksCount: 0,
    createdAt: Date.now().toString(),
    updatedAt: Date.now().toString(),
  }
}
