import gql from 'graphql-tag'

import { projectListFragment } from './projects.gql'

export const projectGroupListFragment = gql`
  fragment ProjectGroupListSchema on ProjectGroup {
    id
    name
    description
    logo
    color
    parentId
    createdAt
    updatedAt
    analitycs {
      projectsTotal
      tasksDone
      tasksTotal
      tasksExpired
    }
  }
`

export const projectGroupFragment = gql`
  fragment ProjectGroupSchema on ProjectGroup {
    id
    name
    color
    parentId
    description
    updatedAt
    breadcrumbs {
      name
      id
    }
  }
`

export const projectGroupsByIdQuery = gql`
  query ProjectGroupsGetById($groupId: String!, $companyId: String!) {
    data: projectGroupsGetById(companyId: $companyId, groupId: $groupId) {
      ...ProjectGroupSchema
    }
  }
  ${projectGroupFragment}
`

export const projectGroupAll = gql`
  query ProjectGroupsAll($companyId: String!, $parentId: String, $search: ProjectGroupsSearch) {
    breadcrumb: projectGroupsGetById(companyId: $companyId, groupId: $parentId) {
      ...ProjectGroupSchema
    }
    data: projectAndGroupAll(companyId: $companyId, search: $search) {
      projects {
        ...ProjectListSchema
      }
      groups {
        ...ProjectGroupListSchema
      }
      total
      totalGroup
      limit
      offset
    }
  }
  ${projectGroupFragment}
  ${projectGroupListFragment}
  ${projectListFragment}
`

export const projectGroupListSubscription = gql`
  subscription ProjectGroupSubscription($companyId: String!, $parentId: String) {
    data: projectGroup(companyId: $companyId, parentId: $parentId) {
      group {
        ...ProjectGroupListSchema
      }
      type
    }
  }
  ${projectGroupListFragment}
`

export const groupsSubscriptionProject = gql`
  subscription groupsSubscription($companyId: String!) {
    data: projectGroup(companyId: $companyId) {
      group {
        ...ProjectGroupListSchema
      }
      type
    }
  }
  ${projectGroupListFragment}
`
