export const queryStringSirialazer = (obj: any) => {
  const str = []
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      if (obj[p] !== undefined && obj[p] !== '' && obj[p] !== null) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
      }
    }
  return str.join('&')
}
