import { gql, useMutation, useQuery } from '@apollo/client'

import {
  changeEmail,
  changeEmailVariables,
  changeUsername,
  changeUsernameVariables,
  getKUser,
  kUsersUpdateById,
  kUsersUpdateByIdVariables,
  sendMeLinkForChangePassword,
  sendMeLinkForChangePasswordVariables,
} from '@ui/types'

import { gqlClient } from '.'
import { avatarFragment } from './avatar.gql'
import { profileUpdateCache } from './profile.cache'
import { getUser, kUserInfoFragment } from './profile.gql'

export const useNewPassword = () =>
  useMutation<sendMeLinkForChangePassword, sendMeLinkForChangePasswordVariables>(
    gql`
      mutation sendMeLinkForChangePassword($password: String!) {
        data: sendMeLinkForChangePassword(password: $password)
      }
    `,
    {
      onError: (err) =>
        console.error('"useNewPassword" fn is crashed on operation: "useMutation"', err),
      client: gqlClient.auth,
    },
  )

export const useChangeEmail = () =>
  useMutation<changeEmail, changeEmailVariables>(
    gql`
      mutation changeEmail($password: String!, $newEmail: String!) {
        data: changeEmail(password: $password, newEmail: $newEmail)
      }
    `,
    {
      onError: (err) =>
        console.error('"useChangeEmail" fn is crashed on operation: "useMutation"', err),
      client: gqlClient.auth,
    },
  )

export const useConfirmChangeEmail = () =>
  useMutation(
    gql`
      mutation confirmChangeEmail($newEmail: String!) {
        data: confirmChangeEmail(newEmail: $newEmail)
      }
    `,
    {
      onError: (err) =>
        console.error('"useConfirmChangeEmail" fn is crashed on operation: "useMutation"', err),
      client: gqlClient.auth,
    },
  )

export const useCancelChangeEmail = () =>
  useMutation(
    gql`
      mutation cancelChangeEmail($newEmail: String!) {
        data: cancelChangeEmail(newEmail: $newEmail)
      }
    `,
    {
      update: (_, { data: item }) => {
        try {
          if (item) {
            profileUpdateCache(item.data)
          }
        } catch (err) {
          console.error(
            '"useCancelChangeEmail" fn is crashed on operation: "profileUpdateCache"',
            err,
          )
        }
      },
      onError: (err) =>
        console.error('"useCancelChangeEmail" fn is crashed on operation: "useMutation"', err),
      client: gqlClient.auth,
    },
  )

export const useChangeUsername = () =>
  useMutation<changeUsername, changeUsernameVariables>(
    gql`
      mutation changeUsername($password: String!, $newUsername: String!) {
        data: changeUsername(password: $password, newUsername: $newUsername) {
          avatar {
            ...AvatarSchema
          }
          ...kUserInfoSchema
        }
      }
      ${kUserInfoFragment}
      ${avatarFragment}
    `,
    {
      update: (_, { data: item }) => {
        try {
          if (item && item.data) {
            profileUpdateCache(item.data)
          }
        } catch (err) {
          console.error('"useChangeUsername" fn is crashed on operation: "profileUpdateCache"', err)
        }
      },
      onError: (err) =>
        console.error('"useChangeUsername" fn is crashed on operation: "useMutation"', err),
      client: gqlClient.auth,
    },
  )

export const getKUserInfo = () => {
  gqlClient.core.query<getKUser>({
    query: getUser,
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
  })
}

export const useUpdateUser = () =>
  useMutation<kUsersUpdateById, kUsersUpdateByIdVariables>(
    gql`
      mutation kUsersUpdateById($id: String!, $data: kUserUpdateInput!) {
        data: kUsersUpdateById(id: $id, data: $data) {
          avatar {
            ...AvatarSchema
          }
          ...kUserInfoSchema
        }
      }
      ${kUserInfoFragment}
      ${avatarFragment}
    `,
    {
      update: (_, { data: item }) => {
        try {
          if (item && item.data) {
            /* TODO пока бек не может вернуть в мутации файлы мы делаем рефетч.
               После фикса бека убрать раскоментить */
            // profileUpdateCache(item.data);
          }
        } catch (err) {
          console.error(
            '"useUpdateUser" fn is crashed on operation: "profileUpdateCache or getKUserInfo"',
            err,
          )
        }
      },
      onError: (err) =>
        console.error('"useUpdateUser" fn is crashed on operation: "useMutation"', err),
      client: gqlClient.auth,
    },
  )

export const useGetUserInfo = (
  cache = false,
  fetchPolicy?:
    | 'cache-first'
    | 'network-only'
    | 'cache-only'
    | 'no-cache'
    | 'standby'
    | 'cache-and-network'
    | undefined,
) =>
  useQuery<getKUser>(getUser, {
    onError: (err) => console.error('"useGetUserInfo" fn is crashed on operation: "useQuery"', err),
    client: gqlClient.core,
    nextFetchPolicy: fetchPolicy,
    fetchPolicy: cache ? 'cache-and-network' : 'cache-only',
  })
