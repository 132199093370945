type ColorsTypes = {
  black: '#000000'
  primary: '#01b075'
  purpure: '#778BEB'
  coral: '#C44569'
  orange: '#E15F41'
  yellow: '#F5CD79'
  green: '#05C46B'
  [key: string]: string
}

export const colorsTypes: ColorsTypes = {
  black: '#000000',
  primary: '#01b075',
  purpure: '#778BEB',
  coral: '#C44569',
  orange: '#E15F41',
  yellow: '#F5CD79',
  green: '#05C46B',
}

export const colorsTypesAccents = {
  black: '#666666',
  primary: 'var(--accent-bg-color)',
  purpure: '#ccd4fd',
  coral: '#bda3ab',
  orange: '#fbad9b',
  yellow: '#f3e6cb',
  green: '#bfe8d5',
} as {
  [key: string]: string
}

export const colors = Object.keys(colorsTypes).map((e) => ({
  accent: colorsTypes[e],
  accentWr: colorsTypesAccents[e],
}))

export const colorsMap = Object.keys(colorsTypes).reduce((result, e) => {
  result[colorsTypes[e]] = {
    accent: colorsTypes[e],
    accentWr: colorsTypesAccents[e],
  }
  return result
}, {} as { [key: string]: { accent: string; accentWr: string } })
