import produce from 'immer'

import {
  ProjectGroupsAll,
  ProjectGroupsAllVariables,
  ProjectGroupsAll_data_groups,
  ProjectGroupsSearch,
  ProjectsGetById,
  ProjectsGetByIdVariables,
} from '@ui/types'

import { gqlClient } from '.'
import { projectGroupAll, projectGroupsByIdQuery } from './project-groups.gql'

export const projectsGroupAddCache = (
  group: ProjectGroupsAll_data_groups,
  companyId: string,
  search: ProjectGroupsSearch,
) => {
  const cache = gqlClient.core.cache

  try {
    const data = cache.readQuery<ProjectGroupsAll, ProjectGroupsAllVariables>({
      query: projectGroupAll,
      variables: {
        companyId: companyId,
        parentId: search.parentId,
        search,
      },
    })
    const existingItem = data?.data.groups.find((item) => item.id === group.id)
    if (!existingItem && data) {
      cache.writeQuery<ProjectGroupsAll, ProjectGroupsAllVariables>({
        query: projectGroupAll,
        variables: {
          companyId: companyId,
          parentId: search.parentId,
          search,
        },
        data: produce(data, (draft) => {
          draft!.data.groups.push(group)
          if (draft!.data.total) {
            draft!.data.total += 1
          }
        }),
      })
    }
  } catch (err) {
    console.error('"projectsGroupAddCache" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const projectsGroupUpdateCache = (
  group: ProjectGroupsAll_data_groups,
  companyId: string,
  search: ProjectGroupsSearch,
  parentId?: string | null,
) => {
  const cache = gqlClient.core.cache

  try {
    const data = cache.readQuery<ProjectGroupsAll, ProjectGroupsAllVariables>({
      query: projectGroupAll,
      variables: {
        companyId: companyId,
        parentId: parentId,
        search: search,
      },
    })
    if (data) {
      cache.writeQuery<ProjectGroupsAll, ProjectGroupsAllVariables>({
        query: projectGroupAll,
        variables: {
          companyId: companyId,
          parentId: parentId,
          search: search,
        },
        data: produce(data, (draft) => {
          if (group) {
            const index = draft.data.groups.findIndex((e) => e.id === group?.id)
            draft!.data.groups[index] = group
          }
        }),
      })
    }
  } catch (err) {
    console.error('"projectsGroupUpdateCache" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const projectsGroupRemoveCache = (
  projectGroupId: string,
  companyId: string,
  search: any,
  parentId?: string | null,
) => {
  const cache = gqlClient.core.cache

  try {
    const data = cache.readQuery<ProjectGroupsAll, ProjectGroupsAllVariables>({
      query: projectGroupAll,
      variables: {
        companyId: companyId,
        parentId: parentId,
        search: search,
      },
    })
    if (data) {
      cache.writeQuery<ProjectsGetById, ProjectsGetByIdVariables>({
        query: projectGroupsByIdQuery,
        variables: {
          projectId: projectGroupId,
        },
        data: {
          data: null,
        },
      })

      cache.writeQuery<ProjectGroupsAll, ProjectGroupsAllVariables>({
        query: projectGroupAll,
        variables: {
          companyId: companyId,
          parentId: parentId,
          search: search,
        },
        data: produce(data, (draft) => {
          if (draft.data) {
            draft.data.groups = draft.data.groups.filter((e) => e.id !== projectGroupId)
            if (draft!.data.total) {
              draft!.data.total -= 1
            }
          }
        }),
      })
    }
  } catch (err) {
    console.error('"projectsGroupRemoveCache" fn is crashed on operation: ".writeQuery"', err)
  }
}
