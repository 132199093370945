import { gql } from '@apollo/client'

import { fileFragment } from './files.gql'

export const avatarFragment = gql`
  fragment AvatarSchema on FileMeta {
    ...FileMetaSchema
  }
  ${fileFragment}
`
