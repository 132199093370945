import { gql, useLazyQuery, useSubscription } from '@apollo/client'

import { gqlClient } from '.'
import {
  FocusInputQuery,
  FocusInputQueryVariables,
  FocusInputSubscribtin,
} from './__generated__/types'

export const simpleInputQuery = (params: { [key: string]: any }) => {
  try {
    const isLogout = localStorage.getItem('logout-process')
    if (!isLogout) {
      gqlClient.core.query({
        query: focusInputQuery,
        variables: {
          ...params,
        },
        fetchPolicy: 'no-cache',
      })
    }
  } catch (err) {
    console.error('"simpleInputQuery" fn is crashed on operation: ".query"', err, params)
  }
}

// input focused
export const useInputFocusLazy = () =>
  useLazyQuery(focusInputQuery, {
    fetchPolicy: 'no-cache',
    onError: (err) =>
      console.error('"useInputFocusLazy" fn is crashed on operation: "useLazyQuery"', err),
  })

export const loadInputFocusLazy = (variables: FocusInputQueryVariables) => {
  gqlClient.core.query<FocusInputQuery, FocusInputQueryVariables>({
    query: focusInputQuery,
    variables,
    errorPolicy: 'ignore',
  })
}

export const focusInputQuery = gql`
  query FocusInputQuery($typeField: inputType!, $value: pageValue!) {
    data: pageFocusInput(typeField: $typeField, value: $value)
  }
`

export const useFocusInputSubscription = (companyId: string, userId: string, namePage: string) =>
  useSubscription<FocusInputSubscribtin>(focusInputSubscribtin, {
    variables: {
      companyId,
      userId,
      namePage,
    },
  })

export const focusInputSubscribtin = gql`
  subscription FocusInputSubscribtin($companyId: String!, $userId: String!, $namePage: String!) {
    data: pageFocusInput(companyId: $companyId, userId: $userId, namePage: $namePage)
  }
`
