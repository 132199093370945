import React from 'react'

const FolderFilledIcon = ({
  accent = '#71B9F4',
  accentWr = '#92CEFF',
  style,
}: {
  accent?: string
  accentWr?: string
  style?: React.CSSProperties
}) => {
  return (
    <svg height='1em' style={style} viewBox='0 0 40 40' width='1em'>
      <g fill='none' fillRule='evenodd'>
        <path
          d='M18.422 11h15.07c.84 0 1.508.669 1.508 1.493v18.014c0 .818-.675 1.493-1.508 1.493H6.508C5.668 32 5 31.331 5 30.507V9.493C5 8.663 5.671 8 6.5 8h7.805c.564 0 1.229.387 1.502.865l1.015 1.777s.4.358 1.6.358z'
          fill={accent}
        />
        <path
          d='M18.422 10h15.07c.84 0 1.508.669 1.508 1.493v18.014c0 .818-.675 1.493-1.508 1.493H6.508C5.668 31 5 30.331 5 29.507V8.493C5 7.663 5.671 7 6.5 7h7.805c.564 0 1.229.387 1.502.865l1.015 1.777s.4.358 1.6.358z'
          fill={accentWr}
        />
        <path
          d='M20.013 25.14l-.5-1.302C19.12 22.82 17.918 22 16.824 22h-.907c-1.087 0-2.297.823-2.687 1.838l-.454 1.18c-.197.514.088.937.642.937h13.189c.554 0 .84-.42.641-.937l-.453-1.18C26.404 22.82 25.202 22 24.109 22H23.2c-1.087 0-2.297.823-2.687 1.838l-.5 1.303zM16.5 20a2 2 0 110-4 2 2 0 010 4zm7 0a2 2 0 110-4 2 2 0 010 4z'
          fill='currentColor'
        />
      </g>
    </svg>
  )
}

export { FolderFilledIcon }
