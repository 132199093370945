import { produce } from 'immer'

import {
  EmployeesAll,
  EmployeesAllVariables,
  EmployeeSearch,
  EmployeesSubscribe_data_employee,
} from '@ui/types'

import { gqlClient } from '.'
import { employeesGetAllQuery } from './employees.gql'

export const employeeRemove = (
  employee: EmployeesSubscribe_data_employee,
  companyId: string,
  search?: EmployeeSearch,
) => {
  const cache = gqlClient.core.cache

  try {
    if (cache) {
      cache.evict({
        id: cache.identify({ __typename: 'Employee', id: employee.id }),
      })
      cache.gc()
    }

    // const data = cache.readQuery<EmployeesAll, EmployeesAllVariables>({
    //   query: employeesGetAllQuery,
    //   variables: {
    //     companyId,
    //     search
    //   }
    // })

    // if(data) {
    //   cache.writeQuery<EmployeesAll, EmployeesAllVariables>({
    //     query: employeesGetAllQuery,
    //     variables: {
    //       companyId,
    //       search
    //     },
    //     data: produce(data, (draft)=> {
    //       draft!.data.employees = draft!.data.employees.filter(item => item.id !== employee.id)
    //     })
    //   })
    // }
  } catch (err) {
    console.error('"employeeRemove" fn is crashed on operation: ".evict or .gc"', err)
  }
}

export const addEmployee = (
  employee: EmployeesSubscribe_data_employee,
  companyId: string,
  search?: EmployeeSearch,
) => {
  const cache = gqlClient.core.cache
  try {
    const data = cache.readQuery<EmployeesAll, EmployeesAllVariables>({
      query: employeesGetAllQuery,
      variables: {
        companyId,
        search,
      },
    })
    if (data) {
      const existingEmployee = data.data.employees.find((item) => item.id === employee.id)
      if (!existingEmployee) {
        cache.writeQuery<EmployeesAll, EmployeesAllVariables>({
          query: employeesGetAllQuery,
          variables: {
            companyId,
            search,
          },
          data: produce(data, (draft) => {
            const previewEmployees = draft!.data.employees || []
            draft!.data.employees = [employee, ...previewEmployees]
          }),
        })
      }
    }
  } catch (err) {
    console.error('"addEmployee" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const updateEmployeeAvatar = (employee: EmployeesSubscribe_data_employee) => {
  try {
    const cache = gqlClient.core.cache
    cache.modify({
      id: cache.identify({
        __typename: 'Employee',
        id: employee?.id,
      }),
      fields: {
        avatar: () => employee?.avatar,
      },
    })
  } catch (err) {
    console.error('"updateEmployeeAvatar" fn is crashed on operation: ".modify"', err)
  }
}
