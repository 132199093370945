import { produce } from 'immer'

import { getKUser, kUsersUpdateById_data, SubcribeOnCompanyFilesUpdate_data_files } from '@ui/types'

import { gqlClient } from '.'
import { getUser } from './profile.gql'

export const profileUpdateCache = (profile: kUsersUpdateById_data) => {
  const cache = gqlClient.core.cache

  try {
    const data = cache.readQuery<getKUser>({
      query: getUser,
    })

    if (profile.avatar) {
      cache.modify({
        id: cache.identify({
          __typename: 'KUser',
          id: profile.id,
        }),
        fields: {
          avatar: () => profile.avatar,
        },
      })
    }

    if (data) {
      cache.writeQuery<getKUser>({
        query: getUser,
        data: produce(data, (draft) => {
          draft.data = profile
        }),
      })
    }
  } catch (err) {
    console.error('"profileUpdateCache" fn is crashed on operation: ".modify or .writeQuery"', err)
  }
}

export const profileUpdateCacheAvatar = (
  files: SubcribeOnCompanyFilesUpdate_data_files[],
): void => {
  const cache = gqlClient.core.cache

  try {
    const data = cache.readQuery<getKUser>({
      query: getUser,
    })

    if (data) {
      cache.writeQuery<getKUser>({
        query: getUser,
        data: produce(data, (draft) => {
          if (draft.data.avatar) {
            draft.data.avatar = { ...files[0] }
          }
        }),
      })
    }
  } catch (err) {
    console.error('"profileUpdateCache" fn is crashed on operation: ".modify or .writeQuery"', err)
  }
}
