export const getIntervalValueDependsOnBatteryLevel = (min: number, max: number) => {
  const battery =
    // @ts-ignore
    window.navigator.battery ||
    // @ts-ignore
    navigator.webkitBattery ||
    // @ts-ignore
    navigator.mozBattery ||
    // @ts-ignore
    navigator.msBattery

  if (battery) {
    const isOK = battery.charging || battery.level > 0.25
    return isOK ? min : max
  }
  return min
}
