import { useRouteMatch, useHistory, useLocation } from 'react-router'

import { EditorParams } from '@ui/models'

export function useRouter() {
  const match = useRouteMatch<EditorParams>()
  const history = useHistory()
  const location = useLocation()
  return {
    match,
    history,
    location,
  }
}
