/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BlocksVersions
// ====================================================

export interface BlocksVersions_data_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface BlocksVersions_data_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface BlocksVersions_data_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: BlocksVersions_data_test_onSuccess | null;
  onFail: BlocksVersions_data_test_onFail | null;
  isResult: boolean | null;
}

export interface BlocksVersions_data_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface BlocksVersions_data_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: BlocksVersions_data_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface BlocksVersions_data_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
}

export interface BlocksVersions_data {
  __typename: "BlockVersionItem";
  uuid: string;
  name: string;
  test: BlocksVersions_data_test | null;
  elements: any;
  schema: string[][][] | null;
  files: BlocksVersions_data_files[];
  sectionId: string;
  mode: string;
  type: string;
  options: BlocksVersions_data_options;
  version: number | null;
  createdAt: any;
  updatedAt: any;
  tasksCount: number;
}

export interface BlocksVersions {
  data: BlocksVersions_data[];
}

export interface BlocksVersionsVariables {
  uuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BlocksMultiCreate
// ====================================================

export interface BlocksMultiCreate_data_blocks_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface BlocksMultiCreate_data_blocks_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface BlocksMultiCreate_data_blocks_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: BlocksMultiCreate_data_blocks_test_onSuccess | null;
  onFail: BlocksMultiCreate_data_blocks_test_onFail | null;
  isResult: boolean | null;
}

export interface BlocksMultiCreate_data_blocks_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface BlocksMultiCreate_data_blocks_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: BlocksMultiCreate_data_blocks_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface BlocksMultiCreate_data_blocks_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface BlocksMultiCreate_data_blocks {
  __typename: "Block";
  uuid: string;
  name: string;
  test: BlocksMultiCreate_data_blocks_test | null;
  elements: any;
  schema: string[][][] | null;
  files: BlocksMultiCreate_data_blocks_files[];
  sectionId: string;
  mode: string;
  type: string;
  options: BlocksMultiCreate_data_blocks_options;
  version: number | null;
  createdAt: any;
  updatedAt: any;
  tasksCount: number;
}

export interface BlocksMultiCreate_data {
  __typename: "BlocksResult";
  blocks: BlocksMultiCreate_data_blocks[];
  blocksOrder: string[] | null;
}

export interface BlocksMultiCreate {
  data: BlocksMultiCreate_data;
}

export interface BlocksMultiCreateVariables {
  payload: BlockMultiCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BlocksCreate
// ====================================================

export interface BlocksCreate_data_block_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface BlocksCreate_data_block_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface BlocksCreate_data_block_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: BlocksCreate_data_block_test_onSuccess | null;
  onFail: BlocksCreate_data_block_test_onFail | null;
  isResult: boolean | null;
}

export interface BlocksCreate_data_block_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface BlocksCreate_data_block_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: BlocksCreate_data_block_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface BlocksCreate_data_block_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface BlocksCreate_data_block {
  __typename: "Block";
  uuid: string;
  name: string;
  test: BlocksCreate_data_block_test | null;
  elements: any;
  schema: string[][][] | null;
  files: BlocksCreate_data_block_files[];
  sectionId: string;
  mode: string;
  type: string;
  options: BlocksCreate_data_block_options;
  version: number | null;
  createdAt: any;
  updatedAt: any;
  tasksCount: number;
}

export interface BlocksCreate_data {
  __typename: "BlockCreateResult";
  block: BlocksCreate_data_block;
  blocksOrder: string[] | null;
}

export interface BlocksCreate {
  data: BlocksCreate_data;
}

export interface BlocksCreateVariables {
  payload: BlockInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BlocksDeleteById
// ====================================================

export interface BlocksDeleteById_data {
  __typename: "BlockRemoveResult";
  uuid: string | null;
  blocksOrder: string[] | null;
}

export interface BlocksDeleteById {
  data: BlocksDeleteById_data;
}

export interface BlocksDeleteByIdVariables {
  uuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BlocksDeleteByIds
// ====================================================

export interface BlocksDeleteByIds_data {
  __typename: "BlocksRemoveResult";
  uuid: string[];
  blocksOrder: string[] | null;
}

export interface BlocksDeleteByIds {
  data: BlocksDeleteByIds_data;
}

export interface BlocksDeleteByIdsVariables {
  uuid: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BlocksCreateVersion
// ====================================================

export interface BlocksCreateVersion_data_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface BlocksCreateVersion_data_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface BlocksCreateVersion_data_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: BlocksCreateVersion_data_test_onSuccess | null;
  onFail: BlocksCreateVersion_data_test_onFail | null;
  isResult: boolean | null;
}

export interface BlocksCreateVersion_data_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface BlocksCreateVersion_data_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: BlocksCreateVersion_data_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface BlocksCreateVersion_data_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface BlocksCreateVersion_data {
  __typename: "Block";
  uuid: string;
  name: string;
  test: BlocksCreateVersion_data_test | null;
  elements: any;
  schema: string[][][] | null;
  files: BlocksCreateVersion_data_files[];
  sectionId: string;
  mode: string;
  type: string;
  options: BlocksCreateVersion_data_options;
  version: number | null;
  createdAt: any;
  updatedAt: any;
  tasksCount: number;
}

export interface BlocksCreateVersion {
  data: BlocksCreateVersion_data | null;
}

export interface BlocksCreateVersionVariables {
  uuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BlockSetActiveByVersion
// ====================================================

export interface BlockSetActiveByVersion_data_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface BlockSetActiveByVersion_data_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface BlockSetActiveByVersion_data_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: BlockSetActiveByVersion_data_test_onSuccess | null;
  onFail: BlockSetActiveByVersion_data_test_onFail | null;
  isResult: boolean | null;
}

export interface BlockSetActiveByVersion_data_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface BlockSetActiveByVersion_data_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: BlockSetActiveByVersion_data_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface BlockSetActiveByVersion_data_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface BlockSetActiveByVersion_data {
  __typename: "Block";
  uuid: string;
  name: string;
  test: BlockSetActiveByVersion_data_test | null;
  elements: any;
  schema: string[][][] | null;
  files: BlockSetActiveByVersion_data_files[];
  sectionId: string;
  mode: string;
  type: string;
  options: BlockSetActiveByVersion_data_options;
  version: number | null;
  createdAt: any;
  updatedAt: any;
  tasksCount: number;
}

export interface BlockSetActiveByVersion {
  data: BlockSetActiveByVersion_data | null;
}

export interface BlockSetActiveByVersionVariables {
  uuid: string;
  version: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BlocksCopy
// ====================================================

export interface BlocksCopy_data_blocks_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface BlocksCopy_data_blocks_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface BlocksCopy_data_blocks_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: BlocksCopy_data_blocks_test_onSuccess | null;
  onFail: BlocksCopy_data_blocks_test_onFail | null;
  isResult: boolean | null;
}

export interface BlocksCopy_data_blocks_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface BlocksCopy_data_blocks_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: BlocksCopy_data_blocks_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface BlocksCopy_data_blocks_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface BlocksCopy_data_blocks {
  __typename: "Block";
  uuid: string;
  name: string;
  test: BlocksCopy_data_blocks_test | null;
  elements: any;
  schema: string[][][] | null;
  files: BlocksCopy_data_blocks_files[];
  sectionId: string;
  mode: string;
  type: string;
  options: BlocksCopy_data_blocks_options;
  version: number | null;
  createdAt: any;
  updatedAt: any;
  tasksCount: number;
}

export interface BlocksCopy_data {
  __typename: "BlocksResult";
  blocks: BlocksCopy_data_blocks[];
  blocksOrder: string[] | null;
}

export interface BlocksCopy {
  data: BlocksCopy_data;
}

export interface BlocksCopyVariables {
  payload: BlockMultiCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BlocksGetById
// ====================================================

export interface BlocksGetById_data_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface BlocksGetById_data_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface BlocksGetById_data_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: BlocksGetById_data_test_onSuccess | null;
  onFail: BlocksGetById_data_test_onFail | null;
  isResult: boolean | null;
}

export interface BlocksGetById_data_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface BlocksGetById_data_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: BlocksGetById_data_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface BlocksGetById_data_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface BlocksGetById_data {
  __typename: "Block";
  uuid: string;
  name: string;
  test: BlocksGetById_data_test | null;
  elements: any;
  schema: string[][][] | null;
  files: BlocksGetById_data_files[];
  sectionId: string;
  mode: string;
  type: string;
  options: BlocksGetById_data_options;
  version: number | null;
  createdAt: any;
  updatedAt: any;
  tasksCount: number;
}

export interface BlocksGetById {
  data: BlocksGetById_data | null;
}

export interface BlocksGetByIdVariables {
  sectionId: string;
  uuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BlocksAll
// ====================================================

export interface BlocksAll_data_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface BlocksAll_data_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface BlocksAll_data_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: BlocksAll_data_test_onSuccess | null;
  onFail: BlocksAll_data_test_onFail | null;
  isResult: boolean | null;
}

export interface BlocksAll_data_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface BlocksAll_data_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: BlocksAll_data_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface BlocksAll_data_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface BlocksAll_data {
  __typename: "Block";
  uuid: string;
  name: string;
  test: BlocksAll_data_test | null;
  elements: any;
  schema: string[][][] | null;
  files: BlocksAll_data_files[];
  sectionId: string;
  mode: string;
  type: string;
  options: BlocksAll_data_options;
  version: number | null;
  createdAt: any;
  updatedAt: any;
  tasksCount: number;
}

export interface BlocksAll {
  data: BlocksAll_data[];
}

export interface BlocksAllVariables {
  sectionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: EditorBlockSubscription
// ====================================================

export interface EditorBlockSubscription_data_block_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface EditorBlockSubscription_data_block_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface EditorBlockSubscription_data_block_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: EditorBlockSubscription_data_block_test_onSuccess | null;
  onFail: EditorBlockSubscription_data_block_test_onFail | null;
  isResult: boolean | null;
}

export interface EditorBlockSubscription_data_block_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface EditorBlockSubscription_data_block_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: EditorBlockSubscription_data_block_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface EditorBlockSubscription_data_block_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface EditorBlockSubscription_data_block {
  __typename: "Block";
  uuid: string;
  name: string;
  test: EditorBlockSubscription_data_block_test | null;
  elements: any;
  schema: string[][][] | null;
  files: EditorBlockSubscription_data_block_files[];
  sectionId: string;
  mode: string;
  type: string;
  options: EditorBlockSubscription_data_block_options;
  version: number | null;
  createdAt: any;
  updatedAt: any;
  tasksCount: number;
}

export interface EditorBlockSubscription_data {
  __typename: "BlockSubscriptionResult";
  block: EditorBlockSubscription_data_block | null;
  type: string;
  blocksOrder: string[] | null;
}

export interface EditorBlockSubscription {
  data: EditorBlockSubscription_data;
}

export interface EditorBlockSubscriptionVariables {
  sectionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BlocksUpdateById
// ====================================================

export interface BlocksUpdateById_data_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface BlocksUpdateById_data_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface BlocksUpdateById_data_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: BlocksUpdateById_data_test_onSuccess | null;
  onFail: BlocksUpdateById_data_test_onFail | null;
  isResult: boolean | null;
}

export interface BlocksUpdateById_data_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface BlocksUpdateById_data_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: BlocksUpdateById_data_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface BlocksUpdateById_data_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface BlocksUpdateById_data {
  __typename: "Block";
  uuid: string;
  name: string;
  test: BlocksUpdateById_data_test | null;
  elements: any;
  schema: string[][][] | null;
  files: BlocksUpdateById_data_files[];
  sectionId: string;
  mode: string;
  type: string;
  options: BlocksUpdateById_data_options;
  version: number | null;
  createdAt: any;
  updatedAt: any;
  tasksCount: number;
}

export interface BlocksUpdateById {
  data: BlocksUpdateById_data | null;
}

export interface BlocksUpdateByIdVariables {
  payload: BlockUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompanyCreate
// ====================================================

export interface CompanyCreate_data_logo_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface CompanyCreate_data_logo {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: CompanyCreate_data_logo_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface CompanyCreate_data_employees {
  __typename: "Employee";
  id: string;
}

export interface CompanyCreate_data {
  __typename: "Company";
  id: string;
  name: string;
  email: string | null;
  logo: CompanyCreate_data_logo | null;
  domainName: string | null;
  color: string | null;
  description: string | null;
  legalId: string | null;
  createdAt: any;
  createdBy: string | null;
  phone: string | null;
  currency: Currency | null;
  updatedAt: any;
  employees: (CompanyCreate_data_employees | null)[] | null;
}

export interface CompanyCreate {
  data: CompanyCreate_data | null;
}

export interface CompanyCreateVariables {
  payload: CompanyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompanyUpdateById
// ====================================================

export interface CompanyUpdateById_data_logo_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface CompanyUpdateById_data_logo {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: CompanyUpdateById_data_logo_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface CompanyUpdateById_data_employees {
  __typename: "Employee";
  id: string;
}

export interface CompanyUpdateById_data {
  __typename: "Company";
  id: string;
  name: string;
  email: string | null;
  logo: CompanyUpdateById_data_logo | null;
  domainName: string | null;
  color: string | null;
  description: string | null;
  legalId: string | null;
  createdAt: any;
  createdBy: string | null;
  phone: string | null;
  currency: Currency | null;
  updatedAt: any;
  employees: (CompanyUpdateById_data_employees | null)[] | null;
}

export interface CompanyUpdateById {
  data: CompanyUpdateById_data | null;
}

export interface CompanyUpdateByIdVariables {
  companyId: string;
  data: CompanyUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompanyDeleteById
// ====================================================

export interface CompanyDeleteById_data_errors_items {
  __typename: "CompanyDeletedError";
  id: string;
  name: string | null;
  projectGroupId: string | null;
}

export interface CompanyDeleteById_data_errors {
  __typename: "CompanyDeletedErrorType";
  type: string;
  items: CompanyDeleteById_data_errors_items[] | null;
}

export interface CompanyDeleteById_data {
  __typename: "CompanyDeleted";
  id: string;
  errors: CompanyDeleteById_data_errors[] | null;
}

export interface CompanyDeleteById {
  data: CompanyDeleteById_data;
}

export interface CompanyDeleteByIdVariables {
  companyId: string;
  data: CompanyDeleteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompanyForceDeleteById
// ====================================================

export interface CompanyForceDeleteById {
  data: string;
}

export interface CompanyForceDeleteByIdVariables {
  companyId: string;
  data: CompanyDeleteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MyCompanyByProjectId
// ====================================================

export interface MyCompanyByProjectId_data_logo_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface MyCompanyByProjectId_data_logo {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: MyCompanyByProjectId_data_logo_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface MyCompanyByProjectId_data_employees {
  __typename: "Employee";
  id: string;
}

export interface MyCompanyByProjectId_data {
  __typename: "Company";
  id: string;
  name: string;
  email: string | null;
  logo: MyCompanyByProjectId_data_logo | null;
  domainName: string | null;
  color: string | null;
  description: string | null;
  legalId: string | null;
  createdAt: any;
  createdBy: string | null;
  phone: string | null;
  currency: Currency | null;
  updatedAt: any;
  employees: (MyCompanyByProjectId_data_employees | null)[] | null;
}

export interface MyCompanyByProjectId {
  data: MyCompanyByProjectId_data | null;
}

export interface MyCompanyByProjectIdVariables {
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyGetMyInfo
// ====================================================

export interface CompanyGetMyInfo_data_user {
  __typename: "KUser";
  id: string;
  name: string | null;
  username: string;
  email: string;
}

export interface CompanyGetMyInfo_data_company_logo_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface CompanyGetMyInfo_data_company_logo {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: CompanyGetMyInfo_data_company_logo_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface CompanyGetMyInfo_data_company {
  __typename: "Company";
  id: string;
  name: string;
  email: string | null;
  logo: CompanyGetMyInfo_data_company_logo | null;
  domainName: string | null;
  color: string | null;
  description: string | null;
  legalId: string | null;
  createdAt: any;
  createdBy: string | null;
  phone: string | null;
  currency: Currency | null;
  updatedAt: any;
}

export interface CompanyGetMyInfo_data_role {
  __typename: "Role";
  id: string;
  name: string | null;
}

export interface CompanyGetMyInfo_data_permissions {
  __typename: "Permission";
  id: string;
  name: string;
  type: string;
}

export interface CompanyGetMyInfo_data_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface CompanyGetMyInfo_data_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: CompanyGetMyInfo_data_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface CompanyGetMyInfo_data_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: CompanyGetMyInfo_data_employee_avatar | null;
}

export interface CompanyGetMyInfo_data {
  __typename: "CompanyInfo";
  user: CompanyGetMyInfo_data_user;
  company: CompanyGetMyInfo_data_company;
  role: CompanyGetMyInfo_data_role;
  permissions: CompanyGetMyInfo_data_permissions[];
  employee: CompanyGetMyInfo_data_employee;
}

export interface CompanyGetMyInfo {
  data: CompanyGetMyInfo_data | null;
}

export interface CompanyGetMyInfoVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyGet
// ====================================================

export interface CompanyGet_data_logo_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface CompanyGet_data_logo {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: CompanyGet_data_logo_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface CompanyGet_data {
  __typename: "Company";
  id: string;
  name: string;
  email: string | null;
  logo: CompanyGet_data_logo | null;
  domainName: string | null;
  color: string | null;
  description: string | null;
  legalId: string | null;
  createdAt: any;
  createdBy: string | null;
  phone: string | null;
  currency: Currency | null;
  updatedAt: any;
}

export interface CompanyGet {
  data: CompanyGet_data | null;
}

export interface CompanyGetVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyBrand
// ====================================================

export interface CompanyBrand_data {
  __typename: "Company";
  id: string;
  brand: any | null;
}

export interface CompanyBrand {
  data: CompanyBrand_data | null;
}

export interface CompanyBrandVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompaniesAll
// ====================================================

export interface CompaniesAll_data_logo_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface CompaniesAll_data_logo {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: CompaniesAll_data_logo_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface CompaniesAll_data_employees {
  __typename: "Employee";
  id: string;
}

export interface CompaniesAll_data {
  __typename: "Company";
  id: string;
  name: string;
  email: string | null;
  logo: CompaniesAll_data_logo | null;
  domainName: string | null;
  color: string | null;
  description: string | null;
  legalId: string | null;
  createdAt: any;
  createdBy: string | null;
  phone: string | null;
  currency: Currency | null;
  updatedAt: any;
  employees: (CompaniesAll_data_employees | null)[] | null;
}

export interface CompaniesAll {
  data: CompaniesAll_data[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: CompanySubscribe
// ====================================================

export interface CompanySubscribe_data_company_logo_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface CompanySubscribe_data_company_logo {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: CompanySubscribe_data_company_logo_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface CompanySubscribe_data_company_employees {
  __typename: "Employee";
  id: string;
}

export interface CompanySubscribe_data_company {
  __typename: "Company";
  id: string;
  name: string;
  email: string | null;
  logo: CompanySubscribe_data_company_logo | null;
  domainName: string | null;
  color: string | null;
  description: string | null;
  legalId: string | null;
  createdAt: any;
  createdBy: string | null;
  phone: string | null;
  currency: Currency | null;
  updatedAt: any;
  employees: (CompanySubscribe_data_company_employees | null)[] | null;
}

export interface CompanySubscribe_data {
  __typename: "CompanySubscription";
  company: CompanySubscribe_data_company;
  type: string;
}

export interface CompanySubscribe {
  data: CompanySubscribe_data;
}

export interface CompanySubscribeVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompanyUpdateBrandById
// ====================================================

export interface CompanyUpdateBrandById_data {
  __typename: "Company";
  id: string;
  brand: any | null;
}

export interface CompanyUpdateBrandById {
  data: CompanyUpdateBrandById_data | null;
}

export interface CompanyUpdateBrandByIdVariables {
  companyId: string;
  data: CompanyUpdateBrandInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EmployeesUpdateById
// ====================================================

export interface EmployeesUpdateById_data_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface EmployeesUpdateById_data_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: EmployeesUpdateById_data_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface EmployeesUpdateById_data_kUser {
  __typename: "KUser";
  id: string;
  name: string | null;
  username: string;
  country: string | null;
  city: string | null;
  isActive: boolean | null;
  isArchived: boolean | null;
  createdAt: any;
  updatedAt: any;
  description: string | null;
  language: string | null;
  position: string | null;
  skype: string | null;
  whatsapp: string | null;
  phone: string | null;
  address: string | null;
  email: string;
  telegram: string | null;
  portfolio: string | null;
  twitter: string | null;
  facebook: string | null;
  linkedin: string | null;
  other: string | null;
}

export interface EmployeesUpdateById_data_roles_permission {
  __typename: "Permission";
  id: string;
  isActive: boolean | null;
  createdAt: any;
  updatedAt: any;
  name: string;
  type: string;
}

export interface EmployeesUpdateById_data_roles {
  __typename: "Role";
  id: string;
  name: string | null;
  description: string | null;
  updatedAt: any;
  companyId: string | null;
  isRemovable: boolean | null;
  isDefault: boolean | null;
  permission: EmployeesUpdateById_data_roles_permission[] | null;
}

export interface EmployeesUpdateById_data_projects {
  __typename: "Project";
  id: string;
}

export interface EmployeesUpdateById_data {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: EmployeesUpdateById_data_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  kUser: EmployeesUpdateById_data_kUser;
  roles: EmployeesUpdateById_data_roles[];
  projects: EmployeesUpdateById_data_projects[];
  tags: string[] | null;
  createdAt: any;
  description: string | null;
  language: string | null;
  status: string | null;
  registrated: any | null;
  edited: any | null;
  position: string | null;
  companyPhone: string | null;
  phone: string | null;
  address: string | null;
  email: string | null;
  skype: string | null;
  whatsapp: string | null;
  telegram: string | null;
  portfolio: string | null;
  twitter: string | null;
  linkedin: string | null;
  facebook: string | null;
}

export interface EmployeesUpdateById {
  data: EmployeesUpdateById_data;
}

export interface EmployeesUpdateByIdVariables {
  id: string;
  companyId: string;
  data: EmployeeUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EmployeesDeleteById
// ====================================================

export interface EmployeesDeleteById_data_errors_items {
  __typename: "EmployeeDeletedErrorTask";
  id: string;
  name: string;
  entityId: string | null;
  entityType: string | null;
}

export interface EmployeesDeleteById_data_errors {
  __typename: "EmployeeDeletedErrorType";
  type: string;
  items: EmployeesDeleteById_data_errors_items[] | null;
}

export interface EmployeesDeleteById_data {
  __typename: "EmployeeDeleted";
  id: string;
  errors: EmployeesDeleteById_data_errors[] | null;
}

export interface EmployeesDeleteById {
  data: EmployeesDeleteById_data;
}

export interface EmployeesDeleteByIdVariables {
  id: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EmployeesForceDeleteById
// ====================================================

export interface EmployeesForceDeleteById {
  data: string;
}

export interface EmployeesForceDeleteByIdVariables {
  id: string;
  companyId: string;
  employeeId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EmployeesSendInvite
// ====================================================

export interface EmployeesSendInvite_data {
  __typename: "InviteSuccess";
  isMailSent: boolean;
  employeeId: string;
}

export interface EmployeesSendInvite {
  data: EmployeesSendInvite_data | null;
}

export interface EmployeesSendInviteVariables {
  companyId: string;
  payload: InviteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EmployeesAll
// ====================================================

export interface EmployeesAll_data_employees_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface EmployeesAll_data_employees_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: EmployeesAll_data_employees_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface EmployeesAll_data_employees_kUser {
  __typename: "KUser";
  id: string;
  name: string | null;
  username: string;
  country: string | null;
  city: string | null;
  isActive: boolean | null;
  isArchived: boolean | null;
  createdAt: any;
  updatedAt: any;
  description: string | null;
  language: string | null;
  position: string | null;
  skype: string | null;
  whatsapp: string | null;
  phone: string | null;
  address: string | null;
  email: string;
  telegram: string | null;
  portfolio: string | null;
  twitter: string | null;
  facebook: string | null;
  linkedin: string | null;
  other: string | null;
}

export interface EmployeesAll_data_employees_roles_permission {
  __typename: "Permission";
  id: string;
  isActive: boolean | null;
  createdAt: any;
  updatedAt: any;
  name: string;
  type: string;
}

export interface EmployeesAll_data_employees_roles {
  __typename: "Role";
  id: string;
  name: string | null;
  description: string | null;
  updatedAt: any;
  companyId: string | null;
  isRemovable: boolean | null;
  isDefault: boolean | null;
  permission: EmployeesAll_data_employees_roles_permission[] | null;
}

export interface EmployeesAll_data_employees_projects {
  __typename: "Project";
  id: string;
}

export interface EmployeesAll_data_employees {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: EmployeesAll_data_employees_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  kUser: EmployeesAll_data_employees_kUser;
  roles: EmployeesAll_data_employees_roles[];
  projects: EmployeesAll_data_employees_projects[];
  tags: string[] | null;
  createdAt: any;
}

export interface EmployeesAll_data {
  __typename: "EmployeeAllResult";
  employees: EmployeesAll_data_employees[];
  limit: number;
  offset: number;
  total: number | null;
}

export interface EmployeesAll {
  data: EmployeesAll_data;
}

export interface EmployeesAllVariables {
  companyId: string;
  search?: EmployeeSearch | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EmployeesGetById
// ====================================================

export interface EmployeesGetById_data_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface EmployeesGetById_data_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: EmployeesGetById_data_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface EmployeesGetById_data_kUser {
  __typename: "KUser";
  id: string;
  name: string | null;
  username: string;
  country: string | null;
  city: string | null;
  isActive: boolean | null;
  isArchived: boolean | null;
  createdAt: any;
  updatedAt: any;
  description: string | null;
  language: string | null;
  position: string | null;
  skype: string | null;
  whatsapp: string | null;
  phone: string | null;
  address: string | null;
  email: string;
  telegram: string | null;
  portfolio: string | null;
  twitter: string | null;
  facebook: string | null;
  linkedin: string | null;
  other: string | null;
}

export interface EmployeesGetById_data_roles_permission {
  __typename: "Permission";
  id: string;
  isActive: boolean | null;
  createdAt: any;
  updatedAt: any;
  name: string;
  type: string;
}

export interface EmployeesGetById_data_roles {
  __typename: "Role";
  id: string;
  name: string | null;
  description: string | null;
  updatedAt: any;
  companyId: string | null;
  isRemovable: boolean | null;
  isDefault: boolean | null;
  permission: EmployeesGetById_data_roles_permission[] | null;
}

export interface EmployeesGetById_data_projects {
  __typename: "Project";
  id: string;
}

export interface EmployeesGetById_data {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: EmployeesGetById_data_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  kUser: EmployeesGetById_data_kUser;
  roles: EmployeesGetById_data_roles[];
  projects: EmployeesGetById_data_projects[];
  tags: string[] | null;
  createdAt: any;
  description: string | null;
  language: string | null;
  status: string | null;
  registrated: any | null;
  edited: any | null;
  position: string | null;
  companyPhone: string | null;
  phone: string | null;
  address: string | null;
  email: string | null;
  skype: string | null;
  whatsapp: string | null;
  telegram: string | null;
  portfolio: string | null;
  twitter: string | null;
  linkedin: string | null;
  facebook: string | null;
}

export interface EmployeesGetById {
  data: EmployeesGetById_data | null;
}

export interface EmployeesGetByIdVariables {
  id: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: EmployeesSubscribe
// ====================================================

export interface EmployeesSubscribe_data_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface EmployeesSubscribe_data_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: EmployeesSubscribe_data_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface EmployeesSubscribe_data_employee_kUser {
  __typename: "KUser";
  id: string;
  name: string | null;
  username: string;
  country: string | null;
  city: string | null;
  isActive: boolean | null;
  isArchived: boolean | null;
  createdAt: any;
  updatedAt: any;
  description: string | null;
  language: string | null;
  position: string | null;
  skype: string | null;
  whatsapp: string | null;
  phone: string | null;
  address: string | null;
  email: string;
  telegram: string | null;
  portfolio: string | null;
  twitter: string | null;
  facebook: string | null;
  linkedin: string | null;
  other: string | null;
}

export interface EmployeesSubscribe_data_employee_roles_permission {
  __typename: "Permission";
  id: string;
  isActive: boolean | null;
  createdAt: any;
  updatedAt: any;
  name: string;
  type: string;
}

export interface EmployeesSubscribe_data_employee_roles {
  __typename: "Role";
  id: string;
  name: string | null;
  description: string | null;
  updatedAt: any;
  companyId: string | null;
  isRemovable: boolean | null;
  isDefault: boolean | null;
  permission: EmployeesSubscribe_data_employee_roles_permission[] | null;
}

export interface EmployeesSubscribe_data_employee_projects {
  __typename: "Project";
  id: string;
}

export interface EmployeesSubscribe_data_employee {
  __typename: "Employee";
  description: string | null;
  language: string | null;
  status: string | null;
  registrated: any | null;
  edited: any | null;
  position: string | null;
  companyPhone: string | null;
  phone: string | null;
  address: string | null;
  email: string | null;
  skype: string | null;
  whatsapp: string | null;
  telegram: string | null;
  portfolio: string | null;
  twitter: string | null;
  linkedin: string | null;
  facebook: string | null;
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: EmployeesSubscribe_data_employee_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  kUser: EmployeesSubscribe_data_employee_kUser;
  roles: EmployeesSubscribe_data_employee_roles[];
  projects: EmployeesSubscribe_data_employee_projects[];
  tags: string[] | null;
  createdAt: any;
}

export interface EmployeesSubscribe_data {
  __typename: "EmployeeSubscription";
  type: string | null;
  employee: EmployeesSubscribe_data_employee | null;
}

export interface EmployeesSubscribe {
  data: EmployeesSubscribe_data | null;
}

export interface EmployeesSubscribeVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FileMetasDeleteById
// ====================================================

export interface FileMetasDeleteById {
  data: string | null;
}

export interface FileMetasDeleteByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FileMetasDeleteByIds
// ====================================================

export interface FileMetasDeleteByIds {
  data: number;
}

export interface FileMetasDeleteByIdsVariables {
  ids: string[];
  companyId?: string | null;
  projectId?: string | null;
  createrId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertFileMeta
// ====================================================

export interface UpsertFileMeta_data_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface UpsertFileMeta_data {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: UpsertFileMeta_data_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface UpsertFileMeta {
  data: UpsertFileMeta_data;
}

export interface UpsertFileMetaVariables {
  data: FileMetaUpsert;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FileMetaGroupAll
// ====================================================

export interface FileMetaGroupAll_breadcrumb_breadcrumbs {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
}

export interface FileMetaGroupAll_breadcrumb {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
  breadcrumbs: FileMetaGroupAll_breadcrumb_breadcrumbs[];
}

export interface FileMetaGroupAll_data_groups_breadcrumbs {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
}

export interface FileMetaGroupAll_data_groups {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
  color: string | null;
  parentId: string | null;
  lvl: number | null;
  isArchived: boolean | null;
  companyId: string | null;
  descendants: (string | null)[];
  createdAt: any;
  updatedAt: any;
  breadcrumbs: FileMetaGroupAll_data_groups_breadcrumbs[];
}

export interface FileMetaGroupAll_data_fileMetas_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface FileMetaGroupAll_data_fileMetas {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: FileMetaGroupAll_data_fileMetas_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface FileMetaGroupAll_data {
  __typename: "FileMetaGroupAllResult";
  groups: FileMetaGroupAll_data_groups[];
  fileMetas: FileMetaGroupAll_data_fileMetas[];
  total: number;
  limit: number;
  offset: number;
}

export interface FileMetaGroupAll {
  breadcrumb: FileMetaGroupAll_breadcrumb | null;
  data: FileMetaGroupAll_data;
}

export interface FileMetaGroupAllVariables {
  companyId: string;
  search?: FileMetaGroupsSearch | null;
  parentId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FileMetaGroupGetById
// ====================================================

export interface FileMetaGroupGetById_data_breadcrumbs {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
}

export interface FileMetaGroupGetById_data {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
  color: string | null;
  parentId: string | null;
  lvl: number | null;
  isArchived: boolean | null;
  companyId: string | null;
  descendants: (string | null)[];
  createdAt: any;
  updatedAt: any;
  breadcrumbs: FileMetaGroupGetById_data_breadcrumbs[];
}

export interface FileMetaGroupGetById {
  data: FileMetaGroupGetById_data | null;
}

export interface FileMetaGroupGetByIdVariables {
  companyId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FileMetaGroupCreate
// ====================================================

export interface FileMetaGroupCreate_data_breadcrumbs {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
}

export interface FileMetaGroupCreate_data {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
  color: string | null;
  parentId: string | null;
  lvl: number | null;
  isArchived: boolean | null;
  companyId: string | null;
  descendants: (string | null)[];
  createdAt: any;
  updatedAt: any;
  breadcrumbs: FileMetaGroupCreate_data_breadcrumbs[];
}

export interface FileMetaGroupCreate {
  data: FileMetaGroupCreate_data;
}

export interface FileMetaGroupCreateVariables {
  data: FileMetaGroupInput;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FileMetaGroupUpdateById
// ====================================================

export interface FileMetaGroupUpdateById_data_breadcrumbs {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
}

export interface FileMetaGroupUpdateById_data {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
  color: string | null;
  parentId: string | null;
  lvl: number | null;
  isArchived: boolean | null;
  companyId: string | null;
  descendants: (string | null)[];
  createdAt: any;
  updatedAt: any;
  breadcrumbs: FileMetaGroupUpdateById_data_breadcrumbs[];
}

export interface FileMetaGroupUpdateById {
  data: FileMetaGroupUpdateById_data | null;
}

export interface FileMetaGroupUpdateByIdVariables {
  id: string;
  companyId: string;
  data: FileMetaGroupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FileMetaAndGroupMove
// ====================================================

export interface FileMetaAndGroupMove_data_breadcrumbs {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
}

export interface FileMetaAndGroupMove_data {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
  color: string | null;
  parentId: string | null;
  lvl: number | null;
  isArchived: boolean | null;
  companyId: string | null;
  descendants: (string | null)[];
  createdAt: any;
  updatedAt: any;
  breadcrumbs: FileMetaAndGroupMove_data_breadcrumbs[];
}

export interface FileMetaAndGroupMove {
  data: FileMetaAndGroupMove_data | null;
}

export interface FileMetaAndGroupMoveVariables {
  data: FileMetaAndGroupMoveInput;
  companyId: string;
  projectId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FileMetaGroupDeleteByIds
// ====================================================

export interface FileMetaGroupDeleteByIds_data_removes {
  __typename: "FileMetaGroupDeletedType";
  id: string;
  name: string | null;
  type: string;
}

export interface FileMetaGroupDeleteByIds_data_errors {
  __typename: "FileMetaGroupDeletedType";
  id: string;
  name: string | null;
  type: string;
}

export interface FileMetaGroupDeleteByIds_data {
  __typename: "FileMetaGroupDeleted";
  removes: FileMetaGroupDeleteByIds_data_removes[];
  errors: FileMetaGroupDeleteByIds_data_errors[];
}

export interface FileMetaGroupDeleteByIds {
  data: FileMetaGroupDeleteByIds_data;
}

export interface FileMetaGroupDeleteByIdsVariables {
  data: FileMetaGroupDeleteInput[];
  companyId: string;
  parentId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FileMetaGroupForceDeleteById
// ====================================================

export interface FileMetaGroupForceDeleteById {
  data: string;
}

export interface FileMetaGroupForceDeleteByIdVariables {
  id: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FileMetasTypesCount
// ====================================================

export interface FileMetasTypesCount_data {
  __typename: "FileTypeCount";
  type: string;
  count: number;
}

export interface FileMetasTypesCount {
  data: FileMetasTypesCount_data[];
}

export interface FileMetasTypesCountVariables {
  id: string;
  fileMetaType: FileMetaType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FileMetasAll
// ====================================================

export interface FileMetasAll_data_fileMetas_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface FileMetasAll_data_fileMetas {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: FileMetasAll_data_fileMetas_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface FileMetasAll_data_totalAll {
  __typename: "FileTypeCount";
  type: string;
  count: number;
}

export interface FileMetasAll_data {
  __typename: "FileMetaAllResult";
  fileMetas: FileMetasAll_data_fileMetas[];
  totalAll: FileMetasAll_data_totalAll[];
  total: number;
  limit: number;
  offset: number;
}

export interface FileMetasAll {
  data: FileMetasAll_data;
}

export interface FileMetasAllVariables {
  search?: FileMetaSearch | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: FilesGroupsSubscription
// ====================================================

export interface FilesGroupsSubscription_data_groups_breadcrumbs {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
}

export interface FilesGroupsSubscription_data_groups {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
  color: string | null;
  parentId: string | null;
  lvl: number | null;
  isArchived: boolean | null;
  companyId: string | null;
  descendants: (string | null)[];
  createdAt: any;
  updatedAt: any;
  breadcrumbs: FilesGroupsSubscription_data_groups_breadcrumbs[];
}

export interface FilesGroupsSubscription_data_fileMetas_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface FilesGroupsSubscription_data_fileMetas {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: FilesGroupsSubscription_data_fileMetas_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface FilesGroupsSubscription_data {
  __typename: "SubscriptionFileMetaGroupResult";
  groups: FilesGroupsSubscription_data_groups[];
  fileMetas: FilesGroupsSubscription_data_fileMetas[];
  type: string;
}

export interface FilesGroupsSubscription {
  data: FilesGroupsSubscription_data;
}

export interface FilesGroupsSubscriptionVariables {
  companyId: string;
  projectId?: string | null;
  parentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: SubcribeOnCompanyFilesUpdate
// ====================================================

export interface SubcribeOnCompanyFilesUpdate_data_counts {
  __typename: "FileTypeCount";
  type: string;
  count: number;
}

export interface SubcribeOnCompanyFilesUpdate_data_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface SubcribeOnCompanyFilesUpdate_data_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: SubcribeOnCompanyFilesUpdate_data_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface SubcribeOnCompanyFilesUpdate_data {
  __typename: "FilesSubscription";
  type: string;
  counts: SubcribeOnCompanyFilesUpdate_data_counts[];
  companyId: string | null;
  projectId: string | null;
  files: SubcribeOnCompanyFilesUpdate_data_files[];
}

export interface SubcribeOnCompanyFilesUpdate {
  data: SubcribeOnCompanyFilesUpdate_data;
}

export interface SubcribeOnCompanyFilesUpdateVariables {
  companyId: string;
  projectId?: string | null;
  parentId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FileUrlInfo
// ====================================================

export interface FileUrlInfo_data_payload {
  __typename: "FileUrlInfoPayload";
  url: string;
  contentType: string | null;
  mediaType: string;
  title: string | null;
  siteName: string | null;
  description: string | null;
  images: (string | null)[] | null;
  favicons: (string | null)[] | null;
}

export interface FileUrlInfo_data_errors {
  __typename: "FileUrlInfoError";
  url: string;
  message: string;
}

export interface FileUrlInfo_data {
  __typename: "FileUrlInfoResult";
  payload: FileUrlInfo_data_payload | null;
  errors: FileUrlInfo_data_errors[] | null;
}

export interface FileUrlInfo {
  data: FileUrlInfo_data | null;
}

export interface FileUrlInfoVariables {
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NotificationsAll
// ====================================================

export interface NotificationsAll_data_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface NotificationsAll_data_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: NotificationsAll_data_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface NotificationsAll_data_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  avatar: NotificationsAll_data_employee_avatar | null;
}

export interface NotificationsAll_data_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface NotificationsAll_data {
  __typename: "Notification";
  id: string;
  type: string;
  employee: NotificationsAll_data_employee | null;
  owner: NotificationsAll_data_owner | null;
  count: number | null;
  entity: string;
  entityId: string;
  isNew: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface NotificationsAll {
  data: NotificationsAll_data[];
}

export interface NotificationsAllVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NotificationsNew
// ====================================================

export interface NotificationsNew_data_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface NotificationsNew_data_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: NotificationsNew_data_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface NotificationsNew_data_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  avatar: NotificationsNew_data_employee_avatar | null;
}

export interface NotificationsNew_data_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface NotificationsNew_data {
  __typename: "Notification";
  id: string;
  type: string;
  employee: NotificationsNew_data_employee | null;
  owner: NotificationsNew_data_owner | null;
  count: number | null;
  entity: string;
  entityId: string;
  isNew: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface NotificationsNew {
  data: NotificationsNew_data[];
}

export interface NotificationsNewVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NotificationsCountNew
// ====================================================

export interface NotificationsCountNew {
  data: number;
}

export interface NotificationsCountNewVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: NotificationsRead
// ====================================================

export interface NotificationsRead {
  data: number;
}

export interface NotificationsReadVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: Notification
// ====================================================

export interface Notification_data_notification_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface Notification_data_notification_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: Notification_data_notification_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface Notification_data_notification_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  avatar: Notification_data_notification_employee_avatar | null;
}

export interface Notification_data_notification_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface Notification_data_notification {
  __typename: "Notification";
  id: string;
  type: string;
  employee: Notification_data_notification_employee | null;
  owner: Notification_data_notification_owner | null;
  count: number | null;
  entity: string;
  entityId: string;
  isNew: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface Notification_data {
  __typename: "NotificationSubscription";
  notification: Notification_data_notification;
  type: string;
}

export interface Notification {
  data: Notification_data;
}

export interface NotificationVariables {
  employeeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PermissionsAll
// ====================================================

export interface PermissionsAll_data_roles {
  __typename: "Role";
  id: string;
  name: string | null;
  description: string | null;
  updatedAt: any;
  companyId: string | null;
  isRemovable: boolean | null;
  isDefault: boolean | null;
}

export interface PermissionsAll_data {
  __typename: "Permission";
  id: string;
  isActive: boolean | null;
  createdAt: any;
  updatedAt: any;
  name: string;
  type: string;
  roles: (PermissionsAll_data_roles | null)[] | null;
}

export interface PermissionsAll {
  data: PermissionsAll_data[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendMeLinkForChangePassword
// ====================================================

export interface sendMeLinkForChangePassword {
  data: string | null;
}

export interface sendMeLinkForChangePasswordVariables {
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changeEmail
// ====================================================

export interface changeEmail {
  data: string | null;
}

export interface changeEmailVariables {
  password: string;
  newEmail: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: confirmChangeEmail
// ====================================================

export interface confirmChangeEmail {
  data: string | null;
}

export interface confirmChangeEmailVariables {
  newEmail: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cancelChangeEmail
// ====================================================

export interface cancelChangeEmail {
  data: string | null;
}

export interface cancelChangeEmailVariables {
  newEmail: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changeUsername
// ====================================================

export interface changeUsername_data_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface changeUsername_data_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: changeUsername_data_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface changeUsername_data {
  __typename: "KUser";
  avatar: changeUsername_data_avatar | null;
  id: string;
  name: string | null;
  username: string;
  country: string | null;
  city: string | null;
  isActive: boolean | null;
  isArchived: boolean | null;
  createdAt: any;
  updatedAt: any;
  description: string | null;
  language: string | null;
  position: string | null;
  skype: string | null;
  whatsapp: string | null;
  phone: string | null;
  address: string | null;
  email: string;
  telegram: string | null;
  portfolio: string | null;
  twitter: string | null;
  facebook: string | null;
  linkedin: string | null;
  other: string | null;
}

export interface changeUsername {
  data: changeUsername_data | null;
}

export interface changeUsernameVariables {
  password: string;
  newUsername: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: kUsersUpdateById
// ====================================================

export interface kUsersUpdateById_data_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface kUsersUpdateById_data_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: kUsersUpdateById_data_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface kUsersUpdateById_data {
  __typename: "KUser";
  avatar: kUsersUpdateById_data_avatar | null;
  id: string;
  name: string | null;
  username: string;
  country: string | null;
  city: string | null;
  isActive: boolean | null;
  isArchived: boolean | null;
  createdAt: any;
  updatedAt: any;
  description: string | null;
  language: string | null;
  position: string | null;
  skype: string | null;
  whatsapp: string | null;
  phone: string | null;
  address: string | null;
  email: string;
  telegram: string | null;
  portfolio: string | null;
  twitter: string | null;
  facebook: string | null;
  linkedin: string | null;
  other: string | null;
}

export interface kUsersUpdateById {
  data: kUsersUpdateById_data | null;
}

export interface kUsersUpdateByIdVariables {
  id: string;
  data: kUserUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getKUser
// ====================================================

export interface getKUser_data_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface getKUser_data_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: getKUser_data_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface getKUser_data {
  __typename: "KUser";
  avatar: getKUser_data_avatar | null;
  id: string;
  name: string | null;
  username: string;
  country: string | null;
  city: string | null;
  isActive: boolean | null;
  isArchived: boolean | null;
  createdAt: any;
  updatedAt: any;
  description: string | null;
  language: string | null;
  position: string | null;
  skype: string | null;
  whatsapp: string | null;
  phone: string | null;
  address: string | null;
  email: string;
  telegram: string | null;
  portfolio: string | null;
  twitter: string | null;
  facebook: string | null;
  linkedin: string | null;
  other: string | null;
}

export interface getKUser {
  data: getKUser_data;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectGroupsCreate
// ====================================================

export interface ProjectGroupsCreate_data_analitycs {
  __typename: "ProjectGroupAnalytics";
  projectsTotal: number;
  tasksDone: number;
  tasksTotal: number;
  tasksExpired: number;
}

export interface ProjectGroupsCreate_data {
  __typename: "ProjectGroup";
  id: string;
  name: string | null;
  description: string | null;
  logo: number | null;
  color: string | null;
  parentId: string | null;
  createdAt: any;
  updatedAt: any;
  analitycs: ProjectGroupsCreate_data_analitycs | null;
}

export interface ProjectGroupsCreate {
  data: ProjectGroupsCreate_data;
}

export interface ProjectGroupsCreateVariables {
  projectGroup: ProjectGroupInput;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectGroupsUpdateById
// ====================================================

export interface ProjectGroupsUpdateById_data_analitycs {
  __typename: "ProjectGroupAnalytics";
  projectsTotal: number;
  tasksDone: number;
  tasksTotal: number;
  tasksExpired: number;
}

export interface ProjectGroupsUpdateById_data {
  __typename: "ProjectGroup";
  id: string;
  name: string | null;
  description: string | null;
  logo: number | null;
  color: string | null;
  parentId: string | null;
  createdAt: any;
  updatedAt: any;
  analitycs: ProjectGroupsUpdateById_data_analitycs | null;
}

export interface ProjectGroupsUpdateById {
  data: ProjectGroupsUpdateById_data | null;
}

export interface ProjectGroupsUpdateByIdVariables {
  groupId: string;
  companyId: string;
  data: ProjectGroupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectGroupsDeleteById
// ====================================================

export interface ProjectGroupsDeleteById_data_errors_items {
  __typename: "ProjectGroupDeletedErrorGroupsAndProjects";
  id: string;
  name: string | null;
  parentId: string | null;
  projectGroupId: string | null;
}

export interface ProjectGroupsDeleteById_data_errors {
  __typename: "ProjectGroupDeletedErrorType";
  type: string;
  items: ProjectGroupsDeleteById_data_errors_items[];
}

export interface ProjectGroupsDeleteById_data {
  __typename: "ProjectGroupDeleted";
  id: string;
  errors: ProjectGroupsDeleteById_data_errors[] | null;
}

export interface ProjectGroupsDeleteById {
  data: ProjectGroupsDeleteById_data;
}

export interface ProjectGroupsDeleteByIdVariables {
  id: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectGroupsForceDeleteById
// ====================================================

export interface ProjectGroupsForceDeleteById {
  data: string;
}

export interface ProjectGroupsForceDeleteByIdVariables {
  id: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectGroupsGetById
// ====================================================

export interface ProjectGroupsGetById_data_breadcrumbs {
  __typename: "ProjectGroup";
  name: string | null;
  id: string;
}

export interface ProjectGroupsGetById_data {
  __typename: "ProjectGroup";
  id: string;
  name: string | null;
  color: string | null;
  parentId: string | null;
  description: string | null;
  updatedAt: any;
  breadcrumbs: ProjectGroupsGetById_data_breadcrumbs[] | null;
}

export interface ProjectGroupsGetById {
  data: ProjectGroupsGetById_data | null;
}

export interface ProjectGroupsGetByIdVariables {
  groupId: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectGroupsAll
// ====================================================

export interface ProjectGroupsAll_breadcrumb_breadcrumbs {
  __typename: "ProjectGroup";
  name: string | null;
  id: string;
}

export interface ProjectGroupsAll_breadcrumb {
  __typename: "ProjectGroup";
  id: string;
  name: string | null;
  color: string | null;
  parentId: string | null;
  description: string | null;
  updatedAt: any;
  breadcrumbs: ProjectGroupsAll_breadcrumb_breadcrumbs[] | null;
}

export interface ProjectGroupsAll_data_projects_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectGroupsAll_data_projects_publicSettings_authors {
  __typename: "PublicAuthorType";
  id: string;
  avatarId: string;
  name: string;
  description: string | null;
}

export interface ProjectGroupsAll_data_projects_publicSettings {
  __typename: "PublicSettingsType";
  id: string | null;
  imageId: string | null;
  authors: ProjectGroupsAll_data_projects_publicSettings_authors[] | null;
  logoId: string | null;
  secondaryLogoId: string | null;
  name: string | null;
  description: string | null;
  authorsDescription: string | null;
  authorsType: string | null;
  colorPrimary: string | null;
  colorFont: string | null;
  infoAboutProgress: string | null;
  tableOfContentInitiallyExpanded: boolean | null;
  supportEmail: string | null;
  footerCopyright: string | null;
  footerText1: string | null;
  footerText2: string | null;
  footerText3: string | null;
  footerText4: string | null;
  templateKey: string | null;
}

export interface ProjectGroupsAll_data_projects_analitycs {
  __typename: "ProjectAnalytics";
  tasksDone: number;
  tasksTotal: number;
  tasksExpired: number;
}

export interface ProjectGroupsAll_data_projects {
  __typename: "Project";
  id: string;
  name: string;
  budget: number | null;
  tags: string[] | null;
  owner: ProjectGroupsAll_data_projects_owner;
  publicSettings: ProjectGroupsAll_data_projects_publicSettings;
  isDone: boolean;
  learningObjectives: string | null;
  learningGoals: string | null;
  endDate: any | null;
  startDate: any | null;
  accent: string | null;
  projectGroupId: string | null;
  updatedAt: any;
  analitycs: ProjectGroupsAll_data_projects_analitycs | null;
}

export interface ProjectGroupsAll_data_groups_analitycs {
  __typename: "ProjectGroupAnalytics";
  projectsTotal: number;
  tasksDone: number;
  tasksTotal: number;
  tasksExpired: number;
}

export interface ProjectGroupsAll_data_groups {
  __typename: "ProjectGroup";
  id: string;
  name: string | null;
  description: string | null;
  logo: number | null;
  color: string | null;
  parentId: string | null;
  createdAt: any;
  updatedAt: any;
  analitycs: ProjectGroupsAll_data_groups_analitycs | null;
}

export interface ProjectGroupsAll_data {
  __typename: "ProjectGroupAllResult";
  projects: ProjectGroupsAll_data_projects[];
  groups: ProjectGroupsAll_data_groups[];
  total: number;
  totalGroup: number;
  limit: number;
  offset: number;
}

export interface ProjectGroupsAll {
  breadcrumb: ProjectGroupsAll_breadcrumb | null;
  data: ProjectGroupsAll_data;
}

export interface ProjectGroupsAllVariables {
  companyId: string;
  parentId?: string | null;
  search?: ProjectGroupsSearch | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: ProjectGroupSubscription
// ====================================================

export interface ProjectGroupSubscription_data_group_analitycs {
  __typename: "ProjectGroupAnalytics";
  projectsTotal: number;
  tasksDone: number;
  tasksTotal: number;
  tasksExpired: number;
}

export interface ProjectGroupSubscription_data_group {
  __typename: "ProjectGroup";
  id: string;
  name: string | null;
  description: string | null;
  logo: number | null;
  color: string | null;
  parentId: string | null;
  createdAt: any;
  updatedAt: any;
  analitycs: ProjectGroupSubscription_data_group_analitycs | null;
}

export interface ProjectGroupSubscription_data {
  __typename: "SubscriptionProjectGroupResult";
  group: ProjectGroupSubscription_data_group | null;
  type: string | null;
}

export interface ProjectGroupSubscription {
  data: ProjectGroupSubscription_data;
}

export interface ProjectGroupSubscriptionVariables {
  companyId: string;
  parentId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: groupsSubscription
// ====================================================

export interface groupsSubscription_data_group_analitycs {
  __typename: "ProjectGroupAnalytics";
  projectsTotal: number;
  tasksDone: number;
  tasksTotal: number;
  tasksExpired: number;
}

export interface groupsSubscription_data_group {
  __typename: "ProjectGroup";
  id: string;
  name: string | null;
  description: string | null;
  logo: number | null;
  color: string | null;
  parentId: string | null;
  createdAt: any;
  updatedAt: any;
  analitycs: groupsSubscription_data_group_analitycs | null;
}

export interface groupsSubscription_data {
  __typename: "SubscriptionProjectGroupResult";
  group: groupsSubscription_data_group | null;
  type: string | null;
}

export interface groupsSubscription {
  data: groupsSubscription_data;
}

export interface groupsSubscriptionVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectsCreate
// ====================================================

export interface ProjectsCreate_data_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectsCreate_data_publicSettings_authors {
  __typename: "PublicAuthorType";
  id: string;
  avatarId: string;
  name: string;
  description: string | null;
}

export interface ProjectsCreate_data_publicSettings {
  __typename: "PublicSettingsType";
  id: string | null;
  imageId: string | null;
  authors: ProjectsCreate_data_publicSettings_authors[] | null;
  logoId: string | null;
  secondaryLogoId: string | null;
  name: string | null;
  description: string | null;
  authorsDescription: string | null;
  authorsType: string | null;
  colorPrimary: string | null;
  colorFont: string | null;
  infoAboutProgress: string | null;
  tableOfContentInitiallyExpanded: boolean | null;
  supportEmail: string | null;
  footerCopyright: string | null;
  footerText1: string | null;
  footerText2: string | null;
  footerText3: string | null;
  footerText4: string | null;
  templateKey: string | null;
}

export interface ProjectsCreate_data_analitycs {
  __typename: "ProjectAnalytics";
  tasksDone: number;
  tasksTotal: number;
  tasksExpired: number;
}

export interface ProjectsCreate_data {
  __typename: "Project";
  id: string;
  name: string;
  budget: number | null;
  tags: string[] | null;
  owner: ProjectsCreate_data_owner;
  publicSettings: ProjectsCreate_data_publicSettings;
  isDone: boolean;
  learningObjectives: string | null;
  learningGoals: string | null;
  endDate: any | null;
  startDate: any | null;
  accent: string | null;
  projectGroupId: string | null;
  updatedAt: any;
  analitycs: ProjectsCreate_data_analitycs | null;
}

export interface ProjectsCreate {
  data: ProjectsCreate_data;
}

export interface ProjectsCreateVariables {
  companyId: string;
  data: ProjectCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectsUpdateById
// ====================================================

export interface ProjectsUpdateById_data_publicSettings_authors {
  __typename: "PublicAuthorType";
  id: string;
  avatarId: string;
  name: string;
  description: string | null;
}

export interface ProjectsUpdateById_data_publicSettings {
  __typename: "PublicSettingsType";
  id: string | null;
  imageId: string | null;
  authors: ProjectsUpdateById_data_publicSettings_authors[] | null;
  logoId: string | null;
  secondaryLogoId: string | null;
  name: string | null;
  description: string | null;
  authorsDescription: string | null;
  authorsType: string | null;
  colorPrimary: string | null;
  colorFont: string | null;
  infoAboutProgress: string | null;
  tableOfContentInitiallyExpanded: boolean | null;
  supportEmail: string | null;
  footerCopyright: string | null;
  footerText1: string | null;
  footerText2: string | null;
  footerText3: string | null;
  footerText4: string | null;
  templateKey: string | null;
}

export interface ProjectsUpdateById_data_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectsUpdateById_data_sectionsOrder {
  __typename: "EditorSectionOrderOptionalType";
  id: string;
  lvl: number;
}

export interface ProjectsUpdateById_data_publicBlocks {
  __typename: "PublicBlockType";
  id: string;
  description: string;
  title: string;
  icon: string | null;
  iconImg: string | null;
}

export interface ProjectsUpdateById_data_publicFiles_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface ProjectsUpdateById_data_publicFiles {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: ProjectsUpdateById_data_publicFiles_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface ProjectsUpdateById_data_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectsUpdateById_data_publicLink {
  __typename: "Link";
  id: string | null;
  link: string | null;
  isOpen: boolean;
}

export interface ProjectsUpdateById_data {
  __typename: "Project";
  id: string;
  companyId: string | null;
  projectGroupId: string | null;
  progressShouldBeGreaterThen: number;
  progressShouldBeLeastThen: number;
  finalTestId: string | null;
  tags: string[] | null;
  publicSettings: ProjectsUpdateById_data_publicSettings;
  owner: ProjectsUpdateById_data_owner;
  sectionsOrder: ProjectsUpdateById_data_sectionsOrder[];
  accent: string | null;
  name: string;
  startDate: any | null;
  endDate: any | null;
  budget: number | null;
  cost: number | null;
  updatedAt: any;
  learningObjectives: string | null;
  learningGoals: string | null;
  publicBlocks: ProjectsUpdateById_data_publicBlocks[];
  publicFiles: ProjectsUpdateById_data_publicFiles[];
  employee: ProjectsUpdateById_data_employee[];
  publicLink: ProjectsUpdateById_data_publicLink;
  createdAt: any;
  isDone: boolean;
}

export interface ProjectsUpdateById {
  data: ProjectsUpdateById_data | null;
}

export interface ProjectsUpdateByIdVariables {
  projectId: string;
  data: ProjectUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectsDeleteById
// ====================================================

export interface ProjectsDeleteById_data_errors_items {
  __typename: "ProjectDeletedErrorSections";
  id: string;
  name: string | null;
  projectId: string;
}

export interface ProjectsDeleteById_data_errors {
  __typename: "ProjectDeletedErrorType";
  type: string;
  items: ProjectsDeleteById_data_errors_items[];
}

export interface ProjectsDeleteById_data {
  __typename: "ProjectDeleted";
  id: string;
  errors: ProjectsDeleteById_data_errors[] | null;
}

export interface ProjectsDeleteById {
  data: ProjectsDeleteById_data;
}

export interface ProjectsDeleteByIdVariables {
  id: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProjectsForceDeleteById
// ====================================================

export interface ProjectsForceDeleteById {
  data: string;
}

export interface ProjectsForceDeleteByIdVariables {
  id: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectsDeepGetById
// ====================================================

export interface ProjectsDeepGetById_data_publicSettings_authors {
  __typename: "PublicAuthorType";
  id: string;
  avatarId: string;
  name: string;
  description: string | null;
}

export interface ProjectsDeepGetById_data_publicSettings {
  __typename: "PublicSettingsType";
  id: string | null;
  imageId: string | null;
  authors: ProjectsDeepGetById_data_publicSettings_authors[] | null;
  logoId: string | null;
  secondaryLogoId: string | null;
  name: string | null;
  description: string | null;
  authorsDescription: string | null;
  authorsType: string | null;
  colorPrimary: string | null;
  colorFont: string | null;
  infoAboutProgress: string | null;
  tableOfContentInitiallyExpanded: boolean | null;
  supportEmail: string | null;
  footerCopyright: string | null;
  footerText1: string | null;
  footerText2: string | null;
  footerText3: string | null;
  footerText4: string | null;
  templateKey: string | null;
}

export interface ProjectsDeepGetById_data_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectsDeepGetById_data_sectionsOrder {
  __typename: "EditorSectionOrderOptionalType";
  id: string;
  lvl: number;
}

export interface ProjectsDeepGetById_data_sections_blocks_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface ProjectsDeepGetById_data_sections_blocks_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface ProjectsDeepGetById_data_sections_blocks_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: ProjectsDeepGetById_data_sections_blocks_test_onSuccess | null;
  onFail: ProjectsDeepGetById_data_sections_blocks_test_onFail | null;
  isResult: boolean | null;
}

export interface ProjectsDeepGetById_data_sections_blocks_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface ProjectsDeepGetById_data_sections_blocks_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: ProjectsDeepGetById_data_sections_blocks_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface ProjectsDeepGetById_data_sections_blocks_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface ProjectsDeepGetById_data_sections_blocks {
  __typename: "Block";
  uuid: string;
  mode: string;
  name: string;
  test: ProjectsDeepGetById_data_sections_blocks_test | null;
  elements: any;
  schema: string[][][] | null;
  files: ProjectsDeepGetById_data_sections_blocks_files[];
  sectionId: string;
  type: string;
  options: ProjectsDeepGetById_data_sections_blocks_options;
  version: number | null;
  createdAt: any;
  updatedAt: any;
  tasksCount: number;
}

export interface ProjectsDeepGetById_data_sections_test {
  __typename: "EditorSectionTest";
  time: number | null;
  testRetry: number | null;
  resultShouldBeGreaterThen: number | null;
  isNextOnSubmit: boolean | null;
  isValidationVisible: boolean | null;
  isResultValuePercent: boolean | null;
  questionOrder: string | null;
  isShuffleQuestions: boolean | null;
  isProgressShown: boolean | null;
}

export interface ProjectsDeepGetById_data_sections {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  description: string | null;
  type: string;
  blocksOrder: string[];
  deadline: any | null;
  blocks: ProjectsDeepGetById_data_sections_blocks[];
  test: ProjectsDeepGetById_data_sections_test | null;
  lvl: number;
  isChapter: boolean;
  updatedAt: any;
}

export interface ProjectsDeepGetById_data_publicBlocks {
  __typename: "PublicBlockType";
  id: string;
  description: string;
  title: string;
  icon: string | null;
  iconImg: string | null;
}

export interface ProjectsDeepGetById_data_publicFiles_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface ProjectsDeepGetById_data_publicFiles {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: ProjectsDeepGetById_data_publicFiles_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface ProjectsDeepGetById_data_company_logo_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface ProjectsDeepGetById_data_company_logo {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: ProjectsDeepGetById_data_company_logo_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface ProjectsDeepGetById_data_company {
  __typename: "Company";
  logo: ProjectsDeepGetById_data_company_logo | null;
  id: string;
  brand: any | null;
}

export interface ProjectsDeepGetById_data {
  __typename: "Project";
  id: string;
  companyId: string | null;
  projectGroupId: string | null;
  progressShouldBeGreaterThen: number;
  progressShouldBeLeastThen: number;
  finalTestId: string | null;
  publicSettings: ProjectsDeepGetById_data_publicSettings;
  owner: ProjectsDeepGetById_data_owner;
  sectionsOrder: ProjectsDeepGetById_data_sectionsOrder[];
  sections: ProjectsDeepGetById_data_sections[];
  accent: string | null;
  name: string;
  endDate: any | null;
  updatedAt: any;
  learningObjectives: string | null;
  learningGoals: string | null;
  publicBlocks: ProjectsDeepGetById_data_publicBlocks[];
  publicFiles: ProjectsDeepGetById_data_publicFiles[];
  company: ProjectsDeepGetById_data_company | null;
}

export interface ProjectsDeepGetById {
  data: ProjectsDeepGetById_data | null;
}

export interface ProjectsDeepGetByIdVariables {
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectsAll
// ====================================================

export interface ProjectsAll_data_projects_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectsAll_data_projects_publicSettings_authors {
  __typename: "PublicAuthorType";
  id: string;
  avatarId: string;
  name: string;
  description: string | null;
}

export interface ProjectsAll_data_projects_publicSettings {
  __typename: "PublicSettingsType";
  id: string | null;
  imageId: string | null;
  authors: ProjectsAll_data_projects_publicSettings_authors[] | null;
  logoId: string | null;
  secondaryLogoId: string | null;
  name: string | null;
  description: string | null;
  authorsDescription: string | null;
  authorsType: string | null;
  colorPrimary: string | null;
  colorFont: string | null;
  infoAboutProgress: string | null;
  tableOfContentInitiallyExpanded: boolean | null;
  supportEmail: string | null;
  footerCopyright: string | null;
  footerText1: string | null;
  footerText2: string | null;
  footerText3: string | null;
  footerText4: string | null;
  templateKey: string | null;
}

export interface ProjectsAll_data_projects_analitycs {
  __typename: "ProjectAnalytics";
  tasksDone: number;
  tasksTotal: number;
  tasksExpired: number;
}

export interface ProjectsAll_data_projects {
  __typename: "Project";
  id: string;
  name: string;
  budget: number | null;
  tags: string[] | null;
  owner: ProjectsAll_data_projects_owner;
  publicSettings: ProjectsAll_data_projects_publicSettings;
  isDone: boolean;
  learningObjectives: string | null;
  learningGoals: string | null;
  endDate: any | null;
  startDate: any | null;
  accent: string | null;
  projectGroupId: string | null;
  updatedAt: any;
  analitycs: ProjectsAll_data_projects_analitycs | null;
}

export interface ProjectsAll_data {
  __typename: "ProjectAllResult";
  projects: ProjectsAll_data_projects[];
  limit: number;
  offset: number;
  total: number | null;
}

export interface ProjectsAll {
  data: ProjectsAll_data;
}

export interface ProjectsAllVariables {
  companyId?: string | null;
  parentId?: string | null;
  search?: ProjectSearch | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectsGetById
// ====================================================

export interface ProjectsGetById_data_publicSettings_authors {
  __typename: "PublicAuthorType";
  id: string;
  avatarId: string;
  name: string;
  description: string | null;
}

export interface ProjectsGetById_data_publicSettings {
  __typename: "PublicSettingsType";
  id: string | null;
  imageId: string | null;
  authors: ProjectsGetById_data_publicSettings_authors[] | null;
  logoId: string | null;
  secondaryLogoId: string | null;
  name: string | null;
  description: string | null;
  authorsDescription: string | null;
  authorsType: string | null;
  colorPrimary: string | null;
  colorFont: string | null;
  infoAboutProgress: string | null;
  tableOfContentInitiallyExpanded: boolean | null;
  supportEmail: string | null;
  footerCopyright: string | null;
  footerText1: string | null;
  footerText2: string | null;
  footerText3: string | null;
  footerText4: string | null;
  templateKey: string | null;
}

export interface ProjectsGetById_data_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectsGetById_data_sectionsOrder {
  __typename: "EditorSectionOrderOptionalType";
  id: string;
  lvl: number;
}

export interface ProjectsGetById_data_publicBlocks {
  __typename: "PublicBlockType";
  id: string;
  description: string;
  title: string;
  icon: string | null;
  iconImg: string | null;
}

export interface ProjectsGetById_data_publicFiles_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface ProjectsGetById_data_publicFiles {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: ProjectsGetById_data_publicFiles_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface ProjectsGetById_data_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectsGetById_data_publicLink {
  __typename: "Link";
  id: string | null;
  link: string | null;
  isOpen: boolean;
}

export interface ProjectsGetById_data {
  __typename: "Project";
  id: string;
  companyId: string | null;
  projectGroupId: string | null;
  progressShouldBeGreaterThen: number;
  progressShouldBeLeastThen: number;
  finalTestId: string | null;
  tags: string[] | null;
  publicSettings: ProjectsGetById_data_publicSettings;
  owner: ProjectsGetById_data_owner;
  sectionsOrder: ProjectsGetById_data_sectionsOrder[];
  accent: string | null;
  name: string;
  startDate: any | null;
  endDate: any | null;
  budget: number | null;
  cost: number | null;
  updatedAt: any;
  learningObjectives: string | null;
  learningGoals: string | null;
  publicBlocks: ProjectsGetById_data_publicBlocks[];
  publicFiles: ProjectsGetById_data_publicFiles[];
  employee: ProjectsGetById_data_employee[];
  publicLink: ProjectsGetById_data_publicLink;
  createdAt: any;
  isDone: boolean;
}

export interface ProjectsGetById {
  data: ProjectsGetById_data | null;
}

export interface ProjectsGetByIdVariables {
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectsFilesGetById
// ====================================================

export interface ProjectsFilesGetById_data_publicSettings_authors {
  __typename: "PublicAuthorType";
  id: string;
  avatarId: string;
  name: string;
  description: string | null;
}

export interface ProjectsFilesGetById_data_publicSettings {
  __typename: "PublicSettingsType";
  id: string | null;
  imageId: string | null;
  authors: ProjectsFilesGetById_data_publicSettings_authors[] | null;
  logoId: string | null;
  secondaryLogoId: string | null;
  name: string | null;
  description: string | null;
  authorsDescription: string | null;
  authorsType: string | null;
  colorPrimary: string | null;
  colorFont: string | null;
  infoAboutProgress: string | null;
  tableOfContentInitiallyExpanded: boolean | null;
  supportEmail: string | null;
  footerCopyright: string | null;
  footerText1: string | null;
  footerText2: string | null;
  footerText3: string | null;
  footerText4: string | null;
  templateKey: string | null;
}

export interface ProjectsFilesGetById_data_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectsFilesGetById_data_sectionsOrder {
  __typename: "EditorSectionOrderOptionalType";
  id: string;
  lvl: number;
}

export interface ProjectsFilesGetById_data_publicBlocks {
  __typename: "PublicBlockType";
  id: string;
  description: string;
  title: string;
  icon: string | null;
  iconImg: string | null;
}

export interface ProjectsFilesGetById_data_publicFiles_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface ProjectsFilesGetById_data_publicFiles {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: ProjectsFilesGetById_data_publicFiles_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface ProjectsFilesGetById_data_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectsFilesGetById_data_publicLink {
  __typename: "Link";
  id: string | null;
  link: string | null;
  isOpen: boolean;
}

export interface ProjectsFilesGetById_data_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface ProjectsFilesGetById_data_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: ProjectsFilesGetById_data_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface ProjectsFilesGetById_data {
  __typename: "Project";
  id: string;
  companyId: string | null;
  projectGroupId: string | null;
  progressShouldBeGreaterThen: number;
  progressShouldBeLeastThen: number;
  finalTestId: string | null;
  tags: string[] | null;
  publicSettings: ProjectsFilesGetById_data_publicSettings;
  owner: ProjectsFilesGetById_data_owner;
  sectionsOrder: ProjectsFilesGetById_data_sectionsOrder[];
  accent: string | null;
  name: string;
  startDate: any | null;
  endDate: any | null;
  budget: number | null;
  cost: number | null;
  updatedAt: any;
  learningObjectives: string | null;
  learningGoals: string | null;
  publicBlocks: ProjectsFilesGetById_data_publicBlocks[];
  publicFiles: ProjectsFilesGetById_data_publicFiles[];
  employee: ProjectsFilesGetById_data_employee[];
  publicLink: ProjectsFilesGetById_data_publicLink;
  createdAt: any;
  isDone: boolean;
  files: ProjectsFilesGetById_data_files[];
}

export interface ProjectsFilesGetById {
  data: ProjectsFilesGetById_data | null;
}

export interface ProjectsFilesGetByIdVariables {
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: ProjectListSubscription
// ====================================================

export interface ProjectListSubscription_data_project_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectListSubscription_data_project_publicSettings_authors {
  __typename: "PublicAuthorType";
  id: string;
  avatarId: string;
  name: string;
  description: string | null;
}

export interface ProjectListSubscription_data_project_publicSettings {
  __typename: "PublicSettingsType";
  id: string | null;
  imageId: string | null;
  authors: ProjectListSubscription_data_project_publicSettings_authors[] | null;
  logoId: string | null;
  secondaryLogoId: string | null;
  name: string | null;
  description: string | null;
  authorsDescription: string | null;
  authorsType: string | null;
  colorPrimary: string | null;
  colorFont: string | null;
  infoAboutProgress: string | null;
  tableOfContentInitiallyExpanded: boolean | null;
  supportEmail: string | null;
  footerCopyright: string | null;
  footerText1: string | null;
  footerText2: string | null;
  footerText3: string | null;
  footerText4: string | null;
  templateKey: string | null;
}

export interface ProjectListSubscription_data_project_analitycs {
  __typename: "ProjectAnalytics";
  tasksDone: number;
  tasksTotal: number;
  tasksExpired: number;
}

export interface ProjectListSubscription_data_project {
  __typename: "Project";
  id: string;
  name: string;
  budget: number | null;
  tags: string[] | null;
  owner: ProjectListSubscription_data_project_owner;
  publicSettings: ProjectListSubscription_data_project_publicSettings;
  isDone: boolean;
  learningObjectives: string | null;
  learningGoals: string | null;
  endDate: any | null;
  startDate: any | null;
  accent: string | null;
  projectGroupId: string | null;
  updatedAt: any;
  analitycs: ProjectListSubscription_data_project_analitycs | null;
}

export interface ProjectListSubscription_data {
  __typename: "SubscriptionProjectResult";
  project: ProjectListSubscription_data_project | null;
  type: string | null;
}

export interface ProjectListSubscription {
  data: ProjectListSubscription_data;
}

export interface ProjectListSubscriptionVariables {
  companyId: string;
  parentId?: string | null;
  projectId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: ProjectSubscription
// ====================================================

export interface ProjectSubscription_data_project_publicSettings_authors {
  __typename: "PublicAuthorType";
  id: string;
  avatarId: string;
  name: string;
  description: string | null;
}

export interface ProjectSubscription_data_project_publicSettings {
  __typename: "PublicSettingsType";
  id: string | null;
  imageId: string | null;
  authors: ProjectSubscription_data_project_publicSettings_authors[] | null;
  logoId: string | null;
  secondaryLogoId: string | null;
  name: string | null;
  description: string | null;
  authorsDescription: string | null;
  authorsType: string | null;
  colorPrimary: string | null;
  colorFont: string | null;
  infoAboutProgress: string | null;
  tableOfContentInitiallyExpanded: boolean | null;
  supportEmail: string | null;
  footerCopyright: string | null;
  footerText1: string | null;
  footerText2: string | null;
  footerText3: string | null;
  footerText4: string | null;
  templateKey: string | null;
}

export interface ProjectSubscription_data_project_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectSubscription_data_project_sectionsOrder {
  __typename: "EditorSectionOrderOptionalType";
  id: string;
  lvl: number;
}

export interface ProjectSubscription_data_project_publicBlocks {
  __typename: "PublicBlockType";
  id: string;
  description: string;
  title: string;
  icon: string | null;
  iconImg: string | null;
}

export interface ProjectSubscription_data_project_publicFiles_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface ProjectSubscription_data_project_publicFiles {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: ProjectSubscription_data_project_publicFiles_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface ProjectSubscription_data_project_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectSubscription_data_project_publicLink {
  __typename: "Link";
  id: string | null;
  link: string | null;
  isOpen: boolean;
}

export interface ProjectSubscription_data_project {
  __typename: "Project";
  id: string;
  companyId: string | null;
  projectGroupId: string | null;
  progressShouldBeGreaterThen: number;
  progressShouldBeLeastThen: number;
  finalTestId: string | null;
  tags: string[] | null;
  publicSettings: ProjectSubscription_data_project_publicSettings;
  owner: ProjectSubscription_data_project_owner;
  sectionsOrder: ProjectSubscription_data_project_sectionsOrder[];
  accent: string | null;
  name: string;
  startDate: any | null;
  endDate: any | null;
  budget: number | null;
  cost: number | null;
  updatedAt: any;
  learningObjectives: string | null;
  learningGoals: string | null;
  publicBlocks: ProjectSubscription_data_project_publicBlocks[];
  publicFiles: ProjectSubscription_data_project_publicFiles[];
  employee: ProjectSubscription_data_project_employee[];
  publicLink: ProjectSubscription_data_project_publicLink;
  createdAt: any;
  isDone: boolean;
}

export interface ProjectSubscription_data {
  __typename: "SubscriptionProjectResult";
  project: ProjectSubscription_data_project | null;
  type: string | null;
}

export interface ProjectSubscription {
  data: ProjectSubscription_data;
}

export interface ProjectSubscriptionVariables {
  companyId: string;
  projectId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PublicLinkInProjectGetByIdUpdate
// ====================================================

export interface PublicLinkInProjectGetByIdUpdate_data_publicLink {
  __typename: "Link";
  id: string | null;
  link: string | null;
  isOpen: boolean;
}

export interface PublicLinkInProjectGetByIdUpdate_data {
  __typename: "Project";
  id: string;
  publicLink: PublicLinkInProjectGetByIdUpdate_data_publicLink;
}

export interface PublicLinkInProjectGetByIdUpdate {
  data: PublicLinkInProjectGetByIdUpdate_data | null;
}

export interface PublicLinkInProjectGetByIdUpdateVariables {
  projectId: string;
  data: ProjectUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPublicPreviewData
// ====================================================

export interface GetPublicPreviewData_data {
  __typename: "LoginAsVisitor";
  id: string;
  status: PreviewLinkAccessStatus;
  token: string | null;
  companyId: string | null;
  projectId: string | null;
}

export interface GetPublicPreviewData {
  data: GetPublicPreviewData_data;
}

export interface GetPublicPreviewDataVariables {
  shortLink: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPublicLinkInProjectGetById
// ====================================================

export interface GetPublicLinkInProjectGetById_data_publicLink {
  __typename: "Link";
  id: string | null;
  link: string | null;
  isOpen: boolean;
}

export interface GetPublicLinkInProjectGetById_data {
  __typename: "Project";
  id: string;
  publicLink: GetPublicLinkInProjectGetById_data_publicLink;
}

export interface GetPublicLinkInProjectGetById {
  data: GetPublicLinkInProjectGetById_data | null;
}

export interface GetPublicLinkInProjectGetByIdVariables {
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RolesGetById
// ====================================================

export interface RolesGetById_data_employee {
  __typename: "Employee";
  id: string;
}

export interface RolesGetById_data_permission {
  __typename: "Permission";
  name: string;
  id: string;
}

export interface RolesGetById_data {
  __typename: "Role";
  id: string;
  name: string | null;
  description: string | null;
  employee: RolesGetById_data_employee[] | null;
  permission: RolesGetById_data_permission[] | null;
  isRemovable: boolean | null;
  isDefault: boolean | null;
  updatedAt: any;
  companyId: string | null;
}

export interface RolesGetById {
  data: RolesGetById_data | null;
}

export interface RolesGetByIdVariables {
  id: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RolesCreate
// ====================================================

export interface RolesCreate_data_employee {
  __typename: "Employee";
  id: string;
}

export interface RolesCreate_data_permission {
  __typename: "Permission";
  name: string;
  id: string;
}

export interface RolesCreate_data {
  __typename: "Role";
  id: string;
  companyId: string | null;
  name: string | null;
  employee: RolesCreate_data_employee[] | null;
  isRemovable: boolean | null;
  isDefault: boolean | null;
  permission: RolesCreate_data_permission[] | null;
  updatedAt: any;
  description: string | null;
}

export interface RolesCreate {
  data: RolesCreate_data;
}

export interface RolesCreateVariables {
  data: RoleInput;
  companyId: string;
  permissionIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RolesDeleteById
// ====================================================

export interface RolesDeleteById_data_errors_items {
  __typename: "RoleDeletedErrorEmployee";
  id: string;
  name: string;
}

export interface RolesDeleteById_data_errors {
  __typename: "RoleDeletedErrorType";
  type: string;
  items: RolesDeleteById_data_errors_items[] | null;
}

export interface RolesDeleteById_data {
  __typename: "RoleDeleted";
  id: string;
  errors: RolesDeleteById_data_errors[] | null;
}

export interface RolesDeleteById {
  data: RolesDeleteById_data;
}

export interface RolesDeleteByIdVariables {
  id: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RolesForceDeleteById
// ====================================================

export interface RolesForceDeleteById {
  data: string;
}

export interface RolesForceDeleteByIdVariables {
  id: string;
  companyId: string;
  roleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RolesUpdateById
// ====================================================

export interface RolesUpdateById_data_employee {
  __typename: "Employee";
  id: string;
}

export interface RolesUpdateById_data_permission {
  __typename: "Permission";
  name: string;
  id: string;
}

export interface RolesUpdateById_data {
  __typename: "Role";
  id: string;
  name: string | null;
  employee: RolesUpdateById_data_employee[] | null;
  permission: RolesUpdateById_data_permission[] | null;
  isDefault: boolean | null;
  isRemovable: boolean | null;
  description: string | null;
  companyId: string | null;
  isArchived: boolean | null;
  updatedAt: any;
}

export interface RolesUpdateById {
  data: RolesUpdateById_data | null;
}

export interface RolesUpdateByIdVariables {
  id: string;
  companyId: string;
  data: RoleInput;
  permissionIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RolesSetEmployees
// ====================================================

export interface RolesSetEmployees_data_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface RolesSetEmployees_data_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: RolesSetEmployees_data_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface RolesSetEmployees_data {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: RolesSetEmployees_data_avatar | null;
}

export interface RolesSetEmployees {
  data: RolesSetEmployees_data[];
}

export interface RolesSetEmployeesVariables {
  employeeIds?: string[] | null;
  roleId: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RolesAll
// ====================================================

export interface RolesAll_data_employee {
  __typename: "Employee";
  id: string;
}

export interface RolesAll_data_permission {
  __typename: "Permission";
  id: string;
}

export interface RolesAll_data {
  __typename: "Role";
  id: string;
  name: string | null;
  description: string | null;
  updatedAt: any;
  companyId: string | null;
  isRemovable: boolean | null;
  isDefault: boolean | null;
  employee: RolesAll_data_employee[] | null;
  permission: RolesAll_data_permission[] | null;
}

export interface RolesAll {
  data: RolesAll_data[];
}

export interface RolesAllVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnnounceGetStatus
// ====================================================

export interface AnnounceGetStatus_data {
  __typename: "Announce";
  id: string | null;
  status: AnnounceStatuses;
  date: any | null;
  message: string | null;
  linkUrl: string | null;
  linkLabel: string | null;
}

export interface AnnounceGetStatus {
  data: AnnounceGetStatus_data;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: AnnounceSubscribe
// ====================================================

export interface AnnounceSubscribe_data {
  __typename: "Announce";
  id: string | null;
  status: AnnounceStatuses;
  date: any | null;
  message: string | null;
  linkUrl: string | null;
  linkLabel: string | null;
}

export interface AnnounceSubscribe {
  data: AnnounceSubscribe_data;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BuildLastGetByProjectId
// ====================================================

export interface BuildLastGetByProjectId_data_payload_employee {
  __typename: "BuildPayloadFieldsType";
  id: string;
  name: string;
}

export interface BuildLastGetByProjectId_data_payload_project {
  __typename: "BuildPayloadFieldsType";
  id: string;
  name: string;
}

export interface BuildLastGetByProjectId_data_payload_category {
  __typename: "BuildPayloadCategoryType";
  id: string | null;
}

export interface BuildLastGetByProjectId_data_payload {
  __typename: "BuildPayloadType";
  employee: BuildLastGetByProjectId_data_payload_employee;
  project: BuildLastGetByProjectId_data_payload_project;
  category: BuildLastGetByProjectId_data_payload_category | null;
  percents: string;
}

export interface BuildLastGetByProjectId_data_lastBuild {
  __typename: "BuildLastType";
  createdAt: any;
  link: string;
}

export interface BuildLastGetByProjectId_data {
  __typename: "Build";
  status: string | null;
  sessionId: string | null;
  payload: BuildLastGetByProjectId_data_payload | null;
  onBuild: boolean | null;
  lastBuild: BuildLastGetByProjectId_data_lastBuild | null;
}

export interface BuildLastGetByProjectId {
  data: BuildLastGetByProjectId_data | null;
}

export interface BuildLastGetByProjectIdVariables {
  employeeId: string;
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: Scorm
// ====================================================

export interface Scorm_data_payload_employee {
  __typename: "BuildPayloadFieldsType";
  id: string;
  name: string;
}

export interface Scorm_data_payload_project {
  __typename: "BuildPayloadFieldsType";
  id: string;
  name: string;
}

export interface Scorm_data_payload_category {
  __typename: "BuildPayloadCategoryType";
  id: string | null;
}

export interface Scorm_data_payload {
  __typename: "BuildPayloadType";
  employee: Scorm_data_payload_employee;
  project: Scorm_data_payload_project;
  category: Scorm_data_payload_category | null;
  percents: string;
}

export interface Scorm_data_lastBuild {
  __typename: "BuildLastType";
  createdAt: any;
  link: string;
}

export interface Scorm_data {
  __typename: "Build";
  status: string | null;
  sessionId: string | null;
  payload: Scorm_data_payload | null;
  onBuild: boolean | null;
  lastBuild: Scorm_data_lastBuild | null;
}

export interface Scorm {
  data: Scorm_data;
}

export interface ScormVariables {
  employeeId: string;
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BuildScormPackageInit
// ====================================================

export interface BuildScormPackageInit_data_currentSession {
  __typename: "BuildSession";
  id: string | null;
}

export interface BuildScormPackageInit_data {
  __typename: "BuildInitResult";
  status: string;
  error: string[];
  currentSession: BuildScormPackageInit_data_currentSession | null;
}

export interface BuildScormPackageInit {
  data: BuildScormPackageInit_data;
}

export interface BuildScormPackageInitVariables {
  employeeId: string;
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProjectExportCurrentSessionId
// ====================================================

export interface GetProjectExportCurrentSessionId_data_currentSession {
  __typename: "BuildSession";
  id: string | null;
}

export interface GetProjectExportCurrentSessionId_data {
  __typename: "BuildSessionCurrent";
  currentSession: GetProjectExportCurrentSessionId_data_currentSession | null;
}

export interface GetProjectExportCurrentSessionId {
  data: GetProjectExportCurrentSessionId_data | null;
}

export interface GetProjectExportCurrentSessionIdVariables {
  employeeId: string;
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SectionsCreate
// ====================================================

export interface SectionsCreate_data_sectionsOrder {
  __typename: "EditorSectionOrderOptionalType";
  id: string;
  lvl: number;
}

export interface SectionsCreate_data_section_blocks {
  __typename: "Block";
  uuid: string;
  mode: string;
}

export interface SectionsCreate_data_section_test {
  __typename: "EditorSectionTest";
  time: number | null;
  testRetry: number | null;
  resultShouldBeGreaterThen: number | null;
  isNextOnSubmit: boolean | null;
  isValidationVisible: boolean | null;
  isResultValuePercent: boolean | null;
  questionOrder: string | null;
  isShuffleQuestions: boolean | null;
  isProgressShown: boolean | null;
}

export interface SectionsCreate_data_section_formats {
  __typename: "DealFormat";
  id: string;
  cost: number;
  unitType: string;
  count: number;
  formatId: string;
}

export interface SectionsCreate_data_section_participants_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface SectionsCreate_data_section_participants_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: SectionsCreate_data_section_participants_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface SectionsCreate_data_section_participants {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: SectionsCreate_data_section_participants_avatar | null;
}

export interface SectionsCreate_data_section {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  description: string | null;
  type: string;
  blocksOrder: string[];
  deadline: any | null;
  blocks: SectionsCreate_data_section_blocks[];
  test: SectionsCreate_data_section_test | null;
  lvl: number;
  isChapter: boolean;
  updatedAt: any;
  isDone: boolean;
  projectId: string;
  totalCost: number | null;
  formats: SectionsCreate_data_section_formats[] | null;
  tags: string[];
  isArchived: boolean;
  createdAt: any;
  participants: SectionsCreate_data_section_participants[];
}

export interface SectionsCreate_data {
  __typename: "EditorSectionCreate";
  sectionsOrder: SectionsCreate_data_sectionsOrder[] | null;
  section: SectionsCreate_data_section;
}

export interface SectionsCreate {
  data: SectionsCreate_data;
}

export interface SectionsCreateVariables {
  projectId: string;
  data: EditorSectionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SectionsUpdateById
// ====================================================

export interface SectionsUpdateById_data_sectionsOrder {
  __typename: "EditorSectionOrderOptionalType";
  id: string;
  lvl: number;
}

export interface SectionsUpdateById_data_section_blocks {
  __typename: "Block";
  uuid: string;
  mode: string;
}

export interface SectionsUpdateById_data_section_test {
  __typename: "EditorSectionTest";
  time: number | null;
  testRetry: number | null;
  resultShouldBeGreaterThen: number | null;
  isNextOnSubmit: boolean | null;
  isValidationVisible: boolean | null;
  isResultValuePercent: boolean | null;
  questionOrder: string | null;
  isShuffleQuestions: boolean | null;
  isProgressShown: boolean | null;
}

export interface SectionsUpdateById_data_section_formats {
  __typename: "DealFormat";
  id: string;
  cost: number;
  unitType: string;
  count: number;
  formatId: string;
}

export interface SectionsUpdateById_data_section_participants_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface SectionsUpdateById_data_section_participants_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: SectionsUpdateById_data_section_participants_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface SectionsUpdateById_data_section_participants {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: SectionsUpdateById_data_section_participants_avatar | null;
}

export interface SectionsUpdateById_data_section {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  description: string | null;
  type: string;
  blocksOrder: string[];
  deadline: any | null;
  blocks: SectionsUpdateById_data_section_blocks[];
  test: SectionsUpdateById_data_section_test | null;
  lvl: number;
  isChapter: boolean;
  updatedAt: any;
  isDone: boolean;
  projectId: string;
  totalCost: number | null;
  formats: SectionsUpdateById_data_section_formats[] | null;
  tags: string[];
  isArchived: boolean;
  createdAt: any;
  participants: SectionsUpdateById_data_section_participants[];
}

export interface SectionsUpdateById_data {
  __typename: "EditorSectionUpdate";
  sectionsOrder: SectionsUpdateById_data_sectionsOrder[] | null;
  section: SectionsUpdateById_data_section | null;
}

export interface SectionsUpdateById {
  data: SectionsUpdateById_data;
}

export interface SectionsUpdateByIdVariables {
  id: string;
  data: EditorSectionUpdateInput;
  projectId: string;
  dragId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SectionsDeleteById
// ====================================================

export interface SectionsDeleteById_data_sectionsOrder {
  __typename: "EditorSectionOrderOptionalType";
  id: string;
  lvl: number;
}

export interface SectionsDeleteById_data {
  __typename: "EditorSectionDelete";
  id: string;
  sectionIdsDeleted: string[];
  sectionsOrder: SectionsDeleteById_data_sectionsOrder[];
}

export interface SectionsDeleteById {
  data: SectionsDeleteById_data;
}

export interface SectionsDeleteByIdVariables {
  id: string;
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SectionsAll
// ====================================================

export interface SectionsAll_data_blocks {
  __typename: "Block";
  uuid: string;
  mode: string;
}

export interface SectionsAll_data_test {
  __typename: "EditorSectionTest";
  time: number | null;
  testRetry: number | null;
  resultShouldBeGreaterThen: number | null;
  isNextOnSubmit: boolean | null;
  isValidationVisible: boolean | null;
  isResultValuePercent: boolean | null;
  questionOrder: string | null;
  isShuffleQuestions: boolean | null;
  isProgressShown: boolean | null;
}

export interface SectionsAll_data_formats {
  __typename: "DealFormat";
  id: string;
  cost: number;
  unitType: string;
  count: number;
  formatId: string;
}

export interface SectionsAll_data_participants_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface SectionsAll_data_participants_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: SectionsAll_data_participants_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface SectionsAll_data_participants {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: SectionsAll_data_participants_avatar | null;
}

export interface SectionsAll_data {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  description: string | null;
  type: string;
  blocksOrder: string[];
  deadline: any | null;
  blocks: SectionsAll_data_blocks[];
  test: SectionsAll_data_test | null;
  lvl: number;
  isChapter: boolean;
  updatedAt: any;
  isDone: boolean;
  projectId: string;
  totalCost: number | null;
  formats: SectionsAll_data_formats[] | null;
  tags: string[];
  isArchived: boolean;
  createdAt: any;
  participants: SectionsAll_data_participants[];
}

export interface SectionsAll {
  data: SectionsAll_data[];
}

export interface SectionsAllVariables {
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SectionsGetById
// ====================================================

export interface SectionsGetById_data_blocks {
  __typename: "Block";
  uuid: string;
  mode: string;
}

export interface SectionsGetById_data_test {
  __typename: "EditorSectionTest";
  time: number | null;
  testRetry: number | null;
  resultShouldBeGreaterThen: number | null;
  isNextOnSubmit: boolean | null;
  isValidationVisible: boolean | null;
  isResultValuePercent: boolean | null;
  questionOrder: string | null;
  isShuffleQuestions: boolean | null;
  isProgressShown: boolean | null;
}

export interface SectionsGetById_data_formats {
  __typename: "DealFormat";
  id: string;
  cost: number;
  unitType: string;
  count: number;
  formatId: string;
}

export interface SectionsGetById_data_participants_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface SectionsGetById_data_participants_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: SectionsGetById_data_participants_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface SectionsGetById_data_participants {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: SectionsGetById_data_participants_avatar | null;
}

export interface SectionsGetById_data {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  description: string | null;
  type: string;
  blocksOrder: string[];
  deadline: any | null;
  blocks: SectionsGetById_data_blocks[];
  test: SectionsGetById_data_test | null;
  lvl: number;
  isChapter: boolean;
  updatedAt: any;
  isDone: boolean;
  projectId: string;
  totalCost: number | null;
  formats: SectionsGetById_data_formats[] | null;
  tags: string[];
  isArchived: boolean;
  createdAt: any;
  participants: SectionsGetById_data_participants[];
}

export interface SectionsGetById {
  data: SectionsGetById_data | null;
}

export interface SectionsGetByIdVariables {
  id: string;
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: EditorSectionSubscription
// ====================================================

export interface EditorSectionSubscription_data_section_blocks {
  __typename: "Block";
  uuid: string;
  mode: string;
}

export interface EditorSectionSubscription_data_section_test {
  __typename: "EditorSectionTest";
  time: number | null;
  testRetry: number | null;
  resultShouldBeGreaterThen: number | null;
  isNextOnSubmit: boolean | null;
  isValidationVisible: boolean | null;
  isResultValuePercent: boolean | null;
  questionOrder: string | null;
  isShuffleQuestions: boolean | null;
  isProgressShown: boolean | null;
}

export interface EditorSectionSubscription_data_section_formats {
  __typename: "DealFormat";
  id: string;
  cost: number;
  unitType: string;
  count: number;
  formatId: string;
}

export interface EditorSectionSubscription_data_section_participants_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface EditorSectionSubscription_data_section_participants_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: EditorSectionSubscription_data_section_participants_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface EditorSectionSubscription_data_section_participants {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: EditorSectionSubscription_data_section_participants_avatar | null;
}

export interface EditorSectionSubscription_data_section {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  description: string | null;
  type: string;
  blocksOrder: string[];
  deadline: any | null;
  blocks: EditorSectionSubscription_data_section_blocks[];
  test: EditorSectionSubscription_data_section_test | null;
  lvl: number;
  isChapter: boolean;
  updatedAt: any;
  isDone: boolean;
  projectId: string;
  totalCost: number | null;
  formats: EditorSectionSubscription_data_section_formats[] | null;
  tags: string[];
  isArchived: boolean;
  createdAt: any;
  participants: EditorSectionSubscription_data_section_participants[];
}

export interface EditorSectionSubscription_data_sectionsOrder {
  __typename: "EditorSectionOrderOptionalType";
  id: string;
  lvl: number;
}

export interface EditorSectionSubscription_data {
  __typename: "EditorSectionSubscription";
  section: EditorSectionSubscription_data_section | null;
  type: string;
  sectionsOrder: EditorSectionSubscription_data_sectionsOrder[] | null;
}

export interface EditorSectionSubscription {
  data: EditorSectionSubscription_data;
}

export interface EditorSectionSubscriptionVariables {
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetServerStorage
// ====================================================

export interface GetServerStorage {
  data: string | null;
}

export interface GetServerStorageVariables {
  key: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetServerStorage
// ====================================================

export interface SetServerStorage {
  data: boolean | null;
}

export interface SetServerStorageVariables {
  key: string;
  template: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveServerStorage
// ====================================================

export interface RemoveServerStorage {
  data: boolean | null;
}

export interface RemoveServerStorageVariables {
  key: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SessionLogout
// ====================================================

export interface SessionLogout {
  data: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SessionLogin
// ====================================================

export interface SessionLogin {
  data: boolean | null;
}

export interface SessionLoginVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Login
// ====================================================

export interface Login_data {
  __typename: "Token";
  token: string;
}

export interface Login {
  data: Login_data | null;
}

export interface LoginVariables {
  payload: LoginInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: registration
// ====================================================

export interface registration_data {
  __typename: "RegistrationSuccess";
  isMailSent: boolean;
}

export interface registration {
  data: registration_data;
}

export interface registrationVariables {
  payload: KUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendMeLinkForRecoverPassword
// ====================================================

export interface sendMeLinkForRecoverPassword {
  data: string | null;
}

export interface sendMeLinkForRecoverPasswordVariables {
  login: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changePassword
// ====================================================

export interface changePassword {
  data: string | null;
}

export interface changePasswordVariables {
  passwordNew: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FocusInputQuery
// ====================================================

export interface FocusInputQuery {
  data: any;
}

export interface FocusInputQueryVariables {
  typeField: inputType;
  value: pageValue;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: FocusInputSubscribtin
// ====================================================

export interface FocusInputSubscribtin {
  data: any;
}

export interface FocusInputSubscribtinVariables {
  companyId: string;
  userId: string;
  namePage: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TagsGetById
// ====================================================

export interface TagsGetById_data {
  __typename: "Tag";
  id: string;
  name: string;
  parentId: string | null;
}

export interface TagsGetById {
  data: TagsGetById_data | null;
}

export interface TagsGetByIdVariables {
  id: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TagsCreate
// ====================================================

export interface TagsCreate_data_employee {
  __typename: "Employee";
  id: string;
  roleId: string;
}

export interface TagsCreate_data_parent {
  __typename: "Tag";
  name: string;
  companyId: string;
}

export interface TagsCreate_data_project {
  __typename: "Project";
  id: string;
}

export interface TagsCreate_data {
  __typename: "Tag";
  id: string;
  name: string;
  parentId: string | null;
  employee: (TagsCreate_data_employee | null)[];
  parent: TagsCreate_data_parent | null;
  project: TagsCreate_data_project[];
}

export interface TagsCreate {
  data: TagsCreate_data | null;
}

export interface TagsCreateVariables {
  companyId: string;
  data: TagInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TagsUpdateById
// ====================================================

export interface TagsUpdateById_data_employee {
  __typename: "Employee";
  id: string;
  roleId: string;
}

export interface TagsUpdateById_data_parent {
  __typename: "Tag";
  name: string;
  companyId: string;
}

export interface TagsUpdateById_data_project {
  __typename: "Project";
  id: string;
}

export interface TagsUpdateById_data {
  __typename: "Tag";
  id: string;
  name: string;
  parentId: string | null;
  employee: (TagsUpdateById_data_employee | null)[];
  parent: TagsUpdateById_data_parent | null;
  project: TagsUpdateById_data_project[];
}

export interface TagsUpdateById {
  data: TagsUpdateById_data | null;
}

export interface TagsUpdateByIdVariables {
  idTag: string;
  dataTag: TagInput;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TagsDeleteById
// ====================================================

export interface TagsDeleteById_data_errors_items {
  __typename: "TagDeletedErrorEmployee";
  id: string;
  name: string;
}

export interface TagsDeleteById_data_errors {
  __typename: "TagDeletedErrorType";
  type: string;
  items: TagsDeleteById_data_errors_items[] | null;
}

export interface TagsDeleteById_data {
  __typename: "TagDeleted";
  id: string;
  errors: TagsDeleteById_data_errors[] | null;
}

export interface TagsDeleteById {
  data: TagsDeleteById_data | null;
}

export interface TagsDeleteByIdVariables {
  id: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TagsForceDeleteById
// ====================================================

export interface TagsForceDeleteById_data_errors_items {
  __typename: "TagDeletedErrorEmployee";
  id: string;
  name: string;
}

export interface TagsForceDeleteById_data_errors {
  __typename: "TagDeletedErrorType";
  type: string;
  items: TagsForceDeleteById_data_errors_items[] | null;
}

export interface TagsForceDeleteById_data {
  __typename: "TagDeleted";
  id: string;
  errors: TagsForceDeleteById_data_errors[] | null;
}

export interface TagsForceDeleteById {
  data: TagsForceDeleteById_data | null;
}

export interface TagsForceDeleteByIdVariables {
  id: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TagsGetByName
// ====================================================

export interface TagsGetByName_data_employee {
  __typename: "Employee";
  id: string;
  roleId: string;
}

export interface TagsGetByName_data_parent {
  __typename: "Tag";
  name: string;
  companyId: string;
}

export interface TagsGetByName_data_project {
  __typename: "Project";
  id: string;
}

export interface TagsGetByName_data {
  __typename: "Tag";
  id: string;
  name: string;
  parentId: string | null;
  employee: (TagsGetByName_data_employee | null)[];
  parent: TagsGetByName_data_parent | null;
  project: TagsGetByName_data_project[];
}

export interface TagsGetByName {
  data: TagsGetByName_data[];
}

export interface TagsGetByNameVariables {
  name: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TagsAll
// ====================================================

export interface TagsAll_data_employee {
  __typename: "Employee";
  id: string;
  roleId: string;
}

export interface TagsAll_data_parent {
  __typename: "Tag";
  name: string;
  companyId: string;
}

export interface TagsAll_data_project {
  __typename: "Project";
  id: string;
}

export interface TagsAll_data {
  __typename: "Tag";
  id: string;
  name: string;
  parentId: string | null;
  employee: (TagsAll_data_employee | null)[];
  parent: TagsAll_data_parent | null;
  project: TagsAll_data_project[];
}

export interface TagsAll {
  data: TagsAll_data[];
}

export interface TagsAllVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TasksSearchAll
// ====================================================

export interface TasksSearchAll_data_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksSearchAll_data_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksSearchAll_data_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksSearchAll_data_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksSearchAll_data_employee_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  createdAt: any;
}

export interface TasksSearchAll_data_assigned_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksSearchAll_data_assigned_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksSearchAll_data_assigned_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksSearchAll_data_assigned {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksSearchAll_data_assigned_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  createdAt: any;
}

export interface TasksSearchAll_data_section {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  deadline: any | null;
  isDone: boolean;
}

export interface TasksSearchAll_data_block {
  __typename: "Block";
  uuid: string;
  name: string;
}

export interface TasksSearchAll_data_owner_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksSearchAll_data_owner_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksSearchAll_data_owner_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksSearchAll_data_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksSearchAll_data_owner_avatar | null;
}

export interface TasksSearchAll_data {
  __typename: "Task";
  id: string;
  name: string | null;
  isDone: boolean;
  description: string;
  deadline: any | null;
  doneTime: any | null;
  employee: TasksSearchAll_data_employee | null;
  assigned: TasksSearchAll_data_assigned | null;
  type: string;
  todo: any | null;
  status: string | null;
  parentId: string | null;
  sectionId: string;
  section: TasksSearchAll_data_section;
  projectId: string;
  blockId: string;
  block: TasksSearchAll_data_block;
  timelog: any | null;
  owner: TasksSearchAll_data_owner | null;
  isArchived: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksSearchAll {
  data: TasksSearchAll_data[];
}

export interface TasksSearchAllVariables {
  companyId: string;
  search: TaskSearch;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TasksGetById
// ====================================================

export interface TasksGetById_data_owner_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksGetById_data_owner_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksGetById_data_owner_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksGetById_data_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksGetById_data_owner_avatar | null;
}

export interface TasksGetById_data_section {
  __typename: "EditorSection";
  id: string;
  name: string | null;
}

export interface TasksGetById_data_block {
  __typename: "Block";
  uuid: string;
  name: string;
}

export interface TasksGetById_data_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksGetById_data_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksGetById_data_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksGetById_data_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksGetById_data_employee_avatar | null;
}

export interface TasksGetById_data_assigned_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksGetById_data_assigned_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksGetById_data_assigned_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksGetById_data_assigned {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksGetById_data_assigned_avatar | null;
}

export interface TasksGetById_data {
  __typename: "Task";
  id: string;
  projectId: string;
  sectionId: string;
  owner: TasksGetById_data_owner | null;
  section: TasksGetById_data_section;
  block: TasksGetById_data_block;
  employee: TasksGetById_data_employee | null;
  assigned: TasksGetById_data_assigned | null;
  name: string | null;
  description: string;
  deadline: any | null;
  type: string;
  todo: any | null;
  status: string | null;
  parentId: string | null;
  blockId: string;
  timelog: any | null;
  createdAt: any;
  updatedAt: any;
}

export interface TasksGetById {
  data: TasksGetById_data | null;
}

export interface TasksGetByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TasksCreate
// ====================================================

export interface TasksCreate_data_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksCreate_data_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksCreate_data_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksCreate_data_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksCreate_data_employee_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  createdAt: any;
}

export interface TasksCreate_data_assigned_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksCreate_data_assigned_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksCreate_data_assigned_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksCreate_data_assigned {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksCreate_data_assigned_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  createdAt: any;
}

export interface TasksCreate_data_section {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  deadline: any | null;
  isDone: boolean;
}

export interface TasksCreate_data_block {
  __typename: "Block";
  uuid: string;
  name: string;
}

export interface TasksCreate_data_owner_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksCreate_data_owner_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksCreate_data_owner_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksCreate_data_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksCreate_data_owner_avatar | null;
}

export interface TasksCreate_data {
  __typename: "Task";
  id: string;
  name: string | null;
  isDone: boolean;
  description: string;
  deadline: any | null;
  doneTime: any | null;
  employee: TasksCreate_data_employee | null;
  assigned: TasksCreate_data_assigned | null;
  type: string;
  todo: any | null;
  status: string | null;
  parentId: string | null;
  sectionId: string;
  section: TasksCreate_data_section;
  projectId: string;
  blockId: string;
  block: TasksCreate_data_block;
  timelog: any | null;
  owner: TasksCreate_data_owner | null;
  isArchived: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksCreate {
  data: TasksCreate_data;
}

export interface TasksCreateVariables {
  companyId: string;
  data: TaskInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TasksUpdateById
// ====================================================

export interface TasksUpdateById_data_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksUpdateById_data_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksUpdateById_data_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksUpdateById_data_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksUpdateById_data_employee_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  createdAt: any;
}

export interface TasksUpdateById_data_assigned_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksUpdateById_data_assigned_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksUpdateById_data_assigned_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksUpdateById_data_assigned {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksUpdateById_data_assigned_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  createdAt: any;
}

export interface TasksUpdateById_data_section {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  deadline: any | null;
  isDone: boolean;
}

export interface TasksUpdateById_data_block {
  __typename: "Block";
  uuid: string;
  name: string;
}

export interface TasksUpdateById_data_owner_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksUpdateById_data_owner_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksUpdateById_data_owner_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksUpdateById_data_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksUpdateById_data_owner_avatar | null;
}

export interface TasksUpdateById_data {
  __typename: "Task";
  id: string;
  name: string | null;
  isDone: boolean;
  description: string;
  deadline: any | null;
  doneTime: any | null;
  employee: TasksUpdateById_data_employee | null;
  assigned: TasksUpdateById_data_assigned | null;
  type: string;
  todo: any | null;
  status: string | null;
  parentId: string | null;
  sectionId: string;
  section: TasksUpdateById_data_section;
  projectId: string;
  blockId: string;
  block: TasksUpdateById_data_block;
  timelog: any | null;
  owner: TasksUpdateById_data_owner | null;
  isArchived: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksUpdateById {
  data: TasksUpdateById_data | null;
}

export interface TasksUpdateByIdVariables {
  id: string;
  data: TaskUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TasksDeleteById
// ====================================================

export interface TasksDeleteById {
  data: string | null;
}

export interface TasksDeleteByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TasksChangeStatus
// ====================================================

export interface TasksChangeStatus_data_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksChangeStatus_data_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksChangeStatus_data_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksChangeStatus_data_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksChangeStatus_data_employee_avatar | null;
}

export interface TasksChangeStatus_data_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface TasksChangeStatus_data {
  __typename: "Task";
  id: string;
  employee: TasksChangeStatus_data_employee | null;
  name: string | null;
  description: string;
  deadline: any | null;
  type: string;
  todo: any | null;
  status: string | null;
  parentId: string | null;
  blockId: string;
  timelog: any | null;
  owner: TasksChangeStatus_data_owner | null;
  createdAt: any;
  updatedAt: any;
}

export interface TasksChangeStatus {
  data: TasksChangeStatus_data | null;
}

export interface TasksChangeStatusVariables {
  data: TaskChangeStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TasksAllQuery
// ====================================================

export interface TasksAllQuery_data_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksAllQuery_data_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksAllQuery_data_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksAllQuery_data_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksAllQuery_data_employee_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  createdAt: any;
}

export interface TasksAllQuery_data_assigned_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksAllQuery_data_assigned_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksAllQuery_data_assigned_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksAllQuery_data_assigned {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksAllQuery_data_assigned_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  createdAt: any;
}

export interface TasksAllQuery_data_section {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  deadline: any | null;
  isDone: boolean;
}

export interface TasksAllQuery_data_block {
  __typename: "Block";
  uuid: string;
  name: string;
}

export interface TasksAllQuery_data_owner_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksAllQuery_data_owner_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksAllQuery_data_owner_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksAllQuery_data_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksAllQuery_data_owner_avatar | null;
}

export interface TasksAllQuery_data {
  __typename: "Task";
  id: string;
  name: string | null;
  isDone: boolean;
  description: string;
  deadline: any | null;
  doneTime: any | null;
  employee: TasksAllQuery_data_employee | null;
  assigned: TasksAllQuery_data_assigned | null;
  type: string;
  todo: any | null;
  status: string | null;
  parentId: string | null;
  sectionId: string;
  section: TasksAllQuery_data_section;
  projectId: string;
  blockId: string;
  block: TasksAllQuery_data_block;
  timelog: any | null;
  owner: TasksAllQuery_data_owner | null;
  isArchived: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksAllQuery {
  data: TasksAllQuery_data[];
}

export interface TasksAllQueryVariables {
  companyId: string;
  entityId?: TasksEntityIdInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TasksSearchAllQuery
// ====================================================

export interface TasksSearchAllQuery_data_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksSearchAllQuery_data_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksSearchAllQuery_data_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksSearchAllQuery_data_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksSearchAllQuery_data_employee_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  createdAt: any;
}

export interface TasksSearchAllQuery_data_assigned_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksSearchAllQuery_data_assigned_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksSearchAllQuery_data_assigned_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksSearchAllQuery_data_assigned {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksSearchAllQuery_data_assigned_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  createdAt: any;
}

export interface TasksSearchAllQuery_data_section {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  deadline: any | null;
  isDone: boolean;
}

export interface TasksSearchAllQuery_data_block {
  __typename: "Block";
  uuid: string;
  name: string;
}

export interface TasksSearchAllQuery_data_owner_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksSearchAllQuery_data_owner_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksSearchAllQuery_data_owner_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksSearchAllQuery_data_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksSearchAllQuery_data_owner_avatar | null;
}

export interface TasksSearchAllQuery_data {
  __typename: "Task";
  id: string;
  name: string | null;
  isDone: boolean;
  description: string;
  deadline: any | null;
  doneTime: any | null;
  employee: TasksSearchAllQuery_data_employee | null;
  assigned: TasksSearchAllQuery_data_assigned | null;
  type: string;
  todo: any | null;
  status: string | null;
  parentId: string | null;
  sectionId: string;
  section: TasksSearchAllQuery_data_section;
  projectId: string;
  blockId: string;
  block: TasksSearchAllQuery_data_block;
  timelog: any | null;
  owner: TasksSearchAllQuery_data_owner | null;
  isArchived: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksSearchAllQuery {
  data: TasksSearchAllQuery_data[];
}

export interface TasksSearchAllQueryVariables {
  companyId: string;
  search: TaskSearch;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TasksGetByIdQuery
// ====================================================

export interface TasksGetByIdQuery_data {
  __typename: "Task";
  id: string;
  companyId: string;
}

export interface TasksGetByIdQuery {
  data: TasksGetByIdQuery_data | null;
}

export interface TasksGetByIdQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: TasksSubscription
// ====================================================

export interface TasksSubscription_data_task_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksSubscription_data_task_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksSubscription_data_task_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksSubscription_data_task_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksSubscription_data_task_employee_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  createdAt: any;
}

export interface TasksSubscription_data_task_assigned_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksSubscription_data_task_assigned_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksSubscription_data_task_assigned_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksSubscription_data_task_assigned {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksSubscription_data_task_assigned_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  createdAt: any;
}

export interface TasksSubscription_data_task_section {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  deadline: any | null;
  isDone: boolean;
}

export interface TasksSubscription_data_task_block {
  __typename: "Block";
  uuid: string;
  name: string;
}

export interface TasksSubscription_data_task_owner_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TasksSubscription_data_task_owner_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TasksSubscription_data_task_owner_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksSubscription_data_task_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TasksSubscription_data_task_owner_avatar | null;
}

export interface TasksSubscription_data_task {
  __typename: "Task";
  id: string;
  name: string | null;
  isDone: boolean;
  description: string;
  deadline: any | null;
  doneTime: any | null;
  employee: TasksSubscription_data_task_employee | null;
  assigned: TasksSubscription_data_task_assigned | null;
  type: string;
  todo: any | null;
  status: string | null;
  parentId: string | null;
  sectionId: string;
  section: TasksSubscription_data_task_section;
  projectId: string;
  blockId: string;
  block: TasksSubscription_data_task_block;
  timelog: any | null;
  owner: TasksSubscription_data_task_owner | null;
  isArchived: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TasksSubscription_data {
  __typename: "TaskSubscription";
  task: TasksSubscription_data_task;
  type: string;
}

export interface TasksSubscription {
  data: TasksSubscription_data;
}

export interface TasksSubscriptionVariables {
  data: TaskInputSubscription;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TemplatesCreate
// ====================================================

export interface TemplatesCreate_data_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface TemplatesCreate_data_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface TemplatesCreate_data_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: TemplatesCreate_data_test_onSuccess | null;
  onFail: TemplatesCreate_data_test_onFail | null;
  isResult: boolean | null;
}

export interface TemplatesCreate_data_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TemplatesCreate_data_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TemplatesCreate_data_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TemplatesCreate_data_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface TemplatesCreate_data {
  __typename: "EditorTemplate";
  employeeId: string | null;
  projectId: string | null;
  companyId: string | null;
  aggregateType: string | null;
  uuid: string;
  name: string;
  test: TemplatesCreate_data_test | null;
  elements: any;
  schema: string[][][] | null;
  files: TemplatesCreate_data_files[];
  mode: string;
  type: string;
  options: TemplatesCreate_data_options;
  createdAt: any;
  updatedAt: any;
}

export interface TemplatesCreate {
  data: TemplatesCreate_data | null;
}

export interface TemplatesCreateVariables {
  companyId: string;
  data: EditorTemplateCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TemplatesDeleteById
// ====================================================

export interface TemplatesDeleteById {
  data: string | null;
}

export interface TemplatesDeleteByIdVariables {
  companyId: string;
  uuid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TemplatesDeleteByIds
// ====================================================

export interface TemplatesDeleteByIds {
  data: string[] | null;
}

export interface TemplatesDeleteByIdsVariables {
  companyId: string;
  uuid: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TemplatesAll
// ====================================================

export interface TemplatesAll_data_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface TemplatesAll_data_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface TemplatesAll_data_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: TemplatesAll_data_test_onSuccess | null;
  onFail: TemplatesAll_data_test_onFail | null;
  isResult: boolean | null;
}

export interface TemplatesAll_data_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TemplatesAll_data_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TemplatesAll_data_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TemplatesAll_data_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface TemplatesAll_data {
  __typename: "EditorTemplate";
  employeeId: string | null;
  projectId: string | null;
  companyId: string | null;
  aggregateType: string | null;
  uuid: string;
  name: string;
  test: TemplatesAll_data_test | null;
  elements: any;
  schema: string[][][] | null;
  files: TemplatesAll_data_files[];
  mode: string;
  type: string;
  options: TemplatesAll_data_options;
  createdAt: any;
  updatedAt: any;
}

export interface TemplatesAll {
  data: (TemplatesAll_data | null)[];
}

export interface TemplatesAllVariables {
  companyId: string;
  data: EditorTemplateAllInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AvatarSchema
// ====================================================

export interface AvatarSchema_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface AvatarSchema {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: AvatarSchema_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BlockSchema
// ====================================================

export interface BlockSchema_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface BlockSchema_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface BlockSchema_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: BlockSchema_test_onSuccess | null;
  onFail: BlockSchema_test_onFail | null;
  isResult: boolean | null;
}

export interface BlockSchema_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface BlockSchema_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: BlockSchema_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface BlockSchema_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface BlockSchema {
  __typename: "Block";
  uuid: string;
  name: string;
  test: BlockSchema_test | null;
  elements: any;
  schema: string[][][] | null;
  files: BlockSchema_files[];
  sectionId: string;
  mode: string;
  type: string;
  options: BlockSchema_options;
  version: number | null;
  createdAt: any;
  updatedAt: any;
  tasksCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BlockVersionSchema
// ====================================================

export interface BlockVersionSchema_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface BlockVersionSchema_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface BlockVersionSchema_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: BlockVersionSchema_test_onSuccess | null;
  onFail: BlockVersionSchema_test_onFail | null;
  isResult: boolean | null;
}

export interface BlockVersionSchema_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface BlockVersionSchema_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: BlockVersionSchema_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface BlockVersionSchema_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
}

export interface BlockVersionSchema {
  __typename: "BlockVersionItem";
  uuid: string;
  name: string;
  test: BlockVersionSchema_test | null;
  elements: any;
  schema: string[][][] | null;
  files: BlockVersionSchema_files[];
  sectionId: string;
  mode: string;
  type: string;
  options: BlockVersionSchema_options;
  version: number | null;
  createdAt: any;
  updatedAt: any;
  tasksCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CompanySchema
// ====================================================

export interface CompanySchema_logo_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface CompanySchema_logo {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: CompanySchema_logo_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface CompanySchema {
  __typename: "Company";
  id: string;
  name: string;
  email: string | null;
  logo: CompanySchema_logo | null;
  domainName: string | null;
  color: string | null;
  description: string | null;
  legalId: string | null;
  createdAt: any;
  createdBy: string | null;
  phone: string | null;
  currency: Currency | null;
  updatedAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CompanyBrandSchema
// ====================================================

export interface CompanyBrandSchema {
  __typename: "Company";
  id: string;
  brand: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CompanyListSchema
// ====================================================

export interface CompanyListSchema_logo_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface CompanyListSchema_logo {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: CompanyListSchema_logo_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface CompanyListSchema_employees {
  __typename: "Employee";
  id: string;
}

export interface CompanyListSchema {
  __typename: "Company";
  id: string;
  name: string;
  email: string | null;
  logo: CompanyListSchema_logo | null;
  domainName: string | null;
  color: string | null;
  description: string | null;
  legalId: string | null;
  createdAt: any;
  createdBy: string | null;
  phone: string | null;
  currency: Currency | null;
  updatedAt: any;
  employees: (CompanyListSchema_employees | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CompanyInfoSchema
// ====================================================

export interface CompanyInfoSchema_user {
  __typename: "KUser";
  id: string;
  name: string | null;
  username: string;
  email: string;
}

export interface CompanyInfoSchema_company_logo_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface CompanyInfoSchema_company_logo {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: CompanyInfoSchema_company_logo_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface CompanyInfoSchema_company {
  __typename: "Company";
  id: string;
  name: string;
  email: string | null;
  logo: CompanyInfoSchema_company_logo | null;
  domainName: string | null;
  color: string | null;
  description: string | null;
  legalId: string | null;
  createdAt: any;
  createdBy: string | null;
  phone: string | null;
  currency: Currency | null;
  updatedAt: any;
}

export interface CompanyInfoSchema_role {
  __typename: "Role";
  id: string;
  name: string | null;
}

export interface CompanyInfoSchema_permissions {
  __typename: "Permission";
  id: string;
  name: string;
  type: string;
}

export interface CompanyInfoSchema_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface CompanyInfoSchema_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: CompanyInfoSchema_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface CompanyInfoSchema_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: CompanyInfoSchema_employee_avatar | null;
}

export interface CompanyInfoSchema {
  __typename: "CompanyInfo";
  user: CompanyInfoSchema_user;
  company: CompanyInfoSchema_company;
  role: CompanyInfoSchema_role;
  permissions: CompanyInfoSchema_permissions[];
  employee: CompanyInfoSchema_employee;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EmployeeSmallSchema
// ====================================================

export interface EmployeeSmallSchema {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EmployeeSchema
// ====================================================

export interface EmployeeSchema_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface EmployeeSchema_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: EmployeeSchema_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface EmployeeSchema {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: EmployeeSchema_avatar | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EmployeeInfoSchema
// ====================================================

export interface EmployeeInfoSchema {
  __typename: "Employee";
  description: string | null;
  language: string | null;
  status: string | null;
  registrated: any | null;
  edited: any | null;
  position: string | null;
  companyPhone: string | null;
  phone: string | null;
  address: string | null;
  email: string | null;
  skype: string | null;
  whatsapp: string | null;
  telegram: string | null;
  portfolio: string | null;
  twitter: string | null;
  linkedin: string | null;
  facebook: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EmployeeListSchema
// ====================================================

export interface EmployeeListSchema_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface EmployeeListSchema_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: EmployeeListSchema_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface EmployeeListSchema_kUser {
  __typename: "KUser";
  id: string;
  name: string | null;
  username: string;
  country: string | null;
  city: string | null;
  isActive: boolean | null;
  isArchived: boolean | null;
  createdAt: any;
  updatedAt: any;
  description: string | null;
  language: string | null;
  position: string | null;
  skype: string | null;
  whatsapp: string | null;
  phone: string | null;
  address: string | null;
  email: string;
  telegram: string | null;
  portfolio: string | null;
  twitter: string | null;
  facebook: string | null;
  linkedin: string | null;
  other: string | null;
}

export interface EmployeeListSchema_roles_permission {
  __typename: "Permission";
  id: string;
  isActive: boolean | null;
  createdAt: any;
  updatedAt: any;
  name: string;
  type: string;
}

export interface EmployeeListSchema_roles {
  __typename: "Role";
  id: string;
  name: string | null;
  description: string | null;
  updatedAt: any;
  companyId: string | null;
  isRemovable: boolean | null;
  isDefault: boolean | null;
  permission: EmployeeListSchema_roles_permission[] | null;
}

export interface EmployeeListSchema_projects {
  __typename: "Project";
  id: string;
}

export interface EmployeeListSchema {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: EmployeeListSchema_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  kUser: EmployeeListSchema_kUser;
  roles: EmployeeListSchema_roles[];
  projects: EmployeeListSchema_projects[];
  tags: string[] | null;
  createdAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FileSchema
// ====================================================

export interface FileSchema {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FileMetaFullSchema
// ====================================================

export interface FileMetaFullSchema_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface FileMetaFullSchema {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: FileMetaFullSchema_files[];
  size: number | null;
  name: string;
  type: string;
  createrId: string | null;
  isProcessed: boolean;
  fileMetaGroupIds: (string | null)[] | null;
  isArchived: boolean;
  createdAt: any;
  updatedAt: any;
  status: string;
  error: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FileMetaSchema
// ====================================================

export interface FileMetaSchema_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface FileMetaSchema {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: FileMetaSchema_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FilesGroups
// ====================================================

export interface FilesGroups_breadcrumbs {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
}

export interface FilesGroups {
  __typename: "FileMetaGroup";
  id: string;
  name: string;
  color: string | null;
  parentId: string | null;
  lvl: number | null;
  isArchived: boolean | null;
  companyId: string | null;
  descendants: (string | null)[];
  createdAt: any;
  updatedAt: any;
  breadcrumbs: FilesGroups_breadcrumbs[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NotificationListSchema
// ====================================================

export interface NotificationListSchema_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface NotificationListSchema_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: NotificationListSchema_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface NotificationListSchema_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  avatar: NotificationListSchema_employee_avatar | null;
}

export interface NotificationListSchema_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface NotificationListSchema {
  __typename: "Notification";
  id: string;
  type: string;
  employee: NotificationListSchema_employee | null;
  owner: NotificationListSchema_owner | null;
  count: number | null;
  entity: string;
  entityId: string;
  isNew: boolean;
  createdAt: any;
  updatedAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PermissionListSchema
// ====================================================

export interface PermissionListSchema {
  __typename: "Permission";
  id: string;
  isActive: boolean | null;
  createdAt: any;
  updatedAt: any;
  name: string;
  type: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: kUserInfoSchema
// ====================================================

export interface kUserInfoSchema {
  __typename: "KUser";
  id: string;
  name: string | null;
  username: string;
  country: string | null;
  city: string | null;
  isActive: boolean | null;
  isArchived: boolean | null;
  createdAt: any;
  updatedAt: any;
  description: string | null;
  language: string | null;
  position: string | null;
  skype: string | null;
  whatsapp: string | null;
  phone: string | null;
  address: string | null;
  email: string;
  telegram: string | null;
  portfolio: string | null;
  twitter: string | null;
  facebook: string | null;
  linkedin: string | null;
  other: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProjectGroupListSchema
// ====================================================

export interface ProjectGroupListSchema_analitycs {
  __typename: "ProjectGroupAnalytics";
  projectsTotal: number;
  tasksDone: number;
  tasksTotal: number;
  tasksExpired: number;
}

export interface ProjectGroupListSchema {
  __typename: "ProjectGroup";
  id: string;
  name: string | null;
  description: string | null;
  logo: number | null;
  color: string | null;
  parentId: string | null;
  createdAt: any;
  updatedAt: any;
  analitycs: ProjectGroupListSchema_analitycs | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProjectGroupSchema
// ====================================================

export interface ProjectGroupSchema_breadcrumbs {
  __typename: "ProjectGroup";
  name: string | null;
  id: string;
}

export interface ProjectGroupSchema {
  __typename: "ProjectGroup";
  id: string;
  name: string | null;
  color: string | null;
  parentId: string | null;
  description: string | null;
  updatedAt: any;
  breadcrumbs: ProjectGroupSchema_breadcrumbs[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PublicLinkProjectSchema
// ====================================================

export interface PublicLinkProjectSchema {
  __typename: "Link";
  id: string | null;
  link: string | null;
  isOpen: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PublicBlockSchema
// ====================================================

export interface PublicBlockSchema {
  __typename: "PublicBlockType";
  id: string;
  description: string;
  title: string;
  icon: string | null;
  iconImg: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PublicSettingsSchema
// ====================================================

export interface PublicSettingsSchema_authors {
  __typename: "PublicAuthorType";
  id: string;
  avatarId: string;
  name: string;
  description: string | null;
}

export interface PublicSettingsSchema {
  __typename: "PublicSettingsType";
  id: string | null;
  imageId: string | null;
  authors: PublicSettingsSchema_authors[] | null;
  logoId: string | null;
  secondaryLogoId: string | null;
  name: string | null;
  description: string | null;
  authorsDescription: string | null;
  authorsType: string | null;
  colorPrimary: string | null;
  colorFont: string | null;
  infoAboutProgress: string | null;
  tableOfContentInitiallyExpanded: boolean | null;
  supportEmail: string | null;
  footerCopyright: string | null;
  footerText1: string | null;
  footerText2: string | null;
  footerText3: string | null;
  footerText4: string | null;
  templateKey: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProjectListSchema
// ====================================================

export interface ProjectListSchema_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectListSchema_publicSettings_authors {
  __typename: "PublicAuthorType";
  id: string;
  avatarId: string;
  name: string;
  description: string | null;
}

export interface ProjectListSchema_publicSettings {
  __typename: "PublicSettingsType";
  id: string | null;
  imageId: string | null;
  authors: ProjectListSchema_publicSettings_authors[] | null;
  logoId: string | null;
  secondaryLogoId: string | null;
  name: string | null;
  description: string | null;
  authorsDescription: string | null;
  authorsType: string | null;
  colorPrimary: string | null;
  colorFont: string | null;
  infoAboutProgress: string | null;
  tableOfContentInitiallyExpanded: boolean | null;
  supportEmail: string | null;
  footerCopyright: string | null;
  footerText1: string | null;
  footerText2: string | null;
  footerText3: string | null;
  footerText4: string | null;
  templateKey: string | null;
}

export interface ProjectListSchema_analitycs {
  __typename: "ProjectAnalytics";
  tasksDone: number;
  tasksTotal: number;
  tasksExpired: number;
}

export interface ProjectListSchema {
  __typename: "Project";
  id: string;
  name: string;
  budget: number | null;
  tags: string[] | null;
  owner: ProjectListSchema_owner;
  publicSettings: ProjectListSchema_publicSettings;
  isDone: boolean;
  learningObjectives: string | null;
  learningGoals: string | null;
  endDate: any | null;
  startDate: any | null;
  accent: string | null;
  projectGroupId: string | null;
  updatedAt: any;
  analitycs: ProjectListSchema_analitycs | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProjectSchema
// ====================================================

export interface ProjectSchema_publicSettings_authors {
  __typename: "PublicAuthorType";
  id: string;
  avatarId: string;
  name: string;
  description: string | null;
}

export interface ProjectSchema_publicSettings {
  __typename: "PublicSettingsType";
  id: string | null;
  imageId: string | null;
  authors: ProjectSchema_publicSettings_authors[] | null;
  logoId: string | null;
  secondaryLogoId: string | null;
  name: string | null;
  description: string | null;
  authorsDescription: string | null;
  authorsType: string | null;
  colorPrimary: string | null;
  colorFont: string | null;
  infoAboutProgress: string | null;
  tableOfContentInitiallyExpanded: boolean | null;
  supportEmail: string | null;
  footerCopyright: string | null;
  footerText1: string | null;
  footerText2: string | null;
  footerText3: string | null;
  footerText4: string | null;
  templateKey: string | null;
}

export interface ProjectSchema_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectSchema_sectionsOrder {
  __typename: "EditorSectionOrderOptionalType";
  id: string;
  lvl: number;
}

export interface ProjectSchema_publicBlocks {
  __typename: "PublicBlockType";
  id: string;
  description: string;
  title: string;
  icon: string | null;
  iconImg: string | null;
}

export interface ProjectSchema_publicFiles_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface ProjectSchema_publicFiles {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: ProjectSchema_publicFiles_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface ProjectSchema_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectSchema_publicLink {
  __typename: "Link";
  id: string | null;
  link: string | null;
  isOpen: boolean;
}

export interface ProjectSchema {
  __typename: "Project";
  id: string;
  companyId: string | null;
  projectGroupId: string | null;
  progressShouldBeGreaterThen: number;
  progressShouldBeLeastThen: number;
  finalTestId: string | null;
  tags: string[] | null;
  publicSettings: ProjectSchema_publicSettings;
  owner: ProjectSchema_owner;
  sectionsOrder: ProjectSchema_sectionsOrder[];
  accent: string | null;
  name: string;
  startDate: any | null;
  endDate: any | null;
  budget: number | null;
  cost: number | null;
  updatedAt: any;
  learningObjectives: string | null;
  learningGoals: string | null;
  publicBlocks: ProjectSchema_publicBlocks[];
  publicFiles: ProjectSchema_publicFiles[];
  employee: ProjectSchema_employee[];
  publicLink: ProjectSchema_publicLink;
  createdAt: any;
  isDone: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProjectSchemaDeep
// ====================================================

export interface ProjectSchemaDeep_publicSettings_authors {
  __typename: "PublicAuthorType";
  id: string;
  avatarId: string;
  name: string;
  description: string | null;
}

export interface ProjectSchemaDeep_publicSettings {
  __typename: "PublicSettingsType";
  id: string | null;
  imageId: string | null;
  authors: ProjectSchemaDeep_publicSettings_authors[] | null;
  logoId: string | null;
  secondaryLogoId: string | null;
  name: string | null;
  description: string | null;
  authorsDescription: string | null;
  authorsType: string | null;
  colorPrimary: string | null;
  colorFont: string | null;
  infoAboutProgress: string | null;
  tableOfContentInitiallyExpanded: boolean | null;
  supportEmail: string | null;
  footerCopyright: string | null;
  footerText1: string | null;
  footerText2: string | null;
  footerText3: string | null;
  footerText4: string | null;
  templateKey: string | null;
}

export interface ProjectSchemaDeep_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
}

export interface ProjectSchemaDeep_sectionsOrder {
  __typename: "EditorSectionOrderOptionalType";
  id: string;
  lvl: number;
}

export interface ProjectSchemaDeep_sections_blocks_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface ProjectSchemaDeep_sections_blocks_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface ProjectSchemaDeep_sections_blocks_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: ProjectSchemaDeep_sections_blocks_test_onSuccess | null;
  onFail: ProjectSchemaDeep_sections_blocks_test_onFail | null;
  isResult: boolean | null;
}

export interface ProjectSchemaDeep_sections_blocks_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface ProjectSchemaDeep_sections_blocks_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: ProjectSchemaDeep_sections_blocks_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface ProjectSchemaDeep_sections_blocks_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface ProjectSchemaDeep_sections_blocks {
  __typename: "Block";
  uuid: string;
  mode: string;
  name: string;
  test: ProjectSchemaDeep_sections_blocks_test | null;
  elements: any;
  schema: string[][][] | null;
  files: ProjectSchemaDeep_sections_blocks_files[];
  sectionId: string;
  type: string;
  options: ProjectSchemaDeep_sections_blocks_options;
  version: number | null;
  createdAt: any;
  updatedAt: any;
  tasksCount: number;
}

export interface ProjectSchemaDeep_sections_test {
  __typename: "EditorSectionTest";
  time: number | null;
  testRetry: number | null;
  resultShouldBeGreaterThen: number | null;
  isNextOnSubmit: boolean | null;
  isValidationVisible: boolean | null;
  isResultValuePercent: boolean | null;
  questionOrder: string | null;
  isShuffleQuestions: boolean | null;
  isProgressShown: boolean | null;
}

export interface ProjectSchemaDeep_sections {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  description: string | null;
  type: string;
  blocksOrder: string[];
  deadline: any | null;
  blocks: ProjectSchemaDeep_sections_blocks[];
  test: ProjectSchemaDeep_sections_test | null;
  lvl: number;
  isChapter: boolean;
  updatedAt: any;
}

export interface ProjectSchemaDeep_publicBlocks {
  __typename: "PublicBlockType";
  id: string;
  description: string;
  title: string;
  icon: string | null;
  iconImg: string | null;
}

export interface ProjectSchemaDeep_publicFiles_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface ProjectSchemaDeep_publicFiles {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: ProjectSchemaDeep_publicFiles_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface ProjectSchemaDeep_company_logo_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface ProjectSchemaDeep_company_logo {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: ProjectSchemaDeep_company_logo_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface ProjectSchemaDeep_company {
  __typename: "Company";
  logo: ProjectSchemaDeep_company_logo | null;
  id: string;
  brand: any | null;
}

export interface ProjectSchemaDeep {
  __typename: "Project";
  id: string;
  companyId: string | null;
  projectGroupId: string | null;
  progressShouldBeGreaterThen: number;
  progressShouldBeLeastThen: number;
  finalTestId: string | null;
  publicSettings: ProjectSchemaDeep_publicSettings;
  owner: ProjectSchemaDeep_owner;
  sectionsOrder: ProjectSchemaDeep_sectionsOrder[];
  sections: ProjectSchemaDeep_sections[];
  accent: string | null;
  name: string;
  endDate: any | null;
  updatedAt: any;
  learningObjectives: string | null;
  learningGoals: string | null;
  publicBlocks: ProjectSchemaDeep_publicBlocks[];
  publicFiles: ProjectSchemaDeep_publicFiles[];
  company: ProjectSchemaDeep_company | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LoginAsVisitorSchema
// ====================================================

export interface LoginAsVisitorSchema {
  __typename: "LoginAsVisitor";
  id: string;
  status: PreviewLinkAccessStatus;
  token: string | null;
  companyId: string | null;
  projectId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RolesAllSchema
// ====================================================

export interface RolesAllSchema {
  __typename: "Role";
  id: string;
  name: string | null;
  description: string | null;
  updatedAt: any;
  companyId: string | null;
  isRemovable: boolean | null;
  isDefault: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: routineMaintenanceSchema
// ====================================================

export interface routineMaintenanceSchema {
  __typename: "Announce";
  id: string | null;
  status: AnnounceStatuses;
  date: any | null;
  message: string | null;
  linkUrl: string | null;
  linkLabel: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ScormBuildSchema
// ====================================================

export interface ScormBuildSchema_payload_employee {
  __typename: "BuildPayloadFieldsType";
  id: string;
  name: string;
}

export interface ScormBuildSchema_payload_project {
  __typename: "BuildPayloadFieldsType";
  id: string;
  name: string;
}

export interface ScormBuildSchema_payload_category {
  __typename: "BuildPayloadCategoryType";
  id: string | null;
}

export interface ScormBuildSchema_payload {
  __typename: "BuildPayloadType";
  employee: ScormBuildSchema_payload_employee;
  project: ScormBuildSchema_payload_project;
  category: ScormBuildSchema_payload_category | null;
  percents: string;
}

export interface ScormBuildSchema_lastBuild {
  __typename: "BuildLastType";
  createdAt: any;
  link: string;
}

export interface ScormBuildSchema {
  __typename: "Build";
  status: string | null;
  sessionId: string | null;
  payload: ScormBuildSchema_payload | null;
  onBuild: boolean | null;
  lastBuild: ScormBuildSchema_lastBuild | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ScormPackageSchema
// ====================================================

export interface ScormPackageSchema_currentSession {
  __typename: "BuildSession";
  id: string | null;
}

export interface ScormPackageSchema {
  __typename: "BuildInitResult";
  status: string;
  error: string[];
  currentSession: ScormPackageSchema_currentSession | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CurrentSessionSchema
// ====================================================

export interface CurrentSessionSchema_currentSession {
  __typename: "BuildSession";
  id: string | null;
}

export interface CurrentSessionSchema {
  __typename: "BuildSessionCurrent";
  currentSession: CurrentSessionSchema_currentSession | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TestSchema
// ====================================================

export interface TestSchema {
  __typename: "EditorSectionTest";
  time: number | null;
  testRetry: number | null;
  resultShouldBeGreaterThen: number | null;
  isNextOnSubmit: boolean | null;
  isValidationVisible: boolean | null;
  isResultValuePercent: boolean | null;
  questionOrder: string | null;
  isShuffleQuestions: boolean | null;
  isProgressShown: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SectionSchema
// ====================================================

export interface SectionSchema_blocks {
  __typename: "Block";
  uuid: string;
  mode: string;
}

export interface SectionSchema_test {
  __typename: "EditorSectionTest";
  time: number | null;
  testRetry: number | null;
  resultShouldBeGreaterThen: number | null;
  isNextOnSubmit: boolean | null;
  isValidationVisible: boolean | null;
  isResultValuePercent: boolean | null;
  questionOrder: string | null;
  isShuffleQuestions: boolean | null;
  isProgressShown: boolean | null;
}

export interface SectionSchema {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  description: string | null;
  type: string;
  blocksOrder: string[];
  deadline: any | null;
  blocks: SectionSchema_blocks[];
  test: SectionSchema_test | null;
  lvl: number;
  isChapter: boolean;
  updatedAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SectionWithBlocksSchema
// ====================================================

export interface SectionWithBlocksSchema_blocks_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface SectionWithBlocksSchema_blocks_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface SectionWithBlocksSchema_blocks_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: SectionWithBlocksSchema_blocks_test_onSuccess | null;
  onFail: SectionWithBlocksSchema_blocks_test_onFail | null;
  isResult: boolean | null;
}

export interface SectionWithBlocksSchema_blocks_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface SectionWithBlocksSchema_blocks_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: SectionWithBlocksSchema_blocks_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface SectionWithBlocksSchema_blocks_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface SectionWithBlocksSchema_blocks {
  __typename: "Block";
  uuid: string;
  mode: string;
  name: string;
  test: SectionWithBlocksSchema_blocks_test | null;
  elements: any;
  schema: string[][][] | null;
  files: SectionWithBlocksSchema_blocks_files[];
  sectionId: string;
  type: string;
  options: SectionWithBlocksSchema_blocks_options;
  version: number | null;
  createdAt: any;
  updatedAt: any;
  tasksCount: number;
}

export interface SectionWithBlocksSchema_test {
  __typename: "EditorSectionTest";
  time: number | null;
  testRetry: number | null;
  resultShouldBeGreaterThen: number | null;
  isNextOnSubmit: boolean | null;
  isValidationVisible: boolean | null;
  isResultValuePercent: boolean | null;
  questionOrder: string | null;
  isShuffleQuestions: boolean | null;
  isProgressShown: boolean | null;
}

export interface SectionWithBlocksSchema {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  description: string | null;
  type: string;
  blocksOrder: string[];
  deadline: any | null;
  blocks: SectionWithBlocksSchema_blocks[];
  test: SectionWithBlocksSchema_test | null;
  lvl: number;
  isChapter: boolean;
  updatedAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SectionOrderSchema
// ====================================================

export interface SectionOrderSchema {
  __typename: "EditorSectionOrderOptionalType";
  id: string;
  lvl: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SectionEditorSchema
// ====================================================

export interface SectionEditorSchema_blocks {
  __typename: "Block";
  uuid: string;
  mode: string;
}

export interface SectionEditorSchema_test {
  __typename: "EditorSectionTest";
  time: number | null;
  testRetry: number | null;
  resultShouldBeGreaterThen: number | null;
  isNextOnSubmit: boolean | null;
  isValidationVisible: boolean | null;
  isResultValuePercent: boolean | null;
  questionOrder: string | null;
  isShuffleQuestions: boolean | null;
  isProgressShown: boolean | null;
}

export interface SectionEditorSchema_formats {
  __typename: "DealFormat";
  id: string;
  cost: number;
  unitType: string;
  count: number;
  formatId: string;
}

export interface SectionEditorSchema_participants_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface SectionEditorSchema_participants_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: SectionEditorSchema_participants_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface SectionEditorSchema_participants {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: SectionEditorSchema_participants_avatar | null;
}

export interface SectionEditorSchema {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  description: string | null;
  type: string;
  blocksOrder: string[];
  deadline: any | null;
  blocks: SectionEditorSchema_blocks[];
  test: SectionEditorSchema_test | null;
  lvl: number;
  isChapter: boolean;
  updatedAt: any;
  isDone: boolean;
  projectId: string;
  totalCost: number | null;
  formats: SectionEditorSchema_formats[] | null;
  tags: string[];
  isArchived: boolean;
  createdAt: any;
  participants: SectionEditorSchema_participants[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TagSchema
// ====================================================

export interface TagSchema_employee {
  __typename: "Employee";
  id: string;
  roleId: string;
}

export interface TagSchema_parent {
  __typename: "Tag";
  name: string;
  companyId: string;
}

export interface TagSchema_project {
  __typename: "Project";
  id: string;
}

export interface TagSchema {
  __typename: "Tag";
  id: string;
  name: string;
  parentId: string | null;
  employee: (TagSchema_employee | null)[];
  parent: TagSchema_parent | null;
  project: TagSchema_project[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskEmployeeSchema
// ====================================================

export interface TaskEmployeeSchema {
  __typename: "Employee";
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  updatedAt: any;
  createdAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskBlockSchema
// ====================================================

export interface TaskBlockSchema {
  __typename: "Block";
  uuid: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskSectionSchema
// ====================================================

export interface TaskSectionSchema {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  deadline: any | null;
  isDone: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskSchema
// ====================================================

export interface TaskSchema_employee_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TaskSchema_employee_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TaskSchema_employee_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TaskSchema_employee {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TaskSchema_employee_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  createdAt: any;
}

export interface TaskSchema_assigned_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TaskSchema_assigned_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TaskSchema_assigned_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TaskSchema_assigned {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TaskSchema_assigned_avatar | null;
  roleId: string;
  companyId: string;
  isActive: boolean;
  isArchived: boolean;
  createdAt: any;
}

export interface TaskSchema_section {
  __typename: "EditorSection";
  id: string;
  name: string | null;
  deadline: any | null;
  isDone: boolean;
}

export interface TaskSchema_block {
  __typename: "Block";
  uuid: string;
  name: string;
}

export interface TaskSchema_owner_avatar_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TaskSchema_owner_avatar {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TaskSchema_owner_avatar_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TaskSchema_owner {
  __typename: "Employee";
  id: string;
  name: string | null;
  username: string;
  kuserId: string;
  updatedAt: any;
  avatar: TaskSchema_owner_avatar | null;
}

export interface TaskSchema {
  __typename: "Task";
  id: string;
  name: string | null;
  isDone: boolean;
  description: string;
  deadline: any | null;
  doneTime: any | null;
  employee: TaskSchema_employee | null;
  assigned: TaskSchema_assigned | null;
  type: string;
  todo: any | null;
  status: string | null;
  parentId: string | null;
  sectionId: string;
  section: TaskSchema_section;
  projectId: string;
  blockId: string;
  block: TaskSchema_block;
  timelog: any | null;
  owner: TaskSchema_owner | null;
  isArchived: boolean;
  createdAt: any;
  updatedAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TemplatesListSchema
// ====================================================

export interface TemplatesListSchema_test_onSuccess {
  __typename: "BlockTestOnSuccess";
  goToBlockId: string | null;
}

export interface TemplatesListSchema_test_onFail {
  __typename: "BlockTestOnFail";
  goToBlockId: string | null;
  retry: number | null;
}

export interface TemplatesListSchema_test {
  __typename: "BlockTest";
  time: number | null;
  successRules: any | null;
  onSuccess: TemplatesListSchema_test_onSuccess | null;
  onFail: TemplatesListSchema_test_onFail | null;
  isResult: boolean | null;
}

export interface TemplatesListSchema_files_files {
  __typename: "File";
  id: string;
  metaId: string;
  origin: string;
  path: string;
  ext: string;
  size: number;
  width: number | null;
  height: number | null;
  type: string;
}

export interface TemplatesListSchema_files {
  __typename: "FileMeta";
  id: string;
  origin: string | null;
  files: TemplatesListSchema_files_files[];
  status: string;
  name: string;
  type: string;
  size: number | null;
  isArchived: boolean;
  isProcessed: boolean;
  createdAt: any;
  updatedAt: any;
}

export interface TemplatesListSchema_options {
  __typename: "BlockOptions";
  backgroundColor: string | null;
  backgroundImage: string | null;
  indents: number[] | null;
  tabs: string[] | null;
  tabsIndex: number | null;
  tabsPosition: string | null;
  borderRadius: number | null;
  questionFeedback: any | null;
}

export interface TemplatesListSchema {
  __typename: "EditorTemplate";
  employeeId: string | null;
  projectId: string | null;
  companyId: string | null;
  aggregateType: string | null;
  uuid: string;
  name: string;
  test: TemplatesListSchema_test | null;
  elements: any;
  schema: string[][][] | null;
  files: TemplatesListSchema_files[];
  mode: string;
  type: string;
  options: TemplatesListSchema_options;
  createdAt: any;
  updatedAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AnnounceStatuses {
  BUG = "BUG",
  IN_PROGRESS = "IN_PROGRESS",
  NONE = "NONE",
  PLANNING = "PLANNING",
}

export enum Currency {
  BYN = "BYN",
  CNY = "CNY",
  EUR = "EUR",
  RUB = "RUB",
  USD = "USD",
}

export enum FileMetaType {
  all = "all",
  companies = "companies",
  owner = "owner",
  projects = "projects",
}

export enum FileMetaTypeDeleteEnum {
  file = "file",
  group = "group",
}

export enum PreviewLinkAccessStatus {
  FORBIDDEN = "FORBIDDEN",
  NOT_FOUND = "NOT_FOUND",
  REDIRECT = "REDIRECT",
  SHOW = "SHOW",
}

export enum UpsertType {
  companies = "companies",
  owner = "owner",
  projects = "projects",
}

export interface BlockInput {
  activeBlockUUID?: string | null;
  type: string;
  name: string;
  mode: string;
  test?: BlockTestInput | null;
  elements: any;
  schema?: string[][][] | null;
  sectionId: string;
  options: BlockOptionsInput;
}

export interface BlockMultiCreateInput {
  uuids: string[];
  activeBlockUUID?: string | null;
  sectionId: string;
}

export interface BlockOptionsInput {
  backgroundColor?: string | null;
  backgroundImage?: string | null;
  borderRadius?: number | null;
  indents?: number[] | null;
  tabs?: string[] | null;
  tabsIndex?: number | null;
  tabsPosition?: string | null;
  questionFeedback?: any | null;
}

export interface BlockTestInput {
  time?: number | null;
  successRules?: any | null;
  onSuccess?: BlockTestOnSuccessInput | null;
  onFail?: BlockTestOnFailInput | null;
  isResult?: boolean | null;
}

export interface BlockTestOnFailInput {
  goToBlockId?: string | null;
  retry?: number | null;
}

export interface BlockTestOnSuccessInput {
  goToBlockId?: string | null;
}

export interface BlockUpdateInput {
  uuid: string;
  type: string;
  name: string;
  mode: string;
  test?: BlockTestInput | null;
  elements: any;
  schema?: string[][][] | null;
  sectionId: string;
  options: BlockOptionsInput;
}

export interface CompanyDeleteInput {
  password: string;
}

export interface CompanyInput {
  name: string;
  ownerId?: string | null;
  color?: string | null;
  isConstractor?: string | null;
  legalId?: string | null;
  contracts?: string | null;
  description?: string | null;
  isArchived?: boolean | null;
  createdBy?: string | null;
  phone?: string | null;
  domainName?: string | null;
  email?: string | null;
  currency?: Currency | null;
  brand?: any | null;
  logo?: string | null;
}

export interface CompanyUpdateBrandInput {
  brand?: any | null;
}

export interface CompanyUpdateInput {
  name?: string | null;
  ownerId?: string | null;
  color?: string | null;
  isConstractor?: string | null;
  legalId?: string | null;
  contracts?: string | null;
  logo?: string | null;
  description?: string | null;
  createdBy?: string | null;
  phone?: string | null;
  domainName?: string | null;
  email?: string | null;
  currency?: Currency | null;
}

export interface DealFormatInput {
  id: string;
  cost: number;
  unitType: string;
  count: number;
  formatId: string;
}

export interface EditorSectionInput {
  participants: string[];
  lvl: number;
  test?: EditorSectionTestInput | null;
  sectionsOrder?: EditorSectionOrderOptionalInput[] | null;
  name?: string | null;
  isDone: boolean;
  description?: string | null;
  type: string;
  deadline?: any | null;
  blocksOrder: string[];
  totalCost?: number | null;
  tags: string[];
  isChapter: boolean;
  formats?: DealFormatInput[] | null;
}

export interface EditorSectionOrderOptionalInput {
  id?: string | null;
  lvl: number;
}

export interface EditorSectionTestInput {
  submitActionShouldSwitched?: boolean | null;
  time?: number | null;
  testRetry?: number | null;
  resultShouldBeGreaterThen?: number | null;
  isNextOnSubmit?: boolean | null;
  isValidationVisible?: boolean | null;
  isResultValuePercent?: boolean | null;
  questionOrder?: string | null;
  isShuffleQuestions?: boolean | null;
  isProgressShown?: boolean | null;
}

export interface EditorSectionUpdateInput {
  name?: string | null;
  isDone?: boolean | null;
  description?: string | null;
  type?: string | null;
  deadline?: any | null;
  blocksOrder?: string[] | null;
  sectionsOrder?: EditorSectionOrderOptionalInput[] | null;
  participants?: string[] | null;
  totalCost?: number | null;
  test?: EditorSectionTestInput | null;
  tags?: string[] | null;
  lvl?: number | null;
  isChapter?: boolean | null;
  formats?: DealFormatInput[] | null;
}

export interface EditorTemplateAllInput {
  projectId: string;
  type: string;
  createdFor: string;
}

export interface EditorTemplateCreateInput {
  employeeId?: string | null;
  projectId?: string | null;
  companyId?: string | null;
  aggregateType: string;
  createdFor: string;
  editorTemplateGroupId?: string | null;
  type: string;
  name: string;
  mode: string;
  test?: BlockTestInput | null;
  elements: any;
  schema?: string[][][] | null;
  options: BlockOptionsInput;
}

export interface EmployeeSearch {
  order?: string[][] | null;
  isArchived?: boolean | null;
  isActive?: boolean | null;
  createFrom?: any | null;
  createTo?: any | null;
  pagination?: PaginationType | null;
  roleName?: string[] | null;
  tags?: string | null;
  query?: string | null;
}

export interface EmployeeUpdateInput {
  roleId?: string | null;
  description?: string | null;
  language?: string | null;
  status?: string | null;
  position?: string | null;
  companyPhone?: string | null;
  phone?: string | null;
  address?: string | null;
  email?: string | null;
  skype?: string | null;
  whatsapp?: string | null;
  telegram?: string | null;
  facebook?: string | null;
  linkedin?: string | null;
  twitter?: string | null;
  portfolio?: string | null;
  tags?: string[] | null;
  avatar?: string | null;
  name?: string | null;
  username?: string | null;
}

export interface FileMetaAndGroupMoveInput {
  targetGroupId: string;
  currentGroupId: string;
  groupIds?: string[] | null;
  elementIds?: string[] | null;
}

export interface FileMetaGroupDeleteInput {
  id: string;
  type: FileMetaTypeDeleteEnum;
}

export interface FileMetaGroupInput {
  name: string;
  color?: string | null;
  parentId?: string | null;
  lvl?: number | null;
  isArchived?: boolean | null;
  createdFor?: string | null;
  projectId?: string | null;
}

export interface FileMetaGroupsSearch {
  id: string;
  contentType?: string | null;
  fileMetaType: FileMetaType;
  createrIds?: string[] | null;
  sizeFrom?: number | null;
  sizeTo?: number | null;
  order?: string[][] | null;
  isArchived?: boolean | null;
  createFrom?: any | null;
  createTo?: any | null;
  ownersIds?: string[] | null;
  query?: string | null;
  pagination?: PaginationType | null;
  parentId?: string | null;
  type?: string | null;
  projectId?: string | null;
  moveGroups?: string[] | null;
}

export interface FileMetaSearch {
  id: string;
  contentType: string;
  fileMetaType: FileMetaType;
  pagination?: PaginationType | null;
  order?: (string[] | null)[] | null;
  query?: string | null;
  isArchived?: boolean | null;
  createrIds?: string[] | null;
  sizeFrom?: number | null;
  sizeTo?: number | null;
  createFrom?: any | null;
  createTo?: any | null;
}

export interface FileMetaUpsert {
  upsertTypes: FileMetaUpsertType[];
  createrId: string;
  fileMetaGroupId?: string | null;
  origin?: string | null;
  type?: string | null;
  name?: string | null;
  size?: number | null;
}

export interface FileMetaUpsertType {
  id: string;
  type: UpsertType;
}

export interface InviteInput {
  email: string;
  roleId?: string | null;
  message?: string | null;
}

export interface KUserInput {
  password: string;
  name?: string | null;
  username: string;
  position?: string | null;
  country?: string | null;
  city?: string | null;
  phone?: string | null;
  language?: string | null;
  email: string;
  other?: string | null;
  isActive?: boolean | null;
  isArchived?: boolean | null;
  description?: string | null;
  address?: string | null;
  skype?: string | null;
  whatsapp?: string | null;
  telegram?: string | null;
  facebook?: string | null;
  linkedin?: string | null;
  twitter?: string | null;
  portfolio?: string | null;
  avatar?: string | null;
}

export interface LoginInput {
  login: string;
  password: string;
}

export interface PaginationType {
  limit?: number | null;
  offset?: number | null;
}

export interface ProjectCreateInput {
  projectGroupId: string;
  tags?: string[] | null;
  ownerId: string;
  progressShouldBeGreaterThen?: number | null;
  progressShouldBeLeastThen?: number | null;
  finalTestId?: string | null;
  accent?: string | null;
  learningObjectives?: string | null;
  learningGoals?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  budget?: number | null;
  cost?: number | null;
  name: string;
}

export interface ProjectGroupInput {
  name?: string | null;
  description?: string | null;
  logo?: number | null;
  color?: string | null;
  parentId?: string | null;
}

export interface ProjectGroupsSearch {
  order?: string[][] | null;
  isArchived?: boolean | null;
  createFrom?: any | null;
  createTo?: any | null;
  deadlineFrom?: any | null;
  deadlineTo?: any | null;
  query?: string | null;
  participantIds?: string[] | null;
  ownersIds?: string[] | null;
  pagination?: PaginationType | null;
  budgetTo?: number | null;
  budgetFrom?: number | null;
  parentId?: string | null;
  type?: string | null;
  moveGroups?: string[] | null;
}

export interface ProjectSearch {
  order?: string[][] | null;
  isArchived?: boolean | null;
  createFrom?: any | null;
  createTo?: any | null;
  deadlineFrom?: any | null;
  deadlineTo?: any | null;
  query?: string | null;
  participantIds?: string[] | null;
  ownersIds?: string[] | null;
  pagination?: PaginationType | null;
  budgetTo?: number | null;
  budgetFrom?: number | null;
  parentId?: string | null;
}

export interface ProjectUpdateInput {
  projectGroupId?: string | null;
  companyId?: string | null;
  tags?: string[] | null;
  sectionsOrder?: EditorSectionOrderOptionalInput[] | null;
  publicBlocks?: PublicBlockInput[] | null;
  ownerId?: string | null;
  isDone?: boolean | null;
  progressShouldBeGreaterThen?: number | null;
  progressShouldBeLeastThen?: number | null;
  finalTestId?: string | null;
  publicSettings?: PublicSettingsInput | null;
  publicLinkIsOpen?: boolean | null;
  accent?: string | null;
  learningObjectives?: string | null;
  learningGoals?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  budget?: number | null;
  cost?: number | null;
  name?: string | null;
}

export interface PublicAuthorInput {
  id: string;
  name: string;
  avatarId: string;
  description?: string | null;
}

export interface PublicBlockInput {
  id: string;
  title: string;
  icon?: string | null;
  iconImg?: string | null;
  description: string;
}

export interface PublicSettingsInput {
  authors?: PublicAuthorInput[] | null;
  id?: string | null;
  name?: string | null;
  imageId?: string | null;
  logoId?: string | null;
  secondaryLogoId?: string | null;
  description?: string | null;
  authorsDescription?: string | null;
  authorsType?: string | null;
  colorPrimary?: string | null;
  colorFont?: string | null;
  supportEmail?: string | null;
  footerCopyright?: string | null;
  templateKey?: string | null;
  footerText1?: string | null;
  footerText2?: string | null;
  footerText3?: string | null;
  footerText4?: string | null;
  tableOfContentInitiallyExpanded?: boolean | null;
  infoAboutProgress?: string | null;
}

export interface RoleInput {
  name: string;
  description?: string | null;
  isDefault?: boolean | null;
}

export interface TagInput {
  name: string;
  parentId?: string | null;
  color?: string | null;
}

export interface TaskChangeStatusInput {
  id?: string | null;
  status?: string | null;
}

export interface TaskInput {
  assignedId?: string | null;
  employeeId?: string | null;
  name?: string | null;
  description: string;
  deadline?: any | null;
  type: string;
  todo?: any | null;
  status?: string | null;
  parentId?: string | null;
  blockId: string;
  sectionId: string;
  projectId: string;
}

export interface TaskInputSubscription {
  blockId?: string | null;
  sectionId?: string | null;
  companyId?: string | null;
}

export interface TaskPropertySearch {
  isArchived?: boolean | null;
  project?: TaskPropertySearchProject | null;
  employeeId?: string | null;
  assignedId?: string | null;
  type?: string | null;
  ownerId?: string | null;
  blockId?: string | null;
  status?: string | null;
  createdAt?: TaskPropertyTimeRange | null;
  deadline?: TaskPropertyTimeRange | null;
}

export interface TaskPropertySearchProject {
  projectId: string[];
  employeeId?: string[] | null;
  assignedId?: string[] | null;
}

export interface TaskPropertyTimeRange {
  from?: any | null;
  to?: any | null;
  offset: string;
}

export interface TaskSearch {
  pagination?: PaginationType | null;
  order?: (string[] | null)[] | null;
  properties?: TaskPropertySearch | null;
}

export interface TaskUpdateInput {
  name?: string | null;
  description?: string | null;
  deadline?: any | null;
  type?: string | null;
  todo?: any | null;
  employeeId?: string | null;
  assignedId?: string | null;
  parentId?: string | null;
  isArchived?: boolean | null;
}

export interface TasksEntityIdInput {
  projectId: string[];
  blockId?: string | null;
  sectionId?: string | null;
}

export interface inputType {
  type: string;
  focus?: boolean | null;
}

export interface kUserUpdateInput {
  name?: string | null;
  position?: string | null;
  country?: string | null;
  city?: string | null;
  phone?: string | null;
  avatar?: string | null;
  language?: string | null;
  other?: string | null;
  description?: string | null;
  address?: string | null;
  skype?: string | null;
  whatsapp?: string | null;
  telegram?: string | null;
  facebook?: string | null;
  linkedin?: string | null;
  twitter?: string | null;
  portfolio?: string | null;
}

export interface pageValue {
  namePage: string;
  userId: string;
  companyId: string;
  isActive?: boolean | null;
  join?: boolean | null;
  blockId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
