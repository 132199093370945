const i18nEn = {
  translations: {
    blocks: 'Blocks',
    bars: 'Bars',
    collage: 'Collage',
    showAs: 'Show as',
    images: 'Images',
    videos: 'Videos',
    music: 'Music',
    documents: 'Documents',
    other: 'Other',
    view: 'View',
    fileName: 'File name',
    fileSize: 'File size',
    fileUpload: 'File upload',
    download: 'Download',
    close: 'Close',
    delete: 'Delete',
    main: 'Main',
    quickSearch: 'Quick search',
    apply: 'Apply',
    mediaLibrary: 'Media Library',
  },
}

export default i18nEn
