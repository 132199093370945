import {
  from,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  split,
  FieldMergeFunction,
  concat,
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

import { config } from '@ui/config'
import { CompanyUpdateById_data } from '@ui/types'

import { cleanTypenameLink } from 'utils/apollo-clear-typename'

import * as helpers from './actions.helpers'
import * as blocks from './blocks.apollo'
import * as companies from './companies.apollo'
import * as employees from './employees.apollo'
import { errorLink } from './error.link'
import * as files from './files.apollo'
import * as notifications from './notifications.apollo'
import * as permissions from './permissions.apollo'
import * as profile from './profile.apollo'
import * as projectGroups from './project-groups.apollo'
import * as projects from './projects.apollo'
import * as publicLink from './public-link.apollo'
import * as roles from './roles.apollo'
import * as scorm from './scorm.apollo'
import * as sections from './sections.apollo'
import * as sessionStorage from './server-storage.apollo'
import * as session from './session.apollo'
import * as sessionResolvers from './session.resolvers'
import * as subscription from './subscribtion.apollo'
import * as tags from './tags.apollo'
import * as tasks from './tasks.apollo'
import * as templates from './templates.apollo'

export const gqlActions = {
  ...publicLink,
  ...companies,
  ...permissions,
  ...roles,
  ...session,
  ...profile,
  ...sessionStorage,
  ...projectGroups,
  ...projects,
  ...scorm,
  ...employees,
  ...helpers,
  ...tags,
  ...notifications,
  ...sections,
  ...files,
  ...blocks,
  ...tasks,
  ...templates,
  ...subscription,
}

const typeDefs = [sessionResolvers.typeDefs]

const resolvers = [sessionResolvers.resolvers]

// https://www.apollographql.com/docs/react/networking/network-layer/#apollo-link
// solution from apollo documentation
const middleware = new ApolloLink((operation: any, forward: any) => {
  const token = localStorage.getItem('token')
  const visitorToken = localStorage.getItem('visitorToken')
  operation.setContext({
    headers: {
      Authorization: token ? `Basic ${token}` : '',
      visitorToken: visitorToken || '',
    },
  })

  return forward(operation)
})

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext()
    const headers = context.response?.headers
    if (headers) {
      // get version from headers through get field response
      const currentVersion = headers.get('Version-Backend-Build')
      if (currentVersion) {
        localStorage.setItem('current_version', currentVersion)
      }
    }

    return response
  })
})

const coreLink = new HttpLink({
  uri: config.io.coreEndpoint,
})

const coreLinkWs = new WebSocketLink({
  uri: config.io.coreEndpointWs || '',
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        Authorization: `${localStorage.getItem('token')}`
          ? `Bearer ${localStorage.getItem('token')}`
          : '',
      },
    },
  },
})

const authLink = new HttpLink({
  uri: config.io.authEndpoint,
})

const serverStorageLink = new HttpLink({
  uri: config.io.serverStorageEndpoint,
})

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  coreLinkWs,
  coreLink,
)

// https://www.apollographql.com/docs/react/caching/cache-field-behavior/#gatsby-focus-wrapper
const optimizeMerge: FieldMergeFunction<any, any> = (
  existing,
  incoming,
  { mergeObjects, readField },
) => {
  if (!existing) {
    return incoming
  }

  if (readField<string>('updatedAt', existing) !== readField<string>('updatedAt', incoming)) {
    return mergeObjects(existing, incoming)
  }

  return existing
}

//TODO we have problems with company logo in branding(when we want to change to default logo)
const optimizeMergeCompany: FieldMergeFunction<CompanyUpdateById_data, CompanyUpdateById_data> = (
  existing,
  incoming,
  { mergeObjects, readField },
) => {
  if (
    existing?.updatedAt &&
    incoming?.updatedAt &&
    readField<string>('updatedAt', existing) !== readField<string>('updatedAt', incoming)
  ) {
    return mergeObjects(existing, incoming)
  }

  return incoming
}

const gqlClient = {
  core: new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        EditorSectionUpdate: {
          keyFields: ['section', ['id']],
        },
        // TODO have id field for EditorSectionTest
        EditorSectionTest: {
          keyFields: [
            'time',
            'testRetry',
            'resultShouldBeGreaterThen',
            'isNextOnSubmit',
            'isValidationVisible',
            'isResultValuePercent',
            'questionOrder',
            'isShuffleQuestions',
            'isProgressShown',
          ],
        },
        ProjectGroup: {
          merge: optimizeMerge,
        },
        Company: {
          merge: optimizeMergeCompany,
        },
        Task: {
          merge: optimizeMerge,
        },
        FileMeta: {
          merge: optimizeMerge,
          fields: {
            files: {
              merge(existing = [], incoming: any[]) {
                if (existing.length !== incoming?.length) {
                  return incoming
                }
                return existing
              },
            },
          },
        },
        Employee: {
          merge: optimizeMerge,
        },
        Project: {
          // TODO optimize merge by using
          // https://github.com/benjamine/jsondiffpatch
          merge: optimizeMerge,
          fields: {
            publicFiles: {
              // TODO optimize
              merge(_ = [], incoming: any[]) {
                return incoming
              },
            },
          },
        },
        Block: {
          // TODO optimize merge by using
          // https://github.com/benjamine/jsondiffpatch
          merge: optimizeMerge,
          fields: {
            files: {
              // TODO optimize
              merge(_ = [], incoming: any[]) {
                return incoming
              },
            },
          },
          keyFields: ['uuid'],
        },
        EditorSection: {
          // TODO optimize merge by using
          // https://github.com/benjamine/jsondiffpatch
          merge: optimizeMerge,
        },
        BlockVersionItem: {
          keyFields: ['uuid', 'version'],
        },
        Query: {
          fields: {
            editorBlocksAll: {
              merge(_ = [], incoming: any[]) {
                return incoming
              },
            },
            tasksSearchAll: {
              merge(_ = [], incoming: any[]) {
                return incoming
              },
            },
          },
        },
      },
    }),
    typeDefs,
    resolvers,
    link: from([cleanTypenameLink, afterwareLink, errorLink as any, concat(middleware, link)]),
  }),
  auth: new ApolloClient({
    cache: new InMemoryCache(),
    typeDefs,
    resolvers,
    link: from([cleanTypenameLink, errorLink as any, concat(middleware, authLink)]),
  }),
  serverStorage: new ApolloClient({
    cache: new InMemoryCache(),
    typeDefs,
    resolvers,
    link: from([cleanTypenameLink, errorLink as any, concat(middleware, serverStorageLink)]),
  }),
}

export { gqlClient }
