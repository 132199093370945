const theme = {
  primaryColor: '#01b075',
  primaryBgColor: '#ebf9f4',
  primaryDarkenColor: '#167857',
  textColor: '#1D2B4B',
  grayColor: '#697787',
  borderColor: '#E9EBF0',
  linkColor: '#3D8BF4',
  linkColorHover: '#065fd4',
  borderRadius: 4,
  elementBorderRadius: 6,
  editorBlockW: 1200,
  editorBlockH: 675,
  applyTheme: function () {
    const doc = document.documentElement
    doc.style.setProperty('--accent-color', this.primaryColor)
    doc.style.setProperty('--gray-color', this.grayColor)
    doc.style.setProperty('--accent-bg-color', this.primaryBgColor)
    doc.style.setProperty('--accent-darken-color', this.primaryDarkenColor)
    doc.style.setProperty('--link-color', this.linkColor)
    doc.style.setProperty('--link-hover-color', this.linkColorHover)
    doc.style.setProperty('--text-color', this.textColor)
    doc.style.setProperty('--border-radius', this.borderRadius + 'px')
    doc.style.setProperty('--element-border-radius', this.elementBorderRadius + 'px')
    doc.style.setProperty('--border-color', this.borderColor)
    doc.style.setProperty('--editor-block-w', this.editorBlockW + 'px')
    doc.style.setProperty('--editor-block-h', this.editorBlockH + 'px')

    // this color used for preview only, can be castomized by user
    doc.style.setProperty('--preview-accent-color', this.primaryColor)
    doc.style.setProperty('--preview-accent-alpha-color', this.primaryColor + '20')
    doc.style.setProperty('--preview-main-text-color', this.textColor)
  },
}

module.exports = theme
