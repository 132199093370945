import produce from 'immer'

import { BlocksAll, BlocksAllVariables, BlocksGetById_data } from '@ui/types'

import { gqlClient } from '.'
import { blockFragment, blocksGetAllQuery } from './blocks.gql'

export const blockUpdateCache = (newBlock: BlocksGetById_data) => {
  const cache = gqlClient.core.cache

  try {
    cache.writeFragment({
      fragment: blockFragment,
      id: cache.identify(newBlock as any),
      fragmentName: 'BlockSchema',
      data: newBlock,
    })
  } catch (err) {
    console.error('"blockUpdateCache" fn is crashed on operation: ".writeFragment"', err)
  }
}

export const blockRemoveCache = (newBlock: { uuid: string; sectionId: string }) => {
  const cache = gqlClient.core.cache

  try {
    cache.modify({
      id: cache.identify({
        __typename: 'EditorSection',
        id: newBlock.sectionId,
      }),
      fields: {
        blocksOrder: (existingRefs) => {
          return existingRefs.filter((e: any) => {
            return e !== newBlock.uuid
          })
        },
      },
    })

    const data = cache.readQuery<BlocksAll, BlocksAllVariables>({
      query: blocksGetAllQuery,
      variables: {
        sectionId: newBlock.sectionId,
      },
    })

    if (data) {
      cache.writeQuery({
        query: blocksGetAllQuery,
        variables: {
          sectionId: newBlock.sectionId,
        },
        data: produce(data, (draft) => {
          const index = draft!.data.findIndex((e) => e.uuid === newBlock?.uuid)
          if (index >= 0) {
            draft!.data.splice(index, 1)
          }
        }),
      })
    }
  } catch (err) {
    console.error('"blockRemoveCache" fn is crashed on operation: ".modify or .writeQuery"', err)
  }
}

export const blockAddCache = (newBlock: BlocksGetById_data | BlocksGetById_data[]) => {
  const cache = gqlClient.core.cache
  const isArray = Array.isArray(newBlock)
  const block = isArray ? (newBlock as BlocksGetById_data[])[0] : (newBlock as BlocksGetById_data)

  try {
    const data = cache.readQuery<BlocksAll, BlocksAllVariables>({
      query: blocksGetAllQuery,
      variables: {
        sectionId: block.sectionId,
      },
    })

    const existingBlock = data?.data.find((bl) => bl.uuid === block.uuid)

    if (data && data.data && !existingBlock) {
      cache.writeQuery<BlocksAll, BlocksAllVariables>({
        query: blocksGetAllQuery,
        variables: {
          sectionId: block.sectionId,
        },
        data: produce(data, (draft) => {
          if (newBlock) {
            if (isArray) {
              draft!.data.push(...(newBlock as BlocksGetById_data[]))
            } else {
              draft!.data.push(newBlock as BlocksGetById_data)
            }
          }
        }),
      })
    }

    if (!data) {
      cache.writeQuery<BlocksAll, BlocksAllVariables>({
        query: blocksGetAllQuery,
        variables: {
          sectionId: block.sectionId,
        },
        data: {
          data: [block],
        },
      })
    }
  } catch (err) {
    console.error('"blockAddCache" fn is crashed on operation: ".writeQuery"', err)
  }
}
