import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client'

import {
  NotificationsAll,
  NotificationsAllVariables,
  NotificationsNew,
  NotificationsNewVariables,
  NotificationsCountNewVariables,
  NotificationsCountNew,
  NotificationsRead,
  NotificationsReadVariables,
} from '@ui/types'

import { getIntervalValueDependsOnBatteryLevel } from 'utils/batteryLifeLvl'

import { NotificationListFragment } from './notifications.qgl'

export const notificationsAllQuery = gql`
  query NotificationsAll($companyId: String!) {
    data: notificationsAll(companyId: $companyId) {
      ...NotificationListSchema
    }
  }
  ${NotificationListFragment}
`

const notificationsNewQuery = gql`
  query NotificationsNew($companyId: String!) {
    data: notificationsNew(companyId: $companyId) {
      ...NotificationListSchema
    }
  }
  ${NotificationListFragment}
`

export const notificationsNewCountQuery = gql`
  query NotificationsCountNew($companyId: String!) {
    data: notificationsCountNew(companyId: $companyId)
  }
`

export const useNotificationAll = (companyId: string) => {
  const intervalDelay = getIntervalValueDependsOnBatteryLevel(5000, 25000)
  return useQuery<NotificationsAll, NotificationsAllVariables>(notificationsAllQuery, {
    variables: {
      companyId,
    },
    pollInterval: intervalDelay,
    onError: (err) =>
      console.error('"useNotificationAll" fn is crashed on operation: "useQuery"', err),
  })
}

export const useNotificationAllQuery = (companyId: string, skip: boolean) => {
  return useQuery<NotificationsAll, NotificationsAllVariables>(notificationsAllQuery, {
    skip,
    variables: {
      companyId,
    },
    onError: (err) =>
      console.error('"useNotificationAllQuery" fn is crashed on operation: "useQuery"', err),
  })
}

export const useNotificationNew = (companyId: string) => {
  const intervalDelay = getIntervalValueDependsOnBatteryLevel(5000, 25000)
  return useQuery<NotificationsNew, NotificationsNewVariables>(notificationsNewQuery, {
    variables: {
      companyId,
    },
    pollInterval: intervalDelay,
    onError: (err) =>
      console.error('"useNotificationNew" fn is crashed on operation: "useQuery"', err),
  })
}

export const useNotificationNewCount = (companyId: string, cache?: boolean) => {
  return useQuery<NotificationsCountNew, NotificationsCountNewVariables>(
    notificationsNewCountQuery,
    {
      variables: {
        companyId,
      },
      fetchPolicy: cache ? 'cache-and-network' : 'cache-only',
      onError: (err) =>
        console.error('"useNotificationNewCount" fn is crashed on operation: "useQuery"', err),
    },
  )
}

export const useNotificationNewCountLazy = (cache?: boolean) => {
  return useLazyQuery<NotificationsCountNew, NotificationsCountNewVariables>(
    notificationsNewCountQuery,
    {
      fetchPolicy: cache ? 'cache-first' : 'cache-only',
      onError: (err) =>
        console.error(
          '"useNotificationNewCountLazy" fn is crashed on operation: "useLazyQuery"',
          err,
        ),
    },
  )
}

export const useNotificationsRead = (companyId: string) =>
  useMutation<NotificationsRead, NotificationsReadVariables>(
    gql`
      mutation NotificationsRead($companyId: String!) {
        data: notificationsRead(companyId: $companyId)
      }
    `,
    {
      refetchQueries: [
        {
          query: notificationsAllQuery,
          variables: {
            companyId,
          },
        },
        {
          query: notificationsNewQuery,
          variables: {
            companyId,
          },
        },
        {
          query: notificationsNewCountQuery,
          variables: {
            companyId,
          },
        },
      ],
      onError: (err) =>
        console.error('"useNotificationsRead" fn is crashed on operation: "useMutation"', err),
    },
  )
