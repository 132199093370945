import { useQuery } from '@apollo/client'

import { PermissionsAll } from '@ui/types'

import { gqlClient } from '.'
import { permissionsAll } from './permission.qgl'

export const usePermissionsAll = () =>
  useQuery<PermissionsAll>(permissionsAll, {
    onError: (err) =>
      console.error('"usePermissionsAll" fn is crashed on operation: "useQuery"', err),
    client: gqlClient.core,
  })
