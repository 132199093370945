import { BlocksAll_data } from '@ui/types'

export type BlockItemSchemaPoint = {
  x: number
  y: number
}

export enum BlockMode {
  questions = 'questions',
  start = 'start',
  end = 'end',
  view = 'view',
}

export type BlockItemSchema = {
  items: [BlockItemSchemaPoint, BlockItemSchemaPoint]
  meta: {
    zIndex?: number
    type: string
    objectFit?: string
    yPosition?: string
    xPosition?: string
    textAlign?: string
  }
}

export type BlockItem = {
  categoryId: string
  id: string
  name: string
  type: string
  schema?: BlockItemSchema[]
  img: string
}

export type BlockItemGroup = {
  id: string
  name: string
  types: string[]
  items: BlockItem[]
  meta: {
    time: {
      created: number
      update: number
    }
  }
}

export type BlockType = BlocksAll_data
