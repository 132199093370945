import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client'

import {
  ProjectGroupsCreate,
  ProjectGroupsCreateVariables,
  ProjectGroupsUpdateById,
  ProjectGroupsUpdateByIdVariables,
  ProjectGroupsDeleteById,
  ProjectGroupsDeleteByIdVariables,
  ProjectGroupsGetById,
  ProjectGroupsForceDeleteById,
  ProjectGroupsForceDeleteByIdVariables,
  ProjectGroupsAll,
  ProjectGroupsAllVariables,
  ProjectGroupsGetByIdVariables,
  ProjectGroupsSearch,
} from '@ui/types'

import {
  projectsGroupAddCache,
  projectsGroupRemoveCache,
  projectsGroupUpdateCache,
} from './project-groups.cache'
import {
  projectGroupAll,
  projectGroupListFragment,
  projectGroupsByIdQuery,
} from './project-groups.gql'

export * from './project-groups.subscription'

export const useProjectGroupsGetById = () =>
  useLazyQuery<ProjectGroupsGetById, ProjectGroupsGetByIdVariables>(projectGroupsByIdQuery)

export const useProjectGroupAll = (variables: ProjectGroupsAllVariables, isCache?: boolean) =>
  useQuery<ProjectGroupsAll, ProjectGroupsAllVariables>(projectGroupAll, {
    fetchPolicy: !isCache ? 'cache-only' : 'cache-and-network',
    variables: variables,
    onError: (err) =>
      console.error('"useProjectGroupAll" fn is crashed on operation: "useQuery"', err),
  })

export const useProjectGroupsCreate = (companyId: string, search: ProjectGroupsSearch) =>
  useMutation<ProjectGroupsCreate, ProjectGroupsCreateVariables>(
    gql`
      mutation ProjectGroupsCreate($projectGroup: ProjectGroupInput!, $companyId: String!) {
        data: projectGroupsCreate(data: $projectGroup, companyId: $companyId) {
          ...ProjectGroupListSchema
        }
      }
      ${projectGroupListFragment}
    `,
    {
      update: (cache, { data: item }) => {
        if (item) {
          projectsGroupAddCache(item.data, companyId, search)
        }
      },
      onError: (err) =>
        console.error('"useProjectGroupsCreate" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useProjectGroupsUpdate = (companyId: string, search: ProjectGroupsSearch) =>
  useMutation<ProjectGroupsUpdateById, ProjectGroupsUpdateByIdVariables>(
    gql`
      mutation ProjectGroupsUpdateById(
        $groupId: String!
        $companyId: String!
        $data: ProjectGroupInput!
      ) {
        data: projectGroupsUpdateById(groupId: $groupId, companyId: $companyId, data: $data) {
          ...ProjectGroupListSchema
        }
      }
      ${projectGroupListFragment}
    `,
    {
      update: (cache, { data: item }) => {
        if (item?.data) {
          projectsGroupUpdateCache(item.data, companyId, search)
        }
      },
      onError: (err) =>
        console.error('"useProjectGroupsUpdate" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useProjectGroupsDelete = (companyId: string, updatedValues: ProjectGroupsSearch) =>
  useMutation<ProjectGroupsDeleteById, ProjectGroupsDeleteByIdVariables>(
    gql`
      mutation ProjectGroupsDeleteById($id: String!, $companyId: String!) {
        data: projectGroupsDeleteById(id: $id, companyId: $companyId) {
          id
          errors {
            type
            items {
              id
              name
              parentId
              projectGroupId
            }
          }
        }
      }
    `,
    {
      update: (cache, { data: item }) => {
        if (item?.data) {
          projectsGroupRemoveCache(item.data.id, companyId, updatedValues)
        }
      },
      onError: (err) =>
        console.error('"useProjectGroupsDelete" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useProjectGroupsForceDeleteById = () =>
  useMutation<ProjectGroupsForceDeleteById, ProjectGroupsForceDeleteByIdVariables>(
    gql`
      mutation ProjectGroupsForceDeleteById($id: String!, $companyId: String!) {
        data: projectGroupsForceDeleteById(id: $id, companyId: $companyId)
      }
    `,
    {
      onError: (err) =>
        console.error(
          '"useProjectGroupsForceDeleteById" fn is crashed on operation: "useMutation"',
          err,
        ),
    },
  )
