import gql from 'graphql-tag'

export const rolesAllFragment = gql`
  fragment RolesAllSchema on Role {
    id
    name
    description
    updatedAt
    companyId
    isRemovable
    isDefault
  }
`

/**
 * Мы не можем экспортировать GQL из .apollo файлов
 * что бы не засорять скоуп глобальными кверями в gqlActions
 */
export const rolesAllGql = gql`
  query RolesAll($companyId: String!) {
    data: rolesAll(companyId: $companyId) {
      ...RolesAllSchema
      employee {
        id
      }
      permission {
        id
      }
    }
  }
  ${rolesAllFragment}
`
