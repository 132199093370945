import gql from 'graphql-tag'

import { fileFragment } from './files.gql'

export const templatesListFragment = gql`
  fragment TemplatesListSchema on EditorTemplate {
    employeeId
    projectId
    companyId
    aggregateType
    uuid
    name
    test {
      time
      successRules
      onSuccess {
        goToBlockId
      }
      onFail {
        goToBlockId
        retry
      }
      isResult
    }
    elements
    schema
    files {
      ...FileMetaSchema
    }
    mode
    type
    options {
      backgroundColor
      backgroundImage
      indents
      tabs
      tabsIndex
      tabsPosition
      borderRadius
      questionFeedback
    }
    createdAt
    updatedAt
  }
  ${fileFragment}
`

export const templatesGetAllQuery = gql`
  query TemplatesAll($companyId: String!, $data: EditorTemplateAllInput!) {
    data: editorTemplatesAll(companyId: $companyId, data: $data) {
      ...TemplatesListSchema
    }
  }
  ${templatesListFragment}
`
