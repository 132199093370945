import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import 'what-input'

import './cssRoot/sizes.less'
import registerServiceWorker from './registerServiceWorker'
import './roboto'
import App from './routes/app'
import theme from './theme'
import './theme-rzd.less'
import './theme.less'

theme.applyTheme()

ReactDOM.render(
  // TODO find way to apply strict mode with apollo as well
  // https://github.com/apollographql/apollo-client/issues/6037
  // <React.StrictMode>
  <HelmetProvider>
    <App />
  </HelmetProvider>,
  // </React.StrictMode>,
  document.getElementById('root'),
)

registerServiceWorker()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
