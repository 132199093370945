import Avatars from '@dicebear/avatars'
import initialsSprites from '@dicebear/avatars-initials-sprites'
import { useParams } from 'react-router'

import { gqlActions } from '@ui/gql'
import { EditorParams } from '@ui/models'
import { FileMetaSchema, FileSchema } from '@ui/types'

import companyLogo20x20 from '../images/companyLogo/companyLogo-20x20.png'
import companyLogo30x30 from '../images/companyLogo/companyLogo-30x30.png'
import companyLogo40x40 from '../images/companyLogo/companyLogo-40x40.png'
import companyLogo64x64 from '../images/companyLogo/companyLogo-64x64.png'
import companyLogo80x80 from '../images/companyLogo/companyLogo-80x80.png'
import companyLogo480x480 from '../images/companyLogo/companyLogo-480x480.png'

const initialsAvatars = new Avatars(initialsSprites, { base64: true })

export type OptionsType = {
  type?: 'avatar' | 'image' | 'document'
  w?: number
  h?: number
}

const initialOptions: OptionsType = {
  type: 'avatar',
  w: 64,
  h: 64,
}

export type FilesType = {
  path?: string
  file?: FileSchema
}

type logoMapType = {
  [key: string]: string
}

const logoMap: logoMapType = {
  size20x20: companyLogo20x20,
  size30x30: companyLogo30x30,
  size40x40: companyLogo40x40,
  size64x64: companyLogo64x64,
  size80x80: companyLogo80x80,
  size480x480: companyLogo480x480,
}

export const getFileFromCacheById = (
  fileId: string | null | undefined,
): FileMetaSchema | null | undefined => {
  return gqlActions.getFileByIdFromCache(fileId)
}

export const getUserDefaultAvatar = (username: string): string => {
  return initialsAvatars.create(username || 'No Name')
}

export const getUserAvatarOrDefault = (
  avatar: FileMetaSchema | null | undefined,
  username: string,
  options: OptionsType = initialOptions,
): string => {
  const file = getFilePath(avatar?.files || [], options)
  return file.path ? file.path : getUserDefaultAvatar(username)
}

export const getCompanyDefaultLogo = (width: number, height: number): string => {
  const companyLogo = `size${width}x${height}`

  return companyLogo in logoMap ? logoMap[companyLogo] : logoMap.size64x64
}

export const getCompanyLogoOrDefault = (
  logo: FileMetaSchema | null,
  options: OptionsType = initialOptions,
): string => {
  const file = logo && getFilePath(logo.files, options)
  const imgWidth = options.w || 64
  const imgHeight = options.h || 64
  return file?.path ? file.path : getCompanyDefaultLogo(imgWidth, imgHeight)
}

export const getFilePath = (files?: FileSchema[] | null, options?: OptionsType): FilesType => {
  const intFiles = files || []
  const fileOriginalPath = getFileOriginal(intFiles)

  const currentFile = intFiles.find((e) =>
    e.path.includes(`${options?.type}-${options?.w}x${options?.h}`),
  )
  const defaultFile = intFiles[0]
    ? { ...intFiles[0], path: fileOriginalPath.file?.path || intFiles[0].path }
    : undefined
  const file = currentFile || defaultFile

  return {
    path: file?.path,
    file: file,
  }
}

export const getFileOriginal = (files: FileSchema[]): FilesType => {
  const findFile =
    files.find((e) => {
      return e.path.split('__').length === 2
    }) || files[0]
  return {
    path: findFile?.path,
    file: findFile,
  }
}

export const useFileByIdInProject = (fileMetaId?: string, options?: OptionsType): FilesType => {
  const params = useParams<EditorParams>()
  const { data: FilesData } = gqlActions.useProjectsFilesGetById(params.projectId, true)

  const files = FilesData?.data?.files || []
  const file = files.find((e) => e.id === fileMetaId)

  return getFilePath(file?.files, options)
}

export const fileInBlock = (fileMetaId?: string, options?: OptionsType): FilesType => {
  const file = getFileFromCacheById(fileMetaId)

  return getFilePath(file?.files, options)
}

export const pruningName = (username: string): string => {
  const values = username.split(' ')
  const f_name = values[0]
  const l_name = values[1]
  const secondLetter = username.length > 1 ? username[1].toLocaleUpperCase() : ''
  if (values.length > 1) {
    return `${f_name[0].toLocaleUpperCase() + l_name[0].toLocaleUpperCase()}`
  } else {
    return `${username[0].toLocaleUpperCase() + secondLetter}`
  }
}
