import { ApolloCache, NormalizedCacheObject } from '@apollo/client'
import produce from 'immer'

import { SectionsAll, SectionsAllVariables, SectionEditorSchema, BlocksAll_data } from '@ui/types'

import { gqlClient } from '.'
import { sectionEditorFragment, sectionFragment, sectionsAllQuery } from './sections.gql'

const getBlocksFromCache = (id: string, cache: ApolloCache<NormalizedCacheObject>) => {
  const currentSection = cache.readFragment<SectionEditorSchema>({
    id: cache.identify({
      __typename: 'EditorSection',
      id,
    }),
    fragment: sectionEditorFragment,
    fragmentName: 'SectionEditorSchema',
  })
  return currentSection ? (currentSection.blocks as BlocksAll_data[]) : []
}

export const sectionBlocksAdd = (
  id: string,
  blocksOrder?: string[] | null,
  blocks?: BlocksAll_data[],
) => {
  const { cache } = gqlClient.core

  const currentBlocks = getBlocksFromCache(id, cache)
  const firstInBlock = blocks && blocks[0]
  const existingBlock =
    blocks && firstInBlock && currentBlocks.find(({ uuid }) => uuid === firstInBlock?.uuid)
  const updatedBlocks =
    !existingBlock &&
    blocks &&
    produce(currentBlocks, (draft) => {
      draft.push(...blocks)
    })

  try {
    if (blocksOrder && updatedBlocks && !existingBlock) {
      cache.modify({
        id: cache.identify({
          __typename: 'EditorSection',
          id: id,
        }),
        fields: {
          blocksOrder: () => {
            return blocksOrder
          },
          blocks: () => {
            return updatedBlocks
          },
        },
      })
    }
  } catch (err) {
    console.error('"sectionBlocksAdd" fn is crashed on operation: ".modify"', err)
  }
}

export const sectionBlocksRemove = (id: string, blocksOrder: string[] | null) => {
  const { cache } = gqlClient.core

  const currentBlocks = getBlocksFromCache(id, cache)
  const updatedBlocks =
    blocksOrder && currentBlocks.filter(({ uuid }) => blocksOrder.includes(uuid))

  try {
    if (blocksOrder && updatedBlocks) {
      cache.modify({
        id: cache.identify({
          __typename: 'EditorSection',
          id: id,
        }),
        fields: {
          blocksOrder: () => {
            return blocksOrder
          },
          blocks: () => {
            return updatedBlocks
          },
        },
      })
    }
  } catch (err) {
    console.error('"sectionBlocksRemove" fn is crashed on operation: ".modify"', err)
  }
}

export const sectionModifyBlocksOrder = (id: string, blocksOrder?: string[] | null) => {
  const { cache } = gqlClient.core

  try {
    if (blocksOrder) {
      cache.modify({
        id: cache.identify({
          __typename: 'EditorSection',
          id: id,
        }),
        fields: {
          blocksOrder: () => {
            return blocksOrder
          },
        },
      })
    }
  } catch (err) {
    console.error('"sectionModifyBlocksOrder" fn is crashed on operation: ".modify"', err)
  }
}

export const sectionsUpdateCache = (section: SectionEditorSchema) => {
  const cache = gqlClient.core.cache

  try {
    cache.writeFragment<SectionEditorSchema>({
      data: section,
      id: cache.identify(section as any),
      fragmentName: 'SectionSchema',
      fragment: sectionFragment,
    })
  } catch (err) {
    console.error('"sectionsUpdateCache" fn is crashed on operation: ".writeFragment"', err)
  }
}

export const sectionsAddCache = (section: SectionEditorSchema) => {
  const { cache } = gqlClient.core

  try {
    const data = cache.readQuery<SectionsAll, SectionsAllVariables>({
      query: sectionsAllQuery,
      variables: {
        projectId: section.projectId,
      },
    })

    const existingSection = data?.data.find((sec) => sec.id === section.id)

    if (data && !existingSection) {
      cache.writeQuery({
        query: sectionsAllQuery,
        variables: {
          projectId: section.projectId,
        },
        data: produce(data, (draft) => {
          draft!.data.push(section)
        }),
      })
    }
  } catch (err) {
    console.error('"sectionsAddCache" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const sectionsRemoveCacheBySection = (sectionId: string) => {
  const { cache } = gqlClient.core

  try {
    if (cache) {
      cache.evict({
        id: cache.identify({
          __typename: 'EditorSection',
          id: sectionId,
        }),
      })
      cache.gc()
    }
  } catch (err) {
    console.error(
      '"sectionsRemoveCacheBySection" fn is crashed on operation: ".writeFragment"',
      err,
    )
  }
}
