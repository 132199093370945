import { gql } from '@apollo/client'

import { avatarFragment } from './avatar.gql'
import { employeeFragment } from './employees.gql'
import { fileFragment } from './files.gql'

export const companyFragment = gql`
  fragment CompanySchema on Company {
    id
    name
    email
    logo {
      ...FileMetaSchema
    }
    domainName
    color
    description
    legalId
    createdAt
    createdBy
    phone
    currency
    domainName
    updatedAt
  }
  ${fileFragment}
`

export const companyBrandFragment = gql`
  fragment CompanyBrandSchema on Company {
    id
    brand
  }
`

export const companyListFragment = gql`
  fragment CompanyListSchema on Company {
    ...CompanySchema
    employees {
      id
    }
  }
  ${companyFragment}
`

export const companyInfoFragment = gql`
  fragment CompanyInfoSchema on CompanyInfo {
    user {
      id
      name
      username
      email
    }
    company {
      ...CompanySchema
    }
    role {
      id
      name
    }
    permissions {
      id
      name
      type
    }
    employee {
      ...EmployeeSchema
    }
  }
  ${companyFragment}
  ${avatarFragment}
  ${employeeFragment}
`

export const getMyUserInfoQuery = gql`
  query CompanyGetMyInfo($companyId: ID!) {
    data: getMyAndCompanyInfo(companyId: $companyId) {
      ...CompanyInfoSchema
    }
  }
  ${companyInfoFragment}
`

export const companyGetByIdQuery = gql`
  query CompanyGet($companyId: String!) {
    data: companiesGetById(companyId: $companyId) {
      ...CompanySchema
    }
  }
  ${companyFragment}
`

export const companyGetBrandByIdQuery = gql`
  query CompanyBrand($companyId: String!) {
    data: companiesGetById(companyId: $companyId) {
      ...CompanyBrandSchema
    }
  }
  ${companyBrandFragment}
`

export const companyGetAllQuery = gql`
  query CompaniesAll {
    data: companiesAll {
      ...CompanyListSchema
    }
  }
  ${companyListFragment}
`

export const companySubscribe = gql`
  subscription CompanySubscribe($userId: String!) {
    data: company(userId: $userId) {
      company {
        ...CompanyListSchema
      }
      type
    }
  }
  ${companyListFragment}
`

export const companyUpdateBrand = gql`
  mutation CompanyUpdateBrandById($companyId: String!, $data: CompanyUpdateBrandInput!) {
    data: companyUpdateBrandById(companyId: $companyId, data: $data) {
      ...CompanyBrandSchema
    }
  }
  ${companyBrandFragment}
`
