import gql from 'graphql-tag'

export const fileObjFragment = gql`
  fragment FileSchema on File {
    id
    metaId
    origin
    path
    ext
    size
    width
    height
    type
  }
`

export const fileFullFragment = gql`
  fragment FileMetaFullSchema on FileMeta {
    id
    origin
    files {
      ...FileSchema
    }
    size
    name
    type
    createrId
    isProcessed
    fileMetaGroupIds
    isArchived
    createdAt
    updatedAt
    status
    error
  }
  ${fileObjFragment}
`

export const fileFragment = gql`
  fragment FileMetaSchema on FileMeta {
    id
    origin
    files {
      ...FileSchema
    }
    status
    name
    type
    size
    isArchived
    isProcessed
    createdAt
    updatedAt
  }
  ${fileObjFragment}
`

export const filesGroupsFragment = gql`
  fragment FilesGroups on FileMetaGroup {
    id
    name
    color
    parentId
    lvl
    isArchived
    companyId
    descendants
    createdAt
    updatedAt
    breadcrumbs {
      id
      name
    }
  }
`

export const upsertFileMeta = gql`
  mutation UpsertFileMeta($data: FileMetaUpsert!) {
    data: upsertFileMeta(data: $data) {
      ...FileMetaSchema
    }
  }
  ${fileFragment}
`

export const fileMetaGroupAllQuery = gql`
  query FileMetaGroupAll($companyId: String!, $search: FileMetaGroupsSearch, $parentId: String) {
    breadcrumb: fileMetaGroupGetById(companyId: $companyId, id: $parentId) {
      # ...FilesGroups
      id
      name
      breadcrumbs {
        # ...FilesGroups
        id
        name
      }
    }
    data: fileMetaGroupAll(companyId: $companyId, search: $search) {
      groups {
        ...FilesGroups
      }
      fileMetas {
        ...FileMetaSchema
      }
      total
      limit
      offset
    }
  }
  ${filesGroupsFragment}
  ${fileFragment}
`

export const fileMetaGroupGetByIdQuery = gql`
  query FileMetaGroupGetById($companyId: String!, $id: String!) {
    data: fileMetaGroupGetById(companyId: $companyId, id: $id) {
      ...FilesGroups
    }
  }
  ${filesGroupsFragment}
`

export const fileMetaGroupCreateMutation = gql`
  mutation FileMetaGroupCreate($data: FileMetaGroupInput!, $companyId: String!) {
    data: fileMetaGroupCreate(data: $data, companyId: $companyId) {
      ...FilesGroups
    }
  }
  ${filesGroupsFragment}
`

export const fileMetaGroupUpdateByIdMutation = gql`
  mutation FileMetaGroupUpdateById($id: String!, $companyId: String!, $data: FileMetaGroupInput!) {
    data: fileMetaGroupUpdateById(id: $id, companyId: $companyId, data: $data) {
      ...FilesGroups
    }
  }
  ${filesGroupsFragment}
`

export const fileMetaAndGroupMove = gql`
  mutation FileMetaAndGroupMove(
    $data: FileMetaAndGroupMoveInput!
    $companyId: String!
    $projectId: String
  ) {
    data: fileMetaAndGroupMove(data: $data, companyId: $companyId, projectId: $projectId) {
      ...FilesGroups
    }
  }
  ${filesGroupsFragment}
`

export const fileMetaGroupDeleteByIdsMutation = gql`
  mutation FileMetaGroupDeleteByIds(
    $data: [FileMetaGroupDeleteInput!]!
    $companyId: String!
    $parentId: String
  ) {
    data: fileMetaGroupDeleteByIds(data: $data, companyId: $companyId, parentId: $parentId) {
      removes {
        id
        name
        type
      }
      errors {
        id
        name
        type
      }
    }
  }
`

export const fileMetaGroupForceDeleteByIdMutation = gql`
  mutation FileMetaGroupForceDeleteById($id: String!, $companyId: String!) {
    data: fileMetaGroupForceDeleteById(id: $id, companyId: $companyId)
  }
`

export const fileMetasTypesCountQuery = gql`
  query FileMetasTypesCount($id: String!, $fileMetaType: FileMetaType!) {
    data: fileMetasTypesCount(id: $id, fileMetaType: $fileMetaType) {
      type
      count
    }
  }
`

export const fileMetasAllQuery = gql`
  query FileMetasAll($search: FileMetaSearch) {
    data: fileMetasAll(search: $search) {
      fileMetas {
        ...FileMetaSchema
      }
      totalAll {
        type
        count
      }
      total
      limit
      offset
    }
  }
  ${fileFragment}
`

export const fileMetaGroupSubscription = gql`
  subscription FilesGroupsSubscription(
    $companyId: String!
    $projectId: String
    $parentId: String!
  ) {
    data: fileMetaGroup(companyId: $companyId, projectId: $projectId, parentId: $parentId) {
      groups {
        ...FilesGroups
      }
      fileMetas {
        ...FileMetaSchema
      }
      type
    }
  }
  ${fileFragment}
  ${filesGroupsFragment}
`

export const filesSubscription = gql`
  # same like in schema
  subscription SubcribeOnCompanyFilesUpdate(
    $companyId: String!
    $projectId: String
    $parentId: String
  ) {
    data: SubcribeOnCompanyFilesUpdate(
      companyId: $companyId
      projectId: $projectId
      parentId: $parentId
    ) {
      type
      counts {
        type
        count
      }
      companyId
      projectId
      files {
        ...FileMetaSchema
      }
    }
  }
  ${fileFragment}
`

export const getFileUrlInfoQuery = gql`
  query FileUrlInfo($url: String!) {
    data: fileUrlInfo(url: $url) {
      payload {
        url
        contentType
        mediaType
        title
        siteName
        description
        images
        favicons
      }
      errors {
        url
        message
      }
    }
  }
`
