import { useSubscription } from '@apollo/client'

import { Scorm, ScormVariables } from '@ui/types'

import { updateBuildLastGetByProjectIdCash } from './scorm.cache'
import { scormBuildSubscription } from './scorm.gql'

export const useScormBuildSubscription = (employeeId: string, projectId: string) =>
  useSubscription<Scorm, ScormVariables>(scormBuildSubscription, {
    onSubscriptionData: (options) => {
      const data = options.subscriptionData.data?.data

      if (data) {
        updateBuildLastGetByProjectIdCash(employeeId, projectId, data)
      }
    },
    variables: {
      employeeId,
      projectId,
    },
  })
