import React from 'react'

import { WithSuspense } from './WithSuspense'

const Lazy = (
  factory: () => Promise<{
    default: React.ComponentType<any>
  }>,
  Fallback?: React.ComponentType<any>,
) => {
  const Component = React.lazy(factory)
  return WithSuspense(Component, Fallback)
}

Lazy.Fallback = WithSuspense.FallbackDefault

export default Lazy
