import { useSubscription } from '@apollo/client'

import { EditorSectionSubscription, EditorSectionSubscriptionVariables } from '@ui/types'

import { projectModifySectionsOrder, projectUpdateSectionOrderCache } from './projects.cache'
import {
  sectionsAddCache,
  sectionsRemoveCacheBySection,
  sectionsUpdateCache,
} from './sections.cache'
import { sectionsSubscription } from './sections.gql'

export const useEditorSectionSubscription = (projectId: string) =>
  useSubscription<EditorSectionSubscription, EditorSectionSubscriptionVariables>(
    sectionsSubscription,
    {
      onSubscriptionData: (options) => {
        const data = options.subscriptionData.data?.data

        if (data) {
          switch (data.type) {
            case 'delete':
              if (data.section) {
                sectionsRemoveCacheBySection(data.section.id)
                projectModifySectionsOrder(projectId, data.sectionsOrder)
              }
              break
            case 'create':
              if (data.section) {
                sectionsAddCache(data.section)
                projectModifySectionsOrder(projectId, data.sectionsOrder)
              }
              break
            case 'update':
              // TODO investigate it
              // that features work by default at Apollo Cache
              if (data.section) {
                sectionsUpdateCache(data.section)
                if (data.sectionsOrder) {
                  projectModifySectionsOrder(projectId, data.sectionsOrder)
                  projectUpdateSectionOrderCache(data.sectionsOrder, projectId)
                }
              }
              break
          }
        }
      },
      variables: {
        projectId,
      },
    },
  )
