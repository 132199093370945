export const PERMISSION = {
  project: {
    target: {
      c: 'project.target.c',
      r: 'project.target.r',
      u: 'project.target.u',
      d: 'project.target.d',
    },
    team: {
      c: 'project.team.c',
      r: 'project.team.r',
      u: 'project.team.u',
      d: 'project.team.d',
    },
    skills: {
      c: 'project.skills.c',
      r: 'project.skills.r',
      u: 'project.skills.u',
      d: 'project.skills.d',
    },
    restrictions: {
      c: 'project.restrictions.c',
      r: 'project.restrictions.r',
      u: 'project.restrictions.u',
      d: 'project.restrictions.d',
      timing: {
        c: 'project.restrictions.timing.c',
        r: 'project.restrictions.timing.r',
        u: 'project.restrictions.timing.u',
        d: 'project.restrictions.timing.d',
      },
      budget: {
        c: 'project.restrictions.budget.c',
        r: 'project.restrictions.budget.r',
        u: 'project.restrictions.budget.u',
        d: 'project.restrictions.budget.d',
      },
    },
    previewButton: {
      r: 'project.previewButton.r',
    },
    content: {
      r: 'project.content.r',
      settings: {
        r: 'project.content.settings.r',
        tags: {
          r: 'project.content.settings.tags.r',
          u: 'project.content.settings.tags.u',
        },
        description: {
          c: 'project.content.settings.description.c',
          r: 'project.content.settings.description.r',
          u: 'project.content.settings.description.u',
          d: 'project.content.settings.description.d',
        },
      },
    },
    sections: {
      c: 'project.sections.c',
      r: 'project.sections.r',
      u: 'project.sections.u',
      d: 'project.sections.d',
      settings: {
        r: 'project.sections.settings.r',
        sectionClose: {
          r: 'project.sections.settings.sectionClose.r',
          u: 'project.sections.settings.sectionClose.u',
        },
        deadline: {
          c: 'project.sections.settings.deadline.c',
          r: 'project.sections.settings.deadline.r',
          u: 'project.sections.settings.deadline.u',
          d: 'project.sections.settings.deadline.d',
        },
        budget: {
          c: 'project.sections.settings.budget.c',
          r: 'project.sections.settings.budget.r',
          u: 'project.sections.settings.budget.u',
          d: 'project.sections.settings.budget.d',
        },
        responsibility: {
          c: 'project.sections.settings.responsibility.c',
          r: 'project.sections.settings.responsibility.r',
          u: 'project.sections.settings.responsibility.u',
          d: 'project.sections.settings.responsibility.d',
        },
        tags: {
          c: 'project.sections.settings.tags.c',
          r: 'project.sections.settings.tags.r',
          u: 'project.sections.settings.tags.u',
          d: 'project.sections.settings.tags.d',
        },
        description: {
          c: 'project.sections.settings.description.c',
          r: 'project.sections.settings.description.r',
          u: 'project.sections.settings.description.u',
          d: 'project.sections.settings.description.d',
        },
      },
    },
    // просмотр (на странице секций кнопка внизу слево)
    showPreviewButton: {
      r: 'project.showPreviewButton.r',
    },
    // страница редактора
    editor: {
      saveAsTemplateButton: {
        r: 'project.editor.saveAsTemplateButton.r',
      },
      tasks: {
        c: 'project.editor.tasks.c',
        r: 'project.editor.tasks.r',
        u: 'project.editor.tasks.u',
        d: 'project.editor.tasks.d',
      },
      // предпросмотр (на странице редактора ктопка вверху спррава)
      previewButton: {
        r: 'project.editor.previewButton.r',
      },
    },
    openInEditorButton: {
      r: 'project.openInEditorButton.r',
    },
    // страница превью
    preview: {
      blockEditIcon: {
        r: 'project.preview.blockEditIcon.r',
      },
    },
    // страница брендирования
    branding: {
      templateSettingButton: {
        r: 'project.branding.templateSettingButton.r',
      },
      previewIcon: {
        r: 'project.branding.previewIcon.r',
      },
    },
    exportScormButton: {
      r: 'project.exportScormButton.r',
    },
    // left toolbar page sections (icon folder)
    library: {
      c: 'project.library.c',
      r: 'project.library.r',
      u: 'project.library.u',
      d: 'project.library.d',
    },
    // left toolbar page sections (icon circle in/yn)
    tasks: {
      c: 'project.tasks.c',
      r: 'project.tasks.r',
      u: 'project.tasks.u',
      d: 'project.tasks.d',
    },
  },
}
