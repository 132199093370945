import gql from 'graphql-tag'

import { publicLinkProjectFragment } from './projects.gql'

export const loginAsVisitorSchema = gql`
  fragment LoginAsVisitorSchema on LoginAsVisitor {
    id
    status
    token
    companyId
    projectId
  }
`

export const getPublicPreviewData = gql`
  query GetPublicPreviewData($shortLink: String!) {
    data: loginAsVisitor(shortLink: $shortLink) {
      ...LoginAsVisitorSchema
    }
  }
  ${loginAsVisitorSchema}
`

export const getPublicLinkInProjectGetById = gql`
  query GetPublicLinkInProjectGetById($projectId: String!) {
    data: projectsGetById(projectId: $projectId) {
      id
      publicLink {
        ...PublicLinkProjectSchema
      }
    }
  }
  ${publicLinkProjectFragment}
`
