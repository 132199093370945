import gql from 'graphql-tag'

import { companyBrandFragment } from './companies.gql'
import { employeeSmallFragment } from './employees.gql'
import { fileFragment } from './files.gql'
import { sectionOrderFragment, sectionWithBlocksFragment } from './sections.gql'

export const publicLinkProjectFragment = gql`
  fragment PublicLinkProjectSchema on Link {
    id
    link
    isOpen
  }
`

export const publicBlockFragment = gql`
  fragment PublicBlockSchema on PublicBlockType {
    id
    description
    title
    icon
    iconImg
  }
`

export const publicSettingsFragment = gql`
  fragment PublicSettingsSchema on PublicSettingsType {
    id
    imageId
    authors {
      id
      avatarId
      name
      description
    }
    logoId
    secondaryLogoId
    name
    description
    authorsDescription
    authorsType
    colorPrimary
    colorFont
    infoAboutProgress
    tableOfContentInitiallyExpanded
    supportEmail
    footerCopyright
    footerText1
    footerText2
    footerText3
    footerText4
    templateKey
  }
`

export const projectListFragment = gql`
  fragment ProjectListSchema on Project {
    id
    name
    budget
    tags
    owner {
      ...EmployeeSmallSchema
    }
    publicSettings {
      ...PublicSettingsSchema
    }
    isDone
    learningObjectives
    learningGoals
    endDate
    startDate
    accent
    projectGroupId
    updatedAt
    analitycs {
      tasksDone
      tasksTotal
      tasksExpired
    }
  }
  ${employeeSmallFragment}
  ${publicSettingsFragment}
`

export const projectFragment = gql`
  fragment ProjectSchema on Project {
    id
    companyId
    projectGroupId
    progressShouldBeGreaterThen
    progressShouldBeLeastThen
    finalTestId
    tags
    publicSettings {
      ...PublicSettingsSchema
    }
    owner {
      ...EmployeeSmallSchema
    }
    sectionsOrder {
      ...SectionOrderSchema
    }
    accent
    name
    startDate
    endDate
    budget
    cost
    updatedAt
    learningObjectives
    learningGoals
    projectGroupId
    publicBlocks {
      ...PublicBlockSchema
    }
    publicFiles {
      ...FileMetaSchema
    }
    employee {
      ...EmployeeSmallSchema
    }
    publicLink {
      ...PublicLinkProjectSchema
    }
    createdAt
    isDone
  }
  ${employeeSmallFragment}
  ${publicBlockFragment}
  ${fileFragment}
  ${publicSettingsFragment}
  ${sectionOrderFragment}
  ${publicLinkProjectFragment}
`

export const projectFragmentDeep = gql`
  fragment ProjectSchemaDeep on Project {
    id
    companyId
    projectGroupId
    progressShouldBeGreaterThen
    progressShouldBeLeastThen
    finalTestId
    publicSettings {
      ...PublicSettingsSchema
    }
    owner {
      ...EmployeeSmallSchema
    }
    sectionsOrder {
      ...SectionOrderSchema
    }
    sections {
      ...SectionWithBlocksSchema
    }
    accent
    name
    endDate
    updatedAt
    learningObjectives
    learningGoals
    projectGroupId
    publicBlocks {
      ...PublicBlockSchema
    }
    publicFiles {
      ...FileMetaSchema
    }
    company {
      logo {
        ...FileMetaSchema
      }
      ...CompanyBrandSchema
    }
  }
  ${publicBlockFragment}
  ${employeeSmallFragment}
  ${fileFragment}
  ${publicSettingsFragment}
  ${sectionOrderFragment}
  ${companyBrandFragment}
  ${sectionWithBlocksFragment}
`

export const projectDeepQuery = gql`
  query ProjectsDeepGetById($projectId: String!) {
    data: projectsGetById(projectId: $projectId) {
      ...ProjectSchemaDeep
    }
  }
  ${projectFragmentDeep}
`

export const projectsAll = gql`
  query ProjectsAll($companyId: String, $parentId: String, $search: ProjectSearch) {
    data: projectAll(companyId: $companyId, parentId: $parentId, search: $search) {
      projects {
        ...ProjectListSchema
      }
      limit
      offset
      total
    }
  }
  ${projectListFragment}
`

export const projectsGetByIdQuery = gql`
  query ProjectsGetById($projectId: String!) {
    data: projectsGetById(projectId: $projectId) {
      ...ProjectSchema
    }
  }
  ${projectFragment}
`

export const projectsFilesGetByIdQuery = gql`
  query ProjectsFilesGetById($projectId: String!) {
    data: projectsGetById(projectId: $projectId) {
      ...ProjectSchema
      files {
        ...FileMetaSchema
      }
    }
  }
  ${fileFragment}
  ${projectFragment}
`

export const projectListSubscription = gql`
  subscription ProjectListSubscription($companyId: String!, $parentId: String, $projectId: String) {
    data: project(companyId: $companyId, parentId: $parentId, projectId: $projectId) {
      project {
        ...ProjectListSchema
      }
      type
    }
  }
  ${projectListFragment}
`

export const projectSubscription = gql`
  subscription ProjectSubscription($companyId: String!, $projectId: String) {
    data: project(companyId: $companyId, projectId: $projectId) {
      project {
        ...ProjectSchema
      }
      type
    }
  }
  ${projectFragment}
`

export const updatePublicLinkInProjectGetById = gql`
  mutation PublicLinkInProjectGetByIdUpdate($projectId: String!, $data: ProjectUpdateInput!) {
    data: projectsUpdateById(projectId: $projectId, data: $data) {
      id
      publicLink {
        ...PublicLinkProjectSchema
      }
    }
  }
  ${publicLinkProjectFragment}
`
