import gql from 'graphql-tag'

import { gqlClient } from '.'

export const typeDefs = gql`
  # this schema allows the following mutation:
  extend type Mutation {
    sessionLogin(item: String): Boolean
    sessionLogout: Boolean
  }
`

export const resolvers = {
  Mutation: {
    sessionLogin: (_: any, payload: { item: string }) => {
      localStorage.setItem('token', payload.item)
      return null
    },
    sessionLogout: async (_: any) => {
      try {
        // https://github.com/apollographql/apollo-client/issues/2919#issuecomment-732746490
        // https://github.com/apollographql/apollo-client/issues/1945
        // SubscribeContext.tsx
        // simpleInputQuery кидает ошибку
        localStorage.setItem('logout-process', 'true')
        await gqlClient.core.clearStore()
        await gqlClient.core.resetStore()
        await gqlClient.auth.clearStore()
        await gqlClient.auth.resetStore()
        await gqlClient.serverStorage.clearStore()
        await gqlClient.serverStorage.resetStore()
        localStorage.removeItem('token')
        localStorage.removeItem('logout-process')
      } catch (err) {
        console.log(err)
        localStorage.removeItem('token')
        localStorage.removeItem('logout-process')
      }

      return null
    },
  },
}
