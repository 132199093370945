import { ApolloCache } from '@apollo/client'
import produce from 'immer'

import { gqlClient } from '.'
import { CompaniesAll, CompaniesAll_data, CompanyUpdateById } from './__generated__/types'
import { companyGetAllQuery } from './companies.gql'

export const updateCompanySettingsLogo = (
  data: CompanyUpdateById,
  cache: ApolloCache<CompanyUpdateById>,
) => {
  try {
    if (cache) {
      cache.modify({
        id: cache.identify({
          __typename: 'Company',
          id: data.data?.id,
        }),
        fields: {
          logo: () => data.data?.logo,
        },
      })
    }
  } catch (err) {
    console.error('"updateCompanySettingsLogo" fn is crashed on operation: ".modify"', err)
  }
}

export const companiesRemoveCache = (companies: CompaniesAll_data, userId: string) => {
  const cache = gqlClient.core.cache
  try {
    const data = cache.readQuery<CompaniesAll>({
      query: companyGetAllQuery,
      variables: { userId },
    })
    if (data) {
      cache.writeQuery({
        query: companyGetAllQuery,
        variables: { userId },
        data: produce(data, (draft) => {
          const index = draft!.data.findIndex((e) => e.id === companies?.id)
          if (index >= 0) {
            draft!.data.splice(index, 1)
          }
        }),
      })
    }
  } catch (err) {
    console.error('"companiesRemoveCache" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const companiesUpdateCache = (companies: CompaniesAll_data, userId: string) => {
  const cache = gqlClient.core.cache
  try {
    // cache.writeFragment({
    //   fragment: companyListFragment,
    //   id: "Company:" + (companies?.id),
    //   fragmentName: "CompanyListSchema",
    //   data: companies,
    // });
    const data = cache.readQuery<CompaniesAll>({
      query: companyGetAllQuery,
      variables: { userId },
    })
    if (data) {
      cache.writeQuery<CompaniesAll>({
        query: companyGetAllQuery,
        variables: {
          userId,
        },
        data: produce(data, (draft) => {
          if (companies) {
            const index = draft.data.findIndex((e) => e.id === companies?.id)
            draft!.data[index] = companies
          }
        }),
      })
    }
  } catch (err) {
    console.error('"companiesUpdateCache" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const companyLogoUpdate = (companies: CompaniesAll_data) => {
  const cache = gqlClient.core.cache
  try {
    cache.modify({
      id: cache.identify({
        __typename: 'Company',
        id: companies?.id,
      }),
      fields: {
        logo: () => companies.logo,
      },
    })
  } catch (err) {
    console.error('"companyLogoUpdate" fn is crashed on operation: ".modify"', err)
  }
}
