import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client'

import {
  RolesAll,
  RolesAllVariables,
  RolesGetById,
  RolesGetByIdVariables,
  RolesCreate,
  RolesCreateVariables,
  RolesDeleteById,
  RolesDeleteByIdVariables,
  RolesUpdateById,
  RolesUpdateByIdVariables,
  RolesForceDeleteById,
  RolesForceDeleteByIdVariables,
  RolesSetEmployees,
  RolesSetEmployeesVariables,
} from '@ui/types'

import { gqlClient } from '.'
import { employeeFragment } from './employees.gql'
import { rolesAllGql } from './roles.gql'

export const useRolesAll = (companyId: string) =>
  useQuery<RolesAll, RolesAllVariables>(rolesAllGql, {
    variables: {
      companyId,
    },
    onError: (err) => console.error('"useRolesAll" fn is crashed on operation: "useQuery"', err),
    client: gqlClient.core,
  })

export const useRolesGetById = () =>
  useLazyQuery<RolesGetById, RolesGetByIdVariables>(
    gql`
      query RolesGetById($id: String!, $companyId: String!) {
        data: rolesGetById(id: $id, companyId: $companyId) {
          id
          name
          description
          employee {
            id
          }
          permission {
            name
            id
          }
          isRemovable
          isDefault
          updatedAt
          companyId
        }
      }
    `,
    {
      onError: (err) =>
        console.error('"useRolesGetById" fn is crashed on operation: "useLazyQuery"', err),
      client: gqlClient.core,
    },
  )

export const useRolesCreate = (companyId: string) =>
  useMutation<RolesCreate, RolesCreateVariables>(
    gql`
      mutation RolesCreate($data: RoleInput!, $companyId: String!, $permissionIds: [String!]) {
        data: rolesCreate(data: $data, companyId: $companyId, permissionIds: $permissionIds) {
          id
          companyId
          name
          employee {
            id
          }
          isRemovable
          isDefault
          permission {
            name
            id
          }
          updatedAt
          description
        }
      }
    `,
    {
      // TODO: убрать
      refetchQueries: [
        {
          query: rolesAllGql,
          variables: {
            companyId,
          },
        },
      ],
      client: gqlClient.core,
      onError: (err) =>
        console.error('"useRolesCreate" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useRolesDeleteById = (companyId: string) =>
  useMutation<RolesDeleteById, RolesDeleteByIdVariables>(
    gql`
      mutation RolesDeleteById($id: String!, $companyId: String!) {
        data: rolesDeleteById(id: $id, companyId: $companyId) {
          id
          errors {
            type
            items {
              id
              name
            }
          }
        }
      }
    `,
    {
      // TODO: убрать
      refetchQueries: [
        {
          query: rolesAllGql,
          variables: {
            companyId,
          },
        },
      ],
      // update: cacheUpdates.deleteItemFromItems(rolesAllGql, () => ({
      //   companyId
      // })),
      onError: (err) =>
        console.error('"useRolesDeleteById" fn is crashed on operation: "useMutation"', err),
      client: gqlClient.core,
    },
  )

export const useRolesForceDeleteById = (companyId: string) =>
  useMutation<RolesForceDeleteById, RolesForceDeleteByIdVariables>(
    gql`
      mutation RolesForceDeleteById($id: String!, $companyId: String!, $roleId: String!) {
        data: rolesForceDeleteById(id: $id, newId: $roleId, companyId: $companyId)
      }
    `,
    {
      // TODO: убрать
      refetchQueries: [
        {
          query: rolesAllGql,
          variables: {
            companyId,
          },
        },
      ],
      onError: (err) =>
        console.error('"useRolesForceDeleteById" fn is crashed on operation: "useMutation"', err),
      client: gqlClient.core,
    },
  )

export const useRolesUpdateById = (companyId: string) =>
  useMutation<RolesUpdateById, RolesUpdateByIdVariables>(
    gql`
      mutation RolesUpdateById(
        $id: String!
        $companyId: String!
        $data: RoleInput!
        $permissionIds: [String!]!
      ) {
        data: rolesUpdateById(
          id: $id
          companyId: $companyId
          # Как обновить пермишены
          data: $data
          permissionIds: $permissionIds
        ) {
          id
          name
          employee {
            id
          }
          permission {
            name
            id
          }
          isDefault
          isRemovable
          description
          companyId
          isArchived
          updatedAt
        }
      }
    `,
    {
      onError: (err) =>
        console.error('"useRolesUpdateById" fn is crashed on operation: "useMutation"', err),
      client: gqlClient.core,
    },
  )

export const useRolesSetEmployees = (companyId: string) =>
  useMutation<RolesSetEmployees, RolesSetEmployeesVariables>(
    gql`
      mutation RolesSetEmployees($employeeIds: [String!], $roleId: String!, $companyId: String!) {
        data: rolesSetEmployees(employeeIds: $employeeIds, roleId: $roleId, companyId: $companyId) {
          ...EmployeeSchema
        }
      }
      ${employeeFragment}
    `,
    {
      onError: (err) =>
        console.error('"useRolesSetEmployees" fn is crashed on operation: "useMutation"', err),
      client: gqlClient.core,
    },
  )
