export const validateWebsiteUrl = (value: string) => {
  const regStr = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
  return regStr.test(String(value).toLowerCase())
}

export const websiteValidation = async (rule: any, value: string) => {
  const websiteValidated = validateWebsiteUrl(value)

  try {
    if (!websiteValidated && value) {
      throw new Error('Введите корректный адрес')
    }
  } catch (err) {
    return Promise.reject(err)
  }
  return Promise.resolve()
}
