export const passwordValidation = (rule: any, value: string) => {
  try {
    if (value && value.length < 10) {
      throw new Error('Длина пароля должна быть не менее 10 символов')
    }
    if (value) {
      const reg = /[A-Z]/
      const upperSymbols = reg.test(value)
      if (!upperSymbols) {
        throw new Error('Пароль должен содержать одну заглавную букву')
      }
    }
    if (value) {
      const reg = /[a-z]/
      const lowSymbols = reg.test(value)
      if (!lowSymbols) {
        throw new Error('Пароль должен содержать одну маленькую букву')
      }
    }
    if (value) {
      const reg = /[!@#$%^&*]/
      const symbols = reg.test(value)
      if (!symbols) {
        throw new Error('Пароль должен содержать один спецсимвол')
      }
    }
    if (value) {
      const reg = /[0-9]/
      const symbols = reg.test(value)
      if (!symbols) {
        throw new Error('Пароль должен содержать одну цифру')
      }
    }
  } catch (err) {
    return Promise.reject(err)
  }

  return Promise.resolve()
}
