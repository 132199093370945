import { gql, useMutation } from '@apollo/client'

import {
  Login,
  LoginVariables,
  registration,
  registrationVariables,
  SessionLogout,
  SessionLoginVariables,
  SessionLogin,
  sendMeLinkForRecoverPassword,
  sendMeLinkForRecoverPasswordVariables,
  changePassword,
  changePasswordVariables,
} from '@ui/types'

export const useSessionLogout = () =>
  useMutation<SessionLogout>(
    gql`
      mutation SessionLogout {
        data: sessionLogout @client
      }
    `,
    {
      onError: (err) =>
        console.error('"useSessionLogout" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useSessionLogin = () =>
  useMutation<SessionLogin, SessionLoginVariables>(
    gql`
      mutation SessionLogin($token: String!) {
        data: sessionLogin(item: $token) @client
      }
    `,
    {
      onError: (err) =>
        console.error('"useSessionLogin" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useLogin = () =>
  useMutation<Login, LoginVariables>(
    gql`
      mutation Login($payload: LoginInput!) {
        data: login(payload: $payload) {
          token
        }
      }
    `,
    {
      onError: (err) => console.error('"useLogin" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useRegistration = () =>
  useMutation<registration, registrationVariables>(
    gql`
      mutation registration($payload: KUserInput!) {
        data: registration(payload: $payload) {
          isMailSent
        }
      }
    `,
    {
      onError: (err) =>
        console.error('"useRegistration" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useRecoverPassword = () =>
  useMutation<sendMeLinkForRecoverPassword, sendMeLinkForRecoverPasswordVariables>(
    gql`
      mutation sendMeLinkForRecoverPassword($login: String!) {
        data: sendMeLinkForRecoverPassword(login: $login)
      }
    `,
    {
      onError: (err) =>
        console.error('"useRecoverPassword" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useChangePassword = () =>
  useMutation<changePassword, changePasswordVariables>(
    gql`
      mutation changePassword($passwordNew: String!) {
        data: changePassword(passwordNew: $passwordNew)
      }
    `,
    {
      onError: (err) =>
        console.error('"useChangePassword" fn is crashed on operation: "useMutation"', err),
    },
  )
