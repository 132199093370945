import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client'
import produce from 'immer'

import {
  EditorBlockTest,
  EditorElement,
  EditorElementContainerOptions,
  EditorElementError,
  EditorElementOptions,
} from '@ui/models'
import {
  BlocksAll,
  BlocksAllVariables,
  BlocksGetById,
  BlocksGetByIdVariables,
  BlocksMultiCreate,
  BlocksMultiCreateVariables,
  BlocksCreate,
  BlocksCreateVariables,
  BlocksUpdateById,
  BlocksUpdateByIdVariables,
  BlocksDeleteById,
  BlocksDeleteByIdVariables,
  BlockSetActiveByVersion,
  BlockSetActiveByVersionVariables,
  BlocksVersionsVariables,
  BlocksVersions,
  BlocksCreateVersionVariables,
  BlocksCreateVersion,
  BlocksCopy,
  BlocksCopyVariables,
  BlocksDeleteByIds,
  BlocksDeleteByIdsVariables,
  BlockSchema,
  BlocksAll_data_options,
  BlocksAll_data_files,
  BlocksAll_data_test,
} from '@ui/types'

import { QuestionNotificationType } from 'components/editor-v2/EditorElements/QuestionResultNotification/QuestionResultNotification.types'

import { gqlClient } from '.'
import { blockAddCache, blockRemoveCache } from './blocks.cache'
import {
  blockFragment,
  blocksGetAllQuery,
  blocksGetById,
  blockUpdateMutation,
  blockVersionFragment,
} from './blocks.gql'
import { sectionBlocksAdd, sectionBlocksRemove } from './sections.cache'

export * from './blocks.subscription'

export const useBlockGetById = (
  variables: BlocksGetByIdVariables,
  onCompleted?: (data: BlocksGetById) => void,
  isNoCache?: boolean,
  fetchPolicy?:
    | 'cache-only'
    | 'cache-and-network'
    | 'cache-first'
    | 'network-only'
    | 'no-cache'
    | 'standby'
    | undefined,
) => {
  const result = useQuery<BlocksGetById, BlocksGetByIdVariables>(blocksGetById, {
    fetchPolicy: !isNoCache ? 'cache-only' : 'cache-and-network',
    variables,
    nextFetchPolicy: fetchPolicy,
    onCompleted,
    onError: (err) =>
      console.error('"useBlockGetById" fn is crashed on operation: "useQuery"', err),
  })

  if (!result.data?.data) {
    setBlockByIdQuery(variables.sectionId, variables.uuid)
  }

  return result
}

export const getBlockGetByIdCache = (uuid?: string) => {
  const cache = gqlClient.core.cache

  return (
    cache.readFragment<BlockSchema>({
      id: cache.identify({
        __typename: 'Block',
        uuid: uuid,
      }),
      fragment: blockFragment,
      fragmentName: 'BlockSchema',
    }) || undefined
  )
}

export const setBlockByIdQuery = (sectionId?: string, uuid?: string) => {
  const cache = gqlClient.core.cache
  const block = getBlockGetByIdCache(uuid)

  if (block && sectionId && uuid) {
    try {
      cache.writeQuery<BlocksGetById, BlocksGetByIdVariables>({
        query: blocksGetById,
        data: {
          data: block,
        },
        variables: {
          sectionId: sectionId,
          uuid: uuid,
        },
      })
    } catch (err) {
      console.error('"setBlockByIdQuery" fn is crashed on operation: ".writeQuery"', err)
    }
  }
}

export const modifyBlockById = (
  uuid: string,
  block: {
    name?: string
    test?: BlocksAll_data_test | null
    elements?: any
    schema?: string[][][] | null
    files?: BlocksAll_data_files[]
    sectionId?: string
    mode?: string
    type?: string
    options?: BlocksAll_data_options
    version?: number | null
    updatedAt?: string
    tasksCount?: number
  },
) => {
  const cache = gqlClient.core.cache
  const fields = Object.keys(block).reduce((result, e) => {
    // @ts-ignore
    result[e] = () => block[e]
    return result
  }, {} as { [key: string]: () => any })

  try {
    cache.modify({
      id: cache.identify({
        __typename: 'Block',
        uuid: uuid,
      }),
      fields: fields,
    })
  } catch (err) {
    console.error('"modifyBlockById" fn is crashed on operation: ".modify"', err)
  }

  // blocksUpdateById(uuid);

  return getBlockGetByIdCache(uuid)
}

export const modifyBlockElementById = (
  uuid: string,
  elementId: string,
  element: {
    id?: string
    options?: EditorElementOptions
    type?: string
    value?: any
    error?: EditorElementError[]
    containerOptions?: EditorElementContainerOptions
  },
) => {
  const cache = gqlClient.core.cache

  try {
    cache.modify({
      id: cache.identify({
        __typename: 'Block',
        uuid: uuid,
      }),
      fields: {
        updatedAt() {
          return Date.now().toString()
        },
        elements(elements: { [key: string]: EditorElement }) {
          return produce(elements, (draft) => {
            draft[elementId] = Object.assign({}, draft[elementId], element)
          })
        },
      },
    })
  } catch (err) {
    console.error('"modifyBlockElementById" fn is crashed on operation: ".modify"', err)
  }

  // blocksUpdateById(uuid);

  const newBlock = getBlockGetByIdCache(uuid)

  return newBlock?.elements[elementId] as EditorElement | undefined
}

export const removeBlockElementById = (uuid: string, elementId: string) => {
  const cache = gqlClient.core.cache
  const block = getBlockGetByIdCache(uuid)
  const element = block?.elements[elementId]

  try {
    cache.modify({
      id: cache.identify({
        __typename: 'Block',
        uuid: uuid,
      }),
      fields: {
        updatedAt() {
          return Date.now().toString()
        },
        elements(elements: { [key: string]: EditorElement }) {
          return produce(elements, (draft) => {
            delete draft[elementId]
          })
        },
        test(test: EditorBlockTest) {
          return produce(test, (draft) => {
            if (draft && element && element.type === 'editor-result') {
              draft.isResult = null
            }

            if (draft && draft.successRules) {
              delete draft.successRules[elementId]
            }
          })
        },
        schema(schema: string[][][]) {
          return produce(schema, (draft) => {
            draft.forEach((item: any, scIndex: number) => {
              item.forEach((yItem: any, yIndex: number) => {
                yItem.forEach((xItem: any, xIndex: number) => {
                  if (xItem === elementId && draft) {
                    draft[scIndex][yIndex].splice(xIndex, 1)
                    if (!draft[scIndex][yIndex].length) {
                      draft[scIndex].splice(yIndex, 1)
                    }
                  }
                })
              })
            })
          })
        },
      },
    })
  } catch (err) {
    console.error('"removeBlockElementById" fn is crashed on operation: ".modify"', err)
  }

  // blocksUpdateById(uuid);
}

export const useBlockGetByIdQuery = (
  isCacheOnly?: boolean,
  fetchPolicy?:
    | 'cache-and-network'
    | 'cache-first'
    | 'network-only'
    | 'cache-only'
    | 'no-cache'
    | 'standby'
    | undefined,
) =>
  useLazyQuery<BlocksGetById, BlocksGetByIdVariables>(blocksGetById, {
    nextFetchPolicy: fetchPolicy,
    fetchPolicy: isCacheOnly ? 'cache-only' : 'cache-and-network',
    onError: (err) =>
      console.error('"useBlockGetByIdQuery" fn is crashed on operation: "useLazyQuery"', err),
  })

export const useBlocksGetAllQueryLazy = (
  isNoCache?: boolean,
  fetchPolicy?:
    | 'cache-and-network'
    | 'cache-first'
    | 'network-only'
    | 'cache-only'
    | 'no-cache'
    | 'standby'
    | undefined,
) =>
  useLazyQuery<BlocksAll, BlocksAllVariables>(blocksGetAllQuery, {
    nextFetchPolicy: fetchPolicy,
    fetchPolicy: !isNoCache ? 'cache-only' : 'cache-and-network',
    onError: (err) =>
      console.error('"useBlockGetByIdQuery" fn is crashed on operation: "useLazyQuery"', err),
  })

export const blocksGetAllQueryLazyCore = async (variables: { sectionId: string }) => {
  return await gqlClient.core.query<BlocksAll, BlocksAllVariables>({
    query: blocksGetAllQuery,
    variables,
    fetchPolicy: 'network-only',
  })
}

export const useBlocksGetAll = (
  variables: BlocksAllVariables,
  isNoCache?: boolean,
  fetchPolicy?:
    | 'cache-and-network'
    | 'cache-first'
    | 'network-only'
    | 'cache-only'
    | 'no-cache'
    | 'standby'
    | undefined,
) =>
  useQuery<BlocksAll, BlocksAllVariables>(blocksGetAllQuery, {
    fetchPolicy: fetchPolicy || !isNoCache ? 'cache-only' : 'cache-and-network',
    variables,
    onError: (err) =>
      console.error('"useBlocksGetAll" fn is crashed on operation: "useQuery"', err),
  })

export const useBlocksVersions = (uuid: string) =>
  useQuery<BlocksVersions, BlocksVersionsVariables>(
    gql`
      query BlocksVersions($uuid: String!) {
        data: editorBlocksVersions(uuid: $uuid) {
          ...BlockVersionSchema
        }
      }
      ${blockVersionFragment}
    `,
    {
      variables: {
        uuid,
      },
      fetchPolicy: 'cache-and-network',
      onError: (err) =>
        console.error('"useBlocksVersions" fn is crashed on operation: "useQuery"', err),
    },
  )

export const useBlocksMultiCreate = (id?: string, projectId?: string) =>
  useMutation<BlocksMultiCreate, BlocksMultiCreateVariables>(
    gql`
      mutation BlocksMultiCreate($payload: BlockMultiCreateInput!) {
        data: editorBlocksMultiCreate(payload: $payload) {
          blocks {
            ...BlockSchema
          }
          blocksOrder
        }
      }
      ${blockFragment}
    `,
    {
      update: (cache, { data: item }) => {
        if (item?.data.blocks && projectId) {
          blockAddCache(item?.data.blocks)
          if (id && projectId && item?.data.blocks) {
            sectionBlocksAdd(id, item?.data.blocksOrder, item?.data.blocks)
          }
        }
      },
      onError: (err) =>
        console.error('"useBlocksMultiCreate" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useBlocksCreate = (id?: string, projectId?: string) =>
  useMutation<BlocksCreate, BlocksCreateVariables>(
    gql`
      mutation BlocksCreate($payload: BlockInput!) {
        data: editorBlocksCreate(payload: $payload) {
          block {
            ...BlockSchema
          }
          blocksOrder
        }
      }
      ${blockFragment}
    `,
    {
      update: (cache, { data: item }) => {
        if (item?.data.block && projectId) {
          blockAddCache(item?.data.block)
          if (id && projectId && item?.data.block) {
            sectionBlocksAdd(id, item?.data.blocksOrder, [item?.data.block])
          }
        }
      },
      onError: (err) =>
        console.error('"useBlocksCreate" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useBlocksUpdateById = () =>
  useMutation<BlocksUpdateById, BlocksUpdateByIdVariables>(blockUpdateMutation, {
    onError: (err) =>
      console.error('"useBlocksUpdateById" fn is crashed on operation: "useMutation"', err),
  })

export const blocksUpdateById = (uuid?: string) => {
  const block = getBlockGetByIdCache(uuid)
  if (block) {
    try {
      gqlClient.core.mutate<BlocksUpdateById, BlocksUpdateByIdVariables>({
        mutation: blockUpdateMutation,
        variables: {
          payload: {
            uuid: block.uuid,
            mode: block.mode,
            options: block.options,
            type: block.type,
            schema: block.schema,
            elements: block.elements,
            test: block.test,
            name: block.name,
            sectionId: block.sectionId,
          },
        },
      })
    } catch (err) {
      console.error('"blocksUpdateById" fn is crashed on operation: ".mutate"', err)
    }
  }
}

export const useBlocksDeleteById = (id?: string, projectId?: string) =>
  useMutation<BlocksDeleteById, BlocksDeleteByIdVariables>(
    gql`
      mutation BlocksDeleteById($uuid: String!) {
        data: editorBlocksDeleteById(uuid: $uuid) {
          uuid
          blocksOrder
        }
      }
    `,
    {
      update: (cache, { data: item }) => {
        if (item?.data.uuid && id && projectId) {
          blockRemoveCache({
            uuid: item.data.uuid,
            sectionId: id,
          })
          if (id && projectId && item?.data.blocksOrder) {
            sectionBlocksRemove(id, item.data.blocksOrder)
          }
        }
      },
      onError: (err) =>
        console.error('"useBlocksDeleteById" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useBlocksDeleteByIds = (id?: string, projectId?: string) =>
  useMutation<BlocksDeleteByIds, BlocksDeleteByIdsVariables>(
    gql`
      mutation BlocksDeleteByIds($uuid: [String!]!) {
        data: editorBlocksDeleteByIds(uuid: $uuid) {
          uuid
          blocksOrder
        }
      }
    `,
    {
      update: (cache, { data: item }) => {
        if (item?.data.uuid && id && projectId) {
          item.data.uuid.forEach((e: string) => {
            blockRemoveCache({ uuid: e, sectionId: id })
          })
          if (id && projectId && item.data.blocksOrder) {
            sectionBlocksRemove(id, item.data.blocksOrder)
          }
        }
      },
      onError: (err) =>
        console.error('"useBlocksDeleteByIds" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useBlocksCreateVersion = (id?: string, projectId?: string) =>
  useMutation<BlocksCreateVersion, BlocksCreateVersionVariables>(
    gql`
      mutation BlocksCreateVersion($uuid: String!) {
        data: editorBlocksCreateVersion(uuid: $uuid) {
          ...BlockSchema
        }
      }
      ${blockFragment}
    `,
    {
      onError: (err) =>
        console.error('"useBlocksCreateVersion" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useBlockSetActiveByVersion = (sectionId: string) =>
  useMutation<BlockSetActiveByVersion, BlockSetActiveByVersionVariables>(
    gql`
      mutation BlockSetActiveByVersion($uuid: String!, $version: Int!) {
        data: editorBlockSetActiveByVersion(uuid: $uuid, version: $version) {
          ...BlockSchema
        }
      }
      ${blockFragment}
    `,
    {
      onError: (err) =>
        console.error(
          '"useBlockSetActiveByVersion" fn is crashed on operation: "useMutation"',
          err,
        ),
    },
  )

export const useBlocksCopy = (id?: string, projectId?: string) =>
  useMutation<BlocksCopy, BlocksCopyVariables>(
    gql`
      mutation BlocksCopy($payload: BlockMultiCreateInput!) {
        data: editorBlocksMultiCopy(payload: $payload) {
          blocks {
            ...BlockSchema
          }
          blocksOrder
        }
      }
      ${blockFragment}
    `,
    {
      update: (cache, { data: item }) => {
        if (item?.data.blocks && projectId) {
          item?.data.blocks.forEach((e) => {
            blockAddCache(e)
          })
          if (id && projectId) {
            sectionBlocksAdd(id, item?.data.blocksOrder, item?.data.blocks)
          }
        }
      },
      onError: (err) =>
        console.error('"useBlocksCopy" fn is crashed on operation: "useMutation"', err),
    },
  )
