import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client'
import produce from 'immer'

import {
  CompaniesAll,
  CompanyGet,
  CompanyCreate,
  CompanyCreateVariables,
  CompanyGetMyInfo,
  CompanyGetMyInfoVariables,
  CompanyUpdateById,
  CompanyUpdateByIdVariables,
  CompanyDeleteByIdVariables,
  CompanyDeleteById,
  MyCompanyByProjectId,
  MyCompanyByProjectIdVariables,
  CompanyForceDeleteById,
  CompanyForceDeleteByIdVariables,
  CompanyUpdateBrandById,
  CompanyUpdateBrandByIdVariables,
  CompanyBrand,
  CompanyBrandVariables,
} from '@ui/types'

import { updateCompanySettingsLogo } from './companies.cache'
import {
  companyGetAllQuery,
  companyGetBrandByIdQuery,
  companyGetByIdQuery,
  companyListFragment,
  companyUpdateBrand,
  getMyUserInfoQuery,
} from './companies.gql'

export const useCompaniesGetAll = () =>
  useQuery<CompaniesAll>(companyGetAllQuery, {
    returnPartialData: true,
    onError: (err) =>
      console.error('"useCompaniesGetAll" fn is crashed on operation: "useQuery"', err),
    // fetchPolicy: 'network-only'
  })

export const useCompanyGetById = (id: string) =>
  useQuery<CompanyGet>(companyGetByIdQuery, {
    variables: {
      companyId: id,
    },
    onError: (err) =>
      console.error('"useCompanyGetById" fn is crashed on operation: "useQuery"', err),
  })

export const useCompanyGetMyInfoLazy = (cache?: boolean) => {
  return useLazyQuery<CompanyGetMyInfo, CompanyGetMyInfoVariables>(getMyUserInfoQuery, {
    fetchPolicy: cache ? 'cache-first' : 'cache-only',
    pollInterval: cache ? 300000 : undefined,
    onError: (err) =>
      console.error('"useCompanyGetMyInfoLazy" fn is crashed on operation: "useLazyQuery"', err),
  })
}

export const useCompanyGetMyInfo = (companyId: string, cache?: boolean) => {
  return useQuery<CompanyGetMyInfo, CompanyGetMyInfoVariables>(getMyUserInfoQuery, {
    variables: {
      companyId,
    },
    fetchPolicy: cache ? 'cache-first' : 'cache-only',
    pollInterval: cache ? 300000 : undefined,
    onError: (err) =>
      console.error('"useCompanyGetMyInfo" fn is crashed on operation: "useQuery"', err),
  })
}

export const useCompanyCreate = () =>
  useMutation<CompanyCreate, CompanyCreateVariables>(
    gql`
      mutation CompanyCreate($payload: CompanyInput!) {
        data: companiesCreate(data: $payload) {
          ...CompanyListSchema
        }
      }
      ${companyListFragment}
    `,
    {
      update: (cache, { data: newCompany }) => {
        try {
          const companies = cache.readQuery<CompaniesAll>({
            query: companyGetAllQuery,
          })

          cache.writeQuery({
            query: companyGetAllQuery,
            data: produce(companies, (draftCompanies) => {
              if (newCompany && newCompany.data) {
                draftCompanies!.data.push(newCompany.data)
              }
            }),
          })
        } catch (err) {
          cache.writeQuery({
            query: companyGetAllQuery,
            data: { data: [newCompany && newCompany.data] },
          })
        }
      },
      onError: (err) =>
        console.error('"useCompanyCreate" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useCompanyUpdate = () =>
  useMutation<CompanyUpdateById, CompanyUpdateByIdVariables>(
    gql`
      mutation CompanyUpdateById($companyId: String!, $data: CompanyUpdateInput!) {
        data: companiesUpdateById(companyId: $companyId, data: $data) {
          ...CompanyListSchema
        }
      }
      ${companyListFragment}
    `,
    {
      update: (cache, { data: item }) => {
        if (item) {
          updateCompanySettingsLogo(item, cache)
        }
      },
      onError: (err) =>
        console.error('"useCompanyUpdate" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useCompanyDelete = () =>
  useMutation<CompanyDeleteById, CompanyDeleteByIdVariables>(
    gql`
      mutation CompanyDeleteById($companyId: String!, $data: CompanyDeleteInput!) {
        data: companiesDeleteById(companyId: $companyId, data: $data) {
          id
          errors {
            type
            items {
              id
              name
              projectGroupId
            }
          }
        }
      }
    `,
    {
      refetchQueries: [{ query: companyGetAllQuery }],
      onError: (err) =>
        console.error('"useCompanyDelete" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useCompanyForceDelete = () =>
  useMutation<CompanyForceDeleteById, CompanyForceDeleteByIdVariables>(
    gql`
      mutation CompanyForceDeleteById($companyId: String!, $data: CompanyDeleteInput!) {
        data: companiesForceDeleteById(companyId: $companyId, data: $data)
      }
    `,
    {
      refetchQueries: [{ query: companyGetAllQuery }],
      onError: (err) =>
        console.error('"useCompanyForceDelete" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useMyCompanyByProjectId = () =>
  useLazyQuery<MyCompanyByProjectId, MyCompanyByProjectIdVariables>(
    gql`
      query MyCompanyByProjectId($projectId: String!) {
        data: getMyCompanyByProjectId(projectId: $projectId) {
          ...CompanyListSchema
        }
      }
      ${companyListFragment}
    `,
    {
      fetchPolicy: 'cache-first',
      onError: (err) =>
        console.error('"useMyCompanyByProjectId" fn is crashed on operation: "useLazyQuery"', err),
    },
  )

export const useCompanyBrand = (companyId: string) =>
  useQuery<CompanyBrand, CompanyBrandVariables>(companyGetBrandByIdQuery, {
    variables: {
      companyId,
    },
    onError: (err) =>
      console.error('"useCompanyGetById" fn is crashed on operation: "useQuery"', err),
  })

export const useCompanyUpdateBrand = () =>
  useMutation<CompanyUpdateBrandById, CompanyUpdateBrandByIdVariables>(companyUpdateBrand, {
    onError: (err) =>
      console.error('"useCompanyUpdate" fn is crashed on operation: "useMutation"', err),
  })
