import {
  VideoCameraTwoTone,
  VideoCameraOutlined,
  GroupOutlined,
  InsertRowLeftOutlined,
  CarryOutOutlined,
  TrophyOutlined,
} from '@ant-design/icons'
import { cloneDeep } from 'lodash'
import moment, { Moment } from 'moment'
import { nanoid } from 'nanoid'
import { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'
import { ElementFinalSchemaType } from 'services/Branding/brandObject'

import { CSLanding, CSSlides, CSTest } from '@ui/icons'
import {
  EditorChapter,
  ProjectItem,
  UserItem,
  SectionBlockType,
  Tag,
  EditorElementContainerOptions,
  EditorProject,
  EditorParams,
  EditorBlockMode,
  EditorTrello,
  EditorTrelloEntity,
  SectionPickerBlocks,
  EditorSection,
  EditorBlock,
  EditorElement,
} from '@ui/models'
import {
  BlocksAll_data_options,
  BlocksAll_data,
  SectionsAll_data,
  ProjectsDeepGetById_data_sections,
} from '@ui/types'

import { TEST_BLOCK_ELEMENTS } from 'components/editor-v2/EditorFormsRender/TestBlockFormsResolver/TestBlockFormsResolver.constants'
import { emptyLayoutTemplate } from 'components/editor-v2/EditorLayouts/AbsoluteLayout/template'
import { collapseLayoutTemplate } from 'components/editor-v2/EditorLayouts/CollapseLayout/template'
import { landingLayoutTemplate } from 'components/editor-v2/EditorLayouts/LandingLayout/template'
import { sliderLayoutTemplate } from 'components/editor-v2/EditorLayouts/SliderLayout/template'
import { tabsLayoutTemplate } from 'components/editor-v2/EditorLayouts/TabsLayout/template'
import { SectionType } from 'store/models/section.model'

import { fileInBlock } from './avatars'

export * from './pSBC'

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const swapItems = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)

  result[startIndex] = list[endIndex]
  result[endIndex] = list[startIndex]

  return result
}

export const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '' : '',
  display: 'flex',
  flexDirection: 'column' as any,
})

export const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // change background colour if dragging
  boxShadow: isDragging ? '0 0 0 4px var(--light-blue-color)' : '',
  borderColor: isDragging ? 'var(--accent-color)' : '',
  background: isDragging ? 'white' : '',
  // styles we need to apply on draggables
  ...draggableStyle,
})

export const getTextObject = () => ({
  object: 'value',
  document: {
    object: 'document',
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [],
      },
    ],
  },
})

export const sectionTypesUnits = {
  presentation: 'Блок',
  landing: 'Блок',
  'presentation-test': 'Тест',
  'landing-test': 'Тест',
  'video-interactive': 'Мин',
  'video-2d': 'Мин',
  'video-3d': 'Мин',
  'video-corporate': 'Мин',
  microlearning: 'Мин',
} as {
  [key: string]: string
}

export const typeMap = {
  presentation: {
    icon: <CSSlides />,
    name: 'Слайды',
  },
  landing: {
    icon: <CSLanding />,
    name: 'Лонгрид',
  },
  test: {
    icon: <CSTest />,
    name: 'Тест',
  },
} as {
  [key: string]: {
    icon: any
    name: string
  }
}

export const sectionTypesTitle = {
  presentation: 'Презентация (Scorm)',
  landing: 'Сайт (Scorm)',
  'presentation-test': 'Тесты - презентация (Scorm)',
  'landing-test': 'Тесты - сайт (Scorm)',
  'video-interactive': 'Видео - интерактивное',
  'video-2d': 'Видео - 2D',
  'video-3d': 'Видео - 3D',
  'video-corporate': 'Видео - корпоративное',
  microlearning: 'Мини-обучение',
} as {
  [key: string]: string
}

export const sectionTypesObj = {
  presentation: {
    id: nanoid(),
    type: 'presentation',
    icon: GroupOutlined,
    name: 'Слайд',
    cost: getRandomInt(0, 1000),
    accent: '#faad14',
  },
  landing: {
    id: nanoid(),
    type: 'landing',
    icon: InsertRowLeftOutlined,
    accent: '#13c2c2',
    cost: getRandomInt(0, 1000),
    name: 'Сайт',
  },
  'presentation-test': {
    id: nanoid(),
    type: 'presentation-test',
    icon: CarryOutOutlined,
    accent: '#52c41a',
    cost: getRandomInt(0, 1000),
    name: 'Тесты - презентация',
  },
  'landing-test': {
    id: nanoid(),
    type: 'landing-test',
    icon: CarryOutOutlined,
    accent: '#52c41a',
    cost: getRandomInt(0, 1000),
    name: 'Тесты - сайт',
  },
  'video-interactive': {
    id: nanoid(),
    type: 'video-interactive',
    icon: VideoCameraOutlined,
    accent: '#eb2f96',
    cost: getRandomInt(0, 1000),
    name: 'Видео - интерактивное',
  },
  'video-2d': {
    id: nanoid(),
    type: 'video-2d',
    icon: VideoCameraOutlined,
    accent: '#eb2f96',
    cost: getRandomInt(0, 1000),
    name: 'Видео - 2D',
  },
  'video-3d': {
    id: nanoid(),
    type: 'video-3d',
    icon: VideoCameraOutlined,
    accent: '#eb2f96',
    cost: getRandomInt(0, 1000),
    name: 'Видео - 3D',
  },
  'video-corporate': {
    id: nanoid(),
    type: 'video-corporate',
    icon: VideoCameraOutlined,
    accent: '#eb2f96',
    cost: getRandomInt(0, 1000),
    name: 'Видео - корпоративное',
  },
  microlearning: {
    id: nanoid(),
    type: 'microlearning',
    icon: TrophyOutlined,
    accent: '#F9851A',
    cost: getRandomInt(0, 1000),
    name: 'Мини-обучение',
  },
} as {
  [key: string]: {
    id: string
    type: string
    icon: typeof VideoCameraTwoTone
    accent?: string
    cost: number
    name: string
  }
}

export const sectionTypes = Object.keys(sectionTypesObj).map((e) => sectionTypesObj[e])

const projectBlock = (title: string) =>
  ({
    categoryId: '7',
    id: nanoid(),
    name: 'Обычный резиновый с заголовком',
    type: 'zoomable-2',
    img: '/tpl_60.png',
    templateId: '111',
    responsible: [],
    threads: [],
    tasks: [],
    concords: [],
    meta: {
      value: {
        object: 'value',
        document: {
          object: 'document',
          data: {},
          nodes: [
            {
              object: 'block',
              type: 'paragraph',
              data: {},
              nodes: [],
            },
          ],
        },
      } as any,
      title: title,
      disabled: true,
      blockHeight: 200,
    },
  } as any)

export const project = (category?: { categoryId: string; currentUser: UserItem }) =>
  ({
    id: nanoid(),
    categoryId: category && category.categoryId ? category.categoryId : (undefined as any),
    name: undefined as any,
    createdAt: Date.now(),
    laborCosts: undefined as any,
    totalPrise: undefined as any,
    dateRange: [Date.now(), undefined as any],
    tags: [],
    linkedSections: [],
    learningHours: undefined as any,
    learningHoursPerDay: undefined as any,
    learningHoursPerWeek: undefined as any,
    requiredPercent: undefined as any,
    team: [],
    owner: category && category.currentUser ? category.currentUser : undefined,
    projectManager: category && category.currentUser ? category.currentUser : undefined,
    contractors: [],
    organizationIds: [],
    sections: [
      {
        id: nanoid(),
        name: 'Описание проекта',
        description: getTextObject(),
        help: {},
        responsible: [],
        tasks: [],
        concords: [],
        threads: [],
        //@ts-ignore
        linkedSections: [],
        type: 'project-description',
        isNotVisible: true,
        perItemPrice: 0,
        tags: [],
        count: 0,
        meta: {
          offsetHeight: 92,
          isOpen: true,
          time: {
            created: Date.now(),
            update: Date.now(),
          },
        },
        blocks: [
          projectBlock('Цели проекта'),
          projectBlock('Задачи проекта'),
          projectBlock('Рекомендации'),
        ],
      },
      {
        id: nanoid(),
        name: 'Проект',
        description: getTextObject(),
        help: {},
        responsible: [],
        tasks: [],
        concords: [],
        threads: [],
        type: 'scenario',
        perItemPrice: 0,
        isNotVisible: true,
        count: 0,
        meta: {
          offsetHeight: 92,
          isOpen: true,
          time: {
            created: Date.now(),
            update: Date.now(),
          },
        },
        tags: [],
        linkedSections: [],
        blocks: [],
      },
    ],
  } as ProjectItem)

export function videoFormat(seconds: number) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

function pad(string: number) {
  return ('0' + string).slice(-2)
}

export function getRandomInt(min: number, max: number) {
  const minInt = Math.ceil(min)
  const maxInt = Math.floor(max)
  return Math.floor(Math.random() * (maxInt - minInt + 1)) + minInt
}

export const defaultBlockByType = {
  landing: ['tabs', 'slider', 'collapse', 'landing'],
  absolute: ['empty'],
  test: ['empty'],
} as {
  [key: string]: string[]
}

export const blockMap = new Map()
  .set('empty', emptyLayoutTemplate)
  .set('tabs', tabsLayoutTemplate)
  .set('slider', sliderLayoutTemplate)
  .set('collapse', collapseLayoutTemplate)
  .set('landing', landingLayoutTemplate)

export const generateBlock = (type?: string): SectionBlockType => {
  const id = nanoid()
  for (const key of blockMap.keys()) {
    if (type === key) {
      return blockMap.get(type)(id)
    }
  }
  return blockMap.get('empty')(id)
}

export const generateProjectGroup = (): SectionPickerBlocks => {
  return [
    {
      id: nanoid(),
      type: 'presentation',
      name: 'Абсолютные',
      items: [],
    },
    {
      id: nanoid(),
      type: 'landing',
      name: 'Лэндинговые',
      items: [],
    },
    {
      id: nanoid(),
      type: 'test',
      name: 'Тестовые',
      items: [],
    },
  ]
}

const types = ['presentation', 'landing', 'test']
export const generateEditor = () => {
  return {
    id: nanoid(),
    name: 'Валера ' + nanoid(),
    avatar: 'https://placeimg.com/32/32/any/' + getRandomInt(10, 100),
    roleId: '1',
    subSubGroupId: '1',
    subGroupId: '2',
    groupId: '3',
  }
}
export const generateSection = (isChapter?: boolean): EditorSection | SectionType => {
  return {
    blocks: [],
    createdAt: Date.now(),
    updatedAt: Date.now(),
    formats: [],
    employers: isChapter ? [] : [],
    isArchived: false,
    isChapter: isChapter ? getRandomInt(0, 3) === 2 : false,
    tags: [],
    totalCost: 0,
    description: isChapter
      ? [
          {
            type: 'paragraph',
            children: [
              {
                text:
                  'Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
                italic: true,
                bold: true,
                color: '#ff4757',
              },
            ],
          },
          { type: 'paragraph', children: [{ text: '' }] },
          { type: 'paragraph', children: [{ text: '' }] },
          {
            type: 'heading-two',
            children: [
              { text: 'Lorem Ipsum comes from sections 1.10.32' },
              { text: ' and 1.10.33 of "de Fini', color: '#0984e3' },
              {
                text:
                  'bus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
              },
            ],
          },
          { type: 'paragraph', children: [{ text: '' }] },
          { type: 'paragraph', children: [{ text: '' }] },
          {
            type: 'paragraph',
            children: [
              {
                text:
                  'Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
              },
            ],
          },
        ]
      : '',
    name: 'Section name ' + getRandomInt(10, 100),
    projectId: nanoid(),
    type: isChapter ? types[getRandomInt(0, 2)] : 'presentation',
    blocksOrder: [],
    deadline: isChapter ? getRandomInt(0, 5) : undefined,
    isDone: isChapter ? getRandomInt(0, 5) === 3 : false,
    // sections: getRandomInt(0, 5) === 1 ? [generateSection(), generateSection(), generateSection()] : undefined,
    id: nanoid(),
    lvl: 0,
  }
}

export const generateChapter = (): EditorChapter => {
  return {
    description:
      'Laudantium tenetur autem voluptatibus culpa. Assumenda in harum sint maxime minima. Blanditiis deserunt officiis qui est qui. Nam doloremque ipsa maxime. A dolor rem veniam placeat eius dolorem. Rerum aliquid…',
    name: 'Chapter name ' + getRandomInt(10, 100),
    projectId: '',
    sections: [],
    sectionsOrder: [],
    id: nanoid(),
  }
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const generateTag = (): Tag => {
  return {
    id: nanoid(),
    color: 'red',
    description: 'Laudantium tenetur autem voluptatibus culpa. Assumenda...',
    name: 'Тэг ' + +getRandomInt(10, 100),
    updatedAt: Date.now(),
    createdAt: Date.now(),
  }
}

export const editorElementTypes = ['empty', 'text', 'image', 'video', 'code']

export const defaultCellSize = 32

export const useElementContainerStyles = (options: EditorElementContainerOptions) => {
  const backgroundImage = fileInBlock(options?.backgroundImage || undefined, {
    type: 'image',
    w: 1600,
    h: 1000,
  })

  return options
    ? {
        /**
         * Есть компоненты которые поумолчанию невидимы
         */
        display: 'flex',
        minHeight:
          options.height && options.height < defaultCellSize ? `1px` : `${defaultCellSize}px`,
        minWidth: options.width && options.width < defaultCellSize ? `1px` : `${defaultCellSize}px`,
        width: options.width,
        height: options.height,
        backgroundColor: options.backgroundColor,
        backgroundImage:
          backgroundImage.path !== undefined ? `url('${backgroundImage.path}')` : undefined,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: options.borderRadius ? `${options.borderRadius}px` : undefined,
        padding:
          options.indents && options.indents.length
            ? options.indents.map((indent) => `${indent}px `).join('')
            : '0',
        transform: options.angle ? `rotate(${options.angle}deg)` : undefined,
        border: options.border
          ? `${options.border.width}px ${options.border.type} ${options.border.color}`
          : 'unset',
        justifyContent: options.justifyContent,
        zIndex: options.zIndex,
        alignItems: options.alignItems,
        textAlign: options.textAlign,
        autoHeight: options.autoHeight,
        autoWidth: options.autoWidth,
      }
    : {}
}

export const getFakeBlockLanding = () => ({
  elements: {
    'UUSSYjhYG-Oti6I_QgAY6': {
      id: 'UUSSYjhYG-Oti6I_QgAY6',
      options: {},
      type: 'text',
      containerOptions: {
        backgroundColor: 'green',
        x: 47,
        y: 58,
        width: 161,
        height: 93,
      },
    },
    A13zYF1cObJB80XL6wIh0: {
      id: 'A13zYF1cObJB80XL6wIh0',
      options: {},
      type: 'empty',
      containerOptions: {
        backgroundColor: 'green',
        x: 175,
        y: 289,
        width: 116,
        height: 73,
      },
    },
    '6pXBdlXvZfqYEWMkvipLA': {
      id: '6pXBdlXvZfqYEWMkvipLA',
      options: {},
      type: 'empty',
      containerOptions: {
        backgroundColor: 'red',
        x: 639,
        y: 215,
        width: 174,
        height: 96,
      },
    },
    ac6Xp2BpSVrISVAH9gTRC: {
      id: 'ac6Xp2BpSVrISVAH9gTRC',
      options: {},
      type: 'image',
      containerOptions: {
        backgroundColor: 'orange',
        x: 729,
        y: 474,
        width: 167,
        height: 72,
      },
    },
  },
  type: 'landing',
  options: { backgroundColor: '#fff' },
  mode: 'view',
  id: 'Og_WkFwFQVq-Dg9G-KgG-',
  schema: [
    [
      ['UUSSYjhYG-Oti6I_QgAY6'],
      ['A13zYF1cObJB80XL6wIh0', '6pXBdlXvZfqYEWMkvipLA', 'ac6Xp2BpSVrISVAH9gTRC'],
    ],
  ],
})

export const getFakeBlockTest3 = () => ({
  elements: {
    Bhb8QkypjyYlb6w6szRkD: {
      id: 'Bhb8QkypjyYlb6w6szRkD',
      options: {},
      type: 'editor-checkbox',
      value: { label: 'asdghasfdhagfsd' },
      containerOptions: {
        backgroundColor: 'orange',
        x: 38,
        y: 116,
        width: 219,
        height: 84,
      },
    },
    mM3tAoyuPq0vIltjU4VP3: {
      id: 'mM3tAoyuPq0vIltjU4VP3',
      options: {},
      type: 'editor-checkbox',
      value: { label: 'asdgasd' },
      containerOptions: {
        backgroundColor: 'red',
        x: 42,
        y: 222,
        width: 140,
        height: 72,
      },
    },
    'mAE0s9Ua6-KboWCaUWJcS': {
      id: 'mAE0s9Ua6-KboWCaUWJcS',
      options: {},
      type: 'editor-checkbox',
      value: { label: 'asdahjsdajsdgjasd' },
      containerOptions: {
        backgroundColor: 'orange',
        x: 50,
        y: 315,
        width: 200,
        height: 74,
      },
    },
    'O-I033u1gEHKGmCk5LFJO': {
      id: 'O-I033u1gEHKGmCk5LFJO',
      options: {},
      type: 'editor-submit',
      value: {
        label: 'dasdasd',
        type: 'primary',
        danger: false,
        size: 'large',
      },
      containerOptions: {
        backgroundColor: 'orange',
        x: 350,
        y: 210,
        width: 150,
        height: 64,
      },
    },
  },
  type: 'absolute',
  options: { backgroundColor: '#FFFFFF' },
  name: 'тест1',
  mode: 'test',
  id: 'JLNO1i1yUAmXjpb6WilnH',
  versions: [],
  currentVersion: 0,
  test: {
    onAction: 'onsubmit',
    onFail: [
      { key: 'retry', value: 3 },
      { key: 'goto', value: 1 },
    ],
    onSuccess: [],
    successRules: {
      Bhb8QkypjyYlb6w6szRkD: [{ value: true, points: 10 }],
      mM3tAoyuPq0vIltjU4VP3: [{ value: true, points: 100 }],
      'mAE0s9Ua6-KboWCaUWJcS': [{ value: false, points: 10 }],
    },
    timer: { isVisible: true, timeLeft: 20 },
  },
})

export const getFakeBlockTest2 = () => ({
  elements: {
    xHCXQmG3V4wiouJzrkuNd: {
      id: 'xHCXQmG3V4wiouJzrkuNd',
      options: {},
      type: 'editor-checkbox',
      value: { label: 'lasdgfhgasdfa' },
      containerOptions: {
        x: 269,
        y: 114,
        width: 209,
        height: 50,
      },
    },
    eG_VmUwYUIIE3U4W3zOeC: {
      id: 'eG_VmUwYUIIE3U4W3zOeC',
      options: {},
      type: 'editor-checkbox',
      value: { label: 'sagdjhagsdjagsd' },
      containerOptions: {
        x: 281,
        y: 200,
        width: 194,
        height: 50,
      },
    },
    kvZMvNj1QQKU4GHDfd7D3: {
      id: 'kvZMvNj1QQKU4GHDfd7D3',
      options: {},
      type: 'editor-checkbox',
      value: { label: 'adashj12' },
      containerOptions: {
        backgroundColor: 'white',
        x: 280,
        y: 289,
        width: 190,
        height: 50,
      },
    },
    qYQuW4VaUJBvenEOfnbfC: {
      id: 'qYQuW4VaUJBvenEOfnbfC',
      options: {},
      type: 'editor-submit',
      value: {
        label: 'asdasdasd',
        type: 'primary',
        danger: true,
        size: 'middle',
      },
      containerOptions: {
        backgroundColor: 'green',
        x: 537,
        y: 132,
        width: 128,
        height: 100,
      },
    },
  },
  type: 'absolute',
  options: { backgroundColor: '#FFFFFF' },
  name: 'skjgafdghasdj',
  mode: 'test',
  id: '-kYyrx1mlJC5UrO5O95R9',
  versions: [],
  currentVersion: 0,
  test: {
    onAction: 'onsubmit',
    onFail: [{ key: 'popup', value: 'Я неуспешный попап' }],
    onSuccess: [
      { key: 'popup', value: 'Я успешный попап' },
      { key: 'popup', value: 'Хелло' },
      { key: 'popup', value: 'Minsk JSON' },
    ],
    successRules: {
      xHCXQmG3V4wiouJzrkuNd: [{ value: true, points: 10 }],
      eG_VmUwYUIIE3U4W3zOeC: [{ value: true, points: 10 }],
      kvZMvNj1QQKU4GHDfd7D3: [{ value: false, points: 0 }],
    },
    timer: { isVisible: true, timeLeft: 120 },
  },
})

export const getFakeBlockTest1 = () => ({
  elements: {
    '_MnQX30aUJsloJrh-k_yX': {
      id: '_MnQX30aUJsloJrh-k_yX',
      options: { backgroundColor: '#ffffff' },
      type: 'editor-checkbox',
      value: { label: 'Правильно' },
      containerOptions: {
        backgroundColor: 'black',
        x: 43,
        y: 69,
        width: 165,
        height: 69,
      },
    },
    UKhdu9Zs_A2RgMxIlhbFO: {
      id: 'UKhdu9Zs_A2RgMxIlhbFO',
      options: {},
      type: 'editor-checkbox',
      value: { label: 'Не правильно' },
      containerOptions: {
        backgroundColor: 'white',
        x: 250,
        y: 70,
        width: 184,
        height: 62,
      },
    },
    'i7bEmGOayAUN-BD81aHh-': {
      id: 'i7bEmGOayAUN-BD81aHh-',
      options: {},
      type: 'editor-submit',
      value: { label: 'Кнопка', type: 'primary', danger: false, size: 'large' },
      containerOptions: {
        backgroundColor: 'white',
        x: 77,
        y: 231,
        width: 423,
        height: 82,
      },
    },
  },
  type: 'absolute',
  options: { backgroundColor: '#FFFFFF' },
  name: 'фывфывфыв',
  mode: 'test',
  id: 'YSKk0wG184psXBrGsJZP1',
  versions: [],
  currentVersion: 0,
  test: {
    onAction: 'onsubmit',
    onFail: [],
    onSuccess: [],
    successRules: {
      '_MnQX30aUJsloJrh-k_yX': [{ value: true, points: 10 }],
      UKhdu9Zs_A2RgMxIlhbFO: [{ value: false, points: 5 }],
    },
    timer: { isVisible: false, timeLeft: 0 },
  },
})
export const getFakeBlockAbsolute = () => ({
  elements: {
    cPv1TM0Fx4cTv7saH4MUM: {
      id: 'cPv1TM0Fx4cTv7saH4MUM',
      options: {},
      type: 'empty',
      value: [
        {
          type: 'paragraph',
          children: [{ text: 'fsdfsdf hjkhjiuhuhuhuhusdfad ' }],
        },
        { type: 'paragraph', children: [{ text: '' }] },
        { type: 'paragraph', children: [{ text: '' }] },
        { type: 'paragraph', children: [{ text: '' }] },
        {
          type: 'paragraph',
          children: [{ text: '43 3423 42 123 123 123 13' }],
        },
        { type: 'paragraph', children: [{ text: '' }] },
        { type: 'paragraph', children: [{ text: '123 123123' }] },
      ],
      containerOptions: {
        backgroundColor: '#8981b5',
        x: 420,
        y: 272,
        width: 143,
        height: 51,
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
      },
    },
    hmXUX81FPxI1OLtSksh_1: {
      id: 'hmXUX81FPxI1OLtSksh_1',
      options: {},
      type: 'text',
      containerOptions: {
        backgroundColor: '#504444',
        x: 140,
        y: 284,
        width: 50,
        height: 50,
      },
    },
  },
  type: 'absolute',
  options: {},
  mode: 'view',
  id: 'g-LmxRW5F8HsdIG6QLZag',
})

export const onFailOptions = ['popup', 'retry', 'goto']
export const onSuccessOptions = ['popup', 'goto']

export const getBlockStyles = (options: BlocksAll_data_options) => {
  const backgroundImage = fileInBlock(options.backgroundImage || undefined, {
    type: 'image',
    w: 1600,
    h: 1000,
  })
  return options
    ? {
        backgroundColor:
          (options.backgroundColor === 'transparent' ? '#fff' : options.backgroundColor) ||
          undefined,
        backgroundImage:
          backgroundImage.path !== undefined ? `url('${backgroundImage.path}')` : undefined,
        backgroundSize: 'cover',
        borderRadius: options.borderRadius ? `${options.borderRadius}px` : '0px',
        padding:
          options.indents && options.indents.length
            ? options.indents.map((indent) => `${indent}px `).join('')
            : '0',
      }
    : {}
}

export const getElementDefaultStyles = (element: EditorElement): ElementFinalSchemaType => {
  const { options, containerOptions } = element
  return {
    root: {
      backgroundColor: options?.backgroundColor,
      width: options?.width || (!containerOptions?.justifyContent && '100%') || undefined,
      height: options?.height || (!containerOptions?.alignItems && '100%') || undefined,
    },
  }
}

export const generateTrello = (projectId: string): EditorTrello => {
  return {
    id: nanoid(),
    projectId,
    block: {},
    section: {},
    updatedAt: Date.now(),
    createdAt: Date.now(),
  }
}

export const generateTrelloEntity = (): EditorTrelloEntity => {
  return {
    tasks: 0,
    concords: [],
    threads: [],
  }
}

export const generateProject = (): EditorProject => {
  return {
    id: nanoid(),
    name: 'Project name ' + getRandomInt(0, 800),
    startDate: Date.now(),
    endDate: Date.now(),
    budget: 0,
    cost: 0,
    companyId: nanoid(),
    tags: [],
    categoryId: nanoid(),
    owner: generateEditor(),
    learningGoals: '',
    learningObjectives: '',
    sections: [],
    createdAt: Date.now(),
    updatedAt: Date.now(),
  }
}

export const getEditorPath = (
  mode: '' | 'preview' | 'scenario' | 'testPreview',
  params: EditorParams & { tab?: string },
) => {
  const checkedQuery = params.sectionId

  if (mode === 'scenario') {
    return `/app/${params.companyId}/editor-v2/${params.projectId}/scenario/tab/${params.tab}${
      params.sectionId ? `/section/${params.sectionId || ''}` : ''
    }`
  }

  const updatedMode =
    mode === 'preview' || mode === 'testPreview'
      ? `${mode}/${params.previewSize || 'desktop'}`
      : mode

  return `/app/${params.companyId}/editor-v2/${params.projectId}${mode ? `/${updatedMode}` : ''}${
    checkedQuery
      ? `/section/${params.sectionId}${params.blockId ? `/block/${params.blockId}` : ''}${
          params.taskId ? `/task/${params.taskId}` : ''
        }`
      : ''
  }`
}

export const defaultTags = [
  generateTag(),
  generateTag(),
  generateTag(),
  generateTag(),
  generateTag(),
  generateTag(),
  generateTag(),
  generateTag(),
  generateTag(),
  generateTag(),
]

export const deepBlockCopy = (block: SectionBlockType, mode?: EditorBlockMode) => {
  const newBlock = cloneDeep(block)
  const newId = nanoid()
  newBlock.uuid = newId

  if (mode && newBlock) {
    newBlock.mode = mode
  }

  if (mode === 'test') {
    newBlock.test = {
      __typename: 'BlockTest',
      onFail: {
        __typename: 'BlockTestOnFail',
        goToBlockId: null,
        retry: 1,
      },
      onSuccess: {
        __typename: 'BlockTestOnSuccess',
        goToBlockId: null,
      },
      isResult: null,
      successRules: {},
      time: null,
    }
  }

  Object.keys(newBlock.elements).forEach((elementId) => {
    const newElementId = nanoid()
    newBlock.elements[newElementId] = {
      ...newBlock.elements[elementId],
      id: newElementId,
    }
    if (newBlock.schema) {
      newBlock.schema.forEach((item: any, scIndex: number) => {
        item.forEach((yItem: any, yIndex: number) => {
          yItem.forEach((xItem: any, xIndex: number) => {
            if (xItem === elementId && newBlock?.schema) {
              newBlock.schema[scIndex][yIndex][xIndex] = newElementId
            }
          })
        })
      })
    }
    if (newBlock.test) {
      Object.keys(newBlock.test.successRules).forEach((keyId) => {
        if (keyId === elementId && newBlock.test) {
          newBlock.test.successRules[newElementId] = newBlock.test.successRules[keyId]
          delete newBlock.test.successRules[keyId]
        }
      })
    }
    delete newBlock.elements[elementId]
  })

  return newBlock
}

// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export function shuffle<T>(array: T[]) {
  const result = array.slice()
  let currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = result[currentIndex]
    result[currentIndex] = result[randomIndex]
    result[randomIndex] = temporaryValue
  }

  return result
}

const sharedPortal = document.createElement('div')
document.body.appendChild(sharedPortal)

const filePortal = document.createElement('div')
filePortal.className = 'file-portal'

document.body.appendChild(filePortal)

export const timePickerSetValue = (value: number) => {
  return moment.utc(value * 1000)
}

export const timePickerGetSeconds = (time: Moment | number, timeFormat?: string): number => {
  let seconds
  // @ts-ignore
  if (time && !time._isUTC) {
    // @ts-ignore
    seconds = moment(time, timeFormat || 'HH:mm:ss').diff(moment().startOf('day'), 'seconds')
  } else {
    seconds = Number(time) / 1000
  }

  return seconds
}

export { sharedPortal, filePortal }

export const getBlockTestScenarioType = (_isTest: boolean, block?: BlocksAll_data) => {
  const isEndBlock = block?.mode === 'end'
  const isStartBlock = block?.mode === 'start'
  const isQuestion = block?.mode === 'questions'

  return {
    isEndBlock,
    isStartBlock,
    isQuestion,
  }
}

export const isBtnValid = (
  btn: EditorElement,
  { isStartBlock, isQuestion }: { isStartBlock: boolean; isQuestion: boolean },
  section?: SectionsAll_data | ProjectsDeepGetById_data_sections,
  block?: BlocksAll_data,
) => {
  const isNextOnSubmit = section?.test?.isNextOnSubmit
  const isValidationVisible = section?.test?.isValidationVisible
  const testRetry = (section?.test?.testRetry || 0) > 1 && section?.test?.testRetry !== 999999
  const isOrderRandom = section?.test?.questionOrder === 'random'
  const isNotTest = block?.mode === 'view'

  return (
    (btn.value.action === 'start' && isStartBlock) ||
    (btn.value.action === 'validate' && isNextOnSubmit && isQuestion) ||
    (btn.value.action === 'validate-and-next' &&
      isNextOnSubmit &&
      isQuestion &&
      !isValidationVisible) ||
    ((btn.value.action === 'next' || btn.value.action === 'prev') &&
      (isNotTest || (isOrderRandom && isNextOnSubmit))) ||
    btn.value.action === 'end' ||
    (btn.value.action === 'restart' && testRetry)
  )
}

export const groupBlocksByStartEndMode = (blocks: EditorBlock[]) => {
  const startOrEnd = ['start', 'end']
  const startIds = blocks.filter((e) => e.mode === 'start').map((e) => e.uuid)
  const endIds = blocks.filter((e) => e.mode === 'end').map((e) => e.uuid)
  const ids = blocks.filter((e) => !startOrEnd.includes(e.mode || '')).map((e) => e.uuid)

  const questionsIds = blocks.filter((e) => e.mode === 'questions').map((e) => e.uuid)

  return {
    startIds: startIds,
    endIds: endIds,
    ids: ids,
    questionsIds: questionsIds,
  }
}

const checkingElementsType = (block: BlocksAll_data, checkedArray: string[]) => {
  const transformedElements = block && block.elements
  const elements = Object.keys(transformedElements).map((key) => transformedElements[key])
  return elements.filter((el) => checkedArray.includes(el.type)) || []
}

export const checkCountElements = (
  block: BlocksAll_data,
  elementType?: string,
  isReturnArray?: boolean,
) => {
  const testElementTypes = [
    TEST_BLOCK_ELEMENTS.editorRadioGroup,
    TEST_BLOCK_ELEMENTS.editorRadio,
    TEST_BLOCK_ELEMENTS.editorCheckbox,
    TEST_BLOCK_ELEMENTS.editorRankingV2,
    TEST_BLOCK_ELEMENTS.editorKeyboardInput,
    TEST_BLOCK_ELEMENTS.editorRanking,
    TEST_BLOCK_ELEMENTS.editorMatching,
    TEST_BLOCK_ELEMENTS.testQuestionsFeedback,
    TEST_BLOCK_ELEMENTS.editorFeedback,
  ]

  const testElementTypesForChecking = elementType ? [elementType] : testElementTypes
  const checkedElementsByType = checkingElementsType(block, testElementTypesForChecking)
  return isReturnArray ? checkedElementsByType : checkedElementsByType.length > 1
}

export const getContentOfElementsList = (
  block: BlocksAll_data,
  elementType: string,
  contentOfElementType: string,
) => {
  const elementsList = checkCountElements(block, elementType, true)
  const contentOfElementsList = Array.isArray(elementsList)
    ? elementsList.filter((item) => item.value.dividerType === contentOfElementType)
    : []

  return contentOfElementsList
}

export const updateCountNumericLine = (block: BlocksAll_data) => {
  const numericLineList = getContentOfElementsList(block, 'divider', 'numeric')
  const updatedNumericLineCount = numericLineList.map((item, index) => {
    return {
      ...item,
      value: {
        ...item.value,
        dividerCount: index + 1,
      },
    }
  })

  const formatedNumericLine = updatedNumericLineCount.reduce((acc, cur) => {
    acc[cur.id] = cur
    return acc
  }, {})

  return formatedNumericLine
}
