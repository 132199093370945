import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React, { useState, useEffect } from 'react'

import './FallbackDefault.less'

const fakeResources = [
  './bin.exe',
  './winamp.ts',
  '../../../magic.svg',
  '../../res',
  '../../resources/',
  '../../react.js',
  'app/call_to_power.js',
  '../../../chrome.browser',
  '../../../ie6.browser',
  '../windows_95.exe',
  '../ms_DOS.exe',
  '../invite/Dark_Lord_Palpatine.dll',
  '../../resources/README.md',
  '../invite/aliens_fleet.exe',
  'assets/holy_bible.txt',
  'src/Never_Gonna_Give_You_Up.mp3',
  '../driver/BFG2000.dll',
  '../driver/great_attractor.tsx',
  '../invite/Doom.slayer',
  'assets/fonts.3ds',
  'src/fuel.apk',
  'app/run_the_chocolate_factory.exe',
]

const FakeResourceBuilderProgress = ({ resources }: { resources: string[] }) => {
  const [currentResource, setCurrentResource] = useState(resources[0])

  useEffect(() => {
    let interval: NodeJS.Timeout
    const doJob = () => {
      clearInterval(interval)
      setCurrentResource(resources[Math.floor(Math.random() * resources.length)])
      interval = setInterval(doJob, Math.floor(Math.random() * 500) + 75)
    }

    doJob()

    return () => clearInterval(interval)
  }, [resources])

  return <>{currentResource}</>
}

const FallbackDefault = ({
  isAbsolute = false,
  isStatic = false,
}: {
  isAbsolute?: boolean
  isStatic?: boolean
}) => {
  return (
    <div
      className={`fallback-default
        fallback-default--is-absolute_${isAbsolute}
        fallback-default--is-static_${isStatic}`}
    >
      <Spin indicator={<LoadingOutlined />} />
      <div className='fallback-default__text'>
        <FakeResourceBuilderProgress resources={fakeResources} />
      </div>
    </div>
  )
}

FallbackDefault.BuildProgress = FakeResourceBuilderProgress

export { FallbackDefault }
