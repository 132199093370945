import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client'

import {
  SectionsAll,
  SectionsAllVariables,
  SectionsCreate,
  SectionsCreateVariables,
  SectionsUpdateById,
  SectionsUpdateByIdVariables,
  SectionsDeleteById,
  SectionsDeleteByIdVariables,
  SectionEditorSchema,
  SectionsGetById,
  SectionsGetByIdVariables,
} from '@ui/types'

import { gqlClient } from '.'
import { projectModifySectionsOrder, projectUpdateSectionOrderCache } from './projects.cache'
import { sectionsAddCache, sectionsRemoveCacheBySection } from './sections.cache'
import {
  sectionEditorFragment,
  sectionOrderFragment,
  sectionsAllQuery,
  sectionsGetById,
} from './sections.gql'

export * from './sections.subscription'

export const useSectionsAll = (
  projectId: string,
  isNoCache?: boolean,
  fetchPolicy?:
    | 'cache-and-network'
    | 'cache-first'
    | 'network-only'
    | 'cache-only'
    | 'no-cache'
    | 'standby'
    | undefined,
) =>
  useQuery<SectionsAll, SectionsAllVariables>(sectionsAllQuery, {
    variables: {
      projectId,
    },
    nextFetchPolicy: fetchPolicy,
    fetchPolicy: !isNoCache ? 'cache-only' : 'cache-and-network',
    onError: (err) => console.error('"useSectionsAll" fn is crashed on operation: "useQuery"', err),
  })

/**
 * Нужно что бы синхронизировать список и детальную страницу
 * @param id - section id
 * @param projectId
 */
export const useSectionsGetByIdFromCache = (id: string) => {
  const cache = gqlClient.core.cache
  return cache.readFragment<SectionEditorSchema>({
    id: cache.identify({
      __typename: 'EditorSection',
      id,
    }),
    fragment: sectionEditorFragment,
    fragmentName: 'SectionEditorSchema',
  })
}

export const useSectionsGetById = (sectionId: string, projectId: string) => {
  return useQuery<SectionsGetById, SectionsGetByIdVariables>(sectionsGetById, {
    variables: {
      id: sectionId,
      projectId,
    },
    onError: (err) =>
      console.error('"useSectionsGetById" fn is crashed on operation: "useQuery"', err),
  })
}

export const useSectionsGetByIdLazy = () => {
  return useLazyQuery<SectionsGetById, SectionsGetByIdVariables>(sectionsGetById, {
    fetchPolicy: 'cache-and-network',
    onError: (err) =>
      console.error('"useSectionsGetById" fn is crashed on operation: "useQuery"', err),
  })
}

export const useSectionsCreate = (projectId: string) =>
  useMutation<SectionsCreate, SectionsCreateVariables>(
    gql`
      mutation SectionsCreate($projectId: String!, $data: EditorSectionInput!) {
        data: editorSectionsCreate(projectId: $projectId, data: $data) {
          sectionsOrder {
            ...SectionOrderSchema
          }
          section {
            ...SectionEditorSchema
          }
        }
      }
      ${sectionEditorFragment}
      ${sectionOrderFragment}
    `,
    {
      update: (cache, { data: item }) => {
        if (item?.data && item?.data.sectionsOrder) {
          sectionsAddCache(item?.data.section)
          projectModifySectionsOrder(projectId, item?.data.sectionsOrder)
        }
      },
      onError: (err) =>
        console.error('"useSectionsCreate" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useSectionsUpdateById = (projectId: string) =>
  useMutation<SectionsUpdateById, SectionsUpdateByIdVariables>(
    gql`
      mutation SectionsUpdateById(
        $id: String!
        $data: EditorSectionUpdateInput!
        $projectId: String!
        $dragId: String
      ) {
        data: editorSectionsUpdateById(
          id: $id
          data: $data
          projectId: $projectId
          dragId: $dragId
        ) {
          sectionsOrder {
            ...SectionOrderSchema
          }
          section {
            ...SectionEditorSchema
          }
        }
      }
      ${sectionEditorFragment}
      ${sectionOrderFragment}
    `,
    {
      update: (cache, { data: item }) => {
        if (item?.data && item?.data.sectionsOrder) {
          projectUpdateSectionOrderCache(item?.data.sectionsOrder, projectId)
        }
      },
      onError: (err) =>
        console.error('"useSectionsUpdateById" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useSectionsDeleteById = (projectId: string) =>
  useMutation<SectionsDeleteById, SectionsDeleteByIdVariables>(
    gql`
      mutation SectionsDeleteById($id: String!, $projectId: String!) {
        data: editorSectionsDeleteById(id: $id, projectId: $projectId) {
          id
          sectionIdsDeleted
          sectionsOrder {
            ...SectionOrderSchema
          }
        }
      }
      ${sectionOrderFragment}
    `,
    {
      update: (cache, { data: item }) => {
        if (item?.data && item?.data.sectionsOrder) {
          item.data.sectionIdsDeleted.forEach((section) => {
            sectionsRemoveCacheBySection(section)
          })
          projectModifySectionsOrder(projectId, item?.data.sectionsOrder)
        }
      },
      onError: (err) =>
        console.error('"useSectionsDeleteById" fn is crashed on operation: "useMutation"', err),
    },
  )
