import { useSubscription } from '@apollo/client'

import {
  ProjectGroupsSearch,
  ProjectListSubscription,
  ProjectListSubscriptionVariables,
  ProjectSubscription,
  ProjectSubscriptionVariables,
} from '@ui/types'

import { projectsAddCache, projectsRemoveCache, projectsUpdateCache } from './projects.cache'
import { projectListSubscription, projectSubscription } from './projects.gql'

export const useProjectListSubscription = (
  companyId: string,
  search: ProjectGroupsSearch,
  parentId?: string | null,
  projectId?: string | null,
) =>
  useSubscription<ProjectListSubscription, ProjectListSubscriptionVariables>(
    projectListSubscription,
    {
      onSubscriptionData: (options) => {
        const data = options.subscriptionData.data?.data

        if (data) {
          switch (data.type) {
            case 'delete':
              if (data.project) {
                projectsRemoveCache(data.project.id)
              }
              break
            case 'create':
              if (data.project) {
                projectsAddCache(data.project, companyId, search, parentId)
              }
              break
            // case 'update':
            //   if (data.project) {
            //     projectsUpdateCacheList(data.project);
            //   }
            //   break;
          }
        }
      },
      variables: {
        companyId,
        parentId,
        projectId,
      },
    },
  )

export const useProjectSubscription = (companyId: string, projectId: string) =>
  useSubscription<ProjectSubscription, ProjectSubscriptionVariables>(projectSubscription, {
    onSubscriptionData: (options) => {
      const data = options.subscriptionData.data?.data
      if (data && data.project) {
        switch (data.type) {
          case 'delete':
            projectsUpdateCache(null, data.project.id)
            break
          case 'update':
            projectsUpdateCache(data.project, data.project.id)
            break
        }
      }
    },
    variables: {
      companyId,
      projectId,
    },
  })
