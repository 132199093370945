import { useSubscription } from '@apollo/client'

import {
  FileMetaGroupsSearch,
  FilesGroupsSubscription,
  FilesGroupsSubscriptionVariables,
  SubcribeOnCompanyFilesUpdate,
  SubcribeOnCompanyFilesUpdateVariables,
} from '@ui/types'

import { fileMetaAndGroupCreate, fileMetaAndGroupRemove, fileMetaCreate } from './files.cache'
import { fileMetaGroupSubscription, filesSubscription } from './files.gql'
import { profileUpdateCacheAvatar } from './profile.cache'

export type FilesSubscriptionsTypes = {
  companyId: string
  projectId: string | null
  parentId: string | null
  search?: FileMetaGroupsSearch
}

export const useFilesSubscription = (params: FilesSubscriptionsTypes) =>
  useSubscription<SubcribeOnCompanyFilesUpdate, SubcribeOnCompanyFilesUpdateVariables>(
    filesSubscription,
    {
      onSubscriptionData: (options) => {
        const data = options.subscriptionData.data?.data
        switch (data?.type) {
          case 'create':
            fileMetaCreate(data, params)
            profileUpdateCacheAvatar(data.files)
            break
        }
      },
      variables: {
        companyId: params.companyId,
        projectId: params.projectId,
        parentId: params.parentId || 'root',
      },
    },
  )

export const useFileMetaGroupSubscription = (params: FilesSubscriptionsTypes) =>
  useSubscription<FilesGroupsSubscription, FilesGroupsSubscriptionVariables>(
    fileMetaGroupSubscription,
    {
      onSubscriptionData: (options) => {
        const data = options.subscriptionData.data?.data
        switch (data?.type) {
          case 'create':
            fileMetaAndGroupCreate(data, params)
            break
          case 'delete':
            fileMetaAndGroupRemove(data, params)
            break
        }
      },
      variables: {
        companyId: params.companyId,
        projectId: params.projectId,
        parentId: params.parentId || 'root',
      },
    },
  )
