import React, { Suspense } from 'react'

import { FallbackDefault } from './FallbackDefault'

const WithSuspense = (
  Component: React.LazyExoticComponent<React.ComponentType<any>>,
  Fallback?: React.ComponentType<any>,
) => {
  return (props: any) => (
    <Suspense fallback={Fallback ? <Fallback /> : <FallbackDefault />}>
      {Component ? <Component {...props} /> : <div />}
    </Suspense>
  )
}

WithSuspense.FallbackDefault = FallbackDefault

export { WithSuspense }
