import produce from 'immer'

import { TemplatesAll, TemplatesDeleteByIds } from '@ui/types'

import { gqlClient } from '.'
import { templatesGetAllQuery } from './templates.gql'

export const templatesUpdateCache = (
  params: {
    companyId: string
    projectId: string
    type: string
    createdFor: string
  },
  templates: TemplatesDeleteByIds,
) => {
  const cache = gqlClient.core.cache

  try {
    const data = cache.readQuery<TemplatesAll>({
      query: templatesGetAllQuery,
      variables: {
        companyId: params.companyId,
        data: {
          projectId: params.projectId,
          type: params.type,
          createdFor: params.createdFor,
        },
      },
    })
    if (data) {
      cache.writeQuery({
        query: templatesGetAllQuery,
        variables: {
          companyId: params.companyId,
          data: {
            projectId: params.projectId,
            type: params.type,
            createdFor: params.createdFor,
          },
        },
        data: {
          data: produce(data.data, (draft) => {
            return draft.filter((e) => {
              const element = templates.data?.find((id) => id === e?.uuid)
              return element ? false : true
            })
          }),
        },
      })
    }
  } catch (err) {
    console.error('"templatesUpdateCache" fn is crashed on operation: ".writeQuery"', err)
  }
}
