type ConvertObjectToArrayPrams = { [key: string]: string | Record<string, any> }

export const getDeepValues = (obj: ConvertObjectToArrayPrams): string[] => {
  const result = []
  for (const prop in obj) {
    const value = obj[prop]
    if (typeof value === 'object') {
      result.push(...getDeepValues(value))
    } else {
      result.push(value)
    }
  }
  return result
}
