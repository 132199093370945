import { TreeNode } from '@ui/models'

export const loop = (
  data: TreeNode[],
  key: string,
  callback: (itemTree: TreeNode, i: number, dataTree: TreeNode[]) => void,
) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].key === key) {
      return callback(data[i], i, data)
    }
    if (data[i].children) {
      loop(data[i].children, key, callback)
    }
  }
}
