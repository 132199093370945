import { useSubscription } from '@apollo/client'

import { EditorBlockSubscription } from '@ui/types'

import {
  blockAddCache,
  blockRemoveCache, // blockUpdateCache,
} from './blocks.cache'
import { blocksSubscription } from './blocks.gql'
import { sectionBlocksRemove, sectionBlocksAdd } from './sections.cache'

export const useEditorBlockSubscription = (id: string, projectId: string) =>
  useSubscription<EditorBlockSubscription>(blocksSubscription, {
    onSubscriptionData: (options) => {
      const data = options.subscriptionData.data?.data

      if (data) {
        switch (data.type) {
          case 'delete':
            if (data.block) {
              blockRemoveCache(data.block)
              sectionBlocksRemove(id, data.blocksOrder)
            }
            break
          case 'create':
            if (data.block) {
              blockAddCache(data.block)
              sectionBlocksAdd(id, data.blocksOrder, [data.block])
            }
            break
          case 'update':
            // NOTE write right ID
            // if (data.block) {
            // blockUpdateCache(data.block);
            // }
            break
        }
      }
    },
    variables: {
      sectionId: id,
    },
  })
