import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client'

import {
  FileMetasDeleteById,
  FileMetasDeleteByIdVariables,
  FileMetasDeleteByIds,
  FileMetasDeleteByIdsVariables,
} from '@ui/types'

import { gqlClient } from '.'
import {
  FileMetaGroupAll,
  FileMetaGroupAllVariables,
  FileMetaGroupsSearch,
  FileMetaGroupCreateVariables,
  FileMetaGroupCreate,
  FileMetaGroupUpdateById,
  FileMetaGroupUpdateByIdVariables,
  FileMetaGroupForceDeleteByIdVariables,
  FileMetaGroupForceDeleteById,
  FileMetaGroupDeleteByIdsVariables,
  FileMetaGroupDeleteByIds,
  FileMetaSchema,
  UpsertFileMeta,
  UpsertFileMetaVariables,
  FileMetaAndGroupMove,
  FileMetaAndGroupMoveVariables,
  FileMetaGroupGetById,
  FileMetaGroupGetByIdVariables,
  FileMetaType,
  FileUrlInfo,
  FileUrlInfoVariables,
} from './__generated__/types'
import { fileMetaUpsertUpdateFileList, fileMetaGroupAllQueryRemove } from './files.cache'
import {
  upsertFileMeta,
  fileMetaGroupAllQuery,
  fileMetaGroupCreateMutation,
  fileMetaGroupUpdateByIdMutation,
  fileMetaGroupDeleteByIdsMutation,
  fileMetaGroupForceDeleteByIdMutation,
  fileFragment,
  fileMetaAndGroupMove,
  fileMetaGroupGetByIdQuery,
  getFileUrlInfoQuery,
} from './files.gql'

export * from './files.subscription'

export const useFileMetasDeleteById = () =>
  useMutation<FileMetasDeleteById, FileMetasDeleteByIdVariables>(
    gql`
      mutation FileMetasDeleteById($id: String!) {
        data: fileMetasDeleteById(id: $id)
      }
    `,
    {
      onError: (err) =>
        console.error('"useFileMetasDeleteById" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useFileUrlInfoLazy = (cache?: boolean) =>
  useLazyQuery<FileUrlInfo, FileUrlInfoVariables>(getFileUrlInfoQuery, {
    onError: (err) =>
      console.error('"usefileUrlInfo" fn is crashed on operation: "useLazyQuery"', err),
  })

export const useUpsertFileMeta = (params: FileMetaGroupAllVariables) => {
  return useMutation<UpsertFileMeta, UpsertFileMetaVariables>(upsertFileMeta, {
    update: (cache, { data: item }) => {
      if (item?.data && item.data.status !== 'dead') {
        fileMetaUpsertUpdateFileList(params, item.data)
      }
    },
    onError: (err) =>
      console.error('"useUpsertFileMeta" fn is crashed on operation: "useMutation"', err),
  })
}

export const upsertFileMetaCore = (params: UpsertFileMetaVariables) => {
  return gqlClient.core.mutate({
    mutation: upsertFileMeta,
    variables: params,
  })
}

export const useFileMetasDeleteByIds = () =>
  useMutation<FileMetasDeleteByIds, FileMetasDeleteByIdsVariables>(
    gql`
      mutation FileMetasDeleteByIds(
        $ids: [String!]!
        $companyId: String
        $projectId: String
        $createrId: String
      ) {
        data: fileMetasDeleteByIds(
          ids: $ids
          companyId: $companyId
          projectId: $projectId
          createrId: $createrId
        )
      }
    `,
    {
      onError: (err) =>
        console.error('"useFileMetasDeleteByIds" fn is crashed on operation: "useMutation"', err),
    },
  )

export const getFileByIdFromCache = (id?: string | null) => {
  const cache = gqlClient.core.cache

  return id
    ? cache.readFragment<FileMetaSchema>({
        id: cache.identify({
          __typename: 'FileMeta',
          id: id,
        }),
        fragment: fileFragment,
        fragmentName: 'FileMetaSchema',
      })
    : undefined
}

export const useFileMetaGroupAll = (
  companyId: string,
  search: FileMetaGroupsSearch,
  projectId: string | null,
  parentId: string | null,
) =>
  useLazyQuery<FileMetaGroupAll, FileMetaGroupAllVariables>(fileMetaGroupAllQuery, {
    variables: { companyId, search, parentId },
    // TODO requests too often
    fetchPolicy: 'cache-and-network',
    onError: (err) =>
      console.error('"useFileMetaGroupAll" fn is crashed on operation: "useLazyQuery"', err),
  })

export const useGetFileMetaGroupAll = (
  companyId: string,
  fileMetaGroupId: string | null,
  projectId: string | null,
) =>
  useQuery<FileMetaGroupAll, FileMetaGroupAllVariables>(fileMetaGroupAllQuery, {
    variables: {
      companyId,
      parentId: fileMetaGroupId,
      search: {
        fileMetaType: FileMetaType.companies,
        id: companyId,
        parentId: 'root',
        isArchived: false,
        order: [['createdAt', 'DESC']],
        pagination: { limit: 25, offset: 0 },
      },
    },
    onError: (err) =>
      console.error('"useGetFileMetaGroupAll" fn is crashed on operation: "useQuery"', err),
  })

export const useFileMetaGroupCreate = () =>
  useMutation<FileMetaGroupCreate, FileMetaGroupCreateVariables>(fileMetaGroupCreateMutation, {
    onError: (err) =>
      console.error('"useFileMetaGroupCreate" fn is crashed on operation: "useMutation"', err),
  })

export const useFileMetaGroupUpdateById = () =>
  useMutation<FileMetaGroupUpdateById, FileMetaGroupUpdateByIdVariables>(
    fileMetaGroupUpdateByIdMutation,
    {
      onError: (err) =>
        console.error(
          '"useFileMetaGroupUpdateById" fn is crashed on operation: "useMutation"',
          err,
        ),
    },
  )

export const useFileMetaGroupGetById = () =>
  useLazyQuery<FileMetaGroupGetById, FileMetaGroupGetByIdVariables>(fileMetaGroupGetByIdQuery, {
    onError: (err) =>
      console.error('"useFileMetaGroupGetById" fn is crashed on operation: "useLazyQuery"', err),
  })

export const useFileMetaAndGroupMove = () =>
  useMutation<FileMetaAndGroupMove, FileMetaAndGroupMoveVariables>(fileMetaAndGroupMove, {
    onError: (err) =>
      console.error('"useFileMetaAndGroupMove" fn is crashed on operation: "useMutation"', err),
  })

export const useFileMetaGroupDeleteByIds = (params: FileMetaGroupDeleteByIdsVariables) =>
  useMutation<FileMetaGroupDeleteByIds, FileMetaGroupDeleteByIdsVariables>(
    fileMetaGroupDeleteByIdsMutation,
    {
      update: (_, { data: item }) => {
        const removes = item?.data.removes
        const errors = !item?.data.errors.length
        errors && fileMetaGroupAllQueryRemove(removes, params)
      },
      onError: (err) =>
        console.error(
          '"useFileMetaGroupDeleteByIds" fn is crashed on operation: "useMutation"',
          err,
        ),
    },
  )

export const useFileMetaGroupForceDeleteById = () =>
  useMutation<FileMetaGroupForceDeleteById, FileMetaGroupForceDeleteByIdVariables>(
    fileMetaGroupForceDeleteByIdMutation,
    {
      onError: (err) =>
        console.error(
          '"useFileMetaGroupForceDeleteById" fn is crashed on operation: "useMutation"',
          err,
        ),
    },
  )

export const checkUrlRequest = async (url: string) => {
  try {
    const data = await gqlClient.core.query({
      query: getFileUrlInfoQuery,
      variables: {
        url,
      },
    })

    return data.data.data
  } catch (err) {
    console.error('"checkUrlRequest" fn is crashed on operation: ".query"', err)
  }
}

// export const useFileMetasTypesCountQuery = () =>
//   useLazyQuery<FileMetasTypesCount, FileMetasTypesCountVariables>(
//     fileMetasTypesCountQuery
//   );

// export const useFileMetasAllQuery = (
//   id: string,
//   contentType: string,
//   fileMetaType: FileMetaType
// ) =>
//   useQuery<FileMetasAll, FileMetasAllVariables>(fileMetasAllQuery, {
//     variables: {
//       search: {
//         id,
//         contentType,
//         fileMetaType,
//       },
//     },
//   });
