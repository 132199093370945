import { useLocalStorageState } from '@umijs/hooks'
import { Tooltip } from 'antd'
import cn from 'classnames'
import moment from 'moment'
import React, { ReactElement } from 'react'

import { DATE_NOTIFICATION } from '@ui/utils'

import { useRoutineMaintenance } from 'gql/routineMaintenance.apollo'
import CSCloseNotification from 'icons/CSCloseNotification'
import CSWarning from 'icons/CSWarning'

import s from './RoutineMaintenance.module.scss'

type RoutineMaintenanceProps = {
  children: ReactElement
}

const RoutineMaintenance = ({ children }: RoutineMaintenanceProps) => {
  const [closedId, setClosedId] = useLocalStorageState<string>('routineMaintenanceId')
  const notificationData = useRoutineMaintenance()

  const handleCloseNotification = () => setClosedId(() => notificationData?.id || '')

  const isClosed = !notificationData || closedId === notificationData?.id
  return (
    <div className={s.root}>
      {!isClosed && notificationData && (
        <div className={s.wrapperNotification}>
          <div className={s.wrapperInfo}>
            <CSWarning wrapperClassName={s.warning} />
            <Tooltip
              color='#f2994a'
              mouseEnterDelay={1}
              overlayInnerStyle={{ color: '#fff' }}
              placement='bottom'
              title={notificationData.message}
            >
              <div className={s.textInfo}>{notificationData.message}</div>
            </Tooltip>
            {notificationData.date && (
              <div className={s.dateBlock}>
                <div className={cn(s.separator)} />
                <div>{moment(notificationData.date).locale('ru').format(DATE_NOTIFICATION)}</div>
              </div>
            )}
          </div>
          <div className={s.closeBlock}>
            <CSCloseNotification onClick={handleCloseNotification} />
          </div>
        </div>
      )}
      <div className={cn(s.wrapperChildren, { [s.notificationOpen]: !isClosed })}>{children}</div>
    </div>
  )
}

export default RoutineMaintenance
