import { useMutation, useLazyQuery } from '@apollo/client'

import {
  BuildLastGetByProjectId,
  BuildLastGetByProjectIdVariables,
  BuildScormPackageInit,
  GetProjectExportCurrentSessionId,
  GetProjectExportCurrentSessionIdVariables,
} from '@ui/types'

import { gqlClient } from '.'
import { updateCashAfterStartBuildInit } from './scorm.cache'
import { scormBuild, scormBuildInit, scormCurrentSession } from './scorm.gql'

export * from './scorm.subscription'

export const buildLastGetByProjectId = (variables: BuildLastGetByProjectIdVariables) => {
  gqlClient.core.query<BuildLastGetByProjectId, BuildLastGetByProjectIdVariables>({
    query: scormBuild,
    variables,
    errorPolicy: 'all',
  })
}

export const useBuildLastGetByProjectId = () =>
  useLazyQuery<BuildLastGetByProjectId, BuildLastGetByProjectIdVariables>(scormBuild, {
    fetchPolicy: 'cache-and-network',
    onError: (err) =>
      console.error('"useBuildLastGetByProjectId" fn is crashed on operation: "useLazyQuery"', err),
  })

export const useBuildScormPackageInit = (employeeId: string, projectId: string) =>
  useMutation<BuildScormPackageInit, BuildLastGetByProjectIdVariables>(scormBuildInit, {
    update: (cache, { data: buildInitResponse }) => {
      const isStarted = buildInitResponse?.data.status === 'SCORM_BUILD_INIT_SUCCESS'

      const currentSessionId = buildInitResponse?.data?.currentSession?.id

      isStarted &&
        currentSessionId &&
        updateCashAfterStartBuildInit(employeeId, projectId, true, currentSessionId)
    },
    variables: { employeeId, projectId },
    onError: (err) =>
      console.error('"useBuildScormPackageInit" fn is crashed on operation: "useMutation"', err),
  })

export const useGetProjectExportCurrentSession = (employeeId: string, projectId: string) =>
  useLazyQuery<GetProjectExportCurrentSessionId, GetProjectExportCurrentSessionIdVariables>(
    scormCurrentSession,
    {
      variables: { employeeId, projectId },
      fetchPolicy: 'network-only',
      onError: (err) =>
        console.error(
          '"useGetProjectExportCurrentSession" fn is crashed on operation: "useLazyQuery"',
          err,
        ),
    },
  )
