import { ApolloProvider } from '@apollo/client'
import { PureComponent, ReactElement } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Redirect } from 'react-router'
import { HashRouter as BrowserRouter, Route, Switch } from 'react-router-dom'

import { CustomRoute, Lazy } from '@ui/components'
import { gqlClient } from '@ui/gql'

import RoutineMaintenance from 'components/notifications/RoutineMaintenance'

import i18n from '../i18n/i18n'
import { AuthRoutes } from './auth'
import { ExceptionRoutes } from './exception'
import { PublicsRoutes } from './public'

const AppRoutes = Lazy(
  () => import('./PrivateAppRoutes' /* webpackChunkName:  " private-app-routes " */),
)
const AppRoute = CustomRoute.CheckOrRedirect(() => {
  return !!localStorage.getItem('token')
}, '/auth')

const AuthRoute = CustomRoute.CheckOrRedirect(() => {
  // если инвайт
  // если переход по ссылке из почты
  // то вылогиниваем
  const hash = window.location.hash
  const search = window.location.search
  if (
    (search && search.includes('?t=')) ||
    hash.includes('recovery-password-success') ||
    hash.includes('registration-confirm') ||
    hash.includes('registration-success') ||
    hash.includes('registration-failure')
  ) {
    localStorage.removeItem('token')
  }

  return !localStorage.getItem('token')
}, '/app')

class App extends PureComponent {
  render(): ReactElement {
    return (
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={gqlClient.core}>
          <RoutineMaintenance>
            <BrowserRouter>
              <Switch>
                <Route component={PublicsRoutes} path='/publics-link' />
                <AuthRoute component={AuthRoutes} path='/auth' />
                <AppRoute component={AppRoutes} path='/app' />
                <Redirect from='/' to='auth' exact />
                <Redirect from='/index.html' to='/auth' exact />
                <Route component={ExceptionRoutes} />
              </Switch>
            </BrowserRouter>
          </RoutineMaintenance>
        </ApolloProvider>
      </I18nextProvider>
    )
  }
}
export default App
