import produce from 'immer'

import {
  BuildLastGetByProjectId,
  BuildLastGetByProjectIdVariables,
  GetProjectExportCurrentSessionId,
  GetProjectExportCurrentSessionIdVariables,
  Scorm_data,
} from '@ui/types'

import { gqlClient } from './index'
import { scormBuild, scormCurrentSession } from './scorm.gql'

export const updateBuildLastGetByProjectIdCash = (
  employeeId: string,
  projectId: string,
  newData: Scorm_data,
) => {
  const cache = gqlClient.core.cache

  try {
    const buildState = cache.readQuery<BuildLastGetByProjectId, BuildLastGetByProjectIdVariables>({
      query: scormBuild,
      variables: { employeeId, projectId },
    })

    if (buildState) {
      cache.writeQuery<BuildLastGetByProjectId, BuildLastGetByProjectIdVariables>({
        query: scormBuild,
        variables: { employeeId, projectId },
        data: {
          data: newData,
        },
      })
    }
  } catch (err) {
    console.error(
      '"updateBuildLastGetByProjectIdCash" fn is crashed on operation: ".writeQuery"',
      err,
    )
  }
}

export const updateCashAfterStartBuildInit = (
  employeeId: string,
  projectId: string,
  onBuild: boolean,
  currentSessionId: string,
) => {
  const cache = gqlClient.core.cache

  try {
    const buildState = cache.readQuery<BuildLastGetByProjectId, BuildLastGetByProjectIdVariables>({
      query: scormBuild,
      variables: { employeeId, projectId },
    })

    if (buildState) {
      cache.writeQuery<BuildLastGetByProjectId, BuildLastGetByProjectIdVariables>({
        query: scormBuild,
        variables: { employeeId, projectId },
        data: produce(buildState, (draft) => {
          draft!.data!.onBuild = onBuild
        }),
      })
    }

    const scormSession = cache.readQuery<
      GetProjectExportCurrentSessionId,
      GetProjectExportCurrentSessionIdVariables
    >({
      query: scormCurrentSession,
      variables: { employeeId, projectId },
    })

    if (scormSession) {
      cache.writeQuery<GetProjectExportCurrentSessionId, GetProjectExportCurrentSessionIdVariables>(
        {
          query: scormCurrentSession,
          variables: { employeeId, projectId },
          data: produce(scormSession, (draft) => {
            draft!.data!.currentSession!.id = currentSessionId
          }),
        },
      )
    }
  } catch (err) {
    console.error('"updateCashAfterStartBuildInit" fn is crashed on operation: ".writeQuery"', err)
  }
}
