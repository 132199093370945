import React from 'react'

import { Lazy } from '@ui/components'

export const PublicsRoutes = Lazy(
  () => import('./routes' /* webpackChunkName: "auth-routes" */),
  () => (
    <div>
      <Lazy.Fallback />
    </div>
  ),
)
