import { useSubscription } from '@apollo/client'

import {
  ProjectGroupsSearch,
  ProjectGroupSubscription,
  ProjectGroupSubscriptionVariables,
} from '@ui/types'

import {
  projectsGroupAddCache,
  projectsGroupRemoveCache,
  projectsGroupUpdateCache,
} from './project-groups.cache'
import { projectGroupListSubscription } from './project-groups.gql'

export const useProjectGroupListSubscription = (
  companyId: string,
  search: ProjectGroupsSearch,
  parentId?: string | null,
) =>
  useSubscription<ProjectGroupSubscription, ProjectGroupSubscriptionVariables>(
    projectGroupListSubscription,
    {
      onSubscriptionData: (options) => {
        const data = options.subscriptionData.data?.data
        if (data) {
          switch (data.type) {
            case 'delete':
              if (data.group) {
                projectsGroupRemoveCache(data.group.id, companyId, search, parentId)
              }
              break
            case 'create':
              if (data.group) {
                projectsGroupAddCache(data.group, companyId, search)
              }
              break
            case 'update':
              if (data.group) {
                projectsGroupUpdateCache(data.group, companyId, search, parentId)
              }
              break
          }
        }
      },
      variables: {
        companyId,
        parentId,
      },
    },
  )
