import { useSubscription } from '@apollo/client'

import {
  TaskInputSubscription,
  TasksSearchAllQueryVariables,
  TasksSubscription,
  TasksSubscriptionVariables,
} from './__generated__/types'
import {
  taskAddCache,
  taskAddInBlock,
  taskRemoveCache,
  taskRemoveInBlock,
  taskUpdateInBlock,
} from './tasks.cache'
import { tasksSubscription } from './tasks.gql'

export const useTasksSubscription = (
  search: TasksSearchAllQueryVariables,
  dataIds: TaskInputSubscription,
) =>
  useSubscription<TasksSubscription, TasksSubscriptionVariables>(tasksSubscription, {
    onSubscriptionData: (options) => {
      const data = options.subscriptionData.data?.data
      if (data && data.task) {
        switch (data.type) {
          case 'delete':
            taskRemoveCache(data.task.id, search)
            taskRemoveInBlock(data.task, dataIds.sectionId, dataIds.blockId)
            break
          case 'create':
            taskAddCache(data.task, search)
            taskAddInBlock(data.task, dataIds.sectionId, dataIds.blockId)
            break
          case 'update':
            taskUpdateInBlock(data.task, dataIds.sectionId, dataIds.blockId, search)
            break
        }
      }
    },
    variables: {
      data: dataIds,
    },
  })
