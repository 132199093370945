import { createPortal } from 'react-dom'

export const EDITOR_FORM_PORTAL_ID = 'draggable-editor-form-elements'

type DndPortalType = {
  position: string
  element: JSX.Element
}

export const DndPortal = ({ position, element }: DndPortalType): JSX.Element => {
  if (position === 'fixed') {
    const container = document.getElementById(EDITOR_FORM_PORTAL_ID)
    if (container) {
      return createPortal(element, container)
    }
  }

  return element
}
