const envVars = process.env

const isSecured = location.protocol === 'https:'

const config = {
  io: {
    authEndpoint:
      envVars.REACT_APP_IO_API_APOLLO_ENDPOINT__AUTH ||
      `http${isSecured ? 's' : ''}://${location.host}/auth/graphql`,
    coreEndpoint:
      envVars.REACT_APP_IO_API_APOLLO_ENDPOINT__CORE ||
      `http${isSecured ? 's' : ''}://${location.host}/core/graphql`,
    coreEndpointWs:
      envVars.REACT_APP_IO_API_APOLLO_ENDPOINT__CORE___WS ||
      `ws${isSecured ? 's' : ''}://${location.host}/core/graphql`,
    fileEndpoint:
      envVars.REACT_APP_IO_API_APOLLO_ENDPOINT__FILE ||
      `http${isSecured ? 's' : ''}://${location.host}/store/files`,
    serverStorageEndpoint: envVars.REACT_APP_IO_API_APOLLO_ENDPOINT__SERVER_STORAGE,
    scormEndpoint:
      envVars.REACT_APP_SCORM || `http${isSecured ? 's' : ''}://${location.host}/scorm`,
  },
  default: {
    locale: envVars.REACT_APP_DEFAULT_LOCALE,
    env: envVars.REACT_APP_ENV,
    isDevelopmentOnly: envVars.REACT_APP_ENV === 'local',
    versionBackend: envVars.REACT_APP_VERSION_BACKEND_BUILD,
    versionFrontend: envVars.REACT_APP_VERSION_FRONTEND_BUILD,
  },
}

export { config }
