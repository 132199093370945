// GENERATE BY ./generate.js
// DO NOT EDIT IT MANUALLY
import React, { ReactElement } from 'react'

import './Icons.less'
import { CSIconProps } from './icons.types'
import { ReactComponent as Svg } from './icons/empty-image.svg'

const CSEmptyImage = ({
  iconClassName = '',
  wrapperClassName = '',
  wrapperStyle = {},
  onClick = () => null,
}: CSIconProps): ReactElement => {
  return (
    <span
      className={`anticon custom-icon ${wrapperClassName}`}
      onClick={onClick}
      style={wrapperStyle}
    >
      <Svg className={iconClassName} />
    </span>
  )
}

export default CSEmptyImage
