import { gql } from '@apollo/client'

import { rolesAllFragment } from './roles.gql'

export const permissionListFragment = gql`
  fragment PermissionListSchema on Permission {
    id
    isActive
    createdAt
    updatedAt
    name
    type
  }
`

export const permissionsAll = gql`
  query PermissionsAll {
    data: permissionsAll {
      ...PermissionListSchema
      roles {
        ...RolesAllSchema
      }
    }
  }
  ${permissionListFragment}
  ${rolesAllFragment}
`
