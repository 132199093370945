import { ElementFinalSchemaType } from 'services/Branding/brandObject'

import { EmployeesAll_data } from '@ui/types'

import { BorderSettings } from 'components/form/BorderPicker/BorderPicker'

import { SectionBlockType, SectionType } from '.'
import { ConcordItem, ThreadItem } from './comment.model'
import { Editor } from './editor.model'
import { User } from './user.model'

export type EditorMode = 'view' | 'fill' | 'edit' | 'skeleton'
export type EditorBlockMode = 'test' | 'view' | 'questions' | 'start' | 'end'
// retry | reset | next | result&next | goto
export type EditorBlockSuccessRule = { value: any; points: number }[]

export type EditorBlockTest = {
  /**
   * Время прохождения блока (время на ответ)
   * NOTES: Если задано время на весь тест, то тут дизейблим
   * -----
   * Если время на ответ/тест вышло
   * 1. Редиректим на следующий слайд
   */
  time?: number | null
  /**
   * В случае подсчета правильных ответов процентами - все блоки имеют одинаковый вес, который мы рассчитываем автоматически. В случае подсчетов баллами - для каждого блока автор теста задает его стоимость в баллах. В случае балльной системы можно опционально включить подсчет стоимости по элементам (смотри ниже, в разделе элемент)
   */
  successRules: {
    [key: string]: EditorBlockSuccessRule
  }
  /**
   * Редиректы в случае ответа (в случае перехода по кнопке триггером является кнопка, в случае перехода по событию триггером является изменение состояния элемента)
   */
  onSuccess: {
    /**
     * 1. По-умолчанию на следующий неотвеченный слайд
     * 2. В списке можно выбрать любой доступный слайд
     */
    goToBlockId?: string | null
  } | null
  onFail: {
    /**
     * 1. По-умолчанию на следующий неотвеченный слайд
     * 2. В списке можно выбрать любой доступный слайд
     */
    goToBlockId?: string | null
    /**
     * Количество попыток ответов на слайд
     */
    retry?: number | null
  } | null
  /**
   * Мы должны знать на каком слайде у нас результат
   */
  isResult?: boolean | null
}

export type SectionTestSettings = {
  /**
   * Время на весь тест
   */
  time?: number
  /**
   * Количество попыток пройти тест
   */
  testRetry?: number
  /**
   * Порог прохождения (количество баллов/процентов)
   */
  resultShouldBeGreaterThen?: number
  /**
   * Тип перехода (по кнопке/по событию = блиц-опрос, когда всегда есть только 1  вариант ответа)
   */
  isNextOnSubmit?: boolean
  /**
   * Показывать ответы (да/нет)
   */
  isValidationVisible?: boolean
  /**
   * Тип подсчета (баллы/проценты)
   */
  isResultValuePercent?: boolean
  /**
   * Перемешивать вопросы?
   */
  isShuffleQuestions?: boolean
  /**
   * Перемешивать вопросы?
   */
  isProgressShown?: boolean
  /**
   * Порядок прохождения (можно ли пропускать слайды)
   */
  questionOrder?: 'default' | 'random' | 'branching'
}

export type EditorElementContainerOptions = {
  backgroundColor?: string | undefined
  backgroundImage?: string | undefined
  indents?: number[] | undefined
  x?: number | undefined
  y?: number | undefined
  angle?: number | undefined
  width?: number | undefined
  height?: number | undefined
  border?: BorderSettings
  justifyContent?: string | undefined
  textAlign?:
    | 'right'
    | 'left'
    | 'start'
    | 'end'
    | 'inherit'
    | '-moz-initial'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'center'
    | 'justify'
    | 'match-parent'
    | undefined
  alignItems?: string | undefined
  // autoWidth?: boolean;
  autoHeight?: boolean
  zIndex?: number
  [key: string]: string | number | number[] | boolean | undefined | BorderSettings
}

export type EditorElementOptions = {
  backgroundColor?: string | undefined
  border?: BorderSettings
  height?: string | number | undefined
  width?: string | number | undefined
  backgroundSize?: string | undefined
  borderRadius?: string | undefined
  [key: string]: string | number | undefined | BorderSettings
}

export type EditorBlockOptions = {
  backgroundColor?: string | undefined
  backgroundImage?: string | undefined
  indents?: number[] | undefined
  tabs?: string[]
  tabsPosition?: 'right' | 'left' | 'top' | 'bottom'
  [key: string]: string | number | undefined | string[] | number[]
}

export type Identity<T> = { [P in keyof T]: T[P] }
export type Replace<T, K extends keyof T, TReplace> = Identity<
  Pick<T, Exclude<keyof T, K>> &
    {
      [P in K]: TReplace
    }
>

export type EditorElementError = {
  type: number
  value: string | null
}

export type EditorElementValue = {
  action?: 'start' | 'end' | 'restart' | 'validate' | 'next' | 'prev' | 'validate-and-next'
  [key: string]: any
}

export type EditorElementType =
  | 'text'
  | 'quote'
  | 'ck-text'
  | 'rich-text'
  | 'image'
  | 'image-hotspots'
  | 'video'
  | 'audio'
  | 'code'
  | 'mini-timeline'
  | 'splitter'
  | 'list'
  | 'downloader'
  | 'beautiful-block'
  | 'next-section'
  | 'prev-section'
  | 'editor-radio'
  | 'editor-checkbox'
  | 'editor-radio-group'
  | 'editor-ranking'
  | 'editor-ranking-v2'
  | 'editor-keyboard-input'
  | 'editor-grouping'
  | 'editor-flipping'
  | 'editor-matching'
  | 'editor-submit'
  | 'editor-feedback'
  | 'editor-try-again'
  | 'editor-progress'
  | 'editor-result'
  | 'editor-total-time'
  | 'divider'
  | 'regular-block-image'
  | 'regular-block'
  | 'link'
  | 'idea-note'
  | 'editor-start-test-info'
  | 'editor-end-test-info'
  | 'editor-start'
  | 'editor-end'
  | 'editor-next'
  | 'editor-validate-and-next'
  | 'editor-restart'
  | 'course-finish-button'
  | 'empty'

export type EditorElement = {
  id: string
  options: EditorElementOptions
  type: EditorElementType
  // для того что бы в каждом элементе переопределить
  // Replace<EditorElement, "value", IValue | undefined>
  value?: EditorElementValue | any
  error?: EditorElementError[]
  containerOptions: EditorElementContainerOptions
}

export type EditorBlockType = 'absolute' | 'landing' | 'tabs' | 'slider' | 'collapse'

export type EditorBlock = {
  uuid: string
  type: EditorBlockType
  name: string
  mode: EditorBlockMode
  test?: EditorBlockTest
  elements: {
    [key: string]: EditorElement
  }
  versions: EditorVersionType[]
  currentVersion: number
  schema?: string[][][]
  options: EditorBlockOptions
  createdAt: number
  updatedAt: number
}

export type SectionPickerBlocks = {
  id: string
  type: string
  name: string
  items: SectionBlockType[]
}[]

export type Company = {
  id: string
  logo: string
  color: string
  name: string
  ownerId: string
  isContractor: boolean
  legalId: string
  contracts: Contract[]
  description: string
  createdAt: number
  updatedAt: number
}

// export type User = {
//   id: string;
//   name: string;
//   username: string;
//   avatar: string;
//   email: string;
//   password: string;
//   createdAt: string;
//   updatedAt: string;
// }

export type Employer = {
  id: string
  companyId: string
  isActive: boolean
  user: User
}

export type Format = {
  id: string
  name: string
  description: string
  type: string
}

export type DealFormat = {
  id: string
  cost: number
  unitType: string
  count: number
  formatId: string
}

export type Tag = {
  id: string
  name: string
  parent?: Tag
  color: string
  description: string
  createdAt: number
  updatedAt: number
}

export type Contract = {
  id: string
  number: string
  startDate: number
  endDate: number
  dealFormats: DealFormat[]
  tags: Tag[]
}

export type EditorSection = {
  id: string
  name: string
  isDone: boolean
  description: any
  type: string
  projectId: string
  // TODO: заменить на Employers
  employers: EmployeesAll_data[]
  deadline?: number
  formats: DealFormat[]
  blocks: EditorBlock[]
  blocksOrder: string[]
  totalCost: number
  isArchived: boolean
  createdAt: number
  updatedAt: number
  tags: Tag[]
  test?: SectionTestSettings
  isChapter: boolean
  lvl: number
}

export type EditorChapter = {
  id: string
  name: string
  description: string
  projectId: string
  sections: SectionType[]
  sectionsOrder: string[]
}

export type EditorVersionType = {
  name: string
  date: number
  block: EditorBlock
}

enum activeTabEnum {
  companies = 'companies',
  owner = 'owner',
  projects = 'projects',
}

export type EditorParams = {
  projectId: string
  companyId: string
  sectionId?: string
  blockId?: string
  taskId?: string
  previewSize?: string
  fileMetaGroupId?: string
  activeTab?: activeTabEnum
}

export type EmployeeIdParams = { employeeId?: string }

export type EditorProject = {
  id: string
  categoryId: string
  companyId: string
  name: string
  startDate: number
  endDate: number
  budget: number
  cost: number
  tags: string[] //Tag[];
  accent?: string
  learningGoals: string
  learningObjectives: string
  owner: Editor
  sections: SectionType[]
  publicDescription?: string
  publicImage?: string
  publicAuthorName?: string
  publicAuthorImage?: string
  publicCourseName?: string
  updatedAt: number
  createdAt: number
}

export type EditorTrelloEntity = {
  tasks?: number
  concords: ConcordItem[]
  threads: ThreadItem[]
}

export type EditorTrello = {
  id: string
  projectId: string
  block: {
    [blockId: string]: EditorTrelloEntity
  }
  section: {
    [sectionId: string]: EditorTrelloEntity
  }
  updatedAt: number
  createdAt: number
}

export type EditorElementComponentProps = {
  disabled: boolean
  styles: ElementFinalSchemaType
  elementId: string
  error?: EditorElementError[]
}
